import React from 'react';
import Modal from 'react-bootstrap/Modal';
import EcoProButton from '../../../../shared/button/button';
import CloseIcon from '../../../../../assets/images/close.svg'
import CloseIconMbl from '../../../../../assets/images/hardware-inclusive/close.svg'
import './LearnMorePrinterFeature.css'

const LearnMorePrinterFeature = (props) => {
    const {show, handleClose} = props
    return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal-vw" className="cp-learn-more-modal">
            <div className='ep-modal-wrapper row'>
                <div className='ep-modal-img-wrapper col-lg-8'>
                    <img onClick={()=>handleClose()} className='close-btn-mbl' src={CloseIconMbl} alt="close"/>
                </div>
                <div className='ep-modal-content-wrapper col-lg-4'>
                    <div className='close-btn close-btn-laptop'><img onClick={()=>handleClose()} src={CloseIcon} alt="close"/></div>
                    <h4>Print in A4 & A3 Print</h4>
                    <p>Lorem ipsum dolor sit amet consectetur.  quam sagittis nibh neque platea ultrices arcu. Malesuada ac diam eget ultrices justo quam tempus euismod. Fringilla vel penatibus interdum semper quam dignissim nibh. Consectetur quis nullam vitae at enim ut. Nisl convallis ridiculus a phasellus ridiculus amet sit.</p>
                    <div>A4 Print, Scan & Copy with A3 Print</div>
                    <div>+ £15.00</div>
                    <EcoProButton
                        cname="web-signup, hardware-inclusive-btn"
                        btnID={`1`}
                        >
                        Add Printer Package
                    </EcoProButton>
                </div>
            </div>
        </Modal>
    );
};

export default LearnMorePrinterFeature;