import { call, put, takeEvery } from 'redux-saga/effects';
import { _CSAGENT_API_ENDPOINTS, USER_ACTIVITY_LOGS } from '../../constants/AppConstants';
import { _CALLPUTAPI,_CALLPOSTAPI } from '../../api/apiHandler';
import {
  fetchBOCDeviceConnectionRequest,
  fetchBOCDeviceConnectionSuccess,
  fetchBOCDeviceConnectionFailure
} from './BOCDeviceConnectionServiceSlice';
import UserActivityLogsPayload from '../../utils/AdminPortal/UserActivityLogsPayload';
import { GB_COUNTRY, LOCALSESSIONDATAKEYS, UK_COUNTRY } from "../../constants/AppConstants";
import { extractLangcodeFromDomain } from "../../utils/Internationalization/handleAppInternationalization";
import { getLocalSessionData, setLocalSessionData } from "../../utils/Authentication/handleUserSession";


async function _BOCDeviceConnectionService(serialNumber, country) {
  let countryCodeFromDomain = extractLangcodeFromDomain()
  let countryCodeFromStorage = getLocalSessionData(LOCALSESSIONDATAKEYS.ECOPROCTRYCODE)
  if (countryCodeFromDomain.toUpperCase() === UK_COUNTRY) {
    countryCodeFromDomain = GB_COUNTRY
  }
  const countryCode = countryCodeFromStorage ? countryCodeFromStorage : countryCodeFromDomain.toUpperCase()
  
  const endpoint = _CSAGENT_API_ENDPOINTS.BOC_DEVICE_CONNECTION;
  const logDetails = {
    action: USER_ACTIVITY_LOGS.BOC_DEVICE_SEARCH,
    description: `Search BOC device details with serial number: ${serialNumber}`
  }
  const payload = {
    "servicePortalUser": await UserActivityLogsPayload(logDetails),
    "serialNumber": serialNumber ,
    "country": countryCode 
  }

  return _CALLPOSTAPI(endpoint, payload)
    .then(response => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function* fetchBOCDeviceConnectionSaga(action) {
  let countryCodeFromDomain = extractLangcodeFromDomain()
  let countryCodeFromStorage = getLocalSessionData(LOCALSESSIONDATAKEYS.ECOPROCTRYCODE)
  if (countryCodeFromDomain.toUpperCase() === UK_COUNTRY) {
    countryCodeFromDomain = GB_COUNTRY
  }
  const countryCode = countryCodeFromStorage ? countryCodeFromStorage : countryCodeFromDomain.toUpperCase()
  try {
    const { deviceSerialNumber } = action.payload;
    const response = yield call(_BOCDeviceConnectionService, deviceSerialNumber, countryCode);
    yield put(fetchBOCDeviceConnectionSuccess(response));
  } catch (error) {
    yield put(fetchBOCDeviceConnectionFailure(error.message));
  }
}

export default function* watchFetchBOCDeviceConnectionService() {
  yield takeEvery(fetchBOCDeviceConnectionRequest.type, fetchBOCDeviceConnectionSaga);
}
