import { call, put, takeEvery } from 'redux-saga/effects';
import i18next from "i18next";
import * as actionTypes from '../../../constants/ActionTypes/AdminPortal/UserActivityLogs';
import { _CALLPOSTAPI } from '../../../api/apiHandler'
import { FAILURE, _ADMINISTRATOR_API_ENDPOINTS } from '../../../constants/AppConstants';
import UserActivityLogsPayload from '../../../utils/AdminPortal/UserActivityLogsPayload';
import { setLocalSessionData } from '../../../utils/Authentication/handleUserSession';

async function _POST_USERSESSION_LOGS(logDetails){
    const endpoint = _ADMINISTRATOR_API_ENDPOINTS.USER_ACTIVITY_LOGS.USER_SESSION_LOGS;
    const payload = {
        servicePortalUser: await UserActivityLogsPayload(logDetails)
    }
    return _CALLPOSTAPI(endpoint, payload)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* handleUserSessionLogs(action){
    try {
        const logDetails = {
            action: action.userActivity,
            description: action.description
        }
        const result = yield call(_POST_USERSESSION_LOGS, logDetails);
        if(result.actionStatus.toUpperCase() !== FAILURE) {
            /**
            * This key is stored to avoid triggering the API multiple time
            *  Especially when URL's are opened in new tabs
            */
            setLocalSessionData('USER_SESSION_ACTIVITY_LOGS', true);
            yield put({type: actionTypes.ADMINISTRATOR_USER_SESSION_LOGS_SUCCESS, data: result});
        } else {
            yield put({
                type: actionTypes.ADMINISTRATOR_USER_SESSION_LOGS_ERROR,
                message: i18next.t('Admintranslation:ADMIN_COMMON_ERRORS.UPDATE_ERROR')
            });
        }   
    } catch (e) {
         yield put({type: actionTypes.ADMINISTRATOR_USER_SESSION_LOGS_ERROR, message: i18next.t('Admintranslation:ADMIN_COMMON_ERRORS.TECHNICAL_ERROR')});
    }
}

function* UserSessionLog(){
    yield takeEvery(actionTypes.ADMINISTRATOR_USER_SESSION_LOGS_INIT, handleUserSessionLogs);
}

export default UserSessionLog;