import React from "react";
import { Route } from "react-router-dom";
import CustomerLayout from "../Layouts/CustomerLayout";

const CustomerUnProtectedRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={props => {
            // authorised so return component
            return (
                <CustomerLayout>
                    <Component {...props} />
                </CustomerLayout>
            );
        }} />
    )
}

export default CustomerUnProtectedRoute;