import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ZuoraPaymentInfoRendering, ZuoraPaymentInfoRenderingResetState, fetchPaymentOptions, setPaymentOption } from "../../../actions/MyAccount";
import Loader from "../loader/Loader";
import ErrorPage from "../errorPage/ErrorPage";
import PaymentOptionsAccordian from "./PaymentOptionsAccordian/PaymentOptionsAccordian";
import { extractCountryCode } from "../../../utils/Internationalization/handleAppInternationalization";
import { ZuoraClientLibInit } from "../../../utils/ZuoraPayment/ZuoraPayment";
import { PAYMENT_OPTIONS } from "../../../constants/AppConstants";
import PaymentOptionsTab from "./PaymentOptionsTab/PaymentOptionsTab";
import './SubscriptionPayments.css';
import { Is_HWInclusive_Signup } from "../../../constants/AppMethod";

const SubscriptionPayments = (props) => {
    const { zrenderData, appType = 'mobile', signUpType = 'mobile', paymentMethod = null } = props;
    const dispatch = useDispatch();
    const paymentOptionsResp = useSelector(state => state.PaymentOptions);
    const zrenderParams =  useSelector(state => state.ZuoraPaymentInfoRendering);
    const [isMobile, setIsMobile] = useState(false);
    const IsHWInclusive_Signup = Is_HWInclusive_Signup();

    const checkDeviceSize = useCallback(() => {
        const isMobileSize = window.matchMedia('(max-width: 1023px)').matches;
        setIsMobile(isMobileSize);
        dispatch(ZuoraPaymentInfoRendering((paymentMethod) ? paymentMethod : PAYMENT_OPTIONS.CREDIT_CARD));
    }, [dispatch, paymentMethod]);
    
    useEffect(() => {
        checkDeviceSize();
        if(IsHWInclusive_Signup){
            dispatch(setPaymentOption(PAYMENT_OPTIONS.CREDIT_CARD));
        } else {
            dispatch(fetchPaymentOptions());
        }
    }, [dispatch, checkDeviceSize, IsHWInclusive_Signup]);

    if(paymentOptionsResp.loading){
        return <Loader />
    }
    if(zrenderParams.error){
        return <ErrorPage errorInfo={zrenderParams.error}></ErrorPage>
    }
    if(paymentOptionsResp.error){
        return <ErrorPage errorInfo={paymentOptionsResp.error}></ErrorPage>
    }
    if(Object.keys(zrenderParams.zrenderParams).length > 0){
        const prePopulatingDetails = {
            creditCard_holderName: `${zrenderData.personalDetails.firstName} ${zrenderData.personalDetails.lastName}`,
            country: extractCountryCode(zrenderData.billingAddress.country),
            address: zrenderData.billingAddress.address,
            address2: (zrenderData.billingAddress.addressLine2) ? zrenderData.billingAddress.addressLine2 : "",
            city: zrenderData.billingAddress.city,
            postal_code: zrenderData.billingAddress.postcode,
            phone: (zrenderData.personalDetails.mobileNumber) ? zrenderData.personalDetails.mobileNumber : "",
            email: (zrenderData.personalDetails.emailAddress) ? zrenderData.personalDetails.emailAddress : "",
            fname: (zrenderData.personalDetails.firstName) ? zrenderData.personalDetails.firstName.substring(0, 15) : "",
            lname: (zrenderData.personalDetails.lastName) ? zrenderData.personalDetails.lastName : "",
            state: zrenderData.billingAddress.province || ""
          }
        ZuoraClientLibInit(zrenderParams.zrenderParams, prePopulatingDetails);
        dispatch(ZuoraPaymentInfoRenderingResetState());
    }
    return (
        <>
            { appType === 'web' && <PaymentOptionsTab 
              paymentOptions={paymentOptionsResp} 
              zrenderParams={zrenderParams}
              signUpType={signUpType}
              paymentMethod={paymentMethod}
            /> }
            { appType === 'mobile' 
                && 
                <>
                    { isMobile && <PaymentOptionsAccordian 
                        paymentOptions={paymentOptionsResp} 
                        zrenderParams={zrenderParams}
                        signUpType={signUpType}
                        paymentMethod={paymentMethod}
                      /> 
                    }
                    { !isMobile && <PaymentOptionsTab 
                        paymentOptions={paymentOptionsResp} 
                        zrenderParams={zrenderParams}
                        signUpType={signUpType}
                        paymentMethod={paymentMethod}
                        /> 
                    }

                </>
            }
        </>
    )

}

export default SubscriptionPayments;