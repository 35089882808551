export const GET_PRE_ENROLLMENT_INFO_INIT= "GET_PRE_ENROLLMENT_INFO_INIT"
export const GET_PRE_ENROLLMENT_INFO_SUCCESS= "GET_PRE_ENROLLMENT_INFO_SUCCESS"
export const GET_PRE_ENROLLMENT_INFO_ERROR= "GET_PRE_ENROLLMENT_INFO_ERROR"

export const VERIFY_PRE_ENROLLMENT_INFO_INIT= "VERIFY_PRE_ENROLLMENT_INFO_INIT"
export const VERIFY_PRE_ENROLLMENT_INFO_SUCCESS= "VERIFY_PRE_ENROLLMENT_INFO_SUCCESS"
export const VERIFY_PRE_ENROLLMENT_INFO_ERROR= "VERIFY_PRE_ENROLLMENT_INFO_ERROR"

export const FETCH_USER_PAYMENT_DETAILS_INIT= "FETCH_USER_PAYMENT_DETAILS_INIT"
export const FETCH_USER_PAYMENT_DETAILS_SUCCESS= "FETCH_USER_PAYMENT_DETAILS_SUCCESS"
export const FETCH_USER_PAYMENT_DETAILS_ERROR= "FETCH_USER_PAYMENT_DETAILS_ERROR"

export const RESET_STATE= "RESET_STATE";