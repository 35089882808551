import * as actionTypes from '../constants/ActionTypes/SelectDevice'

export const fetchDeviceList = (UID) => ({
    type: actionTypes.FETCH_DEVICE_LIST,
    UID
});

export const getDeviceCapability = (UID, deviceId, model) => ({
    type: actionTypes.GET_DEVICE_CAPABILITY,
    UID,
    deviceId,
    model
})

export const resetDeviceCapability = () => ({
    type: actionTypes.RESET_DEVICE_CAPABILITY
})

export const resetDeviceList = () => ({
    type: actionTypes.RESET_DEVICE_LIST
})

export const getDeviceRegitration = (UID, deviceId) => ({
    type: actionTypes.GET_DEVICE_REGISTRATION,
    UID,
    deviceId
})