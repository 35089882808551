import { ECOPROTOKEN_CLAIMS, LOCALSESSIONDATAKEYS } from "../../constants/AppConstants";
import { determineLanguageCodeForPayload, truncateAddressLine1Characters } from "../../constants/AppMethod";
import { getUserSessionData, getUserDataByKey, getLocalSessionData } from "../Authentication/handleUserSession";
import TranslatedPlanNames from "../Dashboard/TranslatedPlanNames";

const SubscriptionOrderPayload = async (PaymentMethodId, Subscription) => {
    const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
    const deviceID = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID);;
    const langCode = determineLanguageCodeForPayload();

    const currency = getLocalSessionData(LOCALSESSIONDATAKEYS.CURRENCY);
    const buildCustomerName = () => {
        let displayName = '';
        if(Subscription.accountDetails.personalDetails.firstName){
            displayName = (Subscription.accountDetails.personalDetails.lastName) 
            ?  `${Subscription.accountDetails.personalDetails.firstName} ${Subscription.accountDetails.personalDetails.lastName}` : Subscription.accountDetails.personalDetails.firstName
        } else {
            displayName = (getUserDataByKey('lastName', 'personal_details')) 
            ? `${getUserDataByKey('firstName', 'personal_details')} ${getUserDataByKey('lastName', 'personal_details')}`
            :  getUserDataByKey('firstName', 'personal_details');
        }
        return displayName;
    }
    const customerName = buildCustomerName();
    const billingAddrsDetails = {...Subscription.accountDetails.billingAddress, 
        businessModel: parseInt(getUserDataByKey('businessModel')) || 0,
        vatId: getUserDataByKey('vatId'),
        taxId: getUserDataByKey('taxId'),
        sapBpId: getUserDataByKey('sapBpId')
    }
    const deviceModel = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_MODEL);
    const deviceSerialNo = getLocalSessionData(LOCALSESSIONDATAKEYS.SERIAL_NUMBER);
    const PreEnrollId = getLocalSessionData(LOCALSESSIONDATAKEYS.PRE_ENROLLMENT_ID);
    const truncatedBillingAddressLine1 = truncateAddressLine1Characters(billingAddrsDetails?.address, 0, 15);
    return {
        UserId: UID,
        paymentMethodId: PaymentMethodId,
        Name: customerName,
        email: Subscription.accountDetails.personalDetails.emailAddress || "",
        marketingEmails: Subscription.accountDetails.personalDetails.offers,
        SubscriptionKind: getUserDataByKey("SubscriptionKind"),
	    PreEnrollId: PreEnrollId || null,
        ShippingAddress: {
            FirstName: Subscription.accountDetails.personalDetails.firstName,
            LastName: Subscription.accountDetails.personalDetails.lastName ,
            Street1: Subscription.accountDetails.shippingAddress.address,
            Street2: Subscription.accountDetails.shippingAddress.addressLine2 || "",
            City: Subscription.accountDetails.shippingAddress.city,
            State: Subscription.accountDetails.shippingAddress.province || "",
            StateName: "",
            PostalCode: Subscription.accountDetails.shippingAddress.postcode,
            AddressNumber: "",
            country: Subscription.accountDetails.shippingAddress.country,
            currency:currency,
            companyName: Subscription.accountDetails.shippingAddress.company || "",
            languageCode: langCode
        },
        BillingAddress: {
            FirstName: Subscription.accountDetails.personalDetails.firstName,
            LastName: Subscription.accountDetails.personalDetails.lastName,
            Street1: truncatedBillingAddressLine1,
            Street2: billingAddrsDetails.addressLine2 || "",
            City: billingAddrsDetails.city,
            State: billingAddrsDetails.province || "",
            StateName: "",
            PostalCode: billingAddrsDetails.postcode,
            AddressNumber: "",
            country: billingAddrsDetails.country,
            currency:currency,
            companyName: billingAddrsDetails.company || "",
            businessModel: billingAddrsDetails.businessModel || 0,
            vatId: billingAddrsDetails.vatId || "",
            taxId: billingAddrsDetails.taxId || "",
            sapBpId: billingAddrsDetails.sapBpId || "",
            homePhone: Subscription.accountDetails.personalDetails.telephoneNumber || "",
            mobilePhone: Subscription.accountDetails.personalDetails.mobileNumber,
            languageCode: langCode,
            eInvoiceId: billingAddrsDetails.eInvoiceId || ""
        },
        BillingInfo: {
            signature: Subscription.billingInfo.signature,
            token: Subscription.billingInfo.token,
            tenantId: Subscription.billingInfo.tenantId,
            key: Subscription.billingInfo.key,
            uri: Subscription.billingInfo.url,
            pageId: Subscription.billingInfo.id,
            style: Subscription.billingInfo.style,
            submitEnabled: Subscription.billingInfo.submitEnabled,
            paymentGateway: null,
            countryWhiteList: null
        },
        SelectedPlan: {
            planId: Subscription?.selectedplan?.planId ?? "",
            discountPlanIds: Subscription?.selectedplan?.discountPlanIds ?? null,
            overagePrice: Subscription?.selectedplan?.overagePrice ?? "",
            currency: currency,
            price: Subscription?.selectedplan?.price ?? "",
            name: Subscription?.selectedplan?.name ?? "",
            description: Subscription?.selectedplan?.description ?? "",
            planPages: Subscription?.selectedplan?.planPages ?? "",
            planName: Subscription?.selectedplan?.planName ?? "",
            translatedPlanName: Subscription?.selectedplan?.planName ? await TranslatedPlanNames(langCode, Subscription.selectedplan.planName) : "",
            planGrade: Subscription?.selectedplan?.planGrade ?? "",
            overageBlockPages: Subscription?.selectedplan?.overageBlockPages ?? "",
            overageThreshold: Subscription?.selectedplan?.overageThreshold ?? "",
            rolloverRule: Subscription?.selectedplan?.rolloverRule ?? "",
            planCategory: Subscription?.selectedplan?.planCategory ?? "",
            rollOverPages: Subscription?.selectedplan?.rollOverPages ?? "",
            overageUnit: Subscription?.selectedplan?.overageUnit ?? "",
            discount: Subscription?.selectedplan?.discount ?? null
        },
        Device: {
            deviceId: deviceID,
            model: deviceModel,
            serialNumber: deviceSerialNo
        },
    }
}

export default SubscriptionOrderPayload;