import React from "react";
import { useLocation } from "react-router-dom";
import WebHeader from "../Web/header/WebHeader";
import CustomerPageHeader from "./Customer/CustomerPageHeader";
import { routePath, routePrefix } from "../../../constants/Routes";

function PageHeader({WebHeaderCName = null, isSubscriptionCompletedRoute}) {
  const location = useLocation();
  if(location.pathname.indexOf(routePrefix.WEB_HARDWARE) > -1 ) {
    return <WebHeader role="admin-csagent" cname="fixed-web-view-header-wrapper"/>
  }
  if(location.pathname.indexOf(routePrefix.CSAGENT) > -1 || location.pathname.indexOf(routePrefix.WEB_CUSTOMER) > -1 ) {
    return <WebHeader role="admin-csagent"  isSubscriptionCompletedRoute={isSubscriptionCompletedRoute}/>
  }
  else if(location.pathname === routePath.HOME ){
    return <WebHeader hideUserProfile={true} />
  }
  return <CustomerPageHeader WebHeaderCName={WebHeaderCName} isSubscriptionCompletedRoute={isSubscriptionCompletedRoute}/>
}

export default PageHeader;
