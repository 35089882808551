import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ecopro_logo from "../../../../assets/images/ecopro_logo.svg";
import chevron_left from "../../../../assets/images/chevron_left.svg";
import HamburgerMenu from "../../hamburgerMenu/HamburgerMenu";
import EcoproModal from "../../modal/EcoproModal";
import { checkHamburgerHeaderLocation} from "../../../../constants/AppMethod";
import { adminRoutePaths, routePath } from "../../../../constants/Routes";
import { ReactComponent as ChevronLeft } from "../../../../assets/images/chevron_left.svg";
import './MobileHeader.css';

const MobileHeader = (props) => {
  const { cname = 'none'} = props;
  const location = useLocation();
    const moveToPreviousPage = () => {
        window.history.go(-1);
        return false;
      };
    const EnrollmentResponse =  useSelector(state => state.Enrollment);

    return (
        location.pathname === routePath.BENEFIT_OF_SUPPLIES?
        null
      :
      <div className={`mobile-header-wrapper ${cname}`}>
        <header>
          <img src={ecopro_logo} alt="EcoPro Logo" className="ecopro_logo" />
        </header>
        <div className="header-left" id="mobile-header-left">
          {checkHamburgerHeaderLocation(location.pathname)? (
            <HamburgerMenu />
          ) : (
              location.pathname === routePath.CONFIRM_DEVICE 
              || (location.pathname ===  routePath.PAYMENT && EnrollmentResponse.success)
              || location.pathname === routePath.CANCEL_PLAN_SUCCESS
              || location.pathname === routePath.UPDATE_ACCOUNT_SUCCESS 
              || location.pathname === routePath.CHANGE_DEVICE_SUCCESS 
              || location.pathname === routePath.LEGAL_TNC_BLANK 
              || location.pathname === routePath.USER_PROFILE_UPDATE 
              || location.pathname === routePath.ENROLLMENT_FEEDBACK
              || location.pathname === adminRoutePaths.MAINTENANCE
            )
          ?
          null:
          (
            <ChevronLeft onClick={moveToPreviousPage}
            src={chevron_left}
            alt="move to previous page"
            className="header-chevron-left"/>
          )}
        </div>
        <div className="header-right">
          {checkHamburgerHeaderLocation(location.pathname) ? <EcoproModal role="customer" /> : null}
        </div>
      </div>
  
      
    );
}

export default MobileHeader;