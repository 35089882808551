import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { routePath } from "../constants/Routes";
import CustomerLayout from "../Layouts/CustomerLayout";
import { currentUserRolesArr } from "../constants/AppMethod";
import { ValidateRoles } from "../constants/UserRoles";


const ADB2CCustomerProtectedRoute = ({ component: Component,  ...rest }) => {
    const { instance } = useMsal();
    const accounts = instance.getAllAccounts();
    const isAuthenticated = (accounts.length > 0) ? true : false;
    const currentUserRoles = currentUserRolesArr();
    const isCustomerRole = ValidateRoles(currentUserRoles).isCustomer;
    
    return (
        <Route {...rest} render={props => {
            const data = {"path": props.location.pathname, "isSubscribed": rest.isSubscribed};
            if(!rest.dontTrack){
                sessionStorage.setItem('USER_PATH_HISTORY', JSON.stringify(data));
            }
            if (!isAuthenticated) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: routePath.SHENLONG_REDIRECT, state: { from: props.location } }} />
            }
            if(!isCustomerRole){
                return <Redirect to={{ pathname: routePath.SHENLONG_REDIRECT, state: { from: props.location } }} />
            }
            // authorised so return component
            return(
                <CustomerLayout isSubscribed={rest.isSubscribed}>
                    <Component {...props} />
                </CustomerLayout>
            )
        }} />
    )
}

export default ADB2CCustomerProtectedRoute;