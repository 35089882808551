export const FAQ_FETCH_CATEGORY_INIT = 'FAQ_FETCH_CATEGORY_INIT';
export const FAQ_FETCH_CATEGORY_SUCCESS = 'FAQ_FETCH_CATEGORY_SUCCESS';
export const FAQ_FETCH_CATEGORY_ERROR = 'FAQ_FETCH_CATEGORY_ERROR';

export const FAQ_FETCH_SUBLIST_INIT= 'FAQ_FETCH_SUBLIST_INIT';
export const FAQ_FETCH_SUBLIST_SUCCESS= 'FAQ_FETCH_SUBLIST_SUCCESS';
export const FAQ_FETCH_SUBLIST_ERROR= 'FAQ_FETCH_SUBLIST_ERROR';

export const FAQ_FETCH_CONTENT_INIT= 'FAQ_FETCH_CONTENT_INIT';
export const FAQ_FETCH_CONTENT_SUCCESS= 'FAQ_FETCH_CONTENT_SUCCESS';
export const FAQ_FETCH_CONTENT_ERROR= 'FAQ_FETCH_CONTENT_ERROR';
