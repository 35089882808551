export const GET_MAINTENANCE_SERVICE_STATUS_INIT = 'GET_MAINTENANCE_SERVICE_STATUS_INIT'
export const GET_MAINTENANCE_SERVICE_STATUS_SUCCESS = 'GET_MAINTENANCE_SERVICE_STATUS_SUCCESS'
export const GET_MAINTENANCE_SERVICE_STATUS_FAILURE = 'GET_MAINTENANCE_SERVICE_STATUS_FAILURE'
export const GET_MAINTENANCE_SERVICE_STATUS_RESET  = 'GET_MAINTENANCE_SERVICE_STATUS_RESET'


export const UPDATE_MAINTENANCE_SERVICE_STATUS_INIT = 'UPDATE_MAINTENANCE_SERVICE_STATUS_INIT'
export const UPDATE_MAINTENANCE_SERVICE_STATUS_SUCCESS = 'UPDATE_MAINTENANCE_SERVICE_STATUS_SUCCESS'
export const UPDATE_MAINTENANCE_SERVICE_STATUS_FAILURE = 'UPDATE_MAINTENANCE_SERVICE_STATUS_FAILURE'
export const UPDATE_MAINTENANCE_SERVICE_STATUS_RESET = 'UPDATE_MAINTENANCE_SERVICE_STATUS_RESET'
