import * as actionTypes from "../../../constants/ActionTypes/AdminPortal/PrinterModels";

const initialState = {
    data: {},
    loading: false,
    success: false,
    error: null
}

const PrinterModels_ByModels = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MANAGE_PRINTER_MODELS_BY_MODELS_INIT:
            return {
                ...state,
                loading: true,
                success: false
            }
        
        case actionTypes.MANAGE_PRINTER_MODELS_BY_MODELS_SUCCESS:
            return {
                ...state,
                data: action.data,
                loading: false,
                error: null,
                success: true
            }
        
        case actionTypes.MANAGE_PRINTER_MODELS_BY_MODELS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message,
                success: false,
                data: {}
            }
        case actionTypes.MANAGE_PRINTER_MODELS_RESET_STATE:
            return {
                data: {},
                loading: false,
                success: false,
                error: null
            }
        default:
            return {...state}
    }
}

export default PrinterModels_ByModels