import * as actionTypes from "../../constants/ActionTypes/HardwareInclusive/HardwareInclusive";

const initialState = {
    printerModel: {},
    loading: false,
    error: "",
    success: false,
}

const GetHWPrinterByFeatures = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.GET_HW_PRINTER_BY_FEATURES:
            return {
                ...state,
                loading: true,
                success: false,
                error: "",
                printerModel:{}
            };
        case actionTypes.GET_HW_PRINTER_BY_FEATURES_SUCCESS:
            return {
                ...state,
                printerModel: action.printerModel,
                loading: false,
                success: true,
                error: "",
            };
        case actionTypes.GET_HW_PRINTER_BY_FEATURES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message,
                success: false,
                printerModel:{}
            };
        case actionTypes.GET_HW_PRINTER_BY_FEATURES_RESET:
            return {
                ...state,
                success: false,
                error: "",
                printerModel:{}
            };
        default:
            return {...state};
    }
}

export default GetHWPrinterByFeatures;