import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { fetchDeviceList } from "../../../../../../actions/SelectDevice";
import ErrorPage from "../../../../../../components/shared/errorPage/ErrorPage";
import Loader from "../../../../../../components/shared/loader/Loader";
import { ECOPROTOKEN_CLAIMS } from "../../../../../../constants/AppConstants";
import { routePath } from "../../../../../../constants/Routes";
import { getUserSessionData } from "../../../../../../utils/Authentication/handleUserSession";

const ProcessAddDevice = () => {
    const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
    const deviceListResult = useSelector((state) => state.SelectDevice);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchDeviceList(UID));
         // eslint-disable-next-line
    }, []);
  
    if (deviceListResult.success === true && deviceListResult.deviceList?.devices) {
      const currentPlan = Object.values(deviceListResult.deviceList.devices).filter((val) => {
        return (val.hasOwnProperty('service') && val.service) ? val.service.currentPlan : "";
      })
      if (currentPlan.length > 0) {
        return <Redirect to={routePath.DASHBOARD}></Redirect>
      } else if (deviceListResult.deviceList?.devices.length === 1) {
        return <Redirect to={{ pathname: routePath.CORRECT_DEVICE_FOUND, state: { deviceListResult: deviceListResult }}}></Redirect>
      } else if(deviceListResult.deviceList?.devices.length > 1) {
        return <Redirect to={{ pathname: routePath.MORE_DEVICE_FOUND, state: { deviceListResult: deviceListResult }}}></Redirect>
      } else {
        return <ErrorPage></ErrorPage>
      }
    } else if ( deviceListResult.error ){
      return <ErrorPage></ErrorPage>
    }
    return ( <Loader />);
}

export default ProcessAddDevice;