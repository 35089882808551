import * as actionTypes from '../../constants/ActionTypes/MyAccount';
import { PAYMENT_OPTIONS } from '../../constants/AppConstants';

const initialState = {
    data : {},
    loading: false,
    error: null,
    success: false
}

const CREDIT_CARD_PAYMENT_OPTION = [
    {
        "paymentMethodId": 1,
        "paymentMethodName": "Credit Card"
    }
]

const SEPA_PAYMENT_OPTION = [
    {
        "paymentMethodId": 2,
        "paymentMethodName": "Bank Transfer-SEPA"
    }

]


const PaymentOptions = (state = initialState, action) => {
    switch (action.type){
        case actionTypes.FETCH_PAYMENT_OPTIONS_INIT:
            return {
                ...state,
                loading: true,
            }
        case actionTypes.FETCH_PAYMENT_OPTIONS_SUCCESS:
            return {
                ...state,
                data: action.payment_options,
                error: false,
                loading: false,
                success: true
            }
        case actionTypes.FETCH_PAYMENT_OPTIONS_ERROR:
            return {
                ...state,
                error: action.message,
                loading: false,
                success: false,
                data: {}
            }
        case actionTypes.SET_PAYMENT_OPTIONS_INIT:
            return {
                ...state,
                data: (action.paymentOption === PAYMENT_OPTIONS.CREDIT_CARD) ? CREDIT_CARD_PAYMENT_OPTION : SEPA_PAYMENT_OPTION,
                error: false,
                loading: false,
                success: true
            }
        case actionTypes.RESET_STATE:
            return {
                ...state,
                data : {},
                loading: false,
                error: null,
                success: false,
            }
        default:
            return {...state}
    }
}

export default PaymentOptions;