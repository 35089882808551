import React from "react";
import PageFooter from "../components/shared/footer/PageFooter";
import PageHeader from "../components/shared/header/PageHeader";

const CustomerLayout = ({children, isSubscribed = false}) => {
    return (
        <>
            <PageHeader isSubscriptionCompletedRoute={isSubscribed} WebHeaderCName={(isSubscribed) ? 'post-enrollment-header' : 'pre-enrollment-header'}/>
                <div className={`${(isSubscribed) ? 'post-enrollment-wrapper' : 'pre-enrollment-wrapper'}`}>
                    {children}
                </div>
            <PageFooter cname={(isSubscribed) ? 'post-enrollment-footer' : 'pre-enrollment-footer'}/>
        </>
    )
}

export default CustomerLayout;