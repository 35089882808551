import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorPage from "../../../../../../components/shared/errorPage/ErrorPage";
import Loader from "../../../../../../components/shared/loader/Loader";
import { WEB_handleLocalData, WEB_handleSelectedPlanLocalData } from "../../../../../../utils/Authentication/handleUserSession";
import PaymentSummary from "../../../../../../components/shared/paymentSummary/paymentSummary";
import WebSubHeader from "../../../../../../components/WebSubHeader/WebSubHeader";
import { WebCustomerRoutePaths, WebHardwareInclusiveRoutePath } from "../../../../../../constants/Routes";
import { Redirect, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CallPreEnrollment } from "../../../../../../actions/Web/Customer/SignUp";
import WebSignupProgressBar from "../../../../../../components/WebSubHeader/progressBar/WebSignupProgressBar";
import SubscriptionPayments from "../../../../../../components/shared/SubscriptionPayments/SubscriptionPayments";
import { LOCALSESSIONDATAKEYS } from "../../../../../../constants/AppConstants";
import EcoproAlert from "../../../../../../components/shared/alert/alert";
import ErrorAlertIcon from "../../../../../../assets/images/error-alert.svg";
import { HWInclusive_ManageInstantPaymentReset } from "../../../../../../actions/HardwareInclusive/HardwareInclusive";
import { Is_HWInclusive_Signup } from "../../../../../../constants/AppMethod";
import './Payment.css';

const WebPayment = (props) => {
    const { t } = useTranslation(["translation"]);  
    const dispatch = useDispatch();
    const history = useHistory();
    const MyAccount = useSelector((state) => state.MyAccount);
    const ManageSubscriptionResponse =  useSelector(state => state.ManageSubscription);
    const PreEnrollmentResponse =  useSelector(state => state.PreEnrollment);
    const HWInclusive_handleInstantPaymentResponse = useSelector(state => state.HWInclusive_ManageInstantPayment);
    const selectedPlan = WEB_handleSelectedPlanLocalData('Get');
    const IsHWInclusive_Signup = Is_HWInclusive_Signup();
    const SelectPrinter_RoutePath = (IsHWInclusive_Signup) ? WebHardwareInclusiveRoutePath.SELECT_PRINTER : WebCustomerRoutePaths.SELECT_PRINTER;

    useEffect(() => {
      if (HWInclusive_handleInstantPaymentResponse.error) {
        handleScrollToErrAlert()
      }
      const listenHistory =  history.listen((location, action) => {
        if ((action === 'POP' || action === 'PUSH') && location.pathname === WebCustomerRoutePaths.ACCOUNT) {
          dispatch(HWInclusive_ManageInstantPaymentReset());
        }
      });
      return () => {
        listenHistory();
      };
    }, [HWInclusive_handleInstantPaymentResponse.error, history, dispatch]);

    const handleScrollToErrAlert = () => {
      const alertElement = document.getElementsByClassName('payment-alert-error');
      if (alertElement.length > 0) {
        alertElement[0].scrollIntoView({ behavior: 'smooth' });
      }
    }
    const handleShippingAddressInfo = () => {
      const { shippingAddress} = (IsHWInclusive_Signup) ? HWInclusive_handleInstantPaymentResponse.data : ManageSubscriptionResponse.subscriptionDetails
      WEB_handleLocalData('Set', LOCALSESSIONDATAKEYS.WEB_SHIPPING_ADDRESS, shippingAddress)
    }
    const moveToPreviousPage = () => {
        history.push(WebCustomerRoutePaths.ACCOUNT);
    }

    if( ManageSubscriptionResponse.loading || PreEnrollmentResponse.loading || HWInclusive_handleInstantPaymentResponse.loading){
      return <Loader slowResponse={true}></Loader>
    }
    if(PreEnrollmentResponse.error){
      return <ErrorPage errorInfo={PreEnrollmentResponse.error}></ErrorPage>
    }
    if(ManageSubscriptionResponse.error){
      return <ErrorPage errorInfo={ManageSubscriptionResponse.error}></ErrorPage>
    }
    if(PreEnrollmentResponse.success || HWInclusive_handleInstantPaymentResponse.success){
      handleShippingAddressInfo();
      return <Redirect to={WebCustomerRoutePaths.CONNECT_DEVICE}/>
    }
    if(ManageSubscriptionResponse.success){
      dispatch(CallPreEnrollment(ManageSubscriptionResponse.subscriptionDetails));
    }
    
    return (
        <div className="mt-40 payment-page-outer-wrapper">
          <WebSignupProgressBar activeStep={3} completedSteps = {[1, 2]}/>
          <WebSubHeader 
            currentStep="4"
            totalStep="4"
            stepName={t('SUB_HEADER.STEP_4.STEP_NAME')}
            stepInfo={t('SUB_HEADER.STEP_4.STEP_INFO')}
            moveToPreviousPage={moveToPreviousPage}
            previousBtnText={t('CUSTOMER_WEB.PAYMENT.ACCOUNT_INFO_BACK_NAV_TEXT')}
            stepInfoSub={t("SUB_HEADER.STEP_4.STEP_INFO_SUB")}
            hideStepInfo={true}
            withProgressBar={true}
          />
          <PaymentSummary planDetails={selectedPlan} goTo={SelectPrinter_RoutePath} />
          {
            (HWInclusive_handleInstantPaymentResponse.error) 
            ? <div className="payment-alert-error">
                <EcoproAlert
                  alertImg={ErrorAlertIcon}
                  showAlertDismiss={false}
                  alertHeader= {t("HARDWARE_SIGNUP.PAYMENT_FAILED_ERR_MSG.ALERT_HEADER")}
                  alertSubHeader= {t("HARDWARE_SIGNUP.PAYMENT_FAILED_ERR_MSG.ALERT_CONTENT")}
                  alertClass="dashboard-error"
                ></EcoproAlert>
              </div>
            : null
          }
          <SubscriptionPayments zrenderData={MyAccount.account} appType="web" signUpType="WEB"/>
        </div>
    );
}

export default WebPayment;