import React from "react";
import checkWithBackground from '../../assets/images/checkWithBackground.svg';
import './AddDeviceCard.css'

const AddDeviceCard = (props) => {
    const {device,toSelectDeviceParameter,imagePath,deviceName,deviceInfo,onClickDeviceParameter,imageclassName,noSelection, onRelacementSelection, selectedreplacementDeviceId} = props  
    return (
    <div
      className={`swap-device-card ${!noSelection && "pointer-cursor"} ${
        selectedreplacementDeviceId === toSelectDeviceParameter && !noSelection ? "card-selected" : null
      }`}
      onClick={ () => !noSelection && onRelacementSelection(device, onClickDeviceParameter)}
    >
      <div className="swap-img-info">
        <div className={`${imageclassName?imageclassName:"swap-device-img"} ${imagePath?"":"image-background"}`}>
          <img src={imagePath} alt="device1" />
        </div>
        <div className="swap-device-card-info f-12">
          <div>{deviceName}</div>
          <div>
            {deviceInfo}
          </div>
        </div>
      </div>
      {selectedreplacementDeviceId === toSelectDeviceParameter && !noSelection ? (
        <div className="swap-device-img-check">
          <img
            className="swap-device-img-checked"
            src={checkWithBackground}
            alt="device1"
          />
        </div>
      ) : null}
    </div>
  );
};

export default AddDeviceCard;
