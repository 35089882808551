import * as actionTypes from '../../constants/ActionTypes/Dashboard';

const initialState = {
    downloadOrderInvoice: {},
    loading: false,
    success:false,
    error: false
}

const DownloadOrderInvoice = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.DOWNLOAD_ORDER_INVOICE:
            return {
                ...state,
                loading: true
            }
        case actionTypes.DOWNLOAD_ORDER_INVOICE_SUCCESS:
            return {
                ...state,
                downloadOrderInvoice: action.orderHistory,
                success:true,
                loading: false
            }
        case actionTypes.DOWNLOAD_ORDER_INVOICE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message
            }
        default:
            return {...state};
    }
}

export default DownloadOrderInvoice;