export const FETCH_PRINTER_TYPES = 'FETCH_PRINTER_TYPES'
export const FETCH_PRINTER_TYPES_SUCCESS = 'FETCH_PRINTER_TYPES_SUCCESS'
export const FETCH_PRINTER_TYPES_ERROR = 'FETCH_PRINTER_TYPES_ERROR'


export const FETCH_PRINTER_MODELS = 'FETCH_PRINTER_MODELS'
export const FETCH_PRINTER_MODELS_SUCCESS = 'FETCH_PRINTER_MODELS_SUCCESS'
export const FETCH_PRINTER_MODELS_ERROR = 'FETCH_PRINTER_MODELS_ERROR'

export const FETCH_WEB_SERVICE_PLAN = 'FETCH_WEB_SERVICE_PLAN'
export const FETCH_WEB_SERVICE_PLAN_SUCCESS = 'FETCH_WEB_SERVICE_PLAN_SUCCESS'
export const FETCH_WEB_SERVICE_PLAN_ERROR = 'FETCH_WEB_SERVICE_PLAN_ERROR'

export const UPDATE_ADB2C_NEW_USER_INIT = 'UPDATE_ADB2C_NEW_USER_INIT'
export const UPDATE_ADB2C_NEW_USER_SUCCESS = 'UPDATE_ADB2C_NEW_USER_SUCCESS'
export const UPDATE_ADB2C_NEW_USER_ERROR = 'UPDATE_ADB2C_NEW_USER_ERROR'

export const PRE_ENROLLMENT_INIT = 'PRE_ENROLLMENT_INIT'
export const PRE_ENROLLMENT_SUCCESS = 'PRE_ENROLLMENT_SUCCESS'
export const PRE_ENROLLMENT_ERROR = 'PRE_ENROLLMENT_ERROR'

export const FETCH_USER_SUBSCRIPTION_INFO_INIT= "FETCH_USER_SUBSCRIPTION_INFO_INIT"
export const FETCH_USER_SUBSCRIPTION_INFO_SUCCESS= "FETCH_USER_SUBSCRIPTION_INFO_SUCCESS"
export const FETCH_USER_SUBSCRIPTION_INFO_ERROR= "FETCH_USER_SUBSCRIPTION_INFO_ERROR"

export const GET_USER_BOC_ID_BY_ADB2C_ID_INIT = 'GET_USER_BOC_ID_BY_ADB2CID_INIT'
export const GET_USER_BOC_ID_BY_ADB2C_ID_SUCCESS = 'GET_USER_BOC_ID_BY_ADB2CID_SUCCESS'
export const GET_USER_BOC_ID_BY_ADB2C_ID_ERROR = 'GET_USER_BOC_ID_BY_ADB2CID_ERROR'

export const FETCH_DASHBOARD_PREENROLL_INFO_INIT = 'FETCH_DASHBOARD_PREENROLL_INFO_INIT'
export const FETCH_DASHBOARD_PREENROLL_INFO_SUCCESS = 'FETCH_DASHBOARD_PREENROLL_INFO_SUCCESS'
export const FETCH_DASHBOARD_PREENROLL_INFO_ERROR = 'FETCH_DASHBOARD_PREENROLL_INFO_ERROR'

export const GET_PROMOTION_BANNER_STATUS = 'GET_PROMOTION_BANNER_STATUS'
export const GET_PROMOTION_BANNER_STATUS_SUCCESS = 'GET_PROMOTION_BANNER_STATUS_SUCCESS'
export const GET_PROMOTION_BANNER_STATUS_ERROR = 'GET_PROMOTION_BANNER_STATUS_ERROR'

export const GET_REGISTRATION_TYPE = 'GET_REGISTRATION_TYPE'
export const GET_REGISTRATION_TYPE_SUCCESS = 'GET_REGISTRATION_TYPE_SUCCESS'
export const GET_REGISTRATION_TYPE_ERROR = 'GET_REGISTRATION_TYPE_ERROR'

export const GET_WEB_DEVICE_REGISTRATION = 'GET_WEB_DEVICE_REGISTRATION'
export const GET_WEB_DEVICE_REGISTRATION_SUCCESS = 'GET_WEB_DEVICE_REGISTRATION_SUCCESS'
export const GET_WEB_DEVICE_REGISTRATION_ERROR = 'GET_WEB_DEVICE_REGISTRATION_ERROR'

export const GET_SCMS_TOKEN = 'GET_SCMS_TOKEN'
export const GET_SCMS_TOKEN_SUCCESS = 'GET_SCMS_TOKEN_SUCCESS'
export const GET_SCMS_TOKEN_ERROR = 'GET_SCMS_TOKEN_ERROR'

export const GET_BOC_WEB_DEVICE_CAPABILITY = 'GET_BOC_WEB_DEVICE_CAPABILITY'
export const GET_BOC_WEB_DEVICE_CAPABILITY_SUCCESS = 'GET_BOC_WEB_DEVICE_CAPABILITY_SUCCESS'
export const GET_BOC_WEB_DEVICE_CAPABILITY_ERROR = 'GET_BOC_WEB_DEVICE_CAPABILITY_ERROR'

export const RESET_ONE_TIME_CODE_ERROR = 'RESET_ONE_TIME_CODE_ERROR'
export const RESET_WEB_CONNECTION_API_DATA = 'RESET_WEB_CONNECTION_API_DATA'
export const RESET_CODE_SUBMISSION_DATA = 'RESET_CODE_SUBMISSION_DATA'
