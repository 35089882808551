import { ECOPROTOKEN_CLAIMS, LOCALSESSIONDATAKEYS, READY_PROMOTION } from "../../constants/AppConstants";
import { getLocalSessionData, getUserDataByKey, getUserSessionData } from "../Authentication/handleUserSession";

const VerifyPreEnrollmentPayload = (preEnrollmentInfo) => {
  const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
  const deviceId = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID);
  const model = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_MODEL);
  const { preEnrollId, PlanId, DiscountPlanIds } = preEnrollmentInfo;
  const personalOrBusinessAccount = getUserDataByKey('businessModel')
  return {
    userId: UID || null,
    deviceid: deviceId || null,
    preEnrollId: preEnrollId || null,
    planType: "new",
    model: model || null,
    personalOrBusiness: personalOrBusinessAccount,
    promotions:
      model && model[model.length - 1] === "E"
        ? Array(READY_PROMOTION.promotion)
        : [],
    preEnrollmentDetailsViewModel: {
      PlanId: PlanId || null,
      DiscountPlanIds: DiscountPlanIds || null,
    },
  };
};

export default VerifyPreEnrollmentPayload;