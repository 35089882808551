import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { ValidateRoles } from "../constants/UserRoles";
import { routePath } from "../constants/Routes";
import CSAgentLayout from "../Layouts/CSAgentLayout";
import { currentUserRolesArr } from "../constants/AppMethod";

const ADB2CCSAgentProtectedRoutes = ({ component: Component, ...rest }) => {
    const { instance } = useMsal();
    const accounts = instance.getAllAccounts();
    const isAuthenticated = (accounts.length > 0) ? true : false;
    const currentUserRoles = currentUserRolesArr();
    const isValidCSAgentRoles = ValidateRoles(currentUserRoles).isCSAgent;

    return (
        <Route {...rest} render={props => {
            if (!isAuthenticated) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: routePath.SHENLONG_REDIRECT, state: { from: props.location } }} />
            }
            if(!isValidCSAgentRoles){
                return <Redirect to={{ pathname: routePath.SHENLONG_REDIRECT, state: { from: props.location } }} />
            }
            // authorised so return component
            return(
                <CSAgentLayout>
                    <Component {...props} />
                </CSAgentLayout>
            )
        }} />
    )
}

export default ADB2CCSAgentProtectedRoutes;