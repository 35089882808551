import { getReasonString } from "../../constants/AppMethod";
import { ECOPROTOKEN_CLAIMS, LOCALSESSIONDATAKEYS } from "../../constants/AppConstants";
import { getUserSessionData, getLocalSessionData } from "../Authentication/handleUserSession";

const CancelOrderPayload = (reasonSelection,otherReason, deviceID = null, customerCountryCode = null, uid = null) => {
    const UID = (uid) ? uid : getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
    const device_ID = (deviceID) ? deviceID : getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID);
    const reasonString = getReasonString(reasonSelection);
    const payload =  {
        UserId:UID,
        DeviceId:device_ID,
        ReasonCodes: (reasonString) ? reasonString : "",
        ReasonText: (otherReason && otherReason.length>0) ? otherReason : null
    }
    let finalPayload = payload;
    if(customerCountryCode) {
        finalPayload = {...payload, country: customerCountryCode};
    };
    return finalPayload;
}
export default CancelOrderPayload;