import * as actionTypes from '../../constants/ActionTypes/Dashboard';

const initialState = {
    cancelOrder: {},
    loading: false,
    error: "",
    success:false,
    selectedFeedback:{
        reasonCodes:[],
        otherReason:''
    }
}

const CancelOrder = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.CANCEL_ORDER:
            return {
                ...state,
                loading: true
            }
        case actionTypes.CANCEL_ORDER_SUCCESS:
            return {
                ...state,
                cancelOrder: action.cancelOrder,
                loading: false,
                success:true
            }
        case actionTypes.CANCEL_ORDER_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message
            }
        case actionTypes.STORE_FEEDBACK:
            return {
                ...state,
                selectedFeedback:action.selectedFeedback,
                error: "",
            }
        case actionTypes.RESET_CANCEL_ORDER_AND_FEEDBACK:
            return {
                ...state,
                cancelOrder: {},
                loading: false,
                error: "",
                success:false,
                selectedFeedback:{
                    reasonCodes:[],
                    otherReason:''
                }
            }
        default:
            return {...state};
    }
}

export default CancelOrder;