import { createSlice } from '@reduxjs/toolkit';

const BOCDeviceConnectionServiceSlice = createSlice({
  name: 'BOCDeviceConnectionService',
  initialState: {
    loading: false,
    BOCDeviceConnectionService: null,
    error: null,
  },
  reducers: {
    fetchBOCDeviceConnectionRequest(state) {
      state.loading = true;
      state.error = null;
    },
    fetchBOCDeviceConnectionSuccess(state, action) {
      state.loading = false;
      state.BOCDeviceConnectionService = action.payload;
    },
    fetchBOCDeviceConnectionFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { 
  fetchBOCDeviceConnectionRequest, 
  fetchBOCDeviceConnectionSuccess, 
  fetchBOCDeviceConnectionFailure 
} = BOCDeviceConnectionServiceSlice.actions;

export default BOCDeviceConnectionServiceSlice.reducer;
