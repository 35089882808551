import React, { useContext } from "react";
import "./Accountinfo.css";
import { useTranslation } from "react-i18next";

const EnrolledDeviceInfoContext = React.createContext();

const EnrolledDevice = (props) => {
    const deviceInfo = props;
    return (
        <EnrolledDeviceInfoContext.Provider value={deviceInfo}>
            <EnrolledDeviceDesktopView />
            <EnrolledDeviceMobileView />
        </EnrolledDeviceInfoContext.Provider>
    )
}

const EnrolledDeviceContents = (props) => {
    const { t } = useTranslation(["translation"]);
    const deviceInfo = useContext(EnrolledDeviceInfoContext);
    const { deviceModel, deviceSerialNumber, subscriptionDate, subscriptionDateText} = deviceInfo;
    const { isMobile = false} = props;
    return (
        <div className="enrolled-device-details-contents">
            <div className={`enrolled-device-name ${(isMobile) ? 'space-bottom' : 'no-space'}`}>
                <p className={`device-model ${(isMobile) ? 'mobile-font' : 'no-mobile-font'}`}>{deviceModel}</p>
                <p className="device-serialnumber">{deviceSerialNumber}</p>
            </div>
            <div className={`enrolled-device-date ${(isMobile) ? 'space-bottom' : 'no-space'}`}>
                <p className={`device-date-label ${(isMobile) ? 'mobile-font' : 'no-mobile-font'}`}>
                    {subscriptionDateText}
                </p>
                <p className="device-date">{(subscriptionDate) ? subscriptionDate : 'N/A'}</p>
            </div>
            <div className="enrolled-device-warranty-status-wrapper">
                <p className={`device-warranty-label ${(isMobile) ? 'mobile-font' : 'no-mobile-font'}`}>{t('DASHBOARD.ENROLLED_DEVICE_SECTION.WARRANTY_STATUS')}</p>
                <p className="device-warranty-status">{t('DASHBOARD.ENROLLED_DEVICE_SECTION.ECOPRO_SUBSCRIPTION')}</p>
            </div>
        </div>
    )
}

const EnrolledDeviceDesktopView = () => {
    const { t } = useTranslation(["translation"]);
    const deviceInfo = useContext(EnrolledDeviceInfoContext);
    const { deviceImagePath, deviceStatus, isSwapEligible, moveToSwapDevice, networkStatus} = deviceInfo;
    return (
        <div className="enrolled-device-outer-wrapper show-lg">
            <div className="enrolled-device-wrapper">
                <div className="enrolled-device-image-wrapper">
                    <img src={deviceImagePath} alt="printer"/>
                    {isSwapEligible ?
                        <div className="change-device" onClick={() => moveToSwapDevice()}>{t('DASHBOARD.DISPLAY_DEVICE.CHANGE_DEVICE')}</div>
                        :
                        null
                    }
                </div>
                <div className="enrolled-device-details-wrapper">
                    <div className="enrolled-device-details-header">
                        <h5>{t('DASHBOARD.ENROLLED_DEVICE_SECTION.YOUR_DEVICE')}</h5>
                        <div className="enrolled-device-status-wrapper">
                            {deviceStatus && networkStatus === "online"
                                ?  (
                                        <>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7" fill="none">
                                                <circle cx="3.5" cy="3.5" r="3.5" fill="#00D72B"/>
                                            </svg>
                                            <p>{t('DASHBOARD.ENROLLED_DEVICE_SECTION.ONLINE')}</p>
                                        </>
                                    ) : 
                                    (
                                        <>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7" fill="none">
                                                <circle cx="3.5" cy="3.5" r="3.5" fill="#FF0000"/>
                                            </svg>
                                            <p>{t('DASHBOARD.ENROLLED_DEVICE_SECTION.OFFLINE')}</p>
                                        </>
                                    )
                            }
                        </div>
                    </div>
                    <EnrolledDeviceContents />
                </div>
            </div>
        </div>
    )
}

const EnrolledDeviceMobileView = () => {
    const { t } = useTranslation(["translation"]);
    const deviceInfo = useContext(EnrolledDeviceInfoContext);
    const { deviceImagePath, deviceStatus, isSwapEligible, moveToSwapDevice, networkStatus} = deviceInfo;
    return (
        <div className="enrolled-device-outer-wrapper show-xs">
            <div className="enrolled-device-wrapper">
                <div className="enrolled-device-header-img-wrapper">
                    <div className="enrolled-device-details-header mobile-font">
                            <h5>{t('DASHBOARD.ENROLLED_DEVICE_SECTION.YOUR_DEVICE')}</h5>
                            <div className="enrolled-device-status-wrapper">
                                {deviceStatus && networkStatus === "online"
                                    ?  (
                                            <>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7" fill="none">
                                                    <circle cx="3.5" cy="3.5" r="3.5" fill="#00D72B"/>
                                                </svg>
                                                <p>{t('DASHBOARD.ENROLLED_DEVICE_SECTION.ONLINE')}</p>
                                            </>
                                        ) : 
                                        (
                                            <>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7" fill="none">
                                                    <circle cx="3.5" cy="3.5" r="3.5" fill="#FF0000"/>
                                                </svg>
                                                <p>{t('DASHBOARD.ENROLLED_DEVICE_SECTION.OFFLINE')}</p>
                                            </>
                                        )
                                }
                            </div>
                    </div>
                    <div className="enrolled-device-image-wrapper">
                        <img src={deviceImagePath} alt="printer"/>
                        {isSwapEligible ?
                            <div className="change-device" onClick={() => moveToSwapDevice()}>{t('DASHBOARD.DISPLAY_DEVICE.CHANGE_DEVICE')}</div>
                            :
                            null
                        }
                    </div>
                </div>
                <div className="enrolled-device-details-wrapper">
                    <EnrolledDeviceContents isMobile = {true} />
                </div>
            </div>
        </div>
    )
}

export default EnrolledDevice;