import React from "react";
import { Redirect, Switch } from "react-router-dom/cjs/react-router-dom.min";
import CustomerUnProtectedRoute from "./CustomerUnProtectedRoute";
import { adminRoutePaths } from "../constants/Routes";
import MaintenancePage from "../pages/MaintenancePage/MaintenancePage";
import { AdminProtectedRoutePath } from "./RoutePaths/AdminRoutePath";
import { ADB2CAdminProtectedRouteComp, CustomerUnProtectedRouteComp } from "./RouteWithSubRouteComp/RoutesWithSubRoutes";
import { maintenanceServiceRoutePaths } from "./RoutePaths/CustomerRoutePaths";

const MaintenanceRoutes = () => {
    return (
        <Switch>
            {maintenanceServiceRoutePaths.map((route, i) => (
                <CustomerUnProtectedRouteComp key={i} {...route} />
            ))}
            {AdminProtectedRoutePath.map((route, i) => (
                <ADB2CAdminProtectedRouteComp key={i} {...route} />
            ))}
            <CustomerUnProtectedRoute exact path={adminRoutePaths.MAINTENANCE} component={MaintenancePage}/>
            <Redirect to={adminRoutePaths.MAINTENANCE}></Redirect>
        </Switch>
    )
}

export default MaintenanceRoutes;