import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { GetCarbonSaving } from '../../actions/CarbonSaving';
import DisplayCarbonOffsetting from '../../components/CarbonOffsetting/DisplayCarbonOffsetting';
import { routePath } from '../../constants/Routes';
import ErrorPage from '../../components/shared/errorPage/ErrorPage';
import Loader from '../../components/shared/loader/Loader';
import { ECOPROTOKEN_CLAIMS, refreshBtn, tryAgainBtn } from '../../constants/AppConstants';
import { getUserSessionData } from '../../utils/Authentication/handleUserSession';

const CarbonOffsetting = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const getCarbonSavingResult = useSelector(state=>state.CarbonSaving)
    const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID)
    const onHowToCalculate =()=>{
        history.push(routePath.HOW_CO2_CALCULATED)
    }
    useEffect(()=>{
        dispatch(GetCarbonSaving(UID))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    if (getCarbonSavingResult.loading) {
        return <Loader></Loader>;
    }
    if(getCarbonSavingResult.error){
        return <ErrorPage errorInfo={getCarbonSavingResult.error} showRefreshBtn={refreshBtn.HIDE} showTryAgain={tryAgainBtn.SHOW} to="/carbon-offsetting"></ErrorPage>
    }
    return (
        <React.Fragment>
            {getCarbonSavingResult.carbonSaving &&
            <DisplayCarbonOffsetting onHowToCalculate={()=>onHowToCalculate()}
            carbonSavings = {getCarbonSavingResult.carbonSaving} />}
        </React.Fragment>
        
    );
};

export default CarbonOffsetting;