import { MSALInstance } from "./MSALInstanceInit";
import { loginRequest } from "./AuthConfig";

export function retrieveAzureAuthToken(currentAuthAccount){
  return MSALInstance.acquireTokenSilent({
    ...loginRequest,
    account: currentAuthAccount
  })
}

