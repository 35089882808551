import * as actionTypes from "../../constants/ActionTypes/AdminPortal/Settings";

export const fetchMaintenanceServiceStatus = () => ({
  type: actionTypes.GET_MAINTENANCE_SERVICE_STATUS_INIT  
})

export const updateMaintenanceServiceStatus = (isServiceEnabled) => ({
    type: actionTypes.UPDATE_MAINTENANCE_SERVICE_STATUS_INIT,
    isServiceEnabled
})

export const ResetMaintenanceServiceStatus_FETCH = () => ({
  type: actionTypes.GET_MAINTENANCE_SERVICE_STATUS_RESET
})

export const ResetMaintenanceServiceStatus_UPDATE = () => ({
  type: actionTypes.UPDATE_MAINTENANCE_SERVICE_STATUS_RESET
})