import React, { useState } from "react";
import './Settings.css';
import AdminFormCheckSwitchType from "../../../components/AdminPortal/Shared/FormElements/FormCheckSwitchType/FormCheckSwitchType";
import EcoProButton from "../../../components/shared/button/button";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ResetMaintenanceServiceStatus_FETCH, ResetMaintenanceServiceStatus_UPDATE, fetchMaintenanceServiceStatus, updateMaintenanceServiceStatus } from "../../../actions/AdminPortal/Settings";
import { useSelector } from "react-redux";
import Loader from "../../../components/shared/loader/Loader";
import { useEffect } from "react";
import AdminAlert from "../../../components/AdminPortal/AdminAlert/AdminAlert";

const Settings = () => {
    const { t } = useTranslation("Admintranslation");
    const dispatch = useDispatch();
    const [maintenanceServiceSettings, setMaintenanceServiceSettings] = useState({'maintenanceStatus': false, 'maintenanceSaveBtnStatus': true});
    const maintenanceServiceStatus = useSelector(state => state.ADMIN.MaintenanceServiceStatus_FETCH)
    const maintenanceServiceStatus_UpdateData = useSelector(state => state.ADMIN.MaintenanceServiceStatus_UPDATE)
    useEffect(() => {
        dispatch(fetchMaintenanceServiceStatus());
    }, [dispatch])
   
    const handleCheckboxChange = () => {
        setMaintenanceServiceSettings({...maintenanceServiceSettings,
            'maintenanceStatus': !maintenanceServiceSettings.maintenanceStatus,
            'maintenanceSaveBtnStatus': false
        });
    }
    const handleMaintenanceServiceSubmit = () => {
        dispatch(updateMaintenanceServiceStatus(maintenanceServiceSettings.maintenanceStatus));
        setMaintenanceServiceSettings({...maintenanceServiceSettings,
            'maintenanceSaveBtnStatus': true,
        });
    }
    if(maintenanceServiceStatus.success){
        setMaintenanceServiceSettings({...maintenanceServiceSettings,
            'maintenanceStatus': maintenanceServiceStatus.data,
        });
        dispatch(ResetMaintenanceServiceStatus_FETCH());
        dispatch(ResetMaintenanceServiceStatus_UPDATE());
    }
    return (
        <>
            { maintenanceServiceStatus.loading && <Loader setOverlay={true}></Loader> }
            { maintenanceServiceStatus.error && <AdminAlert alert_variant="alert-danger" isVisible={true}>{maintenanceServiceStatus.error}</AdminAlert>}
            { maintenanceServiceStatus_UpdateData.error && <AdminAlert alert_variant="alert-danger" isVisible={true}>{maintenanceServiceStatus_UpdateData.error}</AdminAlert>}
            { maintenanceServiceStatus_UpdateData.success && <AdminAlert alert_variant="alert-success" isVisible={true}>{t("ADMIN_COMMON_SUCCESS_MSG.UPDATE_SUCCESS")}</AdminAlert>}
            <div className="settings-wrapper card">
                <div className="settings-page-card-header card-header">
                        <h1>{t('SETTINGS.HEADER')}</h1>
                </div>
                <div className="settings-page-card-body card-body">
                    <div className="admin-settings-row-wrapper row">
                        <div className="col-6 maintenance-page-setting-wrapper">
                            <p>{t('SETTINGS.MAINTENANCE_PAGE')}</p>
                            <AdminFormCheckSwitchType 
                                inputID = "maintenance-page-setting"
                                inputName = "maintenance-page-setting"
                                labelText = {t('SETTINGS.ENABLE')}
                                onChange={() => handleCheckboxChange()}
                                isChecked={maintenanceServiceSettings.maintenanceStatus}
                            />
                            <EcoProButton cname="default maintenance-page-save-btn" onBtnClick={() => handleMaintenanceServiceSubmit()} disabled={maintenanceServiceSettings.maintenanceSaveBtnStatus}>{t('BTN_TEXTS.SAVE')}</EcoProButton>
                            { maintenanceServiceStatus_UpdateData.loading && <div className="form-input-spinner-wrapper">
                                <div class="spinner-border" role="status"></div>
                            </div> }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Settings;