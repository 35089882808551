import * as actionTypes from '../../../constants/ActionTypes/Web/Customer/SignUp';

const initialState = {
    data: null,
    loading: false,
    error: false,
    success: false
  }

const GetUserBOCIdByADB2CId = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_USER_BOC_ID_BY_ADB2C_ID_INIT:
            return {
                ...state,
                loading: true,
                error: false,
                data: null,
                success: false
            }
        
        case actionTypes.GET_USER_BOC_ID_BY_ADB2C_ID_SUCCESS:
            return {
                ...state,
                data: action.userData,
                loading: false,
                error: false,
                success: true
            }
        
        case actionTypes.GET_USER_BOC_ID_BY_ADB2C_ID_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message,
                data: null,
                success: false
            }
        default:
            return {...state}
    }
}

export default GetUserBOCIdByADB2CId;