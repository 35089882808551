import * as actionTypes from '../../../constants/ActionTypes/Web/Customer/SignUp';

const initialState = {
    'success': false,
    'loading': false,
    'error': false,
    'data': {}
}

const GetDeviceRegistration = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.GET_WEB_DEVICE_REGISTRATION:
            return {
                ...state,
                loading: true,
                'success': false,
                'error': false,
                'data': {}
            }
        case actionTypes.GET_WEB_DEVICE_REGISTRATION_SUCCESS:
            return {
                ...state,
                success: true,
                loading: false,
                data: action.deviceRegistration,
                'error': false
            }
        case actionTypes.GET_WEB_DEVICE_REGISTRATION_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.message,
            }
        case actionTypes.RESET_ONE_TIME_CODE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: false,
            }
            
        case actionTypes.RESET_WEB_CONNECTION_API_DATA:
            return {
                ...state,
                'success': false,
                'loading': false,
                'error': false,
                'data': {}
            }
        case actionTypes.RESET_CODE_SUBMISSION_DATA:
            return {
                ...state,
                'success': false,
                'loading': false,
                'error': false,
                'data': {}
            }
            
        default:
            return {...state};
    }
}

export default GetDeviceRegistration;