import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { adminRoutePaths} from "../../constants/Routes";
import { useDispatch } from "react-redux";
import { logUserSessionActivities } from "../../actions/AdminPortal/UserActivityLogs";
import { LOCALSESSIONDATAKEYS, USER_ACTIVITY_LOGS } from "../../constants/AppConstants";
import { getLocalSessionData } from "../../utils/Authentication/handleUserSession";

const AdminRedirection = () => {
    const dispatch = useDispatch();
    const isSessionLogTriggered = getLocalSessionData(LOCALSESSIONDATAKEYS.USER_SESSION_ACTIVITY_LOGS);
    useEffect(() => {
        if(!isSessionLogTriggered){
            dispatch(logUserSessionActivities(USER_ACTIVITY_LOGS.LOGIN, 'LOGIN'));
        }
    }, [dispatch, isSessionLogTriggered])
    return(
        <Redirect to={adminRoutePaths.DASHBOARD}/>
    )
}

export default AdminRedirection;