import { adminRoutePaths } from "../../constants/Routes"

const NavItems = [
    {
        id: 'feature-switch',
        icon: "",
        text: "Feature Switch",
        link: "#",
        sub_menus: [
            {
                id: 'by-country',
                icon: "",
                text: "Manage By Country",
                link: adminRoutePaths.FEATURE_SWITCH.BY_COUNTRY,
            },
            {
                id: 'by-feature',
                icon: "",
                text: "Manage By Feature",
                link: adminRoutePaths.FEATURE_SWITCH.BY_FEATURE,
            }
        ]
    },{
        id: 'printer-models',
        icon: "",
        text: "Printer Models",
        link: "#",
        sub_menus: [
            {
                id: 'printer-models-by-country',
                icon: "",
                text: "Manage By Country",
                link: adminRoutePaths.PRINTER_MODELS.BY_COUNTRY,
            },
            {
                id: 'printer-models-by-feature',
                icon: "",
                text: "Manage By Printer Models",
                link: adminRoutePaths.PRINTER_MODELS.BY_MODELS,
            }
        ]
    }, {
        id: 'admin-users',
        icon: "",
        text: "Manage Admin Users",
        link: adminRoutePaths.ADMIN_USERS.VIEW_USERS
    }, {
        id: 'invoice-posting',
        icon: "",
        text: "Italian Tax Data",
        link: adminRoutePaths.RETRY_INVOICE_POSTING
    },{
        id: 'ecopro-settings',
        icon: "",
        text: "Settings",
        link: adminRoutePaths.SETTINGS 
    }
]

export default NavItems