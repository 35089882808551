import * as actionTypes from "../../constants/ActionTypes/AdminPortal/FeatureSwitch";

export const fetchFeatureListByCountry = (countryCode) =>({
    type: actionTypes.MANAGE_FEATURE_SWITCH_BY_COUNTRY_INIT,
    countryCode
})

export const fetchCountryListByFeature = (featureName) => ({
    type: actionTypes.MANAGE_FEATURE_SWITCH_BY_FEATURE_INIT,
    featureName
})

export const updateFeatureSwitch = (payload, actionDescription) =>({
    type: actionTypes.UPDATE_FEATURE_SWITCH_INIT,
    payload,
    actionDescription
})

export const featureSwitchResetState = () => ({
    type: actionTypes.FEATURE_SWITCH_RESET_STATE
})

export const updateFeatureSwitchStateReset = () => ({
    type: actionTypes.UPDATE_FEATURE_SWITCH_RESET_STATE
})