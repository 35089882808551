import './FormCheck.css';

function FormCheck(props) {
  const {
    formContent,
    label, 
    type = 'checkbox', 
    name,
    placeholder,
    id,
    reverse,
    handleCheck = () =>{},
    required,
    errorMsg,
    web
  } = props;
  
  return (
    <div className={`form-check ${reverse === true && "rtl"}`}>
      <label htmlFor={id} className={`${!reverse && "form-check-label"} f-12 fc-g ${reverse === true && "frtl"}`}>{label}</label>
      <input
        type={type}
        className={`ecopro-form-check form-check-input ${reverse === true && "check-reverse"} ${web && "web-form-check-input"}`}
        id={id}
        aria-describedby="emailHelp"
        name={name}
        value={formContent[name]}
        onChange={handleCheck}
        checked={formContent[name]}
        placeholder={placeholder}
        required={required}
        />
        <div className="invalid-feedback">{errorMsg}</div>
    </div>
  )
}

export default FormCheck;