import React from "react";
import { useTranslation } from "react-i18next";
import DisplayUpdateAccountConfirmation from "../../../components/EditMyAccount/DisplayUpdateAccountConfirmation/DisplayUpdateAccountConfirmation";
import checkWithBackground from '../../../assets/images/checkWithBackground.svg'
import ErrorPage from "../../../components/shared/errorPage/ErrorPage";
import { useHistory } from "react-router-dom";
import { routePath } from "../../../constants/Routes";

const SwapDeviceSuccess = (props) => {
    const { t } = useTranslation(["translation"]);
    const history = useHistory()
    const changeDeviceConfirmed = () => {
        history.push(routePath.DASHBOARD)
    }
  return (
    props.location.state && props.location.state.cancellationsuccess === true?
    <div>
      <DisplayUpdateAccountConfirmation
        updateAddressHeader={t("SWAP_DEVICE.SUCCESS_SWAP_DEVICE.HEADER")}
        updateAddressInfo={t("SWAP_DEVICE.SUCCESS_SWAP_DEVICE.INFO")}
        updateAddressBtn={t("EDIT_FORM.UPDATE_ACCOUNT_BTN.CONTINUE")}
        onUpdateClick={() => changeDeviceConfirmed()}
        image={checkWithBackground}
      ></DisplayUpdateAccountConfirmation>
    </div>
     :
     <ErrorPage errorInfo={t("ERROR_CONTENT.ERR_INFO.SUBSCRIPTION_REQUIRED")} />
    );
};

export default SwapDeviceSuccess;
