import * as actionTypes from '../../constants/ActionTypes/Dashboard';

const initialState = {
    loading:false,
    error:'',
    updateShipping:{},
    success:false
  }

const UpdateShipping = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_SHIPPING_ADDR:
            return {
                ...state,
                loading: true
            }
        
        case actionTypes.UPDATE_SHIPPING_ADDR_SUCCESS:
            return {
                ...state,
                updateShipping: action.updateShippingSuccess,
                loading: false,
                success:true
            }
        
        case actionTypes.UPDATE_SHIPPING_ADDR_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message
            }
        
        case actionTypes.RESET_STATE:
            return {
                ...state,
                error:''
            }
        default:
            return {...state}
    }
}

export default UpdateShipping