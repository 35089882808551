import { call, put, takeEvery } from 'redux-saga/effects';
import { _CALLGETAPI } from '../../api/apiHandler';
import * as actionTypes from '../../constants/ActionTypes/HardwareInclusive/HardwareInclusive';
import { SUCCESS, _HARDWARE_API_ENDPOINTS } from '../../constants/AppConstants';

function _GETHWSERVICEPLAN(model){
    const endpoint = _HARDWARE_API_ENDPOINTS.GET_HW_SERVICE_PLAN
    let payload;

    if(!model){
        payload = {
            params: {
                planType: 'new',
                deviceOption: "purchase", //for hardwareInclusive
                includeHiddenPlans: "false"
            }
        } 
    }
    else{
        payload = {
            params: {
                planType: 'new',
                deviceOption: "purchase", //for hardwareInclusive
                includeHiddenPlans: "false",                     
                model: model, 
            }
        }
    }
    return _CALLGETAPI(endpoint, payload)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* handleHWServicePlan(action){
    try{
        const model = action.model

            const servicePlan = yield call(_GETHWSERVICEPLAN, model);
            if(servicePlan.actionStatus.toUpperCase() === SUCCESS){
                yield put({type: actionTypes.GET_HW_SERVICE_PLAN_SUCCESS, servicePlan: servicePlan});
            } else {
                yield put({type: actionTypes.GET_HW_SERVICE_PLAN_ERROR, servicePlan: servicePlan});
            }
    }catch(e){
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
        if(e instanceof TypeError === false && e.message !== "401"){
            yield put({type: actionTypes.GET_HW_SERVICE_PLAN_ERROR, message: e.message});
        }
    }
}

function* GetHWServicePlan(){
    yield takeEvery(actionTypes.GET_HW_SERVICE_PLAN, handleHWServicePlan);
}

export default GetHWServicePlan;