import { call, put, takeEvery } from 'redux-saga/effects';
import i18next from "i18next";
import * as actionTypes from '../../constants/ActionTypes/Dashboard';
import { _CALLGETAPI } from '../../api/apiHandler';
import { _API_ENDPOINT, FAILURE } from '../../constants/AppConstants';

function _FETCHORDERHISTORY(UID){
   const endpoint = _API_ENDPOINT.ORDER_HISTORY;

    const payload = {
        params: {
            UserId: UID,
            count: 0
        }
      }
    return _CALLGETAPI(endpoint, payload )
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}
function* handleOrderHistory(action){
 try{
    const UID = action.UID;
    if(UID){
        const orderHistory = yield call(_FETCHORDERHISTORY, UID);
        if(orderHistory.actionStatus.toUpperCase() !== FAILURE) {
            yield put({type: actionTypes.DASHBOARD_ORDER_HISTORY_CALL_SUCCESS, orderHistory: orderHistory});
        } else {
            yield put({type: actionTypes.DASHBOARD_ORDER_HISTORY_CALL_ERROR, message: i18next.t("ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR")});
        }
    } else {
        yield put({
            type: actionTypes.DASHBOARD_ORDER_HISTORY_CALL_ERROR, 
            message: i18next.t('ERROR_CONTENT.INFO_GENERAL')
        });
    }
 } catch(e){
     /**
     * This conditional statement is added to avoid
     * displaying error message in view while logout from app
     */
    if(e instanceof TypeError === false && e.message !== "401"){
        yield put({type: actionTypes.DASHBOARD_ORDER_HISTORY_CALL_ERROR, message: e.message});
    }
 }
}
function* DashboardOrderHistory(){
    yield takeEvery(actionTypes.DASHBOARD_ORDER_HISTORY_CALL_INIT, handleOrderHistory);
}

export default DashboardOrderHistory;