import React, { useEffect, useRef, useState } from 'react';
import './CustomizeYourPrinter.css';
import DisplayPrinter from '../../../../../../components/Web/HardwareSignUp/CustomizePrinter/DisplayPrinter/DisplayPrinter';
import Container from "reactstrap/es/Container";
import EcoproLogo from "../../../../../../assets/images/teaser_ecopro_logo.png";
import { useTranslation } from 'react-i18next';
import DisplayMonthlyPageAllowance from '../../../../../../components/Web/HardwareSignUp/CustomizePrinter/DisplaySupplyPlan/MonthlyPageAllowance/DisplayMonthlyPageAllowance';
import DisplayPlanLength from '../../../../../../components/Web/HardwareSignUp/CustomizePrinter/DisplaySupplyPlan/PlanLength/DisplayPlanLength';
import DisplayTotalAmount from '../../../../../../components/Web/HardwareSignUp/CustomizePrinter/DisplayAmountAndOrderPrinter/DisplayTotalAmount/DisplayTotalAmount';
import DisplayOrderYourPrinter from '../../../../../../components/Web/HardwareSignUp/CustomizePrinter/DisplayAmountAndOrderPrinter/DisplayOrderYourPrinter/DisplayOrderYourPrinter';
import { filterPlanDetails, getCPPrinterHeader, getCPPrinterSubHeader, getHardwarePlan, getPlanFromPlanName, getPrinterFeatures, getUniquePlanLengths } from '../../../../../../utils/HardwareInclusive/HardwareInclusive';
import { useDispatch, useSelector } from 'react-redux';
import DisplayHardwareType from '../../../../../../components/Web/HardwareSignUp/CustomizePrinter/DisplayHardwarePlan/DisplayHardwareType/DisplayHardwareType';
import DisplayTypeOfPrinting from '../../../../../../components/Web/HardwareSignUp/CustomizePrinter/DisplayHardwarePlan/DisplayTypeOfPrinting/DisplayTypeOfPrinting';
import DisplayBasePackage from '../../../../../../components/Web/HardwareSignUp/CustomizePrinter/DisplayHardwarePlan/DisplayBasePackage/DisplayBasePackage';
import { GetHardwareInclusivePrinterByFeatures, GetHardwareInclusiveServicePlan, resetHWPrinterByFeature, resetHWPrinterFeatureByType, resetHWServicePlan } from '../../../../../../actions/HardwareInclusive/HardwareInclusive';
import Loader from '../../../../../../components/shared/loader/Loader';
import { WEB_handleSelectedPlanLocalData, setLocalSessionData } from '../../../../../../utils/Authentication/handleUserSession';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { WebCustomerRoutePaths, WebHardwareInclusiveRoutePath } from '../../../../../../constants/Routes';
import { LOCALSESSIONDATAKEYS, SIGNUPTYPE, tryAgainBtn } from '../../../../../../constants/AppConstants';
import { formatNumberOnLocale } from '../../../../../../constants/AppMethod';
import ErrorPage from '../../../../../../components/shared/errorPage/ErrorPage';
import { ReactComponent as ChevronLeft } from "../../../../../../assets/images/chevron_left.svg";
import currencies from '../../../../../../constants/CurrencyTypes';


const CustomizeYourPrinter = (props) => {
    const printerFeaturesByPrinterTypeResult = useSelector((state)=>state.GetHWPrinterFeaturesByPrinterType)
    const printerFeaturesByTypeResult = printerFeaturesByPrinterTypeResult?.printerFeatures?.printerTypeFeatures
    const servicePlanHWResult = useSelector((state) => state.GetHWServicePlan)
    const servicePlansHWOnLoad = servicePlanHWResult?.servicePlan?.products
    const printerByFeaturesResult = useSelector((state) => state.GetHWPrinterByFeatures)

    const dispatch = useDispatch()
    // @@@@@@@@TODO 
    //const [addExtraTitle, setAddExtraTitle] = useState([])
    const {t} = useTranslation(['translation'])
    const printerTypeId = props?.location?.state?.printerTypeId
    const printerType = props?.location?.state?.printerType
    const [printerImage, setPrinterImage] = useState([]);
    const [servicePlansHW, setServicePlanHW] = useState(servicePlansHWOnLoad)
    const [printerModel, setPrinterModel] = useState(getHardwarePlan(servicePlansHW, printerTypeId)?.model)
    const [montlhyPrice, setMonthlyPrice] = useState("")
    const [planLengths, setPlanLengths] = useState([])
    const [hardwarePlan, setHardwarePlan] = useState({})
    const [selections, setSelections] = useState({
        device:printerType,
        hardwareType: "",
        typeOfPrinting: "",
        basePackage: "",
        monthlyPageAllowance: "",
        planLength: "",
    });
    const history = useHistory()
    const initialServicePlansHW = useRef(servicePlansHW);

    const handleSelectionChange = (key, value) => {
        setSelections(prevSelections => ({
            ...prevSelections,
            [key]: value,
        }));

        if(key === "basePackage"){
            dispatch(GetHardwareInclusivePrinterByFeatures(printerTypeId, "2", value))
        }
        if(key === "monthlyPageAllowance"){
            setMonthlyPrice(getPlanFromPlanName(servicePlansHW, value, printerTypeId)?.price)
        }
    };

    useEffect(() => { 
        if (printerFeaturesByTypeResult && printerTypeId) {
            const basePackage = getPrinterFeatures(printerFeaturesByTypeResult, printerTypeId);
            const defaultBasePackage = basePackage && basePackage.length > 0 ? basePackage[0].featureId : "";
            dispatch(GetHardwareInclusivePrinterByFeatures(printerTypeId, "2", defaultBasePackage));
            
            const filteredPlan = filterPlanDetails(initialServicePlansHW.current, printerTypeId)
            const monthlyPageAllowance = filteredPlan
            const defaultMonthlyPageAllownace = monthlyPageAllowance && monthlyPageAllowance.length > 0 ? monthlyPageAllowance[0]?.planPages : "";
            const defaultMonthlyPrice = monthlyPageAllowance && monthlyPageAllowance.length > 0 ? monthlyPageAllowance[0]?.price : "";
            setMonthlyPrice(defaultMonthlyPrice)
            
            const defaultHardwareType = "Refurbished";
            const defaultPlanLength = getUniquePlanLengths(filteredPlan)[0];
            const defaultTypeOfPrinting = "Mono";
            
            setSelections(prevSelections => ({
                ...prevSelections,
                hardwareType: defaultHardwareType,
                planLength: defaultPlanLength,
                basePackage: defaultBasePackage,
                monthlyPageAllowance: defaultMonthlyPageAllownace,
            }));
            
            if (printerTypeId?.toString() === "2") {
                setSelections(prevSelections => ({
                    ...prevSelections,
                    typeOfPrinting: defaultTypeOfPrinting
                }));
            }
        }
    }, [dispatch, printerFeaturesByTypeResult, printerTypeId]);
    
    useEffect(() => {
        if (printerByFeaturesResult?.success) {
            setPrinterImage(printerByFeaturesResult?.printerModel?.printer?.deviceGallery);
            const model = printerByFeaturesResult?.printerModel?.printer?.printerModel
            setPrinterModel(model)
            dispatch(GetHardwareInclusiveServicePlan(model))
        }
         // eslint-disable-next-line
    }, [printerByFeaturesResult?.success]);

    useEffect(()=>{
        if(servicePlanHWResult?.success && printerByFeaturesResult?.success){
            const updatedServicePlans = servicePlanHWResult?.servicePlan?.products;
            setServicePlanHW(updatedServicePlans);

            const filteredPlans = filterPlanDetails(updatedServicePlans, printerTypeId);
            const uniquePlanLengths = getUniquePlanLengths(filteredPlans);
            const hardwarePlan = getHardwarePlan(updatedServicePlans, printerTypeId)
            setPlanLengths(uniquePlanLengths);
            setHardwarePlan(hardwarePlan)
        }
         // eslint-disable-next-line
    },[servicePlanHWResult?.success, printerByFeaturesResult?.success])

    //@@@@@@@@@@@@ TODO
    // const onAddExtraFeature = (extraTitle) => {
    //     if(addExtraTitle.includes(extraTitle)){
    //         setAddExtraTitle(addExtraTitle.filter(item => item !== extraTitle))
    //     }
    //     else{
    //         setAddExtraTitle([...addExtraTitle,extraTitle])
    //     }
    // }


    const handleCheckout = () => {
        const selectedPlanDetails = getPlanFromPlanName(servicePlansHW, selections?.monthlyPageAllowance, printerTypeId)
        const basePlanAndHWPlan = {...selectedPlanDetails, 'HWPlans': hardwarePlan};
        WEB_handleSelectedPlanLocalData("Set", basePlanAndHWPlan);
        setLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_MODEL, printerModel);
        setLocalSessionData(LOCALSESSIONDATAKEYS.CURRENCY,selectedPlanDetails?.currency)
        history.push({pathname: WebCustomerRoutePaths.WEB_REDIRECT, state:{'signupType': SIGNUPTYPE.HARDWARE_INCLUSIVE}});
    }

    if(!printerFeaturesByPrinterTypeResult.success){
       return <ErrorPage errorInfo={t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.ERROR_PRINTER_TYPE_NOT_SELECTED")} showTryAgain={tryAgainBtn.SHOW} to={`${WebHardwareInclusiveRoutePath.SELECT_PRINTER}?fieldtestpass=a3230b5f-cf13-5883-90f6-256bf540ae03&request-id=c46afda9-f417-5563-b543-9a7d245ec161`}></ErrorPage>
    }

    const moveToPreviousPage = () => {
        dispatch(resetHWServicePlan())
        dispatch(resetHWPrinterFeatureByType())
        dispatch(resetHWPrinterByFeature())
        window.history.go(-1);
        return false;
    };
    
    return (
        <Container className='customize-printer-variable-width'>
            <div className="page-title-back-btn" onClick={()=>moveToPreviousPage()}>
                <ChevronLeft fill="#545454" />
                <div className="f-12 fw-b back-btn-text">{t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.PRINTER_TYPE_SELECTION")}</div>
            </div>
        {(printerByFeaturesResult?.loading || servicePlanHWResult?.loading) && 
            <div className={`loader-overlay-wrapper`}>
                <Loader setOverlay={true}></Loader>
            </div>}
        <header className='customize-printer-header-wrapper cp-header-mbl'>
            <img src={EcoproLogo} alt="Ecopro logo"/>
            <div className='customize-printer-header'>{getCPPrinterHeader(printerTypeId)}</div>
            <div className='customize-printer-subheader'>{getCPPrinterSubHeader(printerTypeId)}</div>
        </header>
        <section className='customize-printer1'>
            <div className='customize-printer-image-wrapper1'>
                    <DisplayPrinter
                        printerModel={printerModel}
                        printerTypeId={printerTypeId}
                        printerImage={printerImage}
                    ></DisplayPrinter>
            </div>
            <div className='customize-printer-features-wrapper1'>
                <header className='customize-printer-header cp-header-laptop'>
                    <img src={EcoproLogo} alt="Ecopro logo"/>
                    <div className='customize-printer-header'>{getCPPrinterHeader(printerTypeId)}</div>
                    <div className='customize-printer-subheader'>{getCPPrinterSubHeader(printerTypeId)}</div>
                </header>
                <div className='cp-printer-feature-wrapper'>
                    <div className='cp-hardware-plan-header'>{t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.HARDWARE_PLAN")}</div>
                    <div className='cp-hardware-plan-border'></div>
                    <DisplayHardwareType
                        handleSelectionChange = {(value) => handleSelectionChange('hardwareType', value)}
                        currency={hardwarePlan?.currency}
                        selectedData={selections?.hardwareType}
                        dueTodayPrice = {formatNumberOnLocale(hardwarePlan?.price, currencies[hardwarePlan?.currency]?.code)}
                    ></DisplayHardwareType>

                    {printerTypeId.toString() === "2" ?
                    <DisplayTypeOfPrinting
                        handleSelectionChange = {(value) => handleSelectionChange('typeOfPrinting', value)}
                        selectedData={selections.typeOfPrinting}
                        currency={hardwarePlan?.currency}
                    ></DisplayTypeOfPrinting>: null}

                    <DisplayBasePackage 
                        printerFeatures={getPrinterFeatures(printerFeaturesByTypeResult, printerTypeId)}
                        handleSelectionChange = {(value) => handleSelectionChange('basePackage', value)}
                        selectedData={selections?.basePackage}
                        printerType={printerType}
                    ></DisplayBasePackage>

                    {/* @@@@@@@@@@@@@@@@ TO DO ->  */}
                    {/* <DisplayExtraTitle
                        addExtraTitle = {addExtraTitle}
                        onAddExtraFeature = {onAddExtraFeature}
                    ></DisplayExtraTitle> */}
                    {/* @@@@@@@@@@@@@@@@@@@@@@@@@@ */}
                </div>
                <div className='customize-printer-supply-plan'>
                    <DisplayMonthlyPageAllowance 
                        handleSelectionChange = {(value) => handleSelectionChange('monthlyPageAllowance', value)}
                        selectedData={selections.monthlyPageAllowance}
                        servicePlans={filterPlanDetails(servicePlansHW, printerTypeId)}
                    ></DisplayMonthlyPageAllowance>
                    <DisplayPlanLength
                        handleSelectionChange = {(value) => handleSelectionChange('planLength', value)}
                        selectedData={selections.planLength}
                        planLengths={planLengths}
                    ></DisplayPlanLength>
                </div>
                <div className='cp-order-printer'>
                    <DisplayTotalAmount
                        featureSelections={selections}
                        printerTypeId={printerTypeId}
                        monthlyPaymentPrice = {formatNumberOnLocale(montlhyPrice, currencies[hardwarePlan?.currency]?.code)}
                        dueTodayPrice = {formatNumberOnLocale(hardwarePlan?.price, currencies[hardwarePlan?.currency]?.code)}
                        //currency = {hardwarePlan?.currency}
                    ></DisplayTotalAmount>
                    <DisplayOrderYourPrinter
                        printerTypeId={printerTypeId}
                        handleCheckout={handleCheckout}
                    ></DisplayOrderYourPrinter>
                </div>
            </div>
        </section>
        </Container>
    );
};

export default CustomizeYourPrinter;