import { call, put, takeEvery } from "redux-saga/effects";
import * as actionTypes from '../../../constants/ActionTypes/AdminPortal/InvoicePosting';
import i18next from "i18next";
import { SUCCESS, _ADMINISTRATOR_API_ENDPOINTS } from "../../../constants/AppConstants";
import { _CALLGETAPI } from "../../../api/apiHandler";

function _VIEWINVOICES(taxDataType){
    const endpoint = _ADMINISTRATOR_API_ENDPOINTS.TESI_INVOICE_POSTING.VIEW_INVOICES;
  
    const payload = {
        params: {
            taxDataType: taxDataType
        }
      }
    return _CALLGETAPI(endpoint, payload)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}
function* handleError(taxType, msg = null){
    yield put({
        type: actionTypes.GET_TESI_INVOICES_ERROR,
        message: (msg) ? msg : (taxType === 'Invoice') 
        ? i18next.t("Admintranslation:MANAGE_TESI_INVOICE_POSTING.NO_RECORDS_FOUND_FOR_INVOICE")
        : i18next.t("Admintranslation:MANAGE_TESI_INVOICE_POSTING.NO_RECORDS_FOUND_FOR_CREDIT_MEMO")
    })
}

function* handleInfo(action){
    try{
        const result = yield call(_VIEWINVOICES, action.taxDataType);
        if(result.actionStatus.toUpperCase() === SUCCESS){
            yield put({type: actionTypes.GET_TESI_INVOICES_SUCCESS, data: result.fileProperties});
        } else {
            yield* handleError(action.taxDataType);
        }
    } catch (e) {
        yield* handleError(action.taxDataType);
    }
}

function* TESIInvoice_View(){
    yield takeEvery(actionTypes.GET_TESI_INVOICES_INIT, handleInfo);
}
export default TESIInvoice_View;