import * as actionTypes from "../../constants/ActionTypes/AdminPortal/InvoicePosting";

export const fetchTESIInvoices = (taxDataType) => ({
    type: actionTypes.GET_TESI_INVOICES_INIT,
    taxDataType
})

export const fetchInvoicesReset = () => ({
    type: actionTypes.GET_TESI_INVOICES_RESET
})

export const retryInvoice = (filename, taxDataType) => ({
    type: actionTypes.RETRY_INVOICE_INIT,
    filename,
    taxDataType
})

export const retryInvoiceReset = () => ({
    type: actionTypes.RETRY_INVOICE_RESET
})