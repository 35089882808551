import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../../constants/ActionTypes/MyAccount';
import { _CALLPOSTAPI } from '../../api/apiHandler'
import { SUBSCRIPTION_TYPE, _API_ENDPOINT, SUCCESS } from '../../constants/AppConstants';
import SubscriptionOrderPayload from '../../utils/MyAccount/SubscriptionOrderPayload';
import i18next from 'i18next';
import UpdateBillingAddressPayload from '../../utils/MyAccount/UpdateBillingAddressPayload';
import UpdatePaymentDetailsPayload from '../../utils/MyAccount/UpdatePaymentDetailsPayload';
import UpdateCustomerProfilePayload from '../../utils/MyAccount/UpdateCustomerProfilePayload';

function _MANAGESUBSCRIPTIONCALL(payload){
    const endpoint = _API_ENDPOINT.SUBSCRIPTION_MANAGE;
    return _CALLPOSTAPI(endpoint, payload )
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    }); 
}

function* handleManageSubscription(action){
    try{
        let payload;
        switch (action.subscriptionType) {
            case SUBSCRIPTION_TYPE.UPDATE_BILLING:
                payload = UpdateBillingAddressPayload(action.subscriptionPayload);
                break;
            case SUBSCRIPTION_TYPE.UPDATE_PAYMENT:
                payload = UpdatePaymentDetailsPayload(action.paymentId, action.subscriptionPayload);
                break;
            case SUBSCRIPTION_TYPE.UPDATE_CUSTOMER_PROFILE:
                payload = UpdateCustomerProfilePayload(action.subscriptionPayload);
                break;
            default:
                payload = yield SubscriptionOrderPayload(action.paymentId, action.subscriptionPayload);
                break;
        }
        const subscriptionDetails = yield call(_MANAGESUBSCRIPTIONCALL, payload);
        if(subscriptionDetails.actionStatus.toUpperCase() === SUCCESS){
            yield put({type: actionTypes.ACCOUNT_MANAGE_SUBSCRIPTION_SUCCESS, subscriptionDetails: subscriptionDetails})        
        }
        else{
            yield put({type: actionTypes.ACCOUNT_MANAGE_SUBSCRIPTION_ERROR, message: i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')});
        }
    } catch(e) {
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
        if(e instanceof TypeError === false && e.message !== "401"){
            yield put({type: actionTypes.ACCOUNT_MANAGE_SUBSCRIPTION_ERROR, message: e.message});
        }
    }
}

function* ManageSubscription(){
    yield takeEvery(actionTypes.ACCOUNT_MANAGE_SUBSCRIPTION_INIT, handleManageSubscription)
}

export { _MANAGESUBSCRIPTIONCALL };
export default ManageSubscription;