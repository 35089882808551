import React from "react";
import { Container } from "reactstrap";
import PageHeader from "../../../components/AdminPortal/Shared/PageHeader/PageHeader";
import PrinterModelsSwitch from "../../../components/AdminPortal/PrinterModels/ModelsSwitch";
import { useTranslation } from "react-i18next";
import './PrinterModels.css';
import { useSelector } from "react-redux";
import Loader from "../../../components/shared/loader/Loader";
import AdminAlert from "../../../components/AdminPortal/AdminAlert/AdminAlert";

const PrinterModelsManageModels = () => {
    const { t } = useTranslation("Admintranslation");
    let modelsDropdownOptions;
    const printerModelsList = useSelector(state => state.PrinterModels);
    const properties = {
        featureSwitchType: 'PRINTER_MODELS',
        selectOptionLabel: t('MANAGE_PRINTER_MODELS.SELECT_OPTION_LABEL_PRINTER_MODEL'),
        selectOptionElemName: 'printerModel',
        displayedListTitle: t('MANAGE_PRINTER_MODELS.LIST_TITLE_FOR_MODELS'),
    }
        if(Object.keys(printerModelsList.models).length > 0){
            modelsDropdownOptions = printerModelsList.models.map((data) =>
                <option key={data.printerId} value={data.printerId}>
                    {data.model}
                </option>
            );
        }
    return (
        <Container>
            { printerModelsList.loading && <Loader setOverlay={true} />}
            <div className="feature-switch-manage-country-header-container">
                <PageHeader pageTitle={t('MANAGE_PRINTER_MODELS.PAGE_TITLE')} pageSubTitle={t('MANAGE_PRINTER_MODELS.PAGESUBTITLE_PRINTER_MODELS')}/>
            </div>
            { Object.keys(printerModelsList.models).length > 0 && <AdminAlert alert_variant="alert-success">{t('MANAGE_PRINTER_MODELS.MODEL_LIST_FETCH_SUCCESS_MSG')}</AdminAlert> }
            { printerModelsList.error && <AdminAlert alert_variant="alert-danger">{printerModelsList.error}</AdminAlert> }
            <PrinterModelsSwitch properties={properties} countryDropdownOptions={modelsDropdownOptions}/>
        </Container>
    );
}

export default PrinterModelsManageModels;