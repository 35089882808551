import { call, put, takeEvery } from "redux-saga/effects";
import i18next from "i18next";
import * as actionTypes from "../../constants/ActionTypes/Dashboard";
import { _CALLGETAPI } from "../../api/apiHandler";
import { FAILURE, WEB_CUSTOMER_API_ENDPOINT } from "../../constants/AppConstants";

function _GETUSERSUBSCRIPTION(UID, deviceID) {
  const endpoint = WEB_CUSTOMER_API_ENDPOINT.GET_USER_SUBSCRIPTION;
  const payload = {
    params: {
      UserId: UID,
      deviceId: deviceID,
    },
  };
  return _CALLGETAPI(endpoint, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function* getUserSubscription(action) {
  try {
    const deviceID = action.deviceID;
    const UID = action.UID;
    if(UID && deviceID){
      const userSubscription = yield call(
        _GETUSERSUBSCRIPTION,
        UID,
        deviceID
      );
      if(userSubscription.actionStatus.toUpperCase() !== FAILURE){
        yield put({
          type: actionTypes.GET_USER_SUBSCRIPTION_SUCCESS,
          userSubscription: userSubscription,
        });
      }else{
        yield put({
          type: actionTypes.GET_USER_SUBSCRIPTION_ERROR,
          message: i18next.t('ERROR_CONTENT.ERR_INFO.SUBSCRIPTION_REQUIRED'),
        });
      }
    } else {
      yield put({
        type: actionTypes.GET_USER_SUBSCRIPTION_ERROR,
        message: i18next.t('ERROR_CONTENT.INFO_GENERAL')
      });
    }
  } catch (e) {
    /**
     * This conditional statement is added to avoid
     * displaying error message in view while logout from app
     */
    if(e instanceof TypeError === false && e.message !== "401"){
      yield put({
        type: actionTypes.GET_USER_SUBSCRIPTION_ERROR,
        message: e.message,
      });
    }
  }
}

function* GetUserSubscription() {
  yield takeEvery(actionTypes.GET_USER_SUBSCRIPTION, getUserSubscription);
}

export default GetUserSubscription;
