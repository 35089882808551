import * as actionTypes from '../../constants/ActionTypes/MyAccount';

const initialState = {
    zrenderParams : {},
    loading: false,
    error: null,
}

const ZuoraPaymentInfoRendering = (state = initialState, action) => {
    switch (action.type){
        case actionTypes.ACCOUNT_ZBILLING_INFO_RENDERING_INIT:
            return {
                ...state,
                loading: true,
            }
        case actionTypes.ACCOUNT_ZBILLING_INFO_RENDERING_SUCCESS:
            return {
                ...state,
                zrenderParams: action.zrenderParams,
                error: false,
                loading: false,
            }
        case actionTypes.ACCOUNT_ZBILLING_INFO_RENDERING_ERROR:
            return {
                ...state,
                error: action.message,
                loading: false,
            }
        case actionTypes.ZUORA_PAYMENT_INFO_RENDERING_RESET_STATE:
            return {
                ...state,
                zrenderParams : {},
                loading: false,
                error: null,
            }
        default:
            return {...state}
    }
}

export default ZuoraPaymentInfoRendering;