import * as actionTypes from '../../constants/ActionTypes/SelectDevice';

const initialState = {
    deviceRegistration: {},
    loading: false,
    error: null,
    success:false
  }

const DeviceRegistration = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_DEVICE_REGISTRATION:
            return {
                ...state,
                loading: true,
                success: false
            }
        
        case actionTypes.GET_DEVICE_REGISTRATION_SUCCESS:
            return {
                ...state,
                deviceRegistration: action.deviceRegistration,
                loading: false,
                success: true
            }
        
        case actionTypes.GET_DEVICE_REGISTRATION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message,
                success: false
            }
        
        default:
            return {...state}
    }
}

export default DeviceRegistration