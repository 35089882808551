import React from "react";
import MobileFooter from "../../Mobile/footer/MobileFooter";
import "./WebFooter.css";

const WebFooter = ({customClassName}) => {
       return (
        <MobileFooter footerID="web-footer" customClassName={customClassName}></MobileFooter>
    )
} 

export default WebFooter;