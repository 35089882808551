import React from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import ecopro_logo from "../../../../assets/images/ecopro_logo.svg";
// import bell_icon from "../../../../assets/images/admin-portal/bell-icon.svg";
// import settings_icon from "../../../../assets/images/admin-portal/settings-icon.svg";
import "./WebHeader.css";
import EcoproModal from "../../modal/EcoproModal";
import HamburgerMenuDesktopApp from "../../hamburgerMenuDesktopApp/hamburgerMenuDesktopApp";
import EcoProButton from "../../button/button";
import { adminRoutePaths, routePrefix } from "../../../../constants/Routes";
import { currentUserRolesArr } from "../../../../constants/AppMethod";
import { userRoles } from "../../../../constants/UserRoles";

const WebHeader = (props) => {
  const { cname = 'none', hideUserProfile, role = null, isSubscriptionCompletedRoute } = props;
  const isAuthenticated = useIsAuthenticated();
  const currentuserRoles = currentUserRolesArr();
  return (
    <div className={`web-view-header-wrapper ${cname}`}>
      <div className="web-header-outer-wrapper">
        <div className="web-view-header-container"></div>
        <div className="header-left">
          <img src={ecopro_logo} alt="EcoPro Logo" className="ecopro_logo" />
        </div>
        <div className="header-right">
          {/* @todo Will be enabled once functional
          <div className="bell-icon space-between">
              <img src={bell_icon} alt="notification"/>
          </div>
          <div className="settings-icon space-between">
              <img src={settings_icon} alt="settings"/>
          </div> */}
          {
            currentuserRoles.indexOf(userRoles.ADMIN) > -1
            && window.location.pathname.indexOf(routePrefix.CSAGENT) > -1
            && <EcoProButton cname="default csagent-portal-admin-link" type="link" redirect_URL={adminRoutePaths.DASHBOARD}>Admin Service</EcoProButton> 
          }
          {hideUserProfile ? null : 
            isAuthenticated && <EcoproModal role={role} /> 
          }
        </div>
      </div>
       { isAuthenticated &&  isSubscriptionCompletedRoute && <HamburgerMenuDesktopApp /> }
    </div>
  );
};

export default WebHeader;
