import React from "react";
import {AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";
import Loader from "../../components/shared/loader/Loader";
import VerifyUserAccount from "../Web/Customer/Account/SignUp/VerifyUserAccount";


const WebHome = () => {
    return (
        <>  <UnauthenticatedTemplate>
                <Loader />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <VerifyUserAccount />
            </AuthenticatedTemplate>
        </>
    )
}

export default WebHome;