import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from "../../../constants/ActionTypes/Web/Customer/SignUp";
import { _CALLPUTAPI } from '../../../api/apiHandler';
import { LOCALSESSIONDATAKEYS, WEB_CUSTOMER_API_ENDPOINT } from '../../../constants/AppConstants';
import { getLocalSessionData } from '../../../utils/Authentication/handleUserSession';
import { determineLanguageCodeForPayload } from '../../../constants/AppMethod';

function _UPDATEBOCUSERACCOUNT(){
    const endpoint = WEB_CUSTOMER_API_ENDPOINT.UPDATE_ADB2C_NEW_USER;
    const langCode = determineLanguageCodeForPayload();
    const payload = {
        adB2CId: getLocalSessionData(LOCALSESSIONDATAKEYS.AZURE_AD_B2C_UID),
        languageCode: langCode,
        countryCode: getLocalSessionData(LOCALSESSIONDATAKEYS.ECOPROCTRYCODE)
      }
    return _CALLPUTAPI(endpoint, payload)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}
function* updateBOCUserAccount(){
    try {
        const BOCResp = yield call(_UPDATEBOCUSERACCOUNT);
        yield put({type: actionTypes.UPDATE_ADB2C_NEW_USER_SUCCESS, UID: BOCResp});
    } catch (e) {
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
        if(e instanceof TypeError === false && e.message !== "401"){
            yield put({type: actionTypes.UPDATE_ADB2C_NEW_USER_ERROR, message: e.message});
        }
    }
}
function* UpdateADB2CNewUserAccount(){
    yield takeEvery(actionTypes.UPDATE_ADB2C_NEW_USER_INIT, updateBOCUserAccount);
}
export default UpdateADB2CNewUserAccount;