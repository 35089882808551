import { call, put, takeEvery } from 'redux-saga/effects';
import i18next from 'i18next';
import { _CALLGETAPI } from '../../api/apiHandler';
import * as actionTypes from '../../constants/ActionTypes/HardwareInclusive/HardwareInclusive';
import { SUCCESS, _ADMINISTRATOR_API_ENDPOINTS, _HARDWARE_API_ENDPOINTS } from '../../constants/AppConstants';

function _GETPRINTERFEATUREBYPRINTERTYPE(){
    const endpoint = _HARDWARE_API_ENDPOINTS.GET_HW_PRINTER_FEATURES_BY_PRINTER_TYPE
   
    return _CALLGETAPI(endpoint)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function _GET_HWINCLUSIVE_FEATURE_STATUS(){
    const endpoint = _ADMINISTRATOR_API_ENDPOINTS.GET_FEATURE_SWITCH_ITEMS_VALUE;
    const payload = {
        params: {
            featureName: "HardwareInclusive"
        }
    }
    return _CALLGETAPI(endpoint, payload)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* handlePrinterFeatureByPrinterType(action){
    try{
        const printerFeatures = yield call(_GETPRINTERFEATUREBYPRINTERTYPE);
        if(printerFeatures.actionStatus.toUpperCase() === SUCCESS){
            yield put({type: actionTypes.GET_HW_PRINTER_FEATURE_BY_PRINTER_TYPE_SUCCESS, printerFeatures: printerFeatures});
        } else {
            yield put({type: actionTypes.GET_HW_PRINTER_FEATURE_BY_PRINTER_TYPE_ERROR, message: i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')});
        }
    }catch(e){
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
        if(e instanceof TypeError === false && e.message !== "401"){
            yield put({type: actionTypes.GET_HW_PRINTER_FEATURE_BY_PRINTER_TYPE_ERROR, message: e.message});
        }
    }
}

function* handleHWInclusiveFeatureStatus(action){
    try{
        const HW_InclusiveFeatureStatus = yield call(_GET_HWINCLUSIVE_FEATURE_STATUS);
        if(HW_InclusiveFeatureStatus){
            yield* handlePrinterFeatureByPrinterType(action)
        } else {
            yield put({type: actionTypes.HW_INCLUSIVE_SERVICE_AVAILABLE_STATUS_ERROR, message: "Service not available"});
        }
    }catch(e){
         /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
         if(e instanceof TypeError === false && e.message !== "401"){
            yield put({type: actionTypes.HW_INCLUSIVE_SERVICE_AVAILABLE_STATUS_ERROR, message: "Service not available"});
        }
    }
}

function* GetHWPrinterFeaturesByPrinterType(){
    yield takeEvery(actionTypes.GET_HW_PRINTER_FEATURE_BY_PRINTER_TYPE, handleHWInclusiveFeatureStatus);
}

export default GetHWPrinterFeaturesByPrinterType;