import React from "react";
import './Grid.css';

const Grid = (props) => {
    const { children, cname } = props;
    return (
        <div className="grid-container">
            <div className="table-responsive">
                <table className={`admin-table table table-bordered table-striped ${cname}`}>
                    {children}
                </table>
            </div>
        </div>
    )
}

export default Grid;