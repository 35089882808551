import { call, put, takeEvery } from 'redux-saga/effects';
import i18next from "i18next";
import * as actionTypes from '../../../constants/ActionTypes/AdminPortal/FeatureSwitch';
import { _CALLPUTAPI_WITHQS } from '../../../api/apiHandler'
import { FAILURE, LOCALSESSIONDATAKEYS, USER_ACTIVITY_LOGS, _ADMINISTRATOR_API_ENDPOINTS } from '../../../constants/AppConstants';
import { getLocalSessionData } from '../../../utils/Authentication/handleUserSession';
import UserActivityLogsPayload from '../../../utils/AdminPortal/UserActivityLogsPayload';

async function _UPDATEFEATURESWITCH(payload, actionDescription){
    const endpoint = _ADMINISTRATOR_API_ENDPOINTS.UPDATE_FEATURE_SWITCH;
    const logDetails = {
        action: USER_ACTIVITY_LOGS.ADMIN_UPDATE_FEATURE_SWITCH,
        description: actionDescription
    }
    const queryString = {
        params: {
            userId: getLocalSessionData(LOCALSESSIONDATAKEYS.UID)
        }
      }
    const finalPayload = {...payload, 'servicePortalUser':await UserActivityLogsPayload(logDetails)};

    return _CALLPUTAPI_WITHQS(endpoint, finalPayload, queryString)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* handleUpdate(action){
    try {
        const payload = action.payload;
        const actionDescription = action.actionDescription;
        if(Object.values(payload).length > 0){
            const result = yield call(_UPDATEFEATURESWITCH, payload, actionDescription);
            if(result.actionStatus.toUpperCase() !== FAILURE) {
                yield put({type: actionTypes.UPDATE_FEATURE_SWITCH_SUCCESS, data: result});
            } else {
                yield put({
                    type: actionTypes.UPDATE_FEATURE_SWITCH_ERROR, 
                    message: i18next.t('Admintranslation:ADMIN_COMMON_ERRORS.UPDATE_ERROR')
                });
            }
        } else {
            yield put({
                type: actionTypes.UPDATE_FEATURE_SWITCH_ERROR, 
                message: i18next.t('Admintranslation:ADMIN_COMMON_ERRORS.INVALID_INPUT')
            });
        }
    } catch (e) {
         yield put({type: actionTypes.UPDATE_FEATURE_SWITCH_ERROR, message: i18next.t('Admintranslation:ADMIN_COMMON_ERRORS.TECHNICAL_ERROR')});
    }
}

function* UpdateFeatureSwitch(){
    yield takeEvery(actionTypes.UPDATE_FEATURE_SWITCH_INIT, handleUpdate);
}

export default UpdateFeatureSwitch;