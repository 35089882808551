import { languageCodes } from "../../constants/Country";
import { LOCALSESSIONDATAKEYS, USER_LANGUAGE_CODE_TYPE } from "../../constants/AppConstants";
import { getLocalSessionData, setLocalSessionData } from "../Authentication/handleUserSession";
import { ENVSettings    } from "../../constants/ENVSettings";

/**
 * Method to fetch the language code from local storage
 * @returns string
 */
export const getLangCode = () => {
    return localStorage.getItem(LOCALSESSIONDATAKEYS.ECOPROLANGUAGECODE);
}

/**
 * Method to set the language code in local storage
 * @param {string} langCode
 * @returns void
 */
export const setLangCode = (langCode, type=null) => {
     if(langCode){
        if(type && type === USER_LANGUAGE_CODE_TYPE){
            setLocalSessionData(LOCALSESSIONDATAKEYS.ECOPROLNGCODESTATUS, true);
            let domainLangCode = extractLangcodeFromDomain();
            if(domainLangCode.toLowerCase() === "uk"){
                domainLangCode = "GB"
            }
            const language_code = `${langCode}-${domainLangCode.toUpperCase()}`;
            localStorage.setItem(LOCALSESSIONDATAKEYS.ECOPROLANGUAGECODE, language_code);
        } else {
            localStorage.setItem(LOCALSESSIONDATAKEYS.ECOPROLANGUAGECODE, langCode);
        }
    } else {
        langCode = extractLangcodeFromDomain();
        setLangCode(languageCodes[langCode]);
    }
}
export const removeLangCode = () => {
    localStorage.removeItem(LOCALSESSIONDATAKEYS.ECOPROLANGUAGECODE);
}
/**
 * Method to get the country code
 * @returns void
 */
export const getCountryCode = () => {
    return localStorage.getItem(LOCALSESSIONDATAKEYS.ECOPROCTRYCODE);
}

/**
 * Method to extract the language code from site domain
 * @returns void
 */
export const setAppInternationalization = () => {
    const langCode = extractLangcodeFromDomain();
    const logout_lng = getLocalSessionData(LOCALSESSIONDATAKEYS.LOGOUT_LNG_CODE)
    setLocalSessionData(LOCALSESSIONDATAKEYS.ECOPROCTRYCODE, langCode.toUpperCase());
    //if the language stored during logout exists, set the language from this stored value, else get the lngcode from domain
    if(logout_lng){
        setLangCode(logout_lng)
    }
    else{
        setLangCode(languageCodes[langCode]);
    }
}

export const extractLangcodeFromDomain = () => {
    const AppDomain = window.location.host;
    const splittedDomain = AppDomain.split(".");
    let langCode = splittedDomain.filter(element => Object.keys(languageCodes).includes(element));
    return (langCode.length > 0) ? langCode.toString() : "uk";
}
/**
 * Method to get currency code based on country code
 * @param {string} countryCode 
 * @returns string
 */
export const getCurrencyCode =(countryCode) =>{
    const countries = ENVSettings.COUNTRIES
    let countryObject = countries.find(item => item.code === countryCode)
    return countryObject[`currency-code`];
}
/**
 * Method to get country code by country name
 * @param {string} countryName 
 * @returns string
 */
export const extractCountryCode = (countryName)  => {
    const countries = ENVSettings.COUNTRIES
    const extractedCode = countries.filter((value) => {
      return value.name ===  countryName
    })
    return (extractedCode.length > 0 ) ? extractedCode[0].code : "";
}
/**
 * Method to get country name by country code
 * @param {string} countryCode
 * @returns string
 */
export const extractCountryName = (countryCode)  => {
    const countries = ENVSettings.COUNTRIES
    const extractedCode = countries.filter((value) => {
      return value.code2 ===  countryCode
    })
    return (extractedCode.length > 0 ) ? extractedCode[0].name : "";
}
