import { call, put, takeEvery } from 'redux-saga/effects';
import i18next from "i18next";
import * as actionTypes from '../../constants/ActionTypes/Dashboard';
import { _CALLGETAPI } from '../../api/apiHandler';
import { _API_ENDPOINT, FAILURE } from '../../constants/AppConstants';

function _GETUSERSUBSCRIPTIONACCOUNTSUMMARY(UID){
    const endpoint = _API_ENDPOINT.USER_SUBSCRIPTION_ACCOUNT_SUMMARY;
    const payload = {
        params: {
            UserId: UID,
        }
      }
    return _CALLGETAPI(endpoint, payload )
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}
function* handleUserSubscriptionAccountSummary(action){
 try{
    const UID = action.UID;
    if(UID){
        const userSubscriptionAccountSummary = yield call(_GETUSERSUBSCRIPTIONACCOUNTSUMMARY, UID);
       
        if(userSubscriptionAccountSummary.actionStatus.toUpperCase() !== FAILURE) {
            yield put({type: actionTypes.GET_USER_SUBSCRIPTION_ACCOUNT_SUMMARY_SUCCESS, userSubscriptionAccountSummary: userSubscriptionAccountSummary});
        } else {
            yield put({type: actionTypes.GET_USER_SUBSCRIPTION_ACCOUNT_SUMMARY_ERROR, message: i18next.t("ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR")});
        }
    } else {
        yield put({
            type: actionTypes.GET_USER_SUBSCRIPTION_ACCOUNT_SUMMARY_ERROR, 
            message: i18next.t('ERROR_CONTENT.INFO_GENERAL')
        });
    }
 } catch(e){
     /**
     * This conditional statement is added to avoid
     * displaying error message in view while logout from app
     */
    if(e instanceof TypeError === false && e.message !== "401"){
        yield put({type: actionTypes.GET_USER_SUBSCRIPTION_ACCOUNT_SUMMARY_ERROR, message: e.message});
    }
 }
}
function* UserSubscriptionAccountSummary(){
    yield takeEvery(actionTypes.GET_USER_SUBSCRIPTION_ACCOUNT_SUMMARY, handleUserSubscriptionAccountSummary);
}

export default UserSubscriptionAccountSummary;