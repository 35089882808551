import { call, put, takeEvery } from 'redux-saga/effects';
import i18next from 'i18next';
import * as actionTypes from '../../constants/ActionTypes/SelectDevice';
import { _CALLGETAPI } from '../../api/apiHandler'
import { _API_ENDPOINT, FAILURE, LOCALSESSIONDATAKEYS } from '../../constants/AppConstants';
import { getLocalSessionData, setLocalSessionData } from '../../utils/Authentication/handleUserSession';

function _FETCHDEVICEAPI(UID){
    const endpoint = _API_ENDPOINT.FETCH_DEVICE_LIST;
    const payload = {
        params: {
            UserId: UID
        }
      }
     return _CALLGETAPI(endpoint,payload)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* fetchDeviceList(action) {
    try {
        const UID = action.UID;
        if(UID){
            const deviceList = yield call(_FETCHDEVICEAPI,UID);
            if(deviceList.actionStatus.toUpperCase() === FAILURE){
                yield put({type: actionTypes.FETCH_DEVICE_LIST_ERROR, message: i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')})
            }
            else{
                // if user is having more than one device and already having an enrollment, 
                // then EcoPro should always take user to Dashboard of existing enrollment 
                // irrespective of which device is selected.
                if(deviceList.devices.length > 1){
                    const activeDevice = deviceList.devices.find(device => device.service !== null);
                    if(activeDevice && getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID) !== activeDevice.deviceId){
                        setLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID, activeDevice.deviceId);
                    }
                }
                yield put({type: actionTypes.FETCH_DEVICE_LIST_SUCCESS, deviceList: deviceList});
            }
        } else {
            yield put({
                type: actionTypes.FETCH_DEVICE_LIST_ERROR, 
                message: i18next.t('ERROR_CONTENT.INFO_GENERAL')
            });
        }
    } catch (e) {
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
        if(e instanceof TypeError === false && e.message !== "401"){
            yield put({type: actionTypes.FETCH_DEVICE_LIST_ERROR, message: e.message});
        }
    }
}

function* SelectDevice() {
    yield takeEvery(actionTypes.FETCH_DEVICE_LIST, fetchDeviceList);
 }
 
 export default SelectDevice;