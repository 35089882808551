import React, { useContext } from "react";
import ZuoraPayment from "../../ZuoraPayment/ZuoraPayment";
import { PAYMENT_TYPE } from "../../../constants/AppConstants";
import { Container } from "reactstrap";
import { UpdatePaymentInfoContext } from "../../../pages/Account/EditAccount/EditPayment";

const ZuoraPaymentForm = (props) => {
    const { signUpType } = props;
    const updatePaymentInfo = useContext(UpdatePaymentInfoContext);
    const { accountInfo, paymentType} = updatePaymentInfo;
    return (
        <>
            {
                (paymentType && paymentType === PAYMENT_TYPE.UPDATE)
                ? <ZuoraPayment accountInfo={accountInfo} paymentType={PAYMENT_TYPE.UPDATE}></ZuoraPayment>
                : <ZuoraPayment paymentType={PAYMENT_TYPE.NEW} signUpType={signUpType}></ZuoraPayment>
            }
                <div>
                    <Container id="zuora_payment"></Container>
                </div>
        </>
    )
}

export default ZuoraPaymentForm;