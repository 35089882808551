import * as actionTypes from '../../../constants/ActionTypes/Web/Customer/SignUp';

const initialState = {
    'success': false,
    'loading': false,
    'error': false,
    'data': {}
}

const GetRegistrationType = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.GET_REGISTRATION_TYPE:
            return {
                ...state,
                loading: true,
                'success': false,
                'error': false,
                'data': {}
            }
        case actionTypes.GET_REGISTRATION_TYPE_SUCCESS:
            return {
                ...state,
                success: true,
                loading: false,
                data: action.registrationType,
                'error': false
            }
        case actionTypes.GET_REGISTRATION_TYPE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.message,
            }
        case actionTypes.RESET_WEB_CONNECTION_API_DATA:
            return {
                ...state,
                'success': false,
                'loading': false,
                'error': false,
                'data': {}
            }
        default:
            return {...state};
    }
}

export default GetRegistrationType;