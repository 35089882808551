import React from "react";
import './FormInput.css';

const AdminFormInput = (props) => {
    const {
        id,
        name,
        type = 'text',
        value,
        onChange,
        placeholder,
        cname,
        readonly = false,
        gridSearchableInput = false, // This adds a specific class to grid searchable input.
        minlength,
        maxlength,
        isRequired,
        onKeyUp
    } = props
    return(
        <input 
            id={id} 
            name={name} 
            type={type} 
            className={`admin-input admin-form-control form-control  ${(gridSearchableInput) ? 'admin-table-header-search-input' : 'default-form-input'} ${(cname) && cname}`} 
            placeholder={placeholder} 
            value={value} 
            readOnly={readonly} 
            onChange={onChange}
            required={isRequired}
            minLength={minlength}
            maxLength={maxlength}
            onKeyUp={onKeyUp}
        >
        </input>
    )
}

export default AdminFormInput;