import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../constants/ActionTypes/MyPlan';
import { _CALLGETAPI } from '../api/apiHandler'
import { _API_ENDPOINT } from '../constants/AppConstants';

function _FETCHPLANAPI(){
    const endpoint = _API_ENDPOINT.FETCH_PLAN_LANDING;
    const payload = {
        params: {
            planType: 'new',
            includeHiddenPlans: 'false'
        }
    }
    return _CALLGETAPI(endpoint,payload)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* fetchPlansLandingPage(action) {
    try {
        const plans = yield call(_FETCHPLANAPI);
        yield put({type: actionTypes.FETCH_PLANS_LANDING_PAGE_SUCCESS, plans: plans});
    
    } catch (e) {
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
        if(e instanceof TypeError === false && e.message !== "401"){
            yield put({type: actionTypes.FETCH_PLANS_LANDING_PAGE_ERROR, message: e.message});
        }
    }
}

function* PlansLandingPage() {
    yield takeEvery(actionTypes.FETCH_PLANS_LANDING_PAGE, fetchPlansLandingPage);
 }
 
 export default PlansLandingPage;