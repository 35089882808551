import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDeviceList, getDeviceRegitration } from '../../../../../../actions/SelectDevice';
import DisplayWebEligibleDevice from '../../../../../../components/Web/Customer/DisplayWebEligibleDevice/DisplayWebEligibleDevice';
import { ECOPROTOKEN_CLAIMS, LOCALSESSIONDATAKEYS, refreshBtn } from '../../../../../../constants/AppConstants';
import { pushDataLayer } from '../../../../../../constants/AppMethod';
import { routePath } from '../../../../../../constants/Routes';
import deviceOffline from '../../../../../../assets/images/printer-offline.svg';
import ErrorPage from '../../../../../../components/shared/errorPage/ErrorPage';
import Loader from '../../../../../../components/shared/loader/Loader';
import DisplayUpdateAccountConfirmation from '../../../../../../components/EditMyAccount/DisplayUpdateAccountConfirmation/DisplayUpdateAccountConfirmation';
import { getLocalSessionData, getUserSessionData, setLocalSessionData } from '../../../../../../utils/Authentication/handleUserSession';

const WebAddEligibleDevice = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation(["translation"]);
    const history = useHistory();
    const { location } = props;
    const deviceListResult = location?.state?.deviceListResult;
    const devices = deviceListResult?.deviceList?.devices;
    const [selectedEligibleDeviceId, setSelectedEligibleDeviceId] = useState("")
    const [selectedEligibleDevice,setSelectedEligibleDevice] = useState("")
    const [selectedDeviceNetworkStatus, setSelectedDeviceNetworkStatus] = useState("")
    const deviceCapabilityResult = useSelector((state) => state.DeviceCapability)
    const deviceListRecheckResult = useSelector((state) => state.SelectDevice);
    const [offlineStatus, setOfflineStatus] = useState(false)
    const deviceRegistrationResult = useSelector((state) => state.DeviceRegistration)
    const [oneTimeRecheck, setOneTimeRecheck] = useState(false)
    const [onConfirmDeviceClick, setConfirmDeviceClick] = useState(false)
    let networkStatus; 

    const onDeviceSelection = (newDevice,type) => {
        setLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID, newDevice.deviceId);
        setLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_MODEL, newDevice.model);
        setLocalSessionData(LOCALSESSIONDATAKEYS.SERIAL_NUMBER, newDevice.serialNumber)
        setSelectedEligibleDeviceId(newDevice.deviceId)
        setSelectedDeviceNetworkStatus(newDevice.networkStatus)
        networkStatus = newDevice.networkStatus
        setSelectedEligibleDevice(newDevice)
        setOneTimeRecheck(false)
    }
    const onConfirmEligibleDeviceSelection = () => {
      const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
      const deviceId = selectedEligibleDeviceId;
      if(networkStatus === "online" || selectedDeviceNetworkStatus === "online"){
        dispatch(getDeviceRegitration(UID, deviceId))
      }
      else if(networkStatus === "offline" || selectedDeviceNetworkStatus === "offline"){
        setOfflineStatus(true)
      }
      pushDataLayer('add_device',"device_id",deviceId)
      setConfirmDeviceClick(true)
    }

    const recheckNetworkStatus = () => {
      const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
      setOfflineStatus(false)
      setOneTimeRecheck(true)
      dispatch(fetchDeviceList(UID));
      setConfirmDeviceClick(false)
    }
    useEffect(()=>{
      if(deviceRegistrationResult.success && onConfirmDeviceClick){
        history.push(routePath.WEB_PLACE_ORDER)
        setConfirmDeviceClick(false)
      }
    },[deviceRegistrationResult.success, history, onConfirmDeviceClick])

    if (deviceListRecheckResult.success){
      deviceListRecheckResult.deviceList?.devices.map((device) =>{
          let selectedDeviceId;
          if(selectedEligibleDeviceId){
            selectedDeviceId = selectedEligibleDeviceId
          }
          else{
            selectedDeviceId = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID)
          }
          if(device.deviceId === selectedDeviceId){
            networkStatus = device.networkStatus
            if(device.networkStatus === "online" && oneTimeRecheck){
              const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
              dispatch(getDeviceRegitration(UID, device.deviceId))
              setOneTimeRecheck(false)
            }
          }
          return device;
        })
    }
    
    if(deviceRegistrationResult.error){
      return (
        <ErrorPage
          errorInfo={deviceRegistrationResult.error}
          showRefreshBtn={refreshBtn.HIDE}
          ></ErrorPage>
      )
    }
    if (deviceListRecheckResult.error) {
      return (
        <ErrorPage
          errorInfo={deviceListRecheckResult.error}
          showRefreshBtn={refreshBtn.HIDE}
        ></ErrorPage>
      );
    }
    if (deviceListRecheckResult.deviceList?.devices && deviceListRecheckResult.deviceList?.devices.length === 0) {
      return (
        <ErrorPage
          errorInfo={t("ERROR_CONTENT.NO_DEVICES")}
          showRefreshBtn={refreshBtn.HIDE}
        ></ErrorPage>
      );
    }

    if(deviceCapabilityResult.loading || deviceListRecheckResult.loading || deviceRegistrationResult.loading) {
      return <Loader></Loader>
    }

    let selectedDeviceForPlans = {'deviceId':selectedEligibleDeviceId,'device_model': selectedEligibleDevice.model,'serialNumber':selectedEligibleDevice.serialNumber,'networkStatus':selectedEligibleDevice.networkStatus}
    
    if(offlineStatus){
      return <DisplayUpdateAccountConfirmation
        updateAddressHeader={t("DEVICE_STATUS_CHECK.OFFLINE_STATUS_HEADER")}
        updateAddressInfo={t("DEVICE_STATUS_CHECK.OFFLINE_STATUS_INFO")}
        updateAddressBtn={t("ECOPRO_BTNS.RETRY")}
        onUpdateClick = {()=>recheckNetworkStatus()}
        image={deviceOffline}
        ></DisplayUpdateAccountConfirmation>
    }

    return (
        <DisplayWebEligibleDevice 
            header={t("CUSTOMER_WEB.ADD_MY_DEVICE.CONNECT_YOUR_DEVICE")}
            subheader={t("CUSTOMER_WEB.ADD_MY_DEVICE.CONNECT_DEVICE_INFO")}
            selectInfo={t("CUSTOMER_WEB.ADD_MY_DEVICE.YOUR_ELIGIBLE_DEVICES")}
            eligibleDevices={devices} 
            selectedreplacementDeviceId={selectedEligibleDeviceId}
            onRelacementSelection={onDeviceSelection}
            onConfirmEligibleDeviceSelection={onConfirmEligibleDeviceSelection}
            btnText = {t('ECOPRO_BTNS.CONTINUE')}
            linkUrl = {routePath.PLANS}
            linkWithState = {selectedDeviceForPlans}
            linkText ={t("CUSTOMER_WEB.ADD_MY_DEVICE.CHANGE_SUBSCRIPTION")}
            selectedModel = {selectedEligibleDevice.model}
        ></DisplayWebEligibleDevice>
    );
};

export default WebAddEligibleDevice;