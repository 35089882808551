import * as actionTypes from '../constants/ActionTypes/MyPlan';

const initialState = {
    success:false,
    plans: {},
    loading: false,
    error: null,
  }

const PlansLandingPage = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PLANS_LANDING_PAGE:
            return {
                ...state,
                loading: true
            }
        
        case actionTypes.FETCH_PLANS_LANDING_PAGE_SUCCESS:
            return {
                ...state,
                success:true,
                plans: action.plans,
                loading: false
            }
        
        case actionTypes.FETCH_PLANS_LANDING_PAGE_ERROR:
            return {
                ...state,
                success:false,
                loading: false,
                error: action.message
            }
        default:
            return {...state}
    }
}

export default PlansLandingPage