import React from "react";
import Slider from "react-slick";
import { Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import check from "../../../../assets/images/planFeaturecheck.svg";
import EcoProButton from "../../../../components/shared/button/button";
import currencies from "../../../../constants/CurrencyTypes";
import { findPromotionType, getPlanTranslation, formatNumberOnLocale } from "../../../../constants/AppMethod";
import "./ListPlan.css";
import Recomended from "../../../../assets/images/recomended.svg";
import { SIGNUPTYPE } from "../../../../constants/AppConstants";

const ListPlan = (props) => {
  const { plansData, moveToAccountPage, flowType = SIGNUPTYPE.WEB, isPromotionBannerEnabled } = props;
  let plansList = null;

  if(flowType === SIGNUPTYPE.MOBILE){
      plansList = (plansData && plansData.length > 0) ? plansData : null
  } else {
      plansList = (plansData.hasOwnProperty("webServicePlan") &&
      plansData.webServicePlan.length > 0 ) ? plansData.webServicePlan : null
  }

  const { t } = useTranslation(["translation"]);

  const settings = {
    className: "slider variable-width",
    infinite: true,
    centerPadding: "15px",
    speed: 500,
    dots: true,
    accessibility: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          className: "center",
          centerMode: true,
          infinite: true,
          centerPadding: "60px",
          speed: 500,
          dots: true,
          accessibility: true,
        },
      },
    ],
  };
  return (
    <>
      <Container id="plans-slider-section" className="slick_carousel-web">
        <Slider {...settings}>
            { plansList && plansList.map((plan) => (
              <div
                className={`web-plan f-14 ${
                  plan.isRecommended ? "recomend-highlight" : null
                }`}
                key={plan.planId}
              >
                <div className="plan-content-wrapper ta-left">
                  {plan.isRecommended && (
                    <div className="recomended f-12 fw-b">
                      <img src={Recomended} alt="recomended" />
                      <span>{t("CUSTOMER_WEB.SELECT_PLAN.RECOMMENDED")}</span>
                    </div>
                  )}
                  <div className="fc-e fw-b plan-name">
                    {getPlanTranslation(plan.planName)}
                  </div>
                  <div className="ta-left pages-per-month">
                    {t("CUSTOMER_WEB.SELECT_PLAN.PAGES_PER_MONTH", {
                      planPages: plan.planPages,
                    })}
                  </div>
                  {plan.discount && plan.discount.length>0 && plan.discount[0].planId?
                  <>
                    <div className="plan-price">
                      <span className="price-strike">
                        {formatNumberOnLocale(plan.price, currencies[plan.currency]?.code)}
                      </span>
                      {" "}
                      <span className="f-16 per-month">
                      {t("LANDING_PAGE.PER_MONTH")}<sup>1</sup>
                      </span>
                    </div>
                  
                  
                    <div className="plan-price">
                      <span>
                        {formatNumberOnLocale("0", currencies[plan.currency]?.code)}
                      </span>
                      {" "}
                      <span className="f-16 per-month">
                        {t("LANDING_PAGE.PER_MONTH")}<sup>1</sup>&nbsp;
                        <span className={`promotion-type ${(isPromotionBannerEnabled) ? 'promotion-banner-type' : 'no-promotion-banner-type'}`}>
                          {findPromotionType(plan.discount,isPromotionBannerEnabled?"promotionBanner":"web")}
                        </span>
                      </span>
                    </div>
                    </>
                    :
                    <>
                    <div className="plan-price">
                      <span>
                        {formatNumberOnLocale(plan.price, currencies[plan.currency]?.code)}
                      </span>
                      {" "}
                      <span className="f-16 per-month">
                      {t("LANDING_PAGE.PER_MONTH")}<sup>1</sup>
                      </span>
                    </div>
                    <div className="plan-price plan-price-hide">
                      <span>
                        {formatNumberOnLocale("0", currencies[plan.currency]?.code)}
                      </span>
                      {" "}
                      <span className="f-16 per-month">
                      {t("LANDING_PAGE.PER_MONTH")}<sup>1</sup>&nbsp;
                      <span className={`promotion-type ${(isPromotionBannerEnabled) ? 'promotion-banner-type' : 'no-promotion-banner-type'}`}>
                        {findPromotionType(plan.discount,isPromotionBannerEnabled?"promotionBanner":"web")}</span>
                      </span>
                    </div>
                    </>
                  }
                </div>
                <div className="plan-btn-wrapper">
                  <EcoProButton
                    cname="epic-blue-btn, web-plan-btn f-14"
                    btnID={`plan_selected_${plan.planId}`}
                    onBtnClick={() => moveToAccountPage(plan)}
                  >
                    {t("CUSTOMER_WEB.SELECT_PLAN.SELECT_PLAN_BTN")}
                  </EcoProButton>
                </div>
                <hr></hr>
                <div className="ta-left plan-includes">
                  {t("CUSTOMER_WEB.SELECT_PLAN.PLAN_LIST_TITLE")}:
                </div>
                <div className="web-plan-list">
                  <div className="row">
                    <div className="col-2">
                      <img src={check} alt="plan-point" />
                    </div>
                    <div className="col-10">
                      {t("CUSTOMER_WEB.SELECT_PLAN.LIMITED_PAGES_PRINTED", {
                        planPages: plan.planPages,
                      })}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-2">
                      <img src={check} alt="plan-point" />
                    </div>
                    <div className="col-10">
                      {t("ECOPROCAROUSEL_CONTENT.PLAN_LIST.POINT_1", {
                        rollover: plan.rollOverPages,
                      })}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-2">
                      <img src={check} alt="plan-point" />
                    </div>
                    {plan.currency && (
                      <div className="col-10">
                        {t("ECOPROCAROUSEL_CONTENT.PLAN_LIST.POINT_2", {
                          overage_pages: formatNumberOnLocale(plan.overagePrice, currencies[plan.currency]?.code),
                          overage_threshold: plan.overageBlockPages,
                        })}
                      </div>
                    )}
                    {!plan.currency && (
                      <div className="col-10">
                        {t("ECOPROCAROUSEL_CONTENT.PLAN_LIST.POINT_2", {
                          overage_pages: formatNumberOnLocale(plan.overagePrice),
                          overage_threshold: plan.overageBlockPages,
                        })}
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-2">
                      <img src={check} alt="plan-point" />
                    </div>
                    <div className="col-10">
                      {t("ECOPROCAROUSEL_CONTENT.PLAN_LIST.POINT_3")}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </Slider>
      </Container>
    </>
  );
};

export default ListPlan;
