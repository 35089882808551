import * as actionTypes from '../../constants/ActionTypes/MyAccount';
import * as actionTypesWeb from '../../constants/ActionTypes/Web/Customer/SignUp';

const initialState = {
    'success': false,
    'loading': false,
    'error': false,
    'data': {}
  }

const Enrollment = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.ACCOUNT_ENROLLMENT_INIT:
            return {
                ...state,
                loading: true
            }
        case actionTypes.ACCOUNT_ENROLLMENT_SUCCESS:
            return {
                ...state,
                success: true,
                loading: false,
                data: action.enrollmentResponse
            }
        case actionTypes.ACCOUNT_ENROLLMENT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.message
            }
        case actionTypesWeb.RESET_WEB_CONNECTION_API_DATA:
            return {
                ...state,
                success: false,
                loading: false,
                error: false,
                data: {}
            }
        default:
            return {...state};
    }

}

export default Enrollment;