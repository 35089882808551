import * as actionTypes from '../../constants/ActionTypes/Dashboard';

const initialState = {
  'feedbackResult': {},
  'success': false,
  'loading': false,
  'error': false,
  'error_desktop': false,
  'success_desktop': false,
}

const SignUpUserFeedback = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SIGNUP_USER_FEEDBACK:
          return {
            ...state,
            loading: true,
          };
        case actionTypes.SIGNUP_USER_FEEDBACK_SUCCESS:
        return {
          ...state,
          feedbackResult: action.feedbackResult,
          success: true,
          loading: false,
          success_desktop:false
        };
        case actionTypes.SIGNUP_USER_FEEDBACK_SUCCESS_DESKTOP:
        return {
          ...state,
          feedbackResult: action.feedbackResult,
          success: false,
          loading: false,
          success_desktop:true
        };
        case actionTypes.SIGNUP_USER_FEEDBACK_ERROR:
        return {
            ...state,
          success: false,
          loading: false,
          error: action.message,
          error_desktop: false,
          success_desktop: false
        };
        case actionTypes.SIGNUP_USER_FEEDBACK_ERROR_DESKTOP:
        return {
            ...state,
          success: false,
          loading: false,
          error: false,
          error_desktop: action.message,
          success_desktop: false
        };
        case actionTypes.RESET_STATE:
          return { ...state, 
            success: false,
            error:'',
            feedbackResult: {},
            error_desktop: false,
            success_desktop: false
          };
        default:
          return { ...state };
      }
}

export default SignUpUserFeedback;