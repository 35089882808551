import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EcoProButton from "../../shared/button/button";
import { routePath } from "../../../constants/Routes";
import { resetDeviceCapability } from "../../../actions/SelectDevice";
import { pushDataLayer } from "../../../constants/AppMethod";

const DisplayConfirmDevices = ({confirmDevice}) => {
  const { t } = useTranslation(["translation"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const deviceListResult = useSelector((state) => state.SelectDevice);
  const devices = deviceListResult.deviceList.devices;

  const goToPlansPage = (deviceId, deviceModel, serialNumber,networkStatus) => {
    pushDataLayer('device_confirmation','device_id',deviceId)
    dispatch(resetDeviceCapability())
    history.push({pathname: routePath.PLANS, state: {'deviceId':deviceId,'device_model': deviceModel,'serialNumber':serialNumber,'networkStatus':networkStatus, from: 'device_selection_page'}});
  };
  return (
    <React.Fragment>
      <div className={`confirm-device-img ${confirmDevice.imagePath ? "":"image-background"}`}>
        <img src={confirmDevice.imagePath} alt="confirm device" />
      </div>
      <div className="f-20 fw-b">{confirmDevice.model}</div>
      <div className="confirm-device-info f-12">
        {confirmDevice?.serialNumber}
      </div>
      <div className="confrim-device-btn">
        <EcoProButton cname="default" btnID={`device_confirmation_${confirmDevice.deviceId}`} onBtnClick={() => goToPlansPage(confirmDevice.deviceId, confirmDevice.model, confirmDevice.serialNumber, confirmDevice.networkStatus)}>
          {t("SIGN_UP.CONFIRM_DEVICE.CONTINUE_BTN")}
        </EcoProButton>
      </div>
      {devices && devices.length > 1 ? (
        <div className="confirm-device-link">
          <Link to={routePath.DEVICE_LIST}>
            {t("SIGN_UP.CONFIRM_DEVICE.SELECT_DIFFERENT_LINK")}
          </Link>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default DisplayConfirmDevices;
