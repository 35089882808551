import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateADB2CNewUserAccount } from "../../../../../actions/Web/Customer/SignUp";
import ErrorPage from "../../../../../components/shared/errorPage/ErrorPage";
import Loader from "../../../../../components/shared/loader/Loader";
import { AuthenticateUserByExternalRedirect } from "../../../../../constants/AppMethod";


const CreateUserAccount = () => {
    const dispatch = useDispatch();
    const BOCUserAccountResult = useSelector((state) => state.UpdateADB2CNewUserAccount);
    
    useEffect(() => {
            dispatch(UpdateADB2CNewUserAccount());
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    if(BOCUserAccountResult.error){
        return <ErrorPage errorInfo={BOCUserAccountResult.error}></ErrorPage>
    }
    if(BOCUserAccountResult.success){
        setTimeout(AuthenticateUserByExternalRedirect(), 50000);
    }
    return (
        <Loader />
    );
}

export default CreateUserAccount;