import React from 'react';
import './DisplayPrinterType.css';
import { t } from 'i18next';
import EcoProButton from '../../../shared/button/button';
import { getPrinterTypeTranslation } from '../../../../constants/AppMethod';
import { getHWPrinterFeatureTranslation } from '../../../../utils/HardwareInclusive/HardwareInclusive';


const DisplayPrinterType = (props) => {
    const {moveToCustomizePrinter, printerName, printerDescription, planStartingPrice, deviceImg, printerFeatures} = props
    return (
        <div className='hardware-printer-type-wrapper'>
            <div className='hardware-print-img-wrapper'>
                <img src={deviceImg} alt="printer" />
            </div>
            <div className='printer-name'>{`${getPrinterTypeTranslation(printerName)} ${t("HARDWARE_SIGNUP.CHOOSE_PRINTER_TYPE.PRINTER")}`}</div>
            <div className='printer-description'>{printerDescription}</div>
            <div className='available-options'>{t("HARDWARE_SIGNUP.CHOOSE_PRINTER_TYPE.AVAILABLE_OPTION")}:</div>
            <div className='available-options-list'>
                {printerFeatures?.map(printerFeature => (
                    <li key={printerFeature?.featureId} id={printerFeature?.featureId}>
                        {getHWPrinterFeatureTranslation(printerFeature?.featureId, printerName, printerFeature?.featureName)}
                    </li>
                ))}
            </div>
            <div className='starting-from'>{planStartingPrice}</div>
            <div className='plan-initial-payment'>{t("HARDWARE_SIGNUP.CHOOSE_PRINTER_TYPE.PLAN_REQUIRE_INITIAL_PAYMENT")}</div>
            <EcoProButton
                cname="web-signup, hardware-inclusive-printer-btn"
                btnID={`1`}
                onBtnClick={() => moveToCustomizePrinter(printerName)}
                >
                {t("HARDWARE_SIGNUP.CHOOSE_PRINTER_TYPE.SELECT")}
            </EcoProButton>
        </div>
    );
};

export default DisplayPrinterType;