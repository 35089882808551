import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { ENVSettings } from "../../../../constants/ENVSettings";
import DisplayUpdateAccountConfirmation from "../../../../components/EditMyAccount/DisplayUpdateAccountConfirmation/DisplayUpdateAccountConfirmation";
import checkWithBackground from '../../../../assets/images/complete-confirmation-icon.svg'
import ErrorPage from "../../../../components/shared/errorPage/ErrorPage";
import { getCountryCode, getLangCode } from "../../../../utils/Internationalization/handleAppInternationalization";
import { routePath } from "../../../../constants/Routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { getFeatureSwitchByKey } from "../../../../utils/Authentication/handleUserSession";
import { featureSwitchName } from "../../../../constants/AppConstants";
import { DisplayDate } from "../../../../utils/DisplayDate";


const CancellationConfirmed = (props) => {
    const { t } = useTranslation(["translation"]);
    const DeviceInfo = useSelector((state) => state.DashboardDeviceInfo)
    const history = useHistory()
    const dateDisplayOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const langCode = getLangCode()
    const activatedDate = DeviceInfo?.deviceInfo?.service?.activatedDate
    const isdelayCancellationFeatureSwitch = getFeatureSwitchByKey(featureSwitchName.DELAY_CANCELLATION) ? true : false
    const billingEndDate = DeviceInfo?.deviceInfo?.service?.currentUsage?.usageCycleEndDate
    const formattedBillingEndDate = DisplayDate(billingEndDate,  dateDisplayOptions, langCode)
    
    const cancellationConfirmed = (event) => {
      if(activatedDate && isdelayCancellationFeatureSwitch){ 
        history.push(routePath.DASHBOARD)
      }
      else{
        const countryCode = getCountryCode();
        const redirectUrl = (!ENVSettings.MAIN_BASE_URL) ? window.env[`MAIN_BASEURL_${countryCode}`] : ENVSettings.MAIN_BASE_URL;
        event.preventDefault();
        window.location.href = redirectUrl;
        return false;
      }
    }
   
  return (
    props.location.state && props.location.state.cancellationsuccess === true?
    <div>
      <DisplayUpdateAccountConfirmation
        updateAddressHeader={t("CONFIRMED_CANCELLATION.HEADER")}
        updateAddressInfo={ activatedDate && isdelayCancellationFeatureSwitch ? <Trans i18nKey="CONFIRMED_CANCELLATION.DELAY_CANCEL_INFO" formattedBillingEndDate={formattedBillingEndDate}>
        Your EcoPro subscription has now been cancelled. Your subscription will finish at the end of your last billing month on <strong>{{formattedBillingEndDate}}</strong>.
        </Trans> : t("CONFIRMED_CANCELLATION.INFO")}
        updateAddressBtn={t("EDIT_FORM.UPDATE_ACCOUNT_BTN.CONTINUE")}
        onUpdateClick={(event) => cancellationConfirmed(event)}
        image={checkWithBackground}
      ></DisplayUpdateAccountConfirmation>
    </div>
     :
     <ErrorPage errorInfo={t("ERROR_CONTENT.ERR_INFO.SUBSCRIPTION_REQUIRED")} />
    );
};

export default CancellationConfirmed;
