import { getUserSessionData, getUserDataByKey, getLocalSessionData } from "../Authentication/handleUserSession";
import { ECOPROTOKEN_CLAIMS, LOCALSESSIONDATAKEYS } from "../../constants/AppConstants";
import { determineLanguageCodeForPayload, truncateAddressLine1Characters } from "../../constants/AppMethod";

const UpdatePaymentDetailsPayload = (PaymentMethodId, Subscription) => {
    const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
    const deviceID = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID);;
    const langCode = determineLanguageCodeForPayload();
    const currency = getLocalSessionData(LOCALSESSIONDATAKEYS.CURRENCY);
    const customerName = "";
    const billingAddrsDetails = {...Subscription.accountDetails.billingAddress, 
        businessModel: parseInt(getUserDataByKey('businessModel')) || 0,
        vatId: getUserDataByKey('vatId'),
        taxId: getUserDataByKey('taxId'),
        sapBpId: getUserDataByKey('sapBpId')
    }
    const truncatedBillingAddressLine1 = truncateAddressLine1Characters(billingAddrsDetails?.address, 0, 15);
    return {
        UserId: UID,
        paymentMethodId: PaymentMethodId,
        Name: customerName,
        email: "",
        BillingAddress: {
            FirstName: Subscription.accountDetails.personalDetails.firstName,
            LastName: Subscription.accountDetails.personalDetails.lastName,
            Street1: truncatedBillingAddressLine1,
            Street2: billingAddrsDetails.addressLine2 || "",
            City: billingAddrsDetails.city,
            State: "",
            StateName: "",
            PostalCode: billingAddrsDetails.postcode,
            AddressNumber: "",
            country: billingAddrsDetails.country,
            currency:currency,
            companyName: billingAddrsDetails.company || "",
            businessModel: billingAddrsDetails.businessModel || 0,
            vatId: billingAddrsDetails.vatId || "",
            taxId: billingAddrsDetails.taxId || "",
            sapBpId: billingAddrsDetails.sapBpId || "",
            homePhone: Subscription.accountDetails.personalDetails.telephoneNumber || "",
            mobilePhone: Subscription.accountDetails.personalDetails.mobileNumber,
            languageCode: langCode,
            eInvoiceId: billingAddrsDetails.eInvoiceId || ""
        },
        BillingInfo: {
            signature: Subscription.billingInfo.signature,
            token: Subscription.billingInfo.token,
            tenantId: Subscription.billingInfo.tenantId,
            key: Subscription.billingInfo.key,
            uri: Subscription.billingInfo.url,
            pageId: Subscription.billingInfo.id,
            style: Subscription.billingInfo.style,
            submitEnabled: Subscription.billingInfo.submitEnabled,
            paymentGateway: null,
            countryWhiteList: null
        },
        Device: {
            deviceId: deviceID,
        },
    }
}

export default UpdatePaymentDetailsPayload;