import React from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import EcoproModal from "../../../modal/EcoproModal";
import brother_logo from "../../../../../assets/images/ecopro_logo.svg"
import "./Header.css";
import EcoProButton from "../../../button/button";
import { CSAgentRoutePaths } from "../../../../../constants/Routes";
import { useTranslation } from "react-i18next";

const AdminHeader = (props) => {
    const { toggleSidebarNav } = props;
    const { t } = useTranslation(["Admintranslation"]);
    const isAuthenticated = useIsAuthenticated();
    return (
       <div className="admin-header-outer-wrapper">
            <div className="admin-header-left">
                <div className="menu-toggle-icon" onClick={toggleSidebarNav}>
                    <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vnjz4i" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="MenuIcon"><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path></svg>
                </div>
                <div className="admin-header-logo">
                    <img src={brother_logo} alt="Brother" className="brother-logo" />
                </div>
            </div>
            <div className="admin-header-right">
                <EcoProButton cname="default admin-portal-csagent-link" type="link" redirect_URL={CSAgentRoutePaths.CUSTOMER_SEARCH}>{t('CSAGENT_LINK_TXT')}</EcoProButton>
                { isAuthenticated && <EcoproModal role="admin-csagent" exactRole="admin" /> }
            </div>
       </div>
    );
}

export default AdminHeader;