import React from "react";
import EcoProButton from "../../../shared/button/button";
import './AdminForm.css';

const AdminForm = (props) => {
    const {
        children,
        cname,
        cardHeaderTitle,
        cardHeaderButtonInfo = {buttonType: "button", btnTxt: "", redirectURL: "", onBtnClick: ""},
        formName,
        handleSubmit
    } = props;
    return (
        <div className="admin-card card">
            <div className="admin-card-header card-header">
                <div className="card-header-container">
                    <h1>{cardHeaderTitle}</h1>
                    {
                        Object.values.length > 0 &&
                        <>
                         {
                            cardHeaderButtonInfo.buttonType === "link" && 
                            <EcoProButton cname="default" type="link" redirect_URL={cardHeaderButtonInfo.redirectURL}>{cardHeaderButtonInfo.btnTxt}</EcoProButton>
                        }
                        {
                            cardHeaderButtonInfo.buttonType === "button" && 
                            <EcoProButton cname="default" onBtnClick={() => cardHeaderButtonInfo.onBtnClick()}>
                                {cardHeaderButtonInfo.btnTxt}
                            </EcoProButton>
                        }
                        </>
                    }
                </div>
            </div>
            <div className="admin-card-body card-body">
                <form className={`admin-form ${cname}`} onSubmit={handleSubmit} name={formName} noValidate>
                        {children}
                </form>
            </div>
        </div>
    )
}

export default AdminForm;