export const MANAGE_FEATURE_SWITCH_BY_COUNTRY_INIT = 'MANAGE_FEATURE_SWITCH_BY_COUNTRY_INIT';
export const MANAGE_FEATURE_SWITCH_BY_COUNTRY_SUCCESS = 'MANAGE_FEATURE_SWITCH_BY_COUNTRY_SUCCESS';
export const MANAGE_FEATURE_SWITCH_BY_COUNTRY_ERROR = 'MANAGE_FEATURE_SWITCH_BY_COUNTRY_ERROR';

export const MANAGE_FEATURE_SWITCH_BY_FEATURE_INIT = 'MANAGE_FEATURE_SWITCH_BY_FEATURE_INIT';
export const MANAGE_FEATURE_SWITCH_BY_FEATURE_SUCCESS = 'MANAGE_FEATURE_SWITCH_BY_FEATURE_SUCCESS';
export const MANAGE_FEATURE_SWITCH_BY_FEATURE_ERROR = 'MANAGE_FEATURE_SWITCH_BY_FEATURE_ERROR';

export const UPDATE_FEATURE_SWITCH_INIT = "UPDATE_FEATURE_SWITCH_INIT";
export const UPDATE_FEATURE_SWITCH_SUCCESS = "UPDATE_FEATURE_SWITCH_SUCCESS";
export const UPDATE_FEATURE_SWITCH_ERROR = "UPDATE_FEATURE_SWITCH_ERROR";

export const FEATURE_SWITCH_RESET_STATE = "FEATURE_SWITCH_RESET_STATE"
export const UPDATE_FEATURE_SWITCH_RESET_STATE = "UPDATE_FEATURE_SWITCH_RESET_STATE"

