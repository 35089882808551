import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  checkEditForm,
  findMobileError,
  findzipCodeError,
  trimAddressFieldsLength,
  validateEmail,
  verifyItalyBusinessUser,
} from "../../constants/AppMethod";
import EcoProButton from "../shared/button/button";
import "./FormBrother.css";
import FormCheck from "../shared/formElements/FormCheck";
import { showZipCodeError, showMobileError, showEmailError, showSDIError } from "../../actions/MyAccount";
import {
  alphaNumericForms,
  alphaNumericRegex,
  numericRegex,
  onlyNumericForms,
  blockSpecialCharacterRegex,
  ADDRESS_FIELDS_INPUT_MAX_LENGTH,
  BILLING_ADDR_ADDRESS1_INPUT_MAX_LENGTH,
  ITALY_COUNTRY
} from "../../constants/AppConstants";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { routePath, WebCustomerRoutePaths } from "../../constants/Routes";

export const FormContext = React.createContext({
  form: {},
});

function FormBrother(props) {
  const { t } = useTranslation(["translation"]);

  const result = useSelector((state) => state.MyAccount);
  const dispatch = useDispatch();
  const {
    children,
    submit = () => {},
    showForm = () => {},
    checkboxStatus,
    account,
    status,
    title,
    initialValues,
    wasValidated,
    checkboxLabel,
    editTitle,
    btnTitle,
    btnID,
    web,
    validationclassName,
    action = null,
    formType = null
  } = props;

  const [formContent, setFormContent] = useState(initialValues);
  const [checkboxContent, setCheckboxContent] = useState(checkboxLabel);
  const [showProvince, setDisplayProvince] = useState(false);
  const [showSDI, setShowSDI] = useState(false);
  const location = useLocation();

  useEffect(()=>{
    if(location.pathname === routePath.ACCOUNT || location.pathname === WebCustomerRoutePaths.ACCOUNT){
      setFormContent(initialValues)
      setCheckboxContent(checkboxLabel)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[initialValues])
  const handleTel = (value,name) => {
    setFormContent({
      ...formContent,
      [name]:value
    });

    if(name === "mobileNumber"){
      if (findMobileError(value) === false && result.mobileError === true) {
        dispatch(showMobileError("mobile", false));
      }
    }
    if(name === "telephoneNumber"){
      if ((findMobileError(value) === false && result.telephoneError === true) || value === undefined) {
        dispatch(showMobileError("telephone", false));
      }
    } 
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    if (alphaNumericForms.includes(name)) {
      if (value === "" || alphaNumericRegex.test(value)) {
        setFormContent({
          ...formContent,
          [name]: value,
        });
      }
    } else if (onlyNumericForms.includes(name)) {
      if (value === "" || numericRegex.test(value)) {
        setFormContent({
          ...formContent,
          [name]: value,
        });
      }
    } 
    else {
      if (value === "" || blockSpecialCharacterRegex.test(value)) {
        setFormContent({
          ...formContent,
          [name]: value,
        });
      }
    }
    if(name === 'country'){
      handleProvinceField(value);
    } 
    if (account === "billingAddress"  && name === "country"){
      handleSDIfield(value)
    }

    if(event.target.name === "postcode"){
      setFormContent({
        ...formContent,
        [name]: value.toUpperCase(),
      });
    }
    else{
      if (alphaNumericForms.includes(name)) {
        if (value === "" || alphaNumericRegex.test(value)) {
          setFormContent({
            ...formContent,
            [name]: value,
          });
        }
      } else if (onlyNumericForms.includes(name)) {
        if (value === "" || numericRegex.test(value)) {
          setFormContent({
            ...formContent,
            [name]: value,
          });
        }
      } else {
        if (value === "" || blockSpecialCharacterRegex.test(value)) {
          setFormContent({
            ...formContent,
            [name]: value,
          });
        }
      }
    }
    if (
      account === "billingAddress" &&
      formContent.sameAsShippingAddress === true  && name !=="eInvoiceId"
    ) {
        setFormContent({
          ...formContent,
          [name]: value,
          sameAsShippingAddress: false,
        });
      
    }

    if (event.target.name === "postcode" && formContent.country) {
      if (
        findzipCodeError(formContent.country, event.target.value) === false &&
        result?.showZipError === true
      ) {
        dispatch(showZipCodeError(false));
      }
    }
    if (event.target.name === "emailAddress" && validateEmail(value) === false && result?.emailError === true) {
      dispatch(showEmailError(false));
    }
    const hasSpaces = (str) => /\s/.test(str);
    const hasInvalidChars = (str) => !alphaNumericRegex.test(str); // Check for spaces or special characters
    
    if (event.target.name === "eInvoiceId" && event?.target?.value?.length === 7 && !(hasInvalidChars(event.target.value) || hasSpaces(event.target.value)) && result?.sdiError === true) {
      dispatch(showSDIError(false));
    }
  };
  const handleCheckChange = (event) => {
    const { name, checked } = event.target;
    setFormContent({
      ...formContent,
      [name]: checked,
    });
    if (event.target.name === "sameAsShippingAddress") {
      if (event.target.checked === true) {
        handleSDIfield(result.account.shippingAddress.country)
        setFormContent({
          ...formContent,
          address: trimAddressFieldsLength(result.account.shippingAddress.address, BILLING_ADDR_ADDRESS1_INPUT_MAX_LENGTH),
          addressLine2: trimAddressFieldsLength(result.account.shippingAddress.addressLine2, ADDRESS_FIELDS_INPUT_MAX_LENGTH),
          city: result.account.shippingAddress.city,
          country: result.account.shippingAddress.country,
          postcode: result.account.shippingAddress.postcode,
          company: result.account.shippingAddress.company,
          province: result.account.shippingAddress.province,
          sameAsShippingAddress: true,
        });
        handleProvinceField(result.account.shippingAddress.country)
      } else if (event.target.checked === false) {
        setFormContent({
          ...formContent,
          address: "",
          addressLine2: "",
          city: "",
          country: "",
          postcode: "",
          company: "",
          province: "",
          sameAsShippingAddress: false,
        });
        setDisplayProvince(false)
      }
    }
  };
/**
 * Method to show/hide province field
 * Applies for Italy users
 * @param {string} country 
 * @returns void
 */
  const handleProvinceField = (country) => {
    (country && country.toUpperCase() === 'ITALY') ? setDisplayProvince(true) : setDisplayProvince(false);
  }
/**
 * Method to show/hide SDI code field
 * Applies for Italy Business Users
 * @param {string} country 
 * @returns void
 */
  const handleSDIfield = (country) => {
    const isItalyBusinessUser = verifyItalyBusinessUser(country)
    setShowSDI(isItalyBusinessUser)
  }

  useEffect(() => {
     if(result.account.shippingAddress.country.toUpperCase() === ITALY_COUNTRY){
      if(checkboxContent?.termsAndPrivacy){
        const updatedObject = { ...checkboxContent, localTerms: t("FORM_CONTENT.FORM_LABEL.CHECK_LOCAL_TERMS") };
        setCheckboxContent(updatedObject)
      }
    }
    else{
      const updatedObject = { ...checkboxContent };
      delete updatedObject.localTerms;
      setCheckboxContent(updatedObject)    
    }
    if(account === "shippingAddress"){
      const country = (initialValues.country !== null ||  result.account.shippingAddress.country !== null) ? (initialValues.country || result.account.shippingAddress.country) : null;
      handleProvinceField(country);
    }
    if (account === "billingAddress") {
      if (formContent.sameAsShippingAddress === true) {
        setFormContent({
          ...formContent,
          address: trimAddressFieldsLength(result.account.shippingAddress.address, BILLING_ADDR_ADDRESS1_INPUT_MAX_LENGTH),
          addressLine2: trimAddressFieldsLength(result.account.shippingAddress.addressLine2, ADDRESS_FIELDS_INPUT_MAX_LENGTH),
          city: result.account.shippingAddress.city,
          country: result.account.shippingAddress.country,
          postcode: result.account.shippingAddress.postcode,
          company: result.account.shippingAddress.company,
          province: result.account.shippingAddress.province,
          sameAsShippingAddress: true,
        });
        handleProvinceField(result.account.shippingAddress.country);
      }
      if(action === "billingAddress_edit"){
        handleProvinceField(initialValues.country);
      }
      const country = (initialValues.country !== null ||  result.account.billingAddress.country !== null) ? (initialValues.country || result.account.billingAddress.country) : null;
        handleSDIfield(country)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const formBtnComponent = (
    status === "active" ?
    <div className={`${web ? 'web-form-btn' : 'form-btn'}`}>
      <EcoProButton
        btnID={btnID}
        btnType="submit"
        cname={`default ${web && 'epic-blue-btn web-signup-form web-ecopro-btn f-14'}`}
        onBtnClick={(e) => submit(e,formContent, account, formType)}
      >
        {btnTitle}
      </EcoProButton>
    </div>:null
  );

  const formCheckComponent = (
    checkboxStatus === true && status === "active" ? (
      Object.keys(checkboxContent).length > 0 && Object.keys(checkboxContent).map((label) => (
        <div className={`form-contianer ${web && 'web-form-container'}`}>
          <form
            className={`form-brother-check mt-10 ${validationclassName? validationclassName :"needs-validation"} ${
              wasValidated && "was-validated"
            } ${web ? 'web-form-check':'form-check-padding'}`}
          >
            <FormCheck
              web={web}
              label={checkboxContent[label]}
              name={
                account === "billingAddress" ? label : "offers"
              }
              id={label}
              handleCheck={(e) => handleCheckChange(e)}
              formContent={formContent}
              required={account === "billingAddress" ? true : false}
              errorMsg={account === "billingAddress" ? t("FORM_CONTENT.FORM_ERROR.CHECK_TERMS_CONDITION"):null}
            />
          </form>
        </div>
      ))
    ) : null
  )
  
  return (status === "inactive" || "" || undefined) && !web ? null : 
   (
    <div className="forms">
      <>
        <div className="form-contianer">
          <form
            className=
            {
              `row form-brother ${validationclassName? validationclassName :"needs-validation"}
              ${wasValidated && "was-validated"} 
              ${status === "completed" && "form-enter-pointer"}
              ${status === "inactive" && "disable-web-form"}
              ${(status === "completed") || (status==="inactive" && web)? "form-padding":undefined} 
              ${checkEditForm(location.pathname) ? "edit-form" : undefined} 
              ${web && "web-form-brother"}`
            }
            noValidate
            {...(status === "completed" && {
              onClick: () => showForm(account),
            })}
          >
            <div className={`${checkEditForm(location.pathname)?null:"form-header"} ${status === "active"?"form-header-margin":null}`}>
              <h1
                className={`form-heading-label ${
                  checkEditForm(location.pathname)
                    ? "f-12 fc-e"
                    : "f-16 fc-g"
                }`}
              >
                {title}
              </h1>
              {checkEditForm(location.pathname) ? (
                  <h1 className="form-heading-label f-18 fc-g mt-10">{editTitle}</h1>
              ) : null}
              {status === "completed" ? <div className="circle"></div> : null}
            </div>

            {status === "active" && account === "billingAddress" && (location.pathname === routePath.ACCOUNT || location.pathname === WebCustomerRoutePaths.ACCOUNT || location.pathname === routePath.SHENLONG_REDIRECT)? (
              <div className="mt-10 was-validated">
                <FormCheck
                  web={web}
                  reverse={true}
                  label={t(
                    "FORM_CONTENT.FORM_LABEL.CHECK_SAME_AS_SHIPPING_ADDR"
                  )}
                  name="sameAsShippingAddress"
                  id="sameAsShippingAddress"
                  handleCheck={(e) => handleCheckChange(e)}
                  formContent={formContent}
                  required={false}
                />
              </div>
            ) : null}

            {status === "active" ? (
              <FormContext.Provider
                value={{
                  formContent,
                  handleFormChange,
                  handleTel,
                  showProvince,
                  showSDI
                }}
              >
                {children}
              </FormContext.Provider>
            ) : null}

            {web && formCheckComponent}

            {status === "active" && web ? 
              formBtnComponent
            : null}
          </form>
        </div>
        {!web && formCheckComponent}
        {!web && 
          formBtnComponent
        }
      </>
    </div>
  );
}

export default FormBrother;
