import * as actionTypes from '../constants/ActionTypes/UserAccount.js'
import { SIGNUPTYPE } from '../constants/AppConstants.js';

export const FetchUserAccount = (ADB2C, UID, deviceId = null, signUpType = SIGNUPTYPE.MOBILE) => ({
    type: actionTypes.FETCH_USER_ACCOUNT,
    ADB2C,
    UID,
    deviceId,
    signUpType
});

export const ResetUserAccount = () => ({
    type: actionTypes.FETCH_USER_ACCOUNT_RESET
})


