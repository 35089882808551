import React from "react";
import { useTranslation } from "react-i18next";
import "./ConfirmDevice.css";
import SubHeader from "../../../../components/shared/subheader/SubHeader";
import ErrorPage from "../../../../components/shared/errorPage/ErrorPage";
import { refreshBtn, LOCALSESSIONDATAKEYS } from "../../../../constants/AppConstants";
import DisplayConfirmDevices from "../../../../components/DisplayDevices/DisplayConfirmDevice/DisplayConfirmDevices";
import { getLocalSessionData } from "../../../../utils/Authentication/handleUserSession";

const ConfirmDevice = (props) => {
  const { t } = useTranslation(["translation"]);
  const deviceId = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID);;
  let deviceListResult = {};
  deviceListResult = props.location?.state?.deviceListResult;
  const devices = deviceListResult?.deviceList?.devices;
  let confirmedDevice = {};

  if (deviceListResult?.success === true) {
    devices &&
      devices.map((device) => {
        if(device.deviceId === deviceId){
          confirmedDevice = device
        }
        return device;
      });
  }
  if (
    deviceListResult?.success === true &&
    devices.map((device) => device.hasOwnProperty("model"))
  ) {
    return (
      <div className="mt-40">
        <div className="confirm-device">
          <SubHeader
            currentStep="1"
            totalStep="4"
            stepName={t("SUB_HEADER.STEP_1.STEP_NAME")}
            stepInfo={t("SUB_HEADER.STEP_1.STEP_INFO")}
          />
          {confirmedDevice  &&
          <DisplayConfirmDevices confirmDevice={confirmedDevice} />}
        </div>
      </div>
    );
  } else {
    return (
      <ErrorPage
        errorInfo={t("ERROR_CONTENT.NO_DEVICES")}
        showRefreshBtn={refreshBtn.HIDE}
      ></ErrorPage>
    );
  }
};

export default ConfirmDevice;
