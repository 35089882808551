import { call, put, takeEvery } from 'redux-saga/effects';
import { _CALLGETAPI } from '../../api/apiHandler';
import { LOCALSESSIONDATAKEYS, _API_ENDPOINT } from '../../constants/AppConstants';
import { setLocalSessionData } from '../../utils/Authentication/handleUserSession';
import * as actionTypes from '../../constants/ActionTypes/SignIn';
import i18next from 'i18next';

function _CALL_BOL_API(loginToken){
    const endpoint = _API_ENDPOINT.BOL_API;
    const payload = {
        params: {
            loginToken: loginToken
        }
      }
     return _CALLGETAPI(endpoint, payload)
    .then(response => {
        if(response.hasOwnProperty('bolAccountId') && response.hasOwnProperty('deviceId')){
            if (response.deviceId && response.bolAccountId) {
                setLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID, response.deviceId);
                setLocalSessionData(LOCALSESSIONDATAKEYS.UID, response.bolAccountId);
                return response;
            } else {
                throw new Error(i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR'));
            }
        } else {
            throw new Error(i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR'));
        }
    })
    .catch((error) => {
        throw error;
    });
}

function* handleBOLAPI(action) {
    try {
        const loginToken = action.BOLLoginToken;
        if(loginToken){
            const BOLAPI_data = yield call(_CALL_BOL_API, action.BOLLoginToken);
            yield put({type: actionTypes.BOL_API_SUCCESS, BOLData: BOLAPI_data});
        } else {
            yield put({type: actionTypes.BOL_API_FAILURE, message: i18next.t('ERROR_CONTENT.INFO_GENERAL')});
        }
    } catch (e) {
        yield put({type: actionTypes.BOL_API_FAILURE, message: e.message});
    }
}

function* BOLAPI() {
    yield takeEvery(actionTypes.BOL_API_INIT, handleBOLAPI);
 }
 
 export default BOLAPI;