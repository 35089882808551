import React from "react";
import { Container } from "reactstrap";
import "./WebSubHeader.css";
import { ReactComponent as ChevronLeft } from "../../assets/images/chevron_left.svg";
import { Trans } from "react-i18next";

const WebSubHeader = (props) => {
  const {currentStep,totalStep,stepName, stepInfo, stepInfoSub, moveToPreviousPage,previousBtnText,planFeature, showBackNavArrow = true, cname = 'none', withProgressBar,hideStepInfo, stepInfoClassName} = props
  return (
    <Container className={`web-subheader ${cname}`}>
      {hideStepInfo ? 
        null 
        :
        <div className="step">
          <Trans
            className="page-title-steps"
            i18nKey="SUB_HEADER.STEPS_COUNT_INFO"
            currentStep={currentStep}
            totalStep={totalStep}
          >
            STEP <strong>{{ currentStep }}</strong> of <strong>{{ totalStep }}</strong>
          </Trans>
        </div>
      }
      {showBackNavArrow && 
      <div className={withProgressBar?"page-title-back-btn header-progress-bar":"page-title-back-btn"} onClick={()=>moveToPreviousPage()}>
        <ChevronLeft fill="#545454" />
        <div className="f-12 fw-b">{previousBtnText}</div>
      </div>
      }
      <h1 className="f-20 fc-e">{stepName}</h1>
      <div className="stepInfo-wrapper">
        <div className={`fc-g sub-step-info ${stepInfoClassName}`}>{stepInfo}</div>
        {planFeature && planFeature}
      </div>
      {stepInfoSub && <p className=" sub-step-info fc-g">{stepInfoSub}</p>}
    </Container>
  );
};

export default WebSubHeader;
