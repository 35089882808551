import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import {Provider} from 'react-redux'
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import store from './store';
import './utils/i18n/i18n'
import './assets/css/main.css'
import Loader from "./components/shared/loader/Loader";
import { EasySetupRoutePath } from "./routes/RoutePaths/EasySetupRoutePath";
import { WebCustomerUnProtectedRouteComp } from "./routes/RouteWithSubRouteComp/RoutesWithSubRoutes";
import { easySetupRoutePath } from "./constants/Routes"; 
// MSAL imports
import {MSALInstance} from './utils/Authentication/Azure/MSALInstanceInit';
import './utils/Authentication/Azure/ADB2CAuthToken';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename={baseUrl}>
      <React.Suspense fallback={<Loader></Loader>}>
        {(window.location.pathname !== easySetupRoutePath.BANNER ) ?(
          <App msalInstance={MSALInstance} />
        )
        :
        <div className="iframe-plans">
          {EasySetupRoutePath.map((route,i) => (
            <WebCustomerUnProtectedRouteComp key={i} {...route} />
          ))}
         </div>
      }
      </React.Suspense>
    </BrowserRouter>
  </Provider>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

