import React from "react";
import { Collapse, Offcanvas } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import EcoproLogo from "../../../../assets/images/admin-portal/ecopro-content-logo.svg"
import { ReactComponent as DashboardImg } from "../../../../assets/images/dashboard.svg";
import { ReactComponent as RightArrowImg } from "../../../../assets/images/rightArrow.svg";
import NavItems from "../../../../utils/AdminPortal/SideBarNavigationMenuItems";
import './SideBarNav.css';

const SideBarNav = (props) => {
    const { isSideNavOpen, handleSidebarNavClose, toggleSubmenu, subNavOpen, handleActiveStatusSubMenu} = props;
    return(
        <Offcanvas show={isSideNavOpen} onHide={handleSidebarNavClose} className="admin-side-nav-offcanvas" id="admin-side-nav-offcanvas">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title><img src={EcoproLogo} alt="Brother" className="brother-logo" /></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="admin-sidebar-nav-offcanvas">
                <ul className="admin-sidebarNav-menu-wrapper">
                {
                    Object.values(NavItems).map((item) => (
                        <li className="main-menu-item-wrapper"  key={item.id}  onClick={() => toggleSubmenu(item.text, item.sub_menus)}>
                            <NavLink to={item.link}className="collapsed">
                                <span className="main-menu-icon"><DashboardImg/></span>
                                <span className="main-menu-text">{item.text}</span>
                                { 
                                    subNavOpen["id"] !== item.text && item.hasOwnProperty('sub_menus') && Object.values(item.sub_menus).length > 0 && 
                                    <span className="main-menu-toggle-arrow"><RightArrowImg/></span>
                                }
                                { 
                                    subNavOpen["id"] === item.text && item.hasOwnProperty('sub_menus') && Object.values(item.sub_menus).length > 0 && 
                                    <span className="main-menu-toggle-arrow"><RightArrowImg/></span>
                                }
                            </NavLink>
                            <Collapse in={subNavOpen["id"] === item.text}>
                                <ul id={item.text.toLowerCase().split(" ").join("-")} className={`admin-sidebarNav-sub-menu-wrapper`} onClick={() => handleActiveStatusSubMenu(item.text)}>
                                    {
                                        item.sub_menus && Object.values(item.sub_menus).map((sub_menu_item) => (
                                            <li className="sub-menu-item-wrapper" key={sub_menu_item.id}>
                                                <NavLink to={sub_menu_item.link}>
                                                    <span className="sub-menu-arrow-indicator"><RightArrowImg/></span>
                                                    <span>{sub_menu_item.text}</span>
                                                </NavLink>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </Collapse>
                        </li>
                    ))
                } 
                </ul>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default SideBarNav;