import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ecopro_logo from "../../../assets/images/teaser_ecopro_logo.png";
import { ReactComponent as RightArrowImg } from "../../../assets/images/rightArrow.svg";
import { Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import EcoProFeedback from "../ecoprofeedback/ecoprofeedback";
import HamburgerNavItems from "../../../utils/Dashboard/HamburgerNavItems";
import './hamburgerMenuDesktopApp.css';

const HamburgerMenuDesktopApp = () => {
    const location = useLocation();
    const enrollmentStatus = useSelector(state => state.EnrollmentStatus);
    const navItems = HamburgerNavItems();
    const [activeNavItem, setActiveNavItem] = useState(null);

    useEffect(() => {
        setActiveNavItem(location.pathname);
    }, [location.pathname])
 
    return (
        <div className="hamburger-menu-desktop-wrapper hamburger-menu-desktop-view">
            <div className="header">
                <div className="title offcanvas-title">
                    <img className="ecopro_logo" src={ecopro_logo} alt="EcoPro Logo" />
                </div>
            </div>
            <div className="body offcanvas-body">
                <div className="navbar-nav">
                    <div className="container">
                        { Object.values(navItems).map((val) => (
                            val.item_enabled &&  <a id={val.item_id} href={val.item_link} className={`${(activeNavItem === val.item_link) ? 'active-link': 'inactive-link'} ${(enrollmentStatus.enrollment_completed) ? 'navigation-enabled' : 'navigation-disabled'}`}>
                            <Row className="row-margin">
                                <div className="col-10 row">
                                    <div className="col">{val.item_logo}</div>
                                    <span className="col-10">{val.item_name}</span>
                                </div>
                                <div className="col arrow">
                                    <RightArrowImg />
                                </div>
                            </Row>
                            </a>
                        
                        ))}
                    </div>
                </div>
               <EcoProFeedback displayType="modal"/>
            </div>
        </div>
    )
}
export default HamburgerMenuDesktopApp;