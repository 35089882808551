import React from "react";
import { useTranslation } from "react-i18next";
import { getLangCode } from "../../../utils/Internationalization/handleAppInternationalization";
import "./AveragePagesUsed.css";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import {
  chooseMaxOrMinDate,
  getAverageValue,
  getChartLabels,
  getChartValues,
  getGradient,
  getYAxesMaxValue,
  sortDateInDescendingOrder,
} from "../../../constants/AppMethod";
Chart.register(...registerables);
Chart.register(annotationPlugin);

const AveragePagesUsed = (props) => {
  const { printHistoryResult } = props;
  

  const { t } = useTranslation(["translation"]);
  const dateDisplayOptions = { year: "numeric", month: "short" };
  const langCode = getLangCode();
  const sixMonthsData = sortDateInDescendingOrder(printHistoryResult.printHistory);
  const chartLabels = getChartLabels(sixMonthsData).labels;
  const chartValues = getChartValues(chartLabels, sixMonthsData);
  const chartAverageValue = getAverageValue(chartValues);
  const yAxesMaxValue = getYAxesMaxValue(sixMonthsData);
  const endDate = chooseMaxOrMinDate("max",sixMonthsData,dateDisplayOptions,langCode);
  const startDate = getChartLabels(sixMonthsData).endDate;
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        position: "right",
        min: 0,
        max: yAxesMaxValue,
        ticks: {
          stepSize: 50,
          color: "#545454",
          borderDash: [2, 2],
        },
        grid: {
          drawTicks: false,
          display: false,
        },
      },
      y1: {
        position: "left",
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      x: {
        position: "bottom",
        ticks: {
          font: {
            size: 12,
            family: "Arial",
          },
          tickBorderDash: [2, 2],
          color: "#545454",
        },
        grid: {
          tickBorderDash: [2, 2],
          drawBorder: true,
          borderColor: "#DDDDDD",
          borderWidth: 1,
          borderDash: [2, 2],
          color: "#DDDDDD",
          lineWidth: (ctx) => (ctx.index % 6 ? 0.5 : 0),
        },
      },
      x1: {
        position: "top",
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      border: {
        display: false,
        dashOffset: 1,
        z: 5,
      },
    },
    plugins: {
      autocolors: false,
      datalabels:{
        display:false
      },
      annotation: {
        annotations: {
          line1: {
            type: "line",
            yMin: chartAverageValue,
            yMax: chartAverageValue,
            borderColor: "#E4E4E4",
            borderWidth: 0.5,
            borderDash: [2, 2],
          },
        },
      },
      legend: {
        display: false,
      },
    },
  };

  const data = {
    legend: {
      display: false,
    },
    labels: chartLabels,
    datasets: [
      {
        data: chartValues,
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return null;
          }
          return getGradient(ctx, chartArea, "#007E8F", "#00B1C9");
        },
        barThickness: 15,
        borderRadius: 5,
      },
    ],
  };

  return (
    <div className="average-pages-used-wrapper">
      <div className="average-page-trends">{t("PRINT_ACTIVITY.TRENDS")}</div>
      <div className="average-pages-used-content">
        <div className="trends-header">
          {t("PRINT_ACTIVITY.MONTHLY_AVERAGE_SIX_MONTHS")}
        </div>
        <div>
          <span className="average-page-count">{chartAverageValue}</span>{" "}
          {t("PRINT_ACTIVITY.PAGES")}
        </div>
        <div className="trends-date">
          {startDate} - {endDate}
        </div>
        <div className="trends-bar-chart-wrapper">
          <Bar
            id="chart"
            data={data}
            height={100}
            width={300}
            options={options}
          />
        </div>
      </div>
    </div>
  );
};

export default AveragePagesUsed;
