import React, { useEffect, useState, useMemo } from "react";
import i18next from "i18next";
import './Loader.css';

const Loader = (props) => {
  const { slowResponse, setOverlay = false, webConnection = false, cancelSubscription = false } = props;
  const [contentIndex, setContentIndex] = useState(0);
  
  const mainContent = useMemo(()=> {
    if(webConnection){
      return [i18next.t("LOADER.CONNECT_PRINTER")]
    }
    if(cancelSubscription){
      return [""]
    }
    else{
      return [i18next.t("LOADER.CREATE_SUBSCRIPTION"), i18next.t("LOADER.CONFIGURE_PRINTER"), i18next.t("LOADER.FINALISING")]
    }
  },[webConnection, cancelSubscription])
  const subContent = useMemo(() =>{
    if(webConnection || cancelSubscription){
      return [""];
    }
    else{
      return [i18next.t("LOADER.CONNECT_PLAN"), i18next.t("LOADER.CONNECT_PRINTER"), i18next.t("LOADER.ADD_FINISH_TOUCH")]
    }
    
   }, [webConnection, cancelSubscription]);

  
  useEffect(() => {
    if(slowResponse){
      setLoader(mainContent)
    }
   
  }, [mainContent, subContent, slowResponse]);
  const setLoader = (content) => {
    let startTime = new Date().getTime();
    const timer = () => {
      if(new Date().getTime() - startTime > 12000){
        clearInterval(interval);
        return;
      }
      setContentIndex(prevIndex => {
        if(prevIndex === content.length - 1){
          return 0;
        } 
        return prevIndex + 1;
      })
    };
    const interval = setInterval(timer, 5000);
  }
  return (
        <div className={`spinner-wrapper ${(setOverlay) ? "apply-overlay-bg" : ""}`}>
            <div className="spinner-border">
                <span className="visually-hidden">Loading...</span>
            </div>
            {slowResponse?
            <div className="f-20 fw-b spinner-info">
              <span className="f-20">{mainContent[contentIndex]}</span>
              <div className="f-14 mt-10">{subContent[contentIndex] }</div>
            </div>
             :
            null} 
        </div>
    );
}

export default Loader;