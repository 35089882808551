import { call, put, takeEvery } from "redux-saga/effects";
import i18next from "i18next";
import * as actionTypes from "../../constants/ActionTypes/Dashboard";
import { _CALLGETAPI } from "../../api/apiHandler";
import { _API_ENDPOINT, FAILURE } from "../../constants/AppConstants";

function _FETCHDEVICEINFO(deviceId, UID) {
  const endpoint = _API_ENDPOINT.DEVICE_INFO;
  const payload = {
    params: {
      deviceID: deviceId,
      UserId: UID,
      RequestOrigin: 'MyAccount'
    }
  };
  return _CALLGETAPI(endpoint, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function* handleDeviceInfo(action) {
  try {
    const deviceID = action.deviceId;
    const UID = action.UID;
      if(UID && deviceID){
      const deviceInfo = yield call(
        _FETCHDEVICEINFO,
        deviceID,
        UID
      );
      if (deviceInfo.actionStatus.toUpperCase() !== FAILURE && deviceInfo.service){
        yield put({
          type: actionTypes.DASHBOARD_DEVICE_INFO_CALL_SUCCESS,
          deviceInfo: deviceInfo
        });
      } else if (deviceInfo.actionStatus.toUpperCase() !== FAILURE && !deviceInfo.service){
        yield put({
          type: actionTypes.DASHBOARD_DEVICE_INFO_CALL_ERROR,
          message: i18next.t('ERROR_CONTENT.ERR_INFO.SUBSCRIPTION_REQUIRED')
        });
      } else {
        yield put({
          type: actionTypes.DASHBOARD_DEVICE_INFO_CALL_ERROR,
          message: i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')
        });
      }
    } else {
      yield put({
        type: actionTypes.DASHBOARD_DEVICE_INFO_CALL_ERROR,
        message: i18next.t('ERROR_CONTENT.INFO_GENERAL')
      });
    }
  } catch (e) {
    /**
     * This conditional statement is added to avoid
     * displaying error message in view while logout from app
     */
    if(e instanceof TypeError === false && e.message !== "401"){
      yield put({
        type: actionTypes.DASHBOARD_DEVICE_INFO_CALL_ERROR,
        message: e.message
      });
    }
  }
}
function* DashboardDeviceInfo() {
  yield takeEvery(
    actionTypes.DASHBOARD_DEVICE_INFO_CALL_INIT,
    handleDeviceInfo
  );
}

export default DashboardDeviceInfo;
