import React from "react";
import "./PageHeader.css";

const PageHeader = (props) => {
    const { pageTitle, pageSubTitle }  = props
    return (
        <div className='admin-page-header-wrapper'>
            <h1 className="admin-page-title">{pageTitle}</h1>
            { pageSubTitle && <h2 className="admin-page-sub-title">{pageSubTitle}</h2> }
        </div>
    )
}

export default PageHeader;