import * as actionTypes from "../../constants/ActionTypes/AdminPortal/PrinterModels";

export const fetchModelsListByCountry = (countryCode, printerTypeId) =>({
    type: actionTypes.MANAGE_PRINTER_MODELS_BY_COUNTRY_INIT,
    countryCode,
    printerTypeId
})

export const fetchCountryListByPrinterModel = (printerModel) => ({
    type: actionTypes.MANAGE_PRINTER_MODELS_BY_MODELS_INIT,
    printerModel
})

export const updatePrinterModelsStatus = (payload) =>({
    type: actionTypes.UPDATE_PRINTER_MODELS_INIT,
    payload
})

export const managePrinterModelsResetState = () => ({
    type: actionTypes.MANAGE_PRINTER_MODELS_RESET_STATE
})

export const updatePrinterModelsStateReset = () => ({
    type: actionTypes.UPDATE_PRINTER_MODELS_RESET_STATE
})