import * as actionTypes from '../../constants/ActionTypes/Dashboard';

const initialState = {
    orderHistory: {},
    loading: false,
    error: false
}

const DashboardOrderHistory = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.DASHBOARD_ORDER_HISTORY_CALL_INIT:
            return {
                ...state,
                loading: true
            }
        case actionTypes.DASHBOARD_ORDER_HISTORY_CALL_SUCCESS:
            return {
                ...state,
                orderHistory: action.orderHistory,
                loading: false
            }
        case actionTypes.DASHBOARD_ORDER_HISTORY_CALL_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message
            }
        default:
            return {...state};
    }
}

export default DashboardOrderHistory;