/**
 * This file serves translated plan names.
 * 
 * Though the web signup app is served based on browser setting languages
 * the plan name will be served based on the domain language.
 */
import axios from "axios";
import { extractLangcodeFromDomain } from "../Internationalization/handleAppInternationalization";

const TranslatedPlanNames = async(langcode, dataToBeTranslated) => {
    let ctryCodeExtracted = extractLangcodeFromDomain().toUpperCase();
    if(ctryCodeExtracted === "UK"){
        ctryCodeExtracted = "GB"
    }
    const locale = `${langcode}-${ctryCodeExtracted}`;
    const response = await axios.get(`./locales/${locale}/translation.json`);
    const translatedPlanName = response.data.ECOPROCAROUSEL_CONTENT[dataToBeTranslated];
    return translatedPlanName;
 }
 
 export default TranslatedPlanNames;