import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EcoProButton from "../button/button";
import "./ErrorPage.css";
import { Link } from "react-router-dom";

const ErrorPage = (props) => {
  const { t } = useTranslation(["translation"]);
  const [harmburgermenuErrClass, setHamburgermenuErrClass] = useState('no-hamburger-menu');
  const {
    errorInfo,
    to,
    showRefreshBtn,
    showTryAgain,
    withinComponent,
    isMobileSubscriptionError = false,
    webConnectionDashboardError
  } = props;

  useEffect(() => {
     const isHamburgermenuExists = document.querySelector('.hamburger-menu-desktop-wrapper');
    if(isHamburgermenuExists){
      const isCustomerHeaderDesktop = document.querySelector('.customer-header-desktop');
      const headerDesktopStyles = getComputedStyle(isCustomerHeaderDesktop);
      const getDisplayProperty = headerDesktopStyles.getPropertyValue("display");
      if(getDisplayProperty !== 'none'){
        setHamburgermenuErrClass('hamburger-menu-error')
      }
    }
  }, [])

  const onRefresh = () => { 
    window.location.href="/";
  };

  return (
    <div className={`${(harmburgermenuErrClass === 'no-hamburger-menu' ? 'error-content' : harmburgermenuErrClass)} ${withinComponent?"withinComp":null} ${webConnectionDashboardError? 'web-dashboard-error':null}`}>
      <div>
        <div className="error-header f-20 fc-e">
          <div>{t("ERROR_CONTENT.HEADER1")}</div>
          <div>{t("ERROR_CONTENT.HEADER2")}</div>
        </div>
        <p className="error-info f-12 fc-g mt-10">
          {errorInfo ? errorInfo : t("ERROR_CONTENT.INFO_GENERAL")}
        </p>
        {showRefreshBtn && (
          <div className="mt-30 refresh-btn">
            <EcoProButton cname="default" onBtnClick={onRefresh}>
              {t("ERROR_CONTENT.REFRESH")}
            </EcoProButton>
          </div>
        )}
        { showTryAgain && !isMobileSubscriptionError &&
          <div className="mt-30 refresh-btn">
            <Link className="ecopro_btn default link-txt-align" to={to}>{t("ERROR_CONTENT.TRY_AGAIN")}</Link>
          </div>
        }
        { showTryAgain && isMobileSubscriptionError && 
          <div className="mt-30 refresh-btn">
            <Link className="ecopro_btn default link-txt-align" to={to}>{t("ERROR_CONTENT.TRY_AGAIN")}</Link>
          </div>
        }
        {/* 
          @todo Will be enabled after integration
          <div className="error-support f-16 fc-e mt-20">
            {t("ERROR_CONTENT.GET_SUPPORT")}
          </div> 
        */}
      </div>
    </div>
  );
};

export default ErrorPage;
