import * as actionTypes from '../../constants/ActionTypes/Dashboard';

const initialState = {
    updatePlan: {},
    loading: false,
    error: "",
    success:false,
}

const UpdatePlan = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.UPDATE_PLAN:
            return {
                ...state,
                loading: true,
                error:""
            }
        case actionTypes.UPDATE_PLAN_SUCCESS:
            return {
                ...state,
                updatePlan: action.updatePlan,
                loading: false,
                success:true,
                error:""
            }
        case actionTypes.UPDATE_PLAN_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message
            }
        case actionTypes.UPDATE_PLAN_RESET:
            return {
                ...state,
                error:"",
                updatePlan: {},
                success:false
            }
        default:
            return {...state};
    }
}

export default UpdatePlan;