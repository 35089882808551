import React, { useCallback, useState } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DashboardAccountOverview from "../../../../../../components/DashboardAccountOverview/DashboardAccountOverview";
import DashboardOrdersHistory from "../../../../../../components/DashboardOrdersHistory/DashboardOrdersHistory";
import { useDispatch, useSelector } from "react-redux";
import { 
        Is_BrotherPlus_Signup,
        Is_HWInclusive_Signup, 
        getPlanTranslation, 
        handleNavigateToAppStore, 
        pushDataLayer 
    } from "../../../../../../constants/AppMethod";
import { DisplayDate } from "../../../../../../utils/DisplayDate";
import { getLangCode } from "../../../../../../utils/Internationalization/handleAppInternationalization";
import { useEffect } from "react";
import { FetchDashboardPreEnrollInfo, GetRegistrationType } from "../../../../../../actions/Web/Customer/SignUp";
import ErrorPage from "../../../../../../components/shared/errorPage/ErrorPage";
import EcoProButton from "../../../../../../components/shared/button/button";
import printerIcon from "../../../../../../assets/images/connectdevice-printericon.svg";
import alertPrinterIcon from "../../../../../../assets/images/connectdevice-alert-printericon.svg"
import EcoproAlert from "../../../../../../components/shared/alert/alert";
import './ConnectDeviceDashboard.css';
import { 
        getFeatureSwitchByKey, 
        getLocalSessionData, 
        getUserDataByKey, 
        getUserSessionData, 
        setLocalSessionData, 
        WEB_handleSelectedPlanLocalData
    } from "../../../../../../utils/Authentication/handleUserSession";
import { EnrollmentStatus } from "../../../../../../actions/Dashboard";
import WebConnection from "../../../../../../components/Web/Customer/WebConnection/WebConnection";
import { ECOPROTOKEN_CLAIMS, LOCALSESSIONDATAKEYS, PRINT_CODES, featureSwitchName } from "../../../../../../constants/AppConstants";
import Skeleton from "react-loading-skeleton";
import UseAndroidIosAppIcons from "../../../../../../utils/CustomHooks/UseAndroidIosAppIcons";
import EcoProOverlayModal from "../../../../../../components/shared/modal/OverlayModal/EcoProOverlayModal";
import DashboardPopup from "../../../../../../components/BrotherPlus/DashboardPopup/DashboardPopup";

export const DeviceNotConnected = ({header, content, info, btnText1, btnText2, handleConnectDevice, cname}) => (
    <>
        <div className={`device-not-connected-wrapper enrolledDevice-dashboard-block show-xs ${cname}`}>
            <div className="content">
                <h5>{header}</h5>
                <div className="printer-icon">
                    <img
                        className="printer-icon-img"
                        src={printerIcon}
                        alt="device"
                    />
                </div>
                <p>{content}</p>
                <p className="download-app-highlight-txt">{info}</p>
                <div className="device-not-connected-btn">
                    <EcoProButton cname="default" onBtnClick={(e) => handleConnectDevice(e)}>{btnText1}</EcoProButton>
                    {btnText2 && <EcoProButton cname="default device-not-connected-btn-2" onBtnClick={(e) => handleConnectDevice(e)}>{btnText2}</EcoProButton>}
                </div>
            </div>
        </div>
        <div className="device-not-connected-outer-wrapper enrolledDevice-dashboard-block show-lg">
            <div className={`device-not-connected-wrapper ${cname}`}>
                <div className="printer-icon">
                    <img
                        className="printer-icon-img"
                        src={printerIcon}
                        alt="device"
                    />
                </div>
                <div className="content">
                    <h5>{header}</h5>
                    <p>{content}</p>
                    <p className="download-app-highlight-txt">{info}</p>
                    <div className="device-not-connected-btn">
                        <EcoProButton cname="default" onBtnClick={(e) => handleConnectDevice(e)}>{btnText1}</EcoProButton>
                        {btnText2 && <EcoProButton cname="default device-not-connected-btn-2" onBtnClick={(e) => handleConnectDevice(e)}>{btnText2}</EcoProButton>}
                    </div>
                </div>
            </div>
        </div>
    </>
)
        


const ConnectDeviceDashboard = (props) => {
    const { t } = useTranslation(["translation"]);
    const dispatch = useDispatch();
    const langCode = getLangCode();
    const appIconsImgSrc  = UseAndroidIosAppIcons(langCode);
    let planName= 'N/A', printerType = "", planPages= 0,  planPagesLeft= 0, planGivenRolloverPages= 0, planPrintedRolloverPages= 0, planPurchacedOveragePages = 0, planPrintedOveragePages= 0, planPrice = 0, planCurrency = '', discount = [],
    deviceImagePath = "", deviceModel= "",deviceSerialNumber = "",isSwapEligible= "",overageBlockPages= 0;
    const DashboardPreEnrollInfo = useSelector(state => state.GetDashboardPreEnrollInfo)
    const OrdersHistory = useSelector(state => state.DashboardOrderHistory);
    const RegistrationType = useSelector(state => state.GetRegistrationType);
    const ScmsTokenResult = useSelector(state => state.SCMSTokenAuthentication);
    const dateDisplayOptions = { day: '2-digit', month: 'long', year: 'numeric' };
    const displayName = getUserDataByKey("displayName", "personal_details");
    const [instruction2, setInstruction2] = useState("")
    const [registrationType, setRegistrationType] = useState("")
    const [deviceRegistrationFetched, setDeviceRegistrationFetched] = useState(false)
    const [toDisplayDownloadApp, setToDisplayDownloadApp] = useState(false);
    const [modalState, setModalState] = useState(false);
    const [modalProps, setModalProps] = useState({
        modal_title: "",
        modal_sub_title: "",
        modal_content: "",
        cancel_btn_text: "",
        confirm_btn_text: "",
        toggleModal: "",
        customClassName: "",
        confirm_btn_onClick: "",
        type: "normal"
    })
    const [alertData, setAlertData] = useState({
        alertImg: alertPrinterIcon,
        alertClass: 'dashboard-error',
        alertSubHeader: t("CUSTOMER_WEB.CONNECT_DEVICE.FIND_DEVICE")
    })
    const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
    const DEVICE_MODEL = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_MODEL);
    const IsHWInclusive_Signup = Is_HWInclusive_Signup();
    const IsBrotherPlus_Signup = Is_BrotherPlus_Signup();

    const toggleModal = (event) => {
        setModalState(true);
    }
    const handleModalContinue = () => {
        localStorage.setItem('modal_visited', true);
        setModalState(false);
    }
    const handleBOCWebEnrollment = useCallback(() => {
        if(getFeatureSwitchByKey(featureSwitchName.BOC_WEB_ENROLLMENT)){
            dispatch(GetRegistrationType(DEVICE_MODEL, UID))
            setDeviceRegistrationFetched(true);
        }
        else{
            setRegistrationType("shenlong")
        }  
    }, [DEVICE_MODEL, UID, dispatch]);

    const handleAlertMessage = useCallback(()=> {
        if(IsHWInclusive_Signup){
            setAlertData({
                ...alertData,
                alertImg: null,
                alertClass: 'dashboard-success',
                alertSubHeader: t("CUSTOMER_WEB.CONNECT_DEVICE_HW.FIND_DEVICE"),
            })
        } else if(IsBrotherPlus_Signup){
            setAlertData({
                ...alertData,
                alertSubHeader: t("CUSTOMER_WEB.CONNECT_DEVICE_BROTHER_PLUS.FIND_DEVICE"),
            })
        }
       // eslint-disable-next-line
    }, [IsHWInclusive_Signup, IsBrotherPlus_Signup, t]);

    useEffect(() => {
        if(IsBrotherPlus_Signup){
            if (!deviceRegistrationFetched) {
                handleBOCWebEnrollment();
             }
             if(!localStorage.getItem('modal_visited')){
                setModalState(true);
                setModalProps({
                    modal_content: <DashboardPopup handleModalContinue = {handleModalContinue}/>,
                    toggleModal: toggleModal,
                    customClassName: "brother-plus-dashboard-initial-popup-modal",
                })
            }
        } else {
            dispatch(FetchDashboardPreEnrollInfo());
        }
        handleAlertMessage();
        dispatch(EnrollmentStatus(false));
    }, [
        dispatch, 
        IsBrotherPlus_Signup, 
        deviceRegistrationFetched, 
        handleAlertMessage, 
        handleBOCWebEnrollment
    ]);

    useEffect(() => {
        if (DashboardPreEnrollInfo?.success && !deviceRegistrationFetched) {
            const PreEnrollID = DashboardPreEnrollInfo?.data?.preEnrollInfoViewModel?.id; 
            setLocalSessionData(LOCALSESSIONDATAKEYS.PRE_ENROLLMENT_ID, PreEnrollID);
            handleBOCWebEnrollment();
        }
    }, [DashboardPreEnrollInfo, deviceRegistrationFetched, handleBOCWebEnrollment])

    useEffect(()=>{
        if (RegistrationType?.success) {
            const bocDeviceRegistrationTypes = RegistrationType?.data?.bocDeviceRegistrationTypes;
        
            const isWebPrinter = bocDeviceRegistrationTypes.some(type => Object.values(PRINT_CODES).includes(type));
            setRegistrationType(isWebPrinter ? "web" : "shenlong");
        
            if (isWebPrinter) {
                const printerCodeData = bocDeviceRegistrationTypes.find(value =>
                    Object.values(PRINT_CODES).includes(value)
                );
        
                const printerCodeKey = printerCodeData ? Object.entries(PRINT_CODES).find(([key, value]) => value === printerCodeData)?.[0] : null;
        
                const instructionKey = printerCodeKey ? `WEB_CONNECTION.INSTRUCTION_2_${printerCodeKey}` : null;
                setInstruction2(instructionKey);
            }
        }
    },[RegistrationType])

    const handleConnectDevice = () =>{
        pushDataLayer("web_signup", "button", "continue");
        setToDisplayDownloadApp(true)
    }
    const dismissAlert = () => {
       document.getElementsByClassName("ecopro-alert")[0].style.display = 'none';
    }
    const displayFurthestPromotionEndDate = (discount) => {
        const maxDate = new Date(
            Math.max(
              ...discount.map(element => {
                return new Date(element?.promotionEndDate);
              }),
            ),
        );
        return DisplayDate(maxDate, dateDisplayOptions, langCode)

    }
    
   
    if(RegistrationType.error ) {
        return <ErrorPage errorInfo={RegistrationType.error} />
    }
    if(ScmsTokenResult.error ) {
        return <ErrorPage errorInfo={ScmsTokenResult.error} />
    }
    if(DashboardPreEnrollInfo.error) {
        return <ErrorPage errorInfo={DashboardPreEnrollInfo.error} />
    }
    if(ScmsTokenResult.error ) {
        return <ErrorPage errorInfo={ScmsTokenResult.error} />
      }
    if(DashboardPreEnrollInfo.success){
        WEB_handleSelectedPlanLocalData('Set', DashboardPreEnrollInfo.data.plan);
        planName = DashboardPreEnrollInfo?.data?.plan?.planName;
        planPages = DashboardPreEnrollInfo?.data?.plan?.planPages;
        discount = DashboardPreEnrollInfo?.data?.plan?.discount;
        overageBlockPages = DashboardPreEnrollInfo?.data?.plan?.overageBlockPages;
        planPrice = DashboardPreEnrollInfo?.data?.plan?.overagePrice;
        planCurrency = DashboardPreEnrollInfo?.data?.plan?.currency;
    }
    return (
        <Container className="subscription-info-wrapper">
             <EcoproAlert 
                 alertImg={alertData.alertImg}
                 alertClass= {alertData.alertClass}
                 alertSubHeader={alertData.alertSubHeader}
                 dismissAlert={()=>dismissAlert()}
            />
            <div className="plan-name-wrapper mt-40">
                <div className="f-14 ta-left">{t("DASHBOARD.CURRENT_PLAN")}</div>
                <div className="f-16 ta-left plan-name">{t('DASHBOARD.CURRENT_PLAN_NAME', { plan_name: getPlanTranslation(planName) , plan_pages: planPages })}</div>
                {discount && discount[0]?.planId && discount[0]?.promotionEndDate?
                <div className="f-14 ta-left plan-offer">{t('DASHBOARD.FREE_TRIAL_EXPIRES')} - {displayFurthestPromotionEndDate(discount)}</div>
                :
                null}
            </div>
            {(DashboardPreEnrollInfo.loading || RegistrationType.loading)?
                 <div className="device-not-connected-wrapper connect-app-section skeleton-web-connection ">
                    <div className="printer-icon">
                        <Skeleton
                        square
                        width="90px"
                        height="90px"
                        className="display-device-img-skeleton"
                        />
                    </div>
                    <div className="content">
                        <Skeleton className="rolled-over-overages-skeleton" />
                        <Skeleton width = "90%" />
                        <Skeleton className="90%" />
                        <Skeleton className="para-skeleton3" />
                    </div>
              </div>
              :
            
                registrationType === "web" ?
                <WebConnection
                header={t("WEB_CONNECTION.GENERATE_ONE_TIME_CODE")}
                subcontent={t("WEB_CONNECTION.ON_PRINT_TOUCH_SCREEN")}
                instruction1={t("WEB_CONNECTION.INSTRUCTION_1")}
                instruction2={t(instruction2)}
                instruction3={t('WEB_CONNECTION.INSTRUCTION_3')}
                btnText1={t('WEB_FOOTER.CONTACT_US')}
                supportcontent={t('WEB_CONNECTION.CONTACT_SUPPORT_TEAM')}
                changeDeviceText={t('DASHBOARD.DISPLAY_DEVICE.CHANGE_DEVICE')}
                isSwapEligible={isSwapEligible}
                codeSubmitText={t("WEB_CONNECTION.SUBMIT")}
                enterCodeLabel={t('WEB_CONNECTION.ENTER_CODE')}
                cname="web-connect-section"
                />
                :
                !toDisplayDownloadApp ?
                <DeviceNotConnected
                header={(IsBrotherPlus_Signup) ? t("DASHBOARD_CONNECT_DEVICE.WELCOME_HEADER_BROTHER_PLUS",{"userName":displayName}) : t("DASHBOARD_CONNECT_DEVICE.WELCOME_HEADER",{"userName":displayName})}
                content={t('DASHBOARD_CONNECT_DEVICE.CONTENT')}
                info={t('DASHBOARD_CONNECT_DEVICE.DOWNLOAD_APP')}
                btnText1={t('DASHBOARD_CONNECT_DEVICE.CONTINUE')}
                handleConnectDevice={()=>handleConnectDevice()}
                cname="connect-app-section"
            ></DeviceNotConnected>
            :
            <DeviceNotConnected
                 header={t("DASHBOARD_CONNECT_DEVICE.DEVICE_NOT_CONNECTED")}
                 content={t('DASHBOARD_CONNECT_DEVICE.DEVICE_NOT_CONNECTED_CONTENT')}
                btnText1={<img alt="andriod-app" src={appIconsImgSrc.androidAppIcon} role="button" height="50px"/>}
                btnText2={<img alt="ios-app" src={appIconsImgSrc.IOSAppIcon} role="button" height="40px"/>}
                 handleConnectDevice={(e)=>handleNavigateToAppStore(e)}
                 cname="download-app-section"
                ></DeviceNotConnected>
            }
            <DashboardAccountOverview 
                deviceImagePath = {deviceImagePath}
                deviceModel = {deviceModel}
                deviceSerialNumber = {deviceSerialNumber}
                isSwapEligible = {isSwapEligible}
                planName = {planName}
                printerType = {printerType}
                planPages = {planPages}
                planPagesLeft = {planPagesLeft}
                planRolloverPages ={planGivenRolloverPages}
                planRolloverpagesLeft = {planPrintedRolloverPages}
                planOveragePages = {planPurchacedOveragePages}
                planOveragesPagesLeft = {planPrintedOveragePages}
                planPrice = {planPrice}
                planCurrency = {planCurrency}
                overageBlockPages = {overageBlockPages}
                cname = "websignup-connect-device-dashboard-wrapper"
            />
            <DashboardOrdersHistory orderHistory= {OrdersHistory.orderHistory} isDashboardHomepage={true} cname = "websignup-connect-device-dashboard-order-wrapper"/>
            {
                IsBrotherPlus_Signup 
                ? <EcoProOverlayModal  {...modalProps} modal_state={modalState}></EcoProOverlayModal>
                : null
            }
        </Container>
    );
}

export default ConnectDeviceDashboard;