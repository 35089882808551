import React from "react";
import { useTranslation } from "react-i18next";
import SignUpFeedback from "../../../components/SignUpFeedback/SignUpFeedback";
import { FEEDBACK_TYPES } from "../../../constants/AppConstants";
import { useSelector } from "react-redux";
import Loader from "../../../components/shared/loader/Loader";
import ErrorPage from "../../../components/shared/errorPage/ErrorPage";
import { Redirect } from "react-router-dom";
import { routePath } from "../../../constants/Routes";

const EcoproFeedback = () => {
  const { t } = useTranslation(["translation"]);
  const issuesOptionListArr = [
    t("DASHBOARD_FEEDBACK.ISSUES_LIST.DASHBOARD_FEATURES"),
    t("DASHBOARD_FEEDBACK.ISSUES_LIST.PRODUCT_QUALITY"),
    t("DASHBOARD_FEEDBACK.ISSUES_LIST.PRICE"),
    t("DASHBOARD_FEEDBACK.ISSUES_LIST.REPLEMISHMENT_AUTOMATION"),
    t("DASHBOARD_FEEDBACK.ISSUES_LIST.DELIVERY_EXPERIENCE"),
    t("DASHBOARD_FEEDBACK.ISSUES_LIST.DELIVERY_TIMING"),
    t("DASHBOARD_FEEDBACK.ISSUES_LIST.OTHER"),
  ];
  const submitFeedbackResp = useSelector((state) => state.SignUpUserFeedback);

   if(submitFeedbackResp.loading){
        return <Loader></Loader>
    }
    if(submitFeedbackResp.error){
        return <ErrorPage errorInfo={submitFeedbackResp.error}></ErrorPage>
    }
    if(submitFeedbackResp.success){
        return <Redirect to={routePath.DASHBOARD}></Redirect>
    }
    return (
      <SignUpFeedback
        issuesOptionListArr={issuesOptionListArr}
        header={t("DASHBOARD_FEEDBACK.HEADER")}
        rateYourExperience={t("DASHBOARD_FEEDBACK.RATE_YOUR_EXPERIENCE")}
        skipThisSectionVisibility={false}
        feedbackSource={FEEDBACK_TYPES.DASHBOARD_USER_FEEDBACK}
      ></SignUpFeedback>
    );
};

export default EcoproFeedback;
