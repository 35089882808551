import React from "react";
import { useTranslation } from "react-i18next";
import rightArrow from "../../assets/images/rightArrow.svg";
import { UNIQUE_ID_GA } from "../../constants/AppConstants";
import { getPersonalOrBusinessTerms } from "../../constants/AppMethod";

const LegalDocuments = ({onTermsAndCondition}) => {
    const { t } = useTranslation(["translation"]);

  return (
    <React.Fragment>
      <div className="row my-account-content plan-border mb-30">
        <div className="f-16 fc-g fw-b ta-left mb-30">
          {t("DASHBOARD.MY_ACCOUNT_LANDING.LEGAL_HEADER")}
        </div>
        <div className="row pointer-cursor" onClick={()=>onTermsAndCondition(getPersonalOrBusinessTerms())} id={UNIQUE_ID_GA.myAccount+"-terms-condition"}>
          <div className="col-10">
            <div className="f-14 ta-left">
              {t("DASHBOARD.MY_ACCOUNT_LANDING.TERMS_CONDITION")}
            </div>
          </div>
          <div className="col my-account-edit-btn">
            <img src={rightArrow} alt="edit shipping address" />
          </div>
        </div>
      {/*
        @todo Will be enabled after integration
        
        <div className="row">
          <div className="col-10 pb-15 mt-15">
            <div className="f-14 ta-left">
              {t("DASHBOARD.MY_ACCOUNT_LANDING.PRIVACY_POLICY")}
            </div>
          </div>
          <div className="col my-account-edit-btn">
            <img src={rightArrow} alt="edit shipping address" />
          </div>
        </div>
        <div className="row">
          <div className="col-10 pb-15 mt-15">
            <div className="f-14 ta-left">
              {t("DASHBOARD.MY_ACCOUNT_LANDING.SUBSCRIPTION_AGREEMENT")}
            </div>
          </div>
          <div className="col my-account-edit-btn">
            <img src={rightArrow} alt="edit shipping address" />
          </div>
        </div>
        <div className="row">
          <div className="col-10 pb-15 mt-15">
            <div className="f-14 ta-left">
              {t("DASHBOARD.MY_ACCOUNT_LANDING.COOKIES_POLICY")}
            </div>
          </div>
          <div className="col my-account-edit-btn">
            <img src={rightArrow} alt="edit shipping address" />
          </div>
        </div> 
      */}
      </div>
    </React.Fragment>
  );
};

export default LegalDocuments;
