import * as actionTypes from '../../../constants/ActionTypes/CSAgentPortal/CustomerDashboard';

const initialState = {
    supplyOrderLogs: {},
    loading: false,
    error: true,
    success:false
}

const SupplyOrderLogs = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_SUPPLY_ORDERS_LOGS:
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        
        case actionTypes.FETCH_SUPPLY_ORDERS_LOGS_SUCCESS:
            return {
                ...state,
                success: true,
                supplyOrderLogs: action.supplyOrderLogs,
                loading: false
            }
        
        case actionTypes.FETCH_SUPPLY_ORDERS_LOGS_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.message
            }
        default:
            return {...state}
    }
}

export default SupplyOrderLogs