import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "reactstrap";
import { featureSwitchResetState } from "../../../../actions/AdminPortal/FeatureSwitch";
import AdminAlert from "../../../../components/AdminPortal/AdminAlert/AdminAlert";
import FeatureSwitch from "../../../../components/AdminPortal/FeatureSwitch/FeatureSwitch";
import PageHeader from "../../../../components/AdminPortal/Shared/PageHeader/PageHeader";
import Loader from "../../../../components/shared/loader/Loader";
import { extractCountryName } from "../../../../utils/Internationalization/handleAppInternationalization";
import "./ManageFeature.css";

const ManageFeature = () => {
    const { t } = useTranslation("Admintranslation");
    const dispatch = useDispatch();
    const featuresList = useSelector(state => state.ADMIN.FeatureSwitchFeatureList);
    const updateFeatureListResp = useSelector(state => state.ADMIN.UpdateFeatureSwitch)
    let displayList = null;
    useEffect(() => {
        dispatch(featureSwitchResetState());
    }, [dispatch]);

    const setCountryList = (data) => {
        return ({
            id: data.featureSwitchId,
            name : extractCountryName(data.countryCode),
            isEnabled: data.isEnabled,
            code: data.countryCode,
            isChangeEventFired: false,
            saveBtnDisabled: true
        })
    }
    if(featuresList.success){
        displayList = Object.values(featuresList?.data).map(setCountryList)
    }

    return (
        <Container>
            { featuresList.loading && <Loader setOverlay={true}/> }
            <div className="feature-switch-manage-country-header-container">
                <PageHeader pageTitle={t('FEATURE_SWITCH.PAGE_TITLE')} pageSubTitle={t('FEATURE_SWITCH.PAGESUBTITLE_FEATURE')}></PageHeader>
            </div>
            { featuresList.error && <AdminAlert alert_variant="alert-danger">{featuresList.error}</AdminAlert> }
            <FeatureSwitch
                featureSwitchType='FEATURE'
                displayList={displayList}
                updateFeatureSwitchResp = {updateFeatureListResp}
            />
        </Container>
    )
}

export default ManageFeature;