import React, { useEffect } from "react";
import Loader from "../../../../../components/shared/loader/Loader";
import { AzureADB2CSignInRedirect } from "../../../../../utils/Authentication/Azure/MSALInstanceInit";

const SignUpRedirect = (props) => {
    const signUpType = props?.location?.state?.signupType;
    useEffect(() => {
        AzureADB2CSignInRedirect(signUpType);
    }, [signUpType])
    return(
        <Loader />
    )
}

export default SignUpRedirect;
