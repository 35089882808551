import React from "react";
import { Container } from "reactstrap";
import PageFooter from "../components/shared/footer/PageFooter";
import PageHeader from "../components/shared/header/PageHeader";

const CSAgentLayout = ({children}) => {
    return (
        <>
            <PageHeader/>
                <Container className="cs-agent-container">
                    {children}
                </Container>
            <PageFooter/>
        </>
    )
}

export default CSAgentLayout;