import { useEffect, useState } from "react";
import { userRoles } from "../../../constants/UserRoles";

/**
 * This hook is to manage admin forms
 * @param {object} initialState 
 * @param {*} callback 
 * @param {*} validate 
 * @param {string} formAction 
 * @returns object
 */
const UseAdminForm = (initialState, callback, validate, formAction = null) => {
    // State to store the current form input values
    const [values, setValues] = useState(initialState);
    // State to store the validation errors of the form inputs
    const [errors, setErrors] = useState({});
    // State to track whether the form is being submitted
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (errors && Object.keys(errors).length === 0 && isSubmitting) {
          callback();
        }
        // eslint-disable-next-line
      }, [errors, isSubmitting]);

      const handleSubmit = (event) => {
        if (event) event.preventDefault();
        setErrors(validate(values, formAction));
        setIsSubmitting(true);
      };
      const handleCsagentViewerChange  = (event) => {
        const newValues = { ...values };
        newValues[userRoles.CSAGENT_VIEWER] = event.target.checked;

        if (newValues[userRoles.CSAGENT_VIEWER]) {
          newValues[userRoles.ADMIN] = false;
          newValues[userRoles.CSAGENT] = false;
          newValues[userRoles.CSAGENT_ADMIN] = false;
        }
        setValues(newValues);
      }
      const handleChange = (event) => {
        event.persist();
        setValues(values => (
        { 
            ...values, 
            [event.target.name]:  event.target.type === "checkbox"
            ? (event.target.name === userRoles.CSAGENT_VIEWER) ? handleCsagentViewerChange(event) : event.target.checked : event.target.value
        }));
        
        setErrors({
            ...errors,
            [event.target.name]: ""
          });
      };

      const resetSubmit = () => {
        setIsSubmitting(false);
      }

      return {
        handleChange,
        handleSubmit,
        values,
        errors,
        resetSubmit
      }
}

export default UseAdminForm;