import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import PageHeader from "../../../../components/AdminPortal/Shared/PageHeader/PageHeader";
import EcoProButton from "../../../../components/shared/button/button";
import { adminRoutePaths } from "../../../../constants/Routes";
import AdminFormInput from "../../../../components/AdminPortal/Shared/FormElements/FormInput/FormInput";
import AdminFormSelect from "../../../../components/AdminPortal/Shared/FormElements/FormSelect/FormSelect";
import { ENVSettings } from "../../../../constants/ENVSettings";
import AdminFormCheckboxDefault from "../../../../components/AdminPortal/Shared/FormElements/FormCheckBoxDefault/FormCheckBoxDefault";
import AdminFormLabel from "../../../../components/AdminPortal/Shared/FormElements/FormLabel/FormLable";
import AdminForm from "../../../../components/AdminPortal/Shared/Form/AdminForm";
import UseAdminForm from "../../../../utils/CustomHooks/AdminPortal/UseAdminForm";
import validate from "../../../../utils/AdminPortal/ValidationRules/AdminAddEditUserFormValidationRules";
import './AddEditUsers.css';
import { useDispatch, useSelector } from "react-redux";
import { addAdminUser, addAdminUserReset, viewAdminUsersReset } from "../../../../actions/AdminPortal/AdminUsers";
import Loader from "../../../../components/shared/loader/Loader";
import AdminAlert from "../../../../components/AdminPortal/AdminAlert/AdminAlert";
import { Redirect, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ADD } from "../../../../constants/ActionTypes/AdminPortal/AdminUsers";
import { adminUserRoles_CONFIG, userRoles } from "../../../../constants/UserRoles";

const AddEditUser = (props) => {
    const { t } = useTranslation('Admintranslation');
    const history = useHistory();
    const dispatch = useDispatch();
    const countryList = ENVSettings.COUNTRIES;
    const addUserResp = useSelector(state => state.ADMIN.AdminUser_ADD);
    const [adminUserRoles, setAdminUserRoles] = useState(adminUserRoles_CONFIG());
    const action =  props?.location?.state?.action;

    const countryOptions =  countryList.map((data) =>
    <option key={data.code2} value={data.code2}>
        {data.name}
    </option>
    );


    
    const editUserRoleData = (roles)  => {
        return adminUserRoles.reduce((acc, role) => {
            const matchingRole = roles.find(r => r.toLowerCase() === role.name.toLowerCase());
            acc[role.name] = Boolean(matchingRole);
            return acc;
        }, {});
    }

    
    const initialUser_Info =  { name: "", email: "", country: ""};
    const initialUser_RoleInfo = editUserRoleData([]);
    let initialState = {...initialUser_Info, ...initialUser_RoleInfo};
   
    if(action === 'edit'){
        const userData = props?.location?.state?.userRecord;
        const userInfo = { name: userData.displayName, email: userData.emailAddress, country: userData.countryCode};
        const userRoleInfo = editUserRoleData(userData.roles);
        initialState = {...userInfo, ...userRoleInfo}
    }

    const handleUserSubmit = ()  => {
        dispatch(addAdminUserReset());
        let editUserRoleData = adminUserRoles.reduce((acc, role) => {
            acc[role.name] = role.isChecked;
            return acc;
        }, {});
        dispatch(addAdminUser(values, ADD, editUserRoleData));
    }
    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = UseAdminForm(initialState, handleUserSubmit, validate);

    useEffect(() => {
        const updatedRolesStatus = adminUserRoles.map(role => ({
          ...role,
          isChecked: (values[role.name] === false) ? false : true,
          isDisabled: (values[userRoles.CSAGENT_VIEWER] === true && role.name !== userRoles.CSAGENT_VIEWER) ? true : false,
        }));
        setAdminUserRoles(updatedRolesStatus);
        // eslint-disable-next-line
    }, [values]);

 
    const handleViewUser = () =>{
        dispatch(viewAdminUsersReset())
        history.push(adminRoutePaths.ADMIN_USERS.VIEW_USERS);
    }
    if(addUserResp.success){
        return <Redirect to={{pathname:adminRoutePaths.ADMIN_USERS.VIEW_USERS,
            state:{stateInfo: {type: "alert-success", "message":t('ADMIN_COMMON_SUCCESS_MSG.INSERT_SUCCESS')}}}}></Redirect>
    }
    return (
        <Container>
            {addUserResp.loading && <Loader setOverlay={true}></Loader>}
            <div className="admin-users-add-header">
                <PageHeader pageTitle={t("MANAGE_ADMIN_USERS.PAGE_TITLE")}></PageHeader>
            </div>
            {addUserResp.error && <AdminAlert alert_variant="alert-danger" isVisible={true}>{addUserResp.error}</AdminAlert>}
            <div className="admin-user-add-edit-wrapper mt-20">
                <AdminForm 
                    cname = "admin-manage-users"
                    cardHeaderTitle = {t("MANAGE_ADMIN_USERS.ADD_USER")}
                    cardHeaderButtonInfo = {{buttonType: "button", btnTxt: t("MANAGE_ADMIN_USERS.VIEW_USERS"), onBtnClick: ()=>handleViewUser()}}
                >
                    <div className="col-md-6">
                        <AdminFormLabel htmlFor="name" required={true}>{t('MANAGE_ADMIN_USERS.FORM_LABELS.NAME')}</AdminFormLabel>
                        <AdminFormInput 
                            id="name"
                            name="name"
                            placeholder="Enter Name"
                            type = "text"
                            onChange={handleChange} 
                            value={values.name || ''}
                            isRequired={true}
                            cname={(errors.name) && 'is-danger'}
                            minlength={0}
                            maxlength={100}
                        />
                        {errors.name && <p className="admin-error is-danger">{errors.name}</p>}
                    </div>
                    <div className="col-md-6">
                        <AdminFormLabel htmlFor="email" required={true}>{t('MANAGE_ADMIN_USERS.FORM_LABELS.EMAIL')}</AdminFormLabel>
                        <AdminFormInput 
                            id="email"
                            name="email"
                            placeholder="Enter Email Id"
                            type = "email"
                            onChange={handleChange} 
                            value={values.email || ''}
                            isRequired={true}
                            cname={(errors.email) && 'is-danger'}
                        />
                        {errors.email && <p className="admin-error is-danger">{errors.email}</p>}
                    </div>
                    <div className="col-md-6">
                        <AdminFormLabel htmlFor="country" required={true}>{t('MANAGE_ADMIN_USERS.FORM_LABELS.COUNTRY')}</AdminFormLabel>
                        <AdminFormSelect
                            name="country"
                            options = {countryOptions}
                            placeholder = "Select Country"
                            onChange={handleChange}
                            value={values.country || 'Select'}
                            cname={(errors.country) && 'is-danger'}
                        />
                        {errors.country && <p className="admin-error is-danger">{errors.country}</p>}
                    </div>
                    <div className="col-md-6">
                        <AdminFormLabel htmlFor="role" required={true} cname="admin-user-role-label">{t('MANAGE_ADMIN_USERS.FORM_LABELS.ROLE')}</AdminFormLabel>
                        {
                            adminUserRoles.map((role) => (
                                <AdminFormCheckboxDefault 
                                    name={role.name}
                                    isChecked = {role.isChecked}
                                    displayInline = {true}
                                    label = {role.label}
                                    onChange={handleChange}
                                    cname={(errors.role) && 'is-danger'}
                                    key={role.name}
                                    disabled = {role.isDisabled}
                                />
                            ))
                        }
                        {(errors.role) && <p className="admin-error is-danger">{errors.role}</p>}
                    </div>
                    <div className="col-md-12 admin-form-submit-btn-wrapper">
                        <EcoProButton cname="default admin-form-submit-btn" onBtnClick={(event)=>handleSubmit(event)}>{t('BTN_TEXTS.SUBMIT')}</EcoProButton>
                    </div>
                </AdminForm>
            </div>
        </Container>
    )
}

export default AddEditUser;