import { call, put, takeLatest } from 'redux-saga/effects';
import { _BROTHER_PLUS_API_ENDPOINTS, ECOPROTOKEN_CLAIMS, LOCALSESSIONDATAKEYS } from '../../../../constants/AppConstants';
import { _CALLPOSTAPI } from '../../../../api/apiHandler';

import { getLocalSessionData, getUserSessionData } from '../../../../utils/Authentication/handleUserSession';
import { EnrollmentRegisterServiceFailure, EnrollmentRegisterServiceInit, EnrollmentRegisterServiceSuccess } from './RegisterServiceSlice';


function _REGISTERSERVICE(){
  const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
  const deviceID = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID);
  const endpoint = _BROTHER_PLUS_API_ENDPOINTS.REGISTER_SERVICE;

    const payload = {
      'userId':UID,
      'deviceId':deviceID
    }
    
    return _CALLPOSTAPI(endpoint, payload)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* handleRegisterServiceSaga(action) {
  try {
    const registerService = yield call(_REGISTERSERVICE);
    yield put(EnrollmentRegisterServiceSuccess(registerService));
  } catch (error) {
    yield put(EnrollmentRegisterServiceFailure(error.message));
  }
}

export default function* watchRegisterService() {
  yield takeLatest(EnrollmentRegisterServiceInit.type, handleRegisterServiceSaga);
}
