import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../../constants/ActionTypes/HardwareInclusive/HardwareInclusive';
import { _CALLGETAPI, _CALLPOSTAPI, _CALLPUTAPI } from '../../api/apiHandler';
import { getUserSessionData } from '../../utils/Authentication/handleUserSession';
import { ECOPROTOKEN_CLAIMS, SUCCESS, _HARDWARE_API_ENDPOINTS } from '../../constants/AppConstants';
import { _MANAGESUBSCRIPTIONCALL } from '../MyAccount/ManageSubscription';
import SubscriptionOrderPayload from '../../utils/MyAccount/SubscriptionOrderPayload';
import HW_INCLUSIVE_InstantPaymentPayload from '../../utils/Hardware_Inclusive/InstantPaymentPayload';

function* handleError(msg = null){
    yield put({
        type: actionTypes.HWINCLUSIVE_MANAGE_INSTANT_PAYMENT_ERROR,
        message: (msg) ? msg : "error"
    })
}

async function HW_INCLUSIVE_INSTANTPAYMENT(payload){
    const endpoint = _HARDWARE_API_ENDPOINTS.CREATE_INSTANT_PAYMENT;
    try {
        const response = await _CALLPUTAPI(endpoint, payload);
        return response;
    } catch (error) {
        throw error;
    } 
}

async function HW_INCLUSIVE_CREATE_PRE_ENROLL_INFO(payload){
    const endpoint = _HARDWARE_API_ENDPOINTS.CREATE_PREENROLL_INFO;
    try {
        const response = await _CALLPOSTAPI(endpoint, payload);
        return response;
    } catch (error) {
        throw error;
    } 
}

async function HW_INCLUSIVE_GET_PRE_ENROLL_INFO(){
    const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
    const endpoint = _HARDWARE_API_ENDPOINTS.GET_PREENROLL_INFO;
    const payload = {
        params: {
            userId: UID
        }
      }
    return _CALLGETAPI(endpoint, payload )
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* handleInstantPayment(preEnrollId, paymentPayload){
    try{
        const payload = HW_INCLUSIVE_InstantPaymentPayload(preEnrollId, paymentPayload);
        return yield call(HW_INCLUSIVE_INSTANTPAYMENT, payload);
    } catch (e) {
        throw e;
    }
}

function* handleCreatePreEnrollment(payload){
    try{
       return yield call(HW_INCLUSIVE_CREATE_PRE_ENROLL_INFO, payload);
    } catch (e) {
        throw e;
    }
}

function* handleManageSubscription(payload){
    const { paymentID, subscriptionPayload } = payload;
    try{
        const subscriptionPayloadData = yield SubscriptionOrderPayload(paymentID, subscriptionPayload);
        return yield call(_MANAGESUBSCRIPTIONCALL, subscriptionPayloadData);
    }catch (e){
        throw e;
    }
}

function* handlePaymentStatus(paymentInfo){
    if(paymentInfo.actionStatus.toUpperCase() === SUCCESS){
        yield put({type: actionTypes.HWINCLUSIVE_MANAGE_INSTANT_PAYMENT_SUCCESS, paymentResponse: paymentInfo})
    } else {
        yield* handleError();
    }
}

function* handleGetPreEnrollmentInfo(){
    try{
        return yield call(HW_INCLUSIVE_GET_PRE_ENROLL_INFO);
    } catch (e) {
        throw e;
    }
}

function* handleCreatingSubscriptionsAndInstantPayment(payload){
    try{
        const ManageSubscriptionInfo = yield* handleManageSubscription(payload);
        if(ManageSubscriptionInfo.actionStatus.toUpperCase() === SUCCESS){
            const CreatePreEnrollmentInfo = yield* handleCreatePreEnrollment(ManageSubscriptionInfo);
            if(CreatePreEnrollmentInfo.actionStatus.toUpperCase() === SUCCESS){
                const preEnrollmentInfo =  yield* handleGetPreEnrollmentInfo();
                if(preEnrollmentInfo.actionStatus.toUpperCase() === SUCCESS){
                    return yield* handleInstantPayment(preEnrollmentInfo.id, payload);
                } else {
                    yield* handleError();
                }
            } else {
                yield* handleError();
            }
        } else {
            yield* handleError();
        }
    }catch (e){
        throw e;
    }
}

function* handleInstantPaymentProcess(action) {
    try{
        const paymentInfo = yield* handleCreatingSubscriptionsAndInstantPayment(action.payload);
        yield* handlePaymentStatus(paymentInfo);
    } catch (e) {
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
        if(e instanceof TypeError === false && e.message !== "401"){
            yield* handleError(e.message);
        }
    }
}

function* HWInclusive_handleInstantPayment(){
    yield takeEvery(actionTypes.HWINCLUSIVE_MANAGE_INSTANT_PAYMENT_INIT, handleInstantPaymentProcess);
}

export { HW_INCLUSIVE_GET_PRE_ENROLL_INFO };
export default HWInclusive_handleInstantPayment;