import React from "react";
import SubscriptionPayments from "../../../components/shared/SubscriptionPayments/SubscriptionPayments";
import { routePath } from "../../../constants/Routes";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { PAYMENT_TYPE, refreshBtn, tryAgainBtn } from "../../../constants/AppConstants";
import Loader from "../../../components/shared/loader/Loader";
import ErrorPage from "../../../components/shared/errorPage/ErrorPage";

export const UpdatePaymentInfoContext = React.createContext({});

const EditPayment = (props) => {
  const ManageSubscriptionResult = useSelector((state)=>state.ManageSubscription)
  const UpdatePaymentData = {
    accountInfo: props.location.state.data,
    paymentType: PAYMENT_TYPE.UPDATE 
  }
    const zrenderData_MyAccount = {
        'personalDetails':{
          firstName: props.location.state.data.myAccount.firstName,
          lastName: props.location.state.data.myAccount.lastName,
          mobileNumber: props.location.state.data.myAccount.mobileNumber,
          emailAddress: props.location.state.data.myAccount.emailAddress
        },
        'billingAddress':props.location.state.data.myAccount.billingAddress
      }
    if(ManageSubscriptionResult.loading){
      return <Loader></Loader>
    }
    if(ManageSubscriptionResult.error){
      return <ErrorPage errorInfo={ManageSubscriptionResult.error} showRefreshBtn={refreshBtn.HIDE} showTryAgain={tryAgainBtn.SHOW} to={routePath.MY_ACCOUNT}></ErrorPage>
    }
    if (ManageSubscriptionResult.success){
      return <Redirect to={{ pathname: routePath.UPDATE_ACCOUNT_SUCCESS,
        state: { accountClicked:"paymentDetails" }
        }}></Redirect>
    }
    return (
      <UpdatePaymentInfoContext.Provider value={UpdatePaymentData}>
          <SubscriptionPayments 
            zrenderData={zrenderData_MyAccount}
            paymentMethod = { props.location.state.data.myAccount?.userPaymentViewModel?.paymentMethod}
          />
        </UpdatePaymentInfoContext.Provider>
    )
}

export default EditPayment