import { call, put, takeLatest } from 'redux-saga/effects';
import { _CALLGETAPI } from '../../../api/apiHandler'
import { WEB_CUSTOMER_API_ENDPOINT, SUCCESS } from '../../../constants/AppConstants';
import * as actionTypes from '../../../constants/ActionTypes/Web/Customer/SignUp';
import i18next from 'i18next';

function _GETSCMSTOKEN(UID){
    const endpoint = WEB_CUSTOMER_API_ENDPOINT.GET_USER_SCMS_TOKEN
     const payload = {
        params:{
            userId:UID
        }
     }
    return _CALLGETAPI(endpoint, payload)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

 export function* handleTokenAuthentication(action){
    const UID = action.UID
    try{    
            const userSCMSTokenData = yield call(_GETSCMSTOKEN, UID);
            if(userSCMSTokenData.actionStatus.toUpperCase() === SUCCESS){
                const currentTime = new Date()
                currentTime.setSeconds(currentTime.getSeconds() + (userSCMSTokenData.expiredIn/2))
                yield put({type: actionTypes.GET_SCMS_TOKEN_SUCCESS,scmsToken:userSCMSTokenData.token,tokenExpirationinseconds: userSCMSTokenData.expiredIn, tokenExpiryTime: currentTime});
            } else {
                yield put({type: actionTypes.GET_SCMS_TOKEN_ERROR, message: i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')});
            }
    }catch(e){
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
        if(e instanceof TypeError === false && e.message !== "401"){
            yield put({type: actionTypes.GET_SCMS_TOKEN_ERROR, message: e.message});
        }
    }
}
function* TokenAuthentication(){
    yield takeLatest(actionTypes.GET_SCMS_TOKEN, handleTokenAuthentication);
}

export default TokenAuthentication;