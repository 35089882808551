let ZBillingParams;
let prePopulatingData;
const ZuoraClientLibSrc = "https://static.zuora.com/Resources/libs/hosted/1.3.1/zuora-min.js";

export const ZuoraClientLibInit = (params, prePopulatedData) => {
    ZBillingParams = params;
    prePopulatingData = prePopulatedData;
    const script = document.createElement("script");
    script.src = ZuoraClientLibSrc;
    script.async = true;
    document.body.appendChild(script);
    script.onload = function() {
        ZuoraClienteSetup()
     };
}
const ZuoraClienteSetup = () => {
    //Include the Zuora JavaScript Library
    // Provide Parameter Values for Payment Pages 2.0
    const zuoraPaymentHostPageNode = document.createElement('script');
    zuoraPaymentHostPageNode.text = `var params=` + JSON.stringify(ZBillingParams) + `;
            var deviceWidth = `+ window.innerWidth + `;
            var prepopulateFields = {
                creditCardHolderName:`+ JSON.stringify(prePopulatingData.creditCard_holderName) +`,
                creditCardCountry: `+ JSON.stringify(prePopulatingData.country) +`,
                creditCardAddress1: `+ JSON.stringify(prePopulatingData.address) +`,
                creditCardAddress2: `+ JSON.stringify(prePopulatingData.address2) +`,
                creditCardCity: `+ JSON.stringify(prePopulatingData.city) +`,
                creditCardPostalCode:  `+ JSON.stringify(prePopulatingData.postal_code) +`,
                creditCardState:  `+ JSON.stringify(prePopulatingData.state) +`,
                phone: `+ JSON.stringify(prePopulatingData.phone) +`,
                email: `+ JSON.stringify(prePopulatingData.email) +`,
                firstName: `+ JSON.stringify(prePopulatingData.fname) +`,
                lastName: `+ JSON.stringify(prePopulatingData.lname) +`,
                streetNumber: `+ JSON.stringify(prePopulatingData.address) +`,
                streetName: `+ JSON.stringify(prePopulatingData.address2) +`,
                city: `+ JSON.stringify(prePopulatingData.city) +`,
                postalCode: `+ JSON.stringify(prePopulatingData.postal_code) +`,
                country: `+ JSON.stringify(prePopulatingData.country) +`,
                state: `+ JSON.stringify(prePopulatingData.state) +`
            };
        
        function callback(response) {
            if(response.success === "true") {
                document.getElementById("paymentRefID").value = response.refId;
                document.getElementById("card_info_submit").click();
            } else {
                document.getElementById("error-alert").style.display = "block";
                document.getElementById("payment-error-alert").innerHTML = "<li>" + response.errorCode +" : "+response.errorMessage +"</li>";
            }
        }
            var clientErrorMessageCallback = function(key, code, message) {
                document.getElementById("error-alert").style.display = "block";
                document.getElementById("payment-error-alert").innerHTML = "<li>" + message + "</li>";
                return;
        };
        function loadHostedPage() {
            // Set height for xs and sm,md,lg devices
            var setZuoraIframeHeight = 750;
            if(deviceWidth <= 480 ){
                // Mobile
                setZuoraIframeHeight  = 750;
            }else{
                // tablet / desktip
                setZuoraIframeHeight  = 430;
            }
            Z.setEventHandler("onloadCallback", function() {
               //setEventHandler
            });
            // Render HPM Iframe
                // Changed from Z.render() to Z.renderWithErrorHandler() to capture Client/Server side error and move scroll bar to top. 
                Z.renderWithErrorHandler(
                    params,
                    prepopulateFields,
                    callback,
                    clientErrorMessageCallback,
                    null,
                    setZuoraIframeHeight
                );
        }
        loadHostedPage();
        `;
    document.body.appendChild(zuoraPaymentHostPageNode);
}