import { call, put, takeEvery } from 'redux-saga/effects';
import { _BROTHER_PLUS_API_ENDPOINTS } from '../../../constants/AppConstants';
import { _CALLPUTAPI } from '../../../api/apiHandler';
import { fetchCancelServiceFailure, fetchCancelServiceRequest, fetchCancelServiceSuccess } from './cancelServiceSlice';


function _CANCELSERVICE(userId, deviceId){
    const endpoint = _BROTHER_PLUS_API_ENDPOINTS.CANCEL_SERVICE;
    const payload = {
        userId:userId,
        deviceId:deviceId
    }
    return _CALLPUTAPI(endpoint, payload )
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* fetchCancelServiceSaga(action) {
  try {
    const userId = action.payload.userId
    const deviceId = action.payload.deviceId
    const cancelService = yield call(_CANCELSERVICE, userId, deviceId);
    yield put(fetchCancelServiceSuccess(cancelService));
  } catch (error) {
    yield put(fetchCancelServiceFailure(error.message));
  }
}

export default function* watchFetchCancelService() {
  yield takeEvery(fetchCancelServiceRequest.type, fetchCancelServiceSaga);
}
