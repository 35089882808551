import { call, put, takeEvery } from "redux-saga/effects";
import * as actionTypes from '../../../constants/ActionTypes/AdminPortal/InvoicePosting';
import i18next from "i18next";
import { SUCCESS, _ADMINISTRATOR_API_ENDPOINTS } from "../../../constants/AppConstants";
import { _CALLPOSTAPI } from "../../../api/apiHandler";

function _RETRYINVOICES(taxDataType, fileName){
    const endpoint = _ADMINISTRATOR_API_ENDPOINTS.TESI_INVOICE_POSTING.RETRY_INVOICE;
  
    const payload = {
        "TaxDataType": taxDataType,
        "FileName": fileName
    }
    return _CALLPOSTAPI(endpoint, payload)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}
function* handleError(msg = null){
    yield put({
        type: actionTypes.RETRY_INVOICE_ERROR,
        message: (msg) ? msg : i18next.t("Admintranslation:ADMIN_COMMON_ERRORS.TECHNICAL_ERROR")
    })
}

function* handleInfo(action){
    try{
        const result = yield call(_RETRYINVOICES, action.taxDataType, action.filename);
        if(result.actionStatus.toUpperCase() === SUCCESS){
            yield put({type: actionTypes.RETRY_INVOICE_SUCCESS, data: result.actionMessage});
        } else {
            yield* handleError();
        }
    } catch (e) {
        yield* handleError();
    }
}

function* TESIInvoice_Retry(){
    yield takeEvery(actionTypes.RETRY_INVOICE_INIT, handleInfo);
}
export default TESIInvoice_Retry;