import { all } from "@redux-saga/core/effects";
import FeatureSwitchCountry from "./FeatureSwitch/Country";
import UpdateFeatureSwitch from "./FeatureSwitch/UpdateFeatureSwitch";
import FeatureSwitchFeatureList from "./FeatureSwitch/FeatureList";
import AdminUser_ADD from "./AdminUsers/AddUser";
import AdminUser_VIEW from "./AdminUsers/ViewUser";
import TESIInvoice_View from "./InvoicePosting/ViewInvoice";
import TESIInvoice_Retry from "./InvoicePosting/RetryInvoice";
import MaintenanceServiceStatus_FETCH from "./Settings/FetchMaintenenceServiceStatus";
import MaintenanceServiceStatus_UPDATE from "./Settings/UpdateMaintenenceServiceStatus";
import PrinterModels_ByCountry from "./PrinterModels/Country";
import PrinterModels_ByModels from "./PrinterModels/Models";
import UpdatePrinterModelStatus from "./PrinterModels/UpdatePrinterModelStatus";
import UserSessionLog from "./UserActivityLogs/UserSessionLog";

export default function* AdminSagas() {
    yield all([
        FeatureSwitchCountry(),
        UpdateFeatureSwitch(),
        FeatureSwitchFeatureList(),
        AdminUser_ADD(),
        AdminUser_VIEW(),
        TESIInvoice_View(),
        TESIInvoice_Retry(),
        MaintenanceServiceStatus_FETCH(),
        MaintenanceServiceStatus_UPDATE(),
        PrinterModels_ByCountry(),
        PrinterModels_ByModels(),
        UpdatePrinterModelStatus(),
        UserSessionLog()
    ])
}