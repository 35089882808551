import { adminRoutePaths } from "../../constants/Routes";
import AddEditUser from "../../pages/Administrator/AdminUsers/AddEditUsers/AddEditUsers";
import ViewUsers from "../../pages/Administrator/AdminUsers/ViewUsers/ViewUsers";
import Dashboard from "../../pages/Administrator/Dashboard/Dashboard";
import ManageCountry from "../../pages/Administrator/FeatureSwitch/ManageCountry/ManageCountry";
import ManageFeature from "../../pages/Administrator/FeatureSwitch/ManageFeature/ManageFeature";
import InvoicePosting from "../../pages/Administrator/InvoicePosting/InvoicePosting";
import Settings from "../../pages/Administrator/Settings/Settings";
import PrinterModelsManageCountry from "../../pages/Administrator/PrinterModels/ManageCountry";
import PrinterModelsManageModels from "../../pages/Administrator/PrinterModels/ManageModels";
import ManageUsersRoleAccess from "../../pages/Administrator/AdminUsers/ManageUsersRoleAccess/ManageUsersRoleAccess";

export const AdminProtectedRoutePath = [
    {
      path: adminRoutePaths.DASHBOARD,
      component: Dashboard,
    },
    {
      path: adminRoutePaths.FEATURE_SWITCH.BY_COUNTRY,
      component: ManageCountry,
    },
    {
      path: adminRoutePaths.FEATURE_SWITCH.BY_FEATURE,
      component: ManageFeature,
    },
    {
      path: adminRoutePaths.ADMIN_USERS.VIEW_USERS,
      component: ViewUsers,
    },
    {
      path: adminRoutePaths.ADMIN_USERS.ADD_USER,
      component: AddEditUser,
    },
    {
      path: adminRoutePaths.ADMIN_USERS.EDIT_USER,
      component: ManageUsersRoleAccess,
    },
    {
      path: adminRoutePaths.RETRY_INVOICE_POSTING,
      component: InvoicePosting
    },
    {
      path: adminRoutePaths.SETTINGS,
      component: Settings
    },
    {
      path: adminRoutePaths.PRINTER_MODELS.BY_COUNTRY,
      component: PrinterModelsManageCountry
    },
    {
      path: adminRoutePaths.PRINTER_MODELS.BY_MODELS,
      component: PrinterModelsManageModels
    }
];