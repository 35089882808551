import React, { useEffect, useState } from 'react';
import './ChooseYourPrinterType.css'
import Container from "reactstrap/es/Container";
import { useTranslation, Trans } from 'react-i18next';
import DisplayPrinterType from '../../../../../../components/Web/HardwareSignUp/DisplayPrinterType/DisplayPrinterType';
import { useHistory } from 'react-router-dom';
import { WebHardwareInclusiveRoutePath } from '../../../../../../constants/Routes';
import { useDispatch, useSelector } from 'react-redux';
import { GetHardwareInclusivePrinterFeaturesByPrinterType, GetHardwareInclusiveServicePlan } from '../../../../../../actions/HardwareInclusive/HardwareInclusive';
import Loader from '../../../../../../components/shared/loader/Loader';
import { formatNumberOnLocale, getPrinterDescriptionKey } from '../../../../../../constants/AppMethod';
import ServiceUnavailable from '../ServiceUnavailable/ServiceUnavailable';
import { useQuery } from '../../../../../../utils/CustomHooks/UseQuery';
import currencies from '../../../../../../constants/CurrencyTypes';
import { sortFeaturesInDescendingOrder } from '../../../../../../utils/HardwareInclusive/HardwareInclusive';
const ChooseYourPrinterType = () => {
    const {t} = useTranslation(['translation'])
    const queryParams = useQuery();
    const history = useHistory()
    const dispatch = useDispatch();
    const printerFeaturesByPrinterTypeResult = useSelector((state)=>state.GetHWPrinterFeaturesByPrinterType)
    const servicePlanHWResult = useSelector((state) => state.GetHWServicePlan);
    const FIELD_TEST_CONSTANT = 'a3230b5f-cf13-5883-90f6-256bf540ae03';
    const REQUEST_ID = 'c46afda9-f417-5563-b543-9a7d245ec161';
    const fieldTestID = queryParams.get('fieldtestpass');
    const requestId = queryParams.get('request-id');
    const [lowestInkPlan, setLowestInkPlan] = useState(null);
    const [lowestMllPlan, setLowestMllPlan] = useState(null);

    const moveToCustomizePrinter = (printerTypeId, startingPrice, printerType) => {
        history.push({pathname:WebHardwareInclusiveRoutePath.CUSTOMIZE_PRINTER,state:{"printerTypeId":printerTypeId, "printerPrice": startingPrice,"printerType":printerType}})
    }
   
    useEffect(()=>{
        dispatch(GetHardwareInclusivePrinterFeaturesByPrinterType())
    },[dispatch])

    useEffect(()=>{
        if(printerFeaturesByPrinterTypeResult?.success){
            dispatch(GetHardwareInclusiveServicePlan())
        }
    },[dispatch, printerFeaturesByPrinterTypeResult?.success])

    useEffect(()=>{
        const inkPlans = [];
        const mllPlans = [];

        if(servicePlanHWResult?.success){
            servicePlanHWResult?.servicePlan?.products?.forEach(product => {
                product.basicPlans.forEach(basicPlan => {
                    basicPlan.planDetails.forEach(plan => {
                        if ((plan?.planPages === "300" || plan?.planPages === "500") && plan?.price) {
                            if (basicPlan.type === "InkJet") {
                                inkPlans.push(plan);
                            } else if (basicPlan.type === "Mono Laser") {
                                mllPlans.push(plan);
                            }
                        }
                    });
                });
            });

          
            setLowestInkPlan(inkPlans[0]);
            setLowestMllPlan(mllPlans[0]);
            }
             // eslint-disable-next-line
    },[servicePlanHWResult?.success])

    const getPriceAndCurrencyBasedOnType = (printerTypeId, type) => {
        if(printerTypeId === 1){
            if(type === "currency"){
                return lowestInkPlan?.currency
            }
            if(type === "price" && lowestInkPlan?.price){
                return lowestInkPlan?.price
            }
        }
        if(printerTypeId === 2){
            if(type === "currency"){
                return lowestMllPlan?.currency
            }
            if(type === "price" && lowestMllPlan?.price){
                return lowestMllPlan?.price
            }
        }

    }

   if(
        !fieldTestID 
        || !requestId 
        || fieldTestID !== FIELD_TEST_CONSTANT 
        || requestId !== REQUEST_ID
    ){
    return <ServiceUnavailable />
   }

    if(printerFeaturesByPrinterTypeResult?.loading || servicePlanHWResult?.loading){
        return <Loader />
    }
    if(printerFeaturesByPrinterTypeResult?.service_available_err){
        return <ServiceUnavailable />
    }
    return (
        <Container className="hardware-choose-printer-container">
            <div className='choose-printer-header'>{t("HARDWARE_SIGNUP.CHOOSE_PRINTER_TYPE.HEADER")}</div>
            <div className='hardware-printer-content-wrapper'>
                {printerFeaturesByPrinterTypeResult?.success && printerFeaturesByPrinterTypeResult?.printerFeatures?.printerTypeFeatures?.map(printerFeature => {
                    const currencyCode = getPriceAndCurrencyBasedOnType(printerFeature?.printerTypeId, "currency");
                    const currency = currencies[currencyCode]?.code
                    const startingPrice = getPriceAndCurrencyBasedOnType(printerFeature?.printerTypeId, "price");
                    const formattedStartingPrice = formatNumberOnLocale(startingPrice, currencies[currencyCode]?.code)
                    return(
                    <DisplayPrinterType
                        key={printerFeature?.printerTypeId}
                        printerName={printerFeature?.printerType}
                        printerDescription={getPrinterDescriptionKey(printerFeature?.printerTypeId)}
                        moveToCustomizePrinter = {()=>moveToCustomizePrinter(printerFeature?.printerTypeId, startingPrice, printerFeature.printerType)}
                        planStartingPrice = {
                        <Trans
                                i18nKey="HARDWARE_SIGNUP.CHOOSE_PRINTER_TYPE.STARTING_PRICE"
                                values={{
                                    currency,
                                    formattedStartingPrice
                                }}
                                components={{ 1: <span /> }}
                            >
                                Starting from <span>{{ currency }} {{ formattedStartingPrice }} /per month</span>.
                            </Trans>
                                            }
                        deviceImg={printerFeature?.deviceTypeImageUrl}
                        printerFeatures = {sortFeaturesInDescendingOrder(printerFeature.features)}
                    ></DisplayPrinterType>
                                        )                       
                }
            )}
            </div>
        </Container>
    );
};

export default ChooseYourPrinterType;
