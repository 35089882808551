import React from "react";
import './CaveatText.css';
import { Trans } from "react-i18next";
import { getPersonalOrBusinessTerms } from "../../../constants/AppMethod";
import { getCountryCode } from "../../../utils/Internationalization/handleAppInternationalization";
import { SIGNUPTYPE } from "../../../constants/AppConstants";

const CaveatText = (props) => {
    const { signupType } = props;
    const countryCode = getCountryCode();
    const allowedCountries = ['DE', 'AT', 'CH'];
    let bothPersonalAndBusiness = (allowedCountries.includes(countryCode) && signupType === SIGNUPTYPE.WEB) ? true : false;
    let termsAndConditionLink = getPersonalOrBusinessTerms(bothPersonalAndBusiness);
    
    return (
        <div className="plans-caveat-txt-wrapper">
            <p className="f-12">
                {
                    (!bothPersonalAndBusiness) ?
                    <Trans i18nKey="CUSTOMER_WEB.SELECT_PLAN.CAVEAT_TXT">
                        RULE_NUMBER <sup>1</sup> Prices quoted on this page include VAT, unless otherwise stated.<a  className="caveat-txt-link" href={termsAndConditionLink} target = "_blank"  rel="noreferrer">Terms and Conditions</a>  apply.
                    </Trans>
                    : <Trans i18nKey="CUSTOMER_WEB.SELECT_PLAN.CAVEAT_TXT_DE">
                        RULE_NUMBER <sup>1</sup> Prices quoted on this page include VAT, unless otherwise stated. The general terms and conditions for 
                        <a  className="caveat-txt-link" href={termsAndConditionLink?.personal} target = "_blank"  rel="noreferrer"> private </a> 
                        and <a  className="caveat-txt-link" href={termsAndConditionLink?.business} target = "_blank"  rel="noreferrer"> business </a> 
                        customers apply.
                    </Trans>
                }
            </p>
        </div>
    );
}

export default CaveatText;