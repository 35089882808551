import * as actionTypes from '../../constants/ActionTypes/HardwareInclusive/HardwareInclusive';

const initialState = {
    'success': false,
    'loading': false,
    'error': false,
    'data': {}
}

const HWInclusive_ManageInstantPayment = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.HWINCLUSIVE_MANAGE_INSTANT_PAYMENT_INIT:
            return {
                ...state,
                loading: true,
                'success': false,
                'error': false,
                'data': {}
            }
        case actionTypes.HWINCLUSIVE_MANAGE_INSTANT_PAYMENT_SUCCESS:
            return {
                ...state,
                success: true,
                loading: false,
                data: action.paymentResponse,
                'error': false
            }
        case actionTypes.HWINCLUSIVE_MANAGE_INSTANT_PAYMENT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.message,
                'data': {}
            }
        case actionTypes.HWINCLUSIVE_MANAGE_INSTANT_PAYMENT_RESET:
            return initialState;
        default:
            return {...state};
    }

}

export default HWInclusive_ManageInstantPayment;