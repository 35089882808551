import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../../constants/ActionTypes/Dashboard';
import { _CALLGETAPI } from '../../api/apiHandler';
import { _API_ENDPOINT } from '../../constants/AppConstants';

function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
       var ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
 }

 function saveByteArray(reportName, byte) {
    var blob = new Blob([byte], {type: "application/pdf"});
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
};

function _DOWNLOADORDERINVOICE(UID,invoiceId,invoiceDate){
   const endpoint = _API_ENDPOINT.DOWNLOAD_ORDER_INVOICE;

    const payload = {
        params: {
            UserId: UID,
            invoiceId:invoiceId,
            invoiceDate:invoiceDate
        }
      }
    return _CALLGETAPI(endpoint, payload )
    .then(response => {
        var sampleArr = base64ToArrayBuffer(response);
        saveByteArray(`Invoice_${invoiceDate}`, sampleArr);
        return response;
    })
    .catch((error) => {
        throw error;
    });
}
function* handleDownlaodOrderInvoice(action){
 try{
    const UID = action.UID;
    const invoiceId = action.invoiceId;
    const invoiceDate = action.invoiceDate;

    const orderInvoice = yield call(_DOWNLOADORDERINVOICE, UID,invoiceId,invoiceDate);
    yield put({type: actionTypes.DOWNLOAD_ORDER_INVOICE_SUCCESS, orderInvoice: orderInvoice});
        
 } catch(e){
     /**
     * This conditional statement is added to avoid
     * displaying error message in view while logout from app
     */
    if(e instanceof TypeError === false && e.message !== "401"){
        yield put({type: actionTypes.DOWNLOAD_ORDER_INVOICE_ERROR, message: e.message});
    }
 }
}
function* DownloadOrderInvoice(){
    yield takeEvery(actionTypes.DOWNLOAD_ORDER_INVOICE, handleDownlaodOrderInvoice);
}

export default DownloadOrderInvoice;