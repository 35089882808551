export const MANAGE_PRINTER_MODELS_BY_COUNTRY_INIT = 'MANAGE_PRINTER_MODELS_BY_COUNTRY_INIT';
export const MANAGE_PRINTER_MODELS_BY_COUNTRY_SUCCESS = 'MANAGE_PRINTER_MODELS_BY_COUNTRY_SUCCESS';
export const MANAGE_PRINTER_MODELS_BY_COUNTRY_ERROR = 'MANAGE_PRINTER_MODELS_BY_COUNTRY_ERROR';

export const MANAGE_PRINTER_MODELS_BY_MODELS_INIT = 'MANAGE_PRINTER_MODELS_BY_MODELS_INIT';
export const MANAGE_PRINTER_MODELS_BY_MODELS_SUCCESS = 'MANAGE_PRINTER_MODELS_BY_MODELS_SUCCESS';
export const MANAGE_PRINTER_MODELS_BY_MODELS_ERROR = 'MANAGE_PRINTER_MODELS_BY_FEATURE_ERROR';

export const UPDATE_PRINTER_MODELS_INIT = "UPDATE_PRINTER_MODELS_INIT";
export const UPDATE_PRINTER_MODELS_SUCCESS = "UPDATE_PRINTER_MODELS_SUCCESS";
export const UPDATE_PRINTER_MODELS_ERROR = "UPDATE_PRINTER_MODELS_ERROR";

export const MANAGE_PRINTER_MODELS_RESET_STATE = "MANAGE_PRINTER_MODELS_RESET_STATE"
export const UPDATE_PRINTER_MODELS_RESET_STATE = "UPDATE_PRINTER_MODELS_RESET_STATE"

