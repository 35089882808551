import React from "react";
import { Container } from "reactstrap";

const Dashboard = () => {
    return (
        <Container>
            <h1 className="mt-40 text-center">Administrator Dashboard</h1>
        </Container>
    )
}

export default Dashboard;

