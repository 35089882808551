import React from "react";
import { useLocation } from "react-router-dom";

/**
 * Custom hook that builds on useLocation to parse
 * the query string
 */

export function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }