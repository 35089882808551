import React,  { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "reactstrap";
import { fetchFAQCategory } from "../../actions/FAQ";
import FAQ from "../../components/FAQ/FAQ";
import "./HelpAndSupport.css";

const HelpAndSupport = () => {
    const dispatch = useDispatch();
    const FAQDetails = useSelector(state => state.FAQTopCategories);
    
    useEffect(() => {
      dispatch(fetchFAQCategory());
    },[dispatch]);

    return (
        <div>
        <Container className="FAQ-wrapper mt-40">
            <FAQ FAQCatList={FAQDetails.FAQTopCategories} FAQType="main_list"></FAQ>
        </Container>
        </div>
    );
}

export default HelpAndSupport;