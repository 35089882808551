import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SwapDevice } from '../../../actions/Dashboard';
import DisplayUpdateConfirmationWithList from '../../../components/EditMyAccount/DisplayUpdateConfirmationWithList/DisplayUpdateConfirmationWithList';
import ErrorPage from '../../../components/shared/errorPage/ErrorPage';
import Loader from '../../../components/shared/loader/Loader';
import { LOCALSESSIONDATAKEYS, UNIQUE_ID_GA, ECOPROTOKEN_CLAIMS } from '../../../constants/AppConstants';
import { routePath } from '../../../constants/Routes';
import { getUserSessionData, setLocalSessionData } from '../../../utils/Authentication/handleUserSession';

const SwapDeviceConfirm = (props) => {
    const {confirmStatus,oldDeviceId, newDevice} = props.location.state
    const history = useHistory()
    const dispatch = useDispatch();
    const { t } = useTranslation(["translation"]);
    const uid = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID)
    const swapDeviceResponse = useSelector((state) => state.SwapDevice);
    
    const onConfirmChangeDevice = () => {
      dispatch(SwapDevice(uid,oldDeviceId,newDevice.deviceId));
    }
    if(swapDeviceResponse.loading){
      return <Loader></Loader>
    }
    if(swapDeviceResponse.error){
      return <ErrorPage errorInfo={swapDeviceResponse.error}></ErrorPage>
    }
    if(swapDeviceResponse.success === true){
      // setDeviceID(newDevice.deviceId);
      setLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID, newDevice.deviceId);
      history.push({pathname: routePath.CHANGE_DEVICE_SUCCESS, state: {'cancellationsuccess': true}})      
    }
    
    return (
      confirmStatus === true?
        <div>
          <DisplayUpdateConfirmationWithList
            account="swapDevice"
            header={t("SWAP_DEVICE.CONFIRM_SWAP_DEVICE.HEADER")}
            subHeader={t("SWAP_DEVICE.CONFIRM_SWAP_DEVICE.SUB_HEADER")}
            imagePath={newDevice.imagePath}
            model={newDevice.model}
            serialNumber={newDevice.serialNumber}
            infoList={t("SWAP_DEVICE.CONFIRM_SWAP_DEVICE.LIST")}
            onBtnClick={onConfirmChangeDevice}
            btnText={t("SWAP_DEVICE.CONFIRM_SWAP_DEVICE.CONFIRM_BTN")}
            linkUrl={routePath.CHANGE_DEVICE}
            linkText={t("SWAP_DEVICE.CONFIRM_SWAP_DEVICE.CONFIRM_LINK")}
            onUpdateBtnID={UNIQUE_ID_GA.myAccount+"-swap-device"}
          ></DisplayUpdateConfirmationWithList>  
        </div>
        :
        <ErrorPage errorInfo={t("ERROR_CONTENT.ERR_INFO.SUBSCRIPTION_REQUIRED")} />
    );
};

export default SwapDeviceConfirm;