import React from "react";
import { Container } from "reactstrap";
import PageHeader from "../../../components/AdminPortal/Shared/PageHeader/PageHeader";
import PrinterModelsSwitch from "../../../components/AdminPortal/PrinterModels/ModelsSwitch";
import { ENVSettings } from "../../../constants/ENVSettings";
import { useTranslation } from "react-i18next";
import './PrinterModels.css';

const PrinterModelsManageCountry = () => {
    const { t } = useTranslation("Admintranslation");
    const countryList = ENVSettings.COUNTRIES;
    const countryDropdownOptions = countryList.map((data) =>
                <option key={data.code2} value={data.code2}>
                    {data.name}
                </option>
            );
    const properties = {
        featureSwitchType: 'COUNTRY',
        selectOptionLabel: t('MANAGE_PRINTER_MODELS.SELECT_OPTION_LABEL_COUNTRY'),
        selectOptionElemName: 'country',
        displayedListTitle: t('MANAGE_PRINTER_MODELS.LIST_TITLE_FOR_COUNTRY')
    }
    return (
        <Container>
            <div className="feature-switch-manage-country-header-container">
                <PageHeader pageTitle={t('MANAGE_PRINTER_MODELS.PAGE_TITLE')} pageSubTitle={t('MANAGE_PRINTER_MODELS.PAGESUBTITLE_COUNTRY')}/>
            </div>
            <PrinterModelsSwitch properties={properties} countryDropdownOptions={countryDropdownOptions}/>
        </Container>
    );
}

export default PrinterModelsManageCountry;