import { call, put, takeEvery } from 'redux-saga/effects';
import i18next from "i18next";
import * as actionTypes from '../../../constants/ActionTypes/AdminPortal/AdminUsers';
import { _CALLPOSTAPI_WITHQS, _CALLPUTAPI_WITHQS } from '../../../api/apiHandler'
import { LOCALSESSIONDATAKEYS, SUCCESS, USER_ACTIVITY_LOGS, _ADMINISTRATOR_API_ENDPOINTS } from '../../../constants/AppConstants';
import { getLocalSessionData, getUserDataByKey } from '../../../utils/Authentication/handleUserSession';
import { getLangCode } from '../../../utils/Internationalization/handleAppInternationalization';
import UserActivityLogsPayload from '../../../utils/AdminPortal/UserActivityLogsPayload';

function _ADD_UPDATE_REVOKE_USER(userData, formAction, editUserRoleData = null, auditLogDescription){
   return (formAction === actionTypes.EDIT_ACCESS) ? _EDITUSERACCESS(userData, editUserRoleData, auditLogDescription) : _ADDUSER(userData, editUserRoleData);
}
async function _EDITUSERACCESS(userData, editUserRoleData, auditLogDescription){
    const endpoint = _ADMINISTRATOR_API_ENDPOINTS.MANAGE_ADMIN_USER.EDIT_USER_ACCESS;
    const queryString = {
        params: {
            userId: getLocalSessionData(LOCALSESSIONDATAKEYS.UID),
        }
      }
      const payload = constructPayloadForEditUserAccess(userData, editUserRoleData);
      const logDetails = {
        action: USER_ACTIVITY_LOGS.UPDATE_ADMIN_USER_ROLES,
        description: auditLogDescription
      }
    const finalPayload = {...payload, 'servicePortalUser': await UserActivityLogsPayload(logDetails)};
      return _CALLPUTAPI_WITHQS(endpoint, finalPayload, queryString)
      .then(response => {
          return response;
      })
      .catch((error) => {
          throw error;
      });
}
async function _ADDUSER(userData, editUserRoleData){
    const endpoint = _ADMINISTRATOR_API_ENDPOINTS.MANAGE_ADMIN_USER.ADD_USER;
    const queryString = {
        params: {
            userId: getLocalSessionData(LOCALSESSIONDATAKEYS.UID)
        }
      }
    const payload = constructPayload(userData, editUserRoleData);
    const rolesArrayResult = getTheRolesInArray(editUserRoleData)   
    const logDetails = {
        action: USER_ACTIVITY_LOGS.ADD_ADMIN_USER,
        description: `New user ${userData.email} has been added with roles ${rolesArrayResult.toString()}`
      }
    const finalPayload = {...payload, 'servicePortalUser': await UserActivityLogsPayload(logDetails)};
     return _CALLPOSTAPI_WITHQS(endpoint, finalPayload, queryString)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}
function constructPayloadForEditUserAccess(UserData, editUserRoleData = null){
    let payload = {
        "emailId": UserData.email,
        "addUserAccess":editUserRoleData.addUserAccess,
        "removeUserAccess": editUserRoleData.removeUserAccess,
        "updatedBy": `${getUserDataByKey('firstName', 'personal_details')} ${getUserDataByKey('lastName', 'personal_details')}`
      };
    return payload;
}

function getTheRolesInArray (editUserRoleData) {
    let roles= [];
    for (const key in editUserRoleData) {
          if (editUserRoleData.hasOwnProperty(key) ) {
            if(editUserRoleData[key]){
              roles.push(key)
            }
          }
        }
    return roles
}
function constructPayload(UserData,editUserRoleData){
    const displayName = getUserDataByKey("displayName", "personal_details");
    const langCode = getLangCode().split("-")[0];
    let payload;

    const rolesArrayResult = getTheRolesInArray(editUserRoleData)      
        payload = rolesArrayResult.map(role => ({
            "emailId": UserData.email,
            "countryCode": UserData.country,
            "languageCode": langCode,
            "role": role,
            "createdBy": displayName
          }));
    return {"createServicePortalUsers":payload};
}
function* handleError(message){
    yield put({
        type: actionTypes.ADMIN_USERS_ADD_ERROR, 
        message: message
    });
}
function* handleInfo(action){
    const formAction = action.formAction.toUpperCase();
    const errMsg = (formAction === actionTypes.EDIT_ACCESS) 
    ? i18next.t(`Admintranslation:ADMIN_COMMON_ERRORS.REVOKE_ACCESS_ERROR`)
    : i18next.t(`Admintranslation:ADMIN_COMMON_ERRORS.INSERT_ERROR`);

    try {
        const userData = action.userData;
        const editUserRoleData = action.editUserRoleData;
        const auditLogDescription = action.auditLogDescription;
        if(Object.values(userData).length > 0){
            const result = yield call(_ADD_UPDATE_REVOKE_USER, userData, formAction, editUserRoleData, auditLogDescription);
            if(result) {
                const recordActionStatus = result.filter((val) => {
                    return (val.actionStatus.toUpperCase() === SUCCESS);
                  });
                (recordActionStatus.length > 0)
                ? yield put({type: actionTypes.ADMIN_USERS_ADD_SUCCESS, data: result})
                : yield* handleError(errMsg);
            } else {
                yield* handleError(errMsg);
            }
        } else {
            yield* handleError(i18next.t('Admintranslation:ADMIN_COMMON_ERRORS.ENTITY_MISSING'));
        }
    } catch (e) {
        yield* handleError(errMsg);
    }
}

function* AdminUser_ADD(){
    yield takeEvery(actionTypes.ADMIN_USERS_ADD_INIT, handleInfo);
}

export default AdminUser_ADD;