import * as actionTypes from '../../../constants/ActionTypes/Web/Customer/SignUp';

const initialState = {
    printerTypes: {},
    loading: false,
    error: false,
  }

const PrinterTypes = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PRINTER_TYPES:
            return {
                ...state,
                loading: true,
                error: false,
                printerTypes: {}
            }
        
        case actionTypes.FETCH_PRINTER_TYPES_SUCCESS:
            return {
                ...state,
                printerTypes: action.printerTypes,
                loading: false,
                error: false
            }
        
        case actionTypes.FETCH_PRINTER_TYPES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message,
                printerTypes: {}
            }
        default:
            return {...state}
    }
}

export default PrinterTypes