import * as actionTypes from "../../../constants/ActionTypes/AdminPortal/Settings";

const initialState = {
    data: {},
    loading: false,
    success: false,
    error: null
}

const MaintenanceServiceStatus_UPDATE = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_MAINTENANCE_SERVICE_STATUS_INIT:
            return {
                ...state,
                loading: true,
                success: false
            }
        
        case actionTypes.UPDATE_MAINTENANCE_SERVICE_STATUS_SUCCESS:
            return {
                ...state,
                data: action.data,
                loading: false,
                error: null,
                success: true
            }
        
        case actionTypes.UPDATE_MAINTENANCE_SERVICE_STATUS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.message,
                success: false,
                data: {}
            }
        case actionTypes.UPDATE_MAINTENANCE_SERVICE_STATUS_RESET:
            return initialState;
        default:
            return {...state}
    }
}

export default MaintenanceServiceStatus_UPDATE