import React from "react";
import { useTranslation } from "react-i18next";

const DisplayListOfDevices = ({ deviceList, className, goToPlansPage }) => {
  const { t } = useTranslation(["translation"]);

  return (
    <div className={`select-different-device-content ${className}` }>
      <div className="f-16 fw-b ta-left">
        {t("SIGN_UP.SELECT_DIFFERENT_DEVICE.HEADER")}
      </div>
      {deviceList &&
        deviceList.map((device) => (
          <div
            key={device.deviceId}
            className="device-card"
            onClick={() => goToPlansPage(device.deviceId,device.model, device.serialNumber, device.networkStatus)}
            id={`different_device_selected_${device.deviceId}`}
          >
            <div className={`select-different-device-img ${device.imagePath ? "":"image-background"}`}>
              <img src={device.imagePath} alt="device" />
            </div>
            <div className="device-info f-12">
              <div>{device.model}</div>
              <div>{device?.serialNumber}</div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default DisplayListOfDevices;
