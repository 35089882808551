import { call, put, takeEvery } from 'redux-saga/effects';
import i18next from "i18next";
import * as actionTypes from '../../../constants/ActionTypes/AdminPortal/PrinterModels';
import { _CALLGETAPI } from '../../../api/apiHandler'
import { SUCCESS, _ADMINISTRATOR_API_ENDPOINTS } from '../../../constants/AppConstants';

function _FETCHPRINTER_COUNTRIESLIST(printerModel){
    const endpoint = _ADMINISTRATOR_API_ENDPOINTS.MANAGE_PRINTER_MODELS.BY_MODELS;

    const payload = {
        params: {
            modelName: printerModel
        }
      }
     return _CALLGETAPI(endpoint, payload)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* handleInfo(action){
    try {
        const printerModel = action.printerModel;
        if(printerModel){
            const result = yield call(_FETCHPRINTER_COUNTRIESLIST, printerModel);
            if(result.actionStatus.toUpperCase() === SUCCESS) {
                yield put({type: actionTypes.MANAGE_PRINTER_MODELS_BY_MODELS_SUCCESS, data: result.result});
            } else {
                yield put({
                    type: actionTypes.MANAGE_PRINTER_MODELS_BY_MODELS_ERROR, 
                    message: i18next.t('Admintranslation:ADMIN_COMMON_ERRORS.NO_RECORDS_FOUND')
                });
            }
        } else {
            yield put({
                type: actionTypes.MANAGE_PRINTER_MODELS_BY_MODELS_ERROR, 
                message: i18next.t('Admintranslation:ADMIN_COMMON_ERRORS.INVALID_INPUT')
            });
        }
    } catch (e) {
         yield put({type: actionTypes.MANAGE_PRINTER_MODELS_BY_MODELS_ERROR, message: i18next.t('Admintranslation:ADMIN_COMMON_ERRORS.TECHNICAL_ERROR')});
    }
}

function* PrinterModels_ByModels(){
    yield takeEvery(actionTypes.MANAGE_PRINTER_MODELS_BY_MODELS_INIT, handleInfo);
}

export default PrinterModels_ByModels;