import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../../constants/ActionTypes/Dashboard';
import { _CALLPUTAPI } from '../../api/apiHandler'
import { _API_ENDPOINT, SUCCESS } from '../../constants/AppConstants';
import UpdateShippingAddressPayload from '../../utils/MyAccount/UpdateShippingAddressPayload';
import i18next from 'i18next';

function _UPDATESHIPPINGCALL(payload){
    const endpoint = _API_ENDPOINT.UPDATE_SHIPPING;
    return _CALLPUTAPI(endpoint, payload )
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    }); 
}

function* handleUpdateShippingAddr(action){
    try{
        const payload = UpdateShippingAddressPayload(action.subscriptionPayload);
        let updateShippingSuccess = yield call(_UPDATESHIPPINGCALL, payload);
        if(updateShippingSuccess.actionStatus.toUpperCase() === SUCCESS){
            yield put({type: actionTypes.UPDATE_SHIPPING_ADDR_SUCCESS,updateShippingSuccess : updateShippingSuccess})
        }
        else{
            yield put({type: actionTypes.UPDATE_SHIPPING_ADDR_ERROR, message: i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')});
        }
    } catch(e) {
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
        if(e instanceof TypeError === false && e.message !== "401"){
            yield put({type: actionTypes.UPDATE_SHIPPING_ADDR_ERROR, message: e.message});
        }
    }
}

function* UpdateShippingAddr(){
    yield takeEvery(actionTypes.UPDATE_SHIPPING_ADDR, handleUpdateShippingAddr)
}

export default UpdateShippingAddr;