import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CancelOrder } from '../../../../actions/Dashboard';
import ErrorPage from '../../../../components/shared/errorPage/ErrorPage';
import Loader from '../../../../components/shared/loader/Loader';
import { routePath } from '../../../../constants/Routes';
import cancelConfirmation from '../../../../assets/images/confirm-cancellation.svg'
import DisplayUpdateConfirmationWithList from '../../../../components/EditMyAccount/DisplayUpdateConfirmationWithList/DisplayUpdateConfirmationWithList';
import { getLocalSessionData, getUserSessionData } from '../../../../utils/Authentication/handleUserSession';
import { LOCALSESSIONDATAKEYS, UNIQUE_ID_GA, ECOPROTOKEN_CLAIMS } from '../../../../constants/AppConstants';

const ConfirmCancellation = (props) => {
    const history = useHistory()
    const dispatch = useDispatch();
    const { t } = useTranslation(["translation"]);
    const cancelOrderResponse = useSelector((state) => state.CancelOrder);
    const uid = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
    const deviceId = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID);
    const reasonCodes = cancelOrderResponse && cancelOrderResponse.selectedFeedback.reasonCodes
    const otherReason = cancelOrderResponse && cancelOrderResponse.selectedFeedback.otherReason;
    const confirmedCancellation = () => {
        dispatch(CancelOrder(uid, deviceId, reasonCodes, otherReason));
     }
     if(cancelOrderResponse.loading){
       return <Loader></Loader>
     }
     if(cancelOrderResponse.error){
        return <ErrorPage errorInfo={cancelOrderResponse.error}></ErrorPage>
      }
      if(cancelOrderResponse.success === true){
        history.push({pathname: routePath.CANCEL_PLAN_SUCCESS, state: {'cancellationsuccess': true}})
      }
    return (
      props.location.state && props.location.state.confirmStatus === true?
        <div>
          <DisplayUpdateConfirmationWithList
            account="cancelPlan"
            header={t("CONFIRM_CANCELLATION.HEADER")}
            subHeader={t("CONFIRM_CANCELLATION.SUB_HEADER")}
            imagePath={cancelConfirmation}
            infoList={t("CONFIRM_CANCELLATION.LIST")}
            onBtnClick={confirmedCancellation}
            btnText={t("CONFIRM_CANCELLATION.CONFIRM_CANCELLATION_BTN")}
            linkUrl={routePath.DASHBOARD}
            linkText={t("CONFIRM_CANCELLATION.STOP_CANCELLATION")}
            onUpdateBtnID={UNIQUE_ID_GA.myAccount+"-cancel-plan"}
          ></DisplayUpdateConfirmationWithList>  
        </div>
        :
        <ErrorPage errorInfo={t("ERROR_CONTENT.ERR_INFO.SUBSCRIPTION_REQUIRED")} />
    );
};

export default ConfirmCancellation;