export const FETCH_DEVICE_LIST = 'FETCH_DEVICE_LIST';
export const FETCH_DEVICE_LIST_SUCCESS = 'FETCH_DEVICE_LIST_SUCCESS';
export const FETCH_DEVICE_LIST_ERROR = 'FETCH_DEVICE_LIST_ERROR';

export const GET_DEVICE_CAPABILITY = 'GET_DEVICE_CAPABILITY';
export const GET_DEVICE_CAPABILITY_SUCCESS = 'GET_DEVICE_CAPABILITY_SUCCESS';
export const GET_DEVICE_CAPABILITY_ERROR = 'GET_DEVICE_CAPABILITY_ERROR'
export const RESET_DEVICE_CAPABILITY = 'RESET_DEVICE_CAPABILITY'
export const RESET_DEVICE_LIST = 'RESET_DEVICE_LIST'

export const GET_DEVICE_REGISTRATION = 'GET_DEVICE_REGISTRATION';
export const GET_DEVICE_REGISTRATION_SUCCESS = 'GET_DEVICE_REGISTRATION_SUCCESS';
export const GET_DEVICE_REGISTRATION_ERROR = 'GET_DEVICE_REGISTRATION_ERROR';

