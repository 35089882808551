import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "reactstrap";
import DashboardOrdersHistory from "../../../components/DashboardOrdersHistory/DashboardOrdersHistory";
import { FetchOrderHistory } from "../../../actions/Dashboard";
import Loader from "../../../components/shared/loader/Loader";
import { getUserSessionData } from "../../../utils/Authentication/handleUserSession";
import { ECOPROTOKEN_CLAIMS } from "../../../constants/AppConstants";

const OrdersHistory = () => {
    const dispatch = useDispatch();
    const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
    const OrdersHistory = useSelector(state => state.DashboardOrderHistory);

    useEffect(() => {
        dispatch(FetchOrderHistory(UID));
    },[dispatch, UID]);
    if(OrdersHistory.loading){
        return <Loader></Loader>
    }
    return(
        <Container>
            <DashboardOrdersHistory orderHistory= {OrdersHistory.orderHistory} isDashboardHomepage={false}></DashboardOrdersHistory>
        </Container>
    )
}

export default OrdersHistory