import React, { useEffect }from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import rightArrow from "../../assets/images/payment-option-right-arrow.svg";
import americanExpressCard from "../../assets/images/americanexpressicon.svg";
import masterCard from "../../assets/images/mastercardicon.svg";
import visaCard from "../../assets/images/visacardicon.svg";
import { routePath, WebCustomerRoutePaths } from "../../constants/Routes";
import { fetchPaymentOptions } from "../../actions/MyAccount";
import Loader from "../../components/shared/loader/Loader";
import ErrorPage from "../../components/shared/errorPage/ErrorPage"
import { PAYMENT_OPTIONS } from "../../constants/AppConstants";
import { paymentOptionsNameTranslation, pushDataLayer } from "../../constants/AppMethod";
import './PaymentOptions.css'
import WebSubHeader from "../../components/WebSubHeader/WebSubHeader";
import SubHeader from "../../components/shared/subheader/SubHeader";
import WebSignupProgressBar from "../../components/WebSubHeader/progressBar/WebSignupProgressBar";

const PaymentOptions = (props) => {
    const { t } = useTranslation(["translation"]);
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const PAYMENT_PATH_NAME = (location.pathname === WebCustomerRoutePaths.SELECT_PAYMENT) ? WebCustomerRoutePaths.PAYMENT : routePath.PAYMENT;
    const PAYMENT_OPTION_PAGE_TYPE = (location.pathname === WebCustomerRoutePaths.SELECT_PAYMENT) ? "type-web" : "type-mobile";
    const paymentOptionsResp = useSelector(state => state.PaymentOptions);
 
    useEffect(() => {
        dispatch(fetchPaymentOptions());
    }, [dispatch])
    const moveToPreviousPage = () => {
        history.push(WebCustomerRoutePaths.ACCOUNT);
    }
    const redirectToPaymentPage = (paymentType) => {
        pushDataLayer('payment_type_selected','payment_type',paymentType)
        let paymentOption;
        if(paymentType === PAYMENT_OPTIONS.CREDIT_CARD){
            paymentOption = PAYMENT_OPTIONS.CREDIT_CARD;
        }else{
            paymentOption = PAYMENT_OPTIONS.DIRECT_BANK_TRANSFER;
        }
        history.push({pathname: PAYMENT_PATH_NAME, state: {'paymentType': paymentOption}});
    }
    if(paymentOptionsResp.loading){
        return <Loader></Loader>
    }
    if(paymentOptionsResp.error){
        return <ErrorPage errorInfo={paymentOptionsResp.error}></ErrorPage>
    }
    if(paymentOptionsResp.success){
            if(paymentOptionsResp.data.length === 1){
                redirectToPaymentPage(paymentOptionsResp.data[0].paymentMethodName)
            }
    }
    return(
        <>
        { paymentOptionsResp.data.length > 1  ? (
            <div className="mt-40 mb-30 container">
                {
                    (location.pathname === WebCustomerRoutePaths.SELECT_PAYMENT) && 
                    <>
                        <WebSignupProgressBar activeStep={3} completedSteps = {[1, 2]}/>
                        <WebSubHeader
                        currentStep="4"
                        totalStep="4"
                        stepName={t("SUB_HEADER.STEP_4.STEP_NAME")}
                        stepInfo={t("SUB_HEADER.STEP_4.STEP_INFO")}
                        moveToPreviousPage={moveToPreviousPage}
                        previousBtnText={t('CUSTOMER_WEB.PAYMENT.ACCOUNT_INFO_BACK_NAV_TEXT')}
                        stepInfoSub={t("SUB_HEADER.STEP_4.STEP_INFO_SUB")}
                        hideStepInfo={true}
                        withProgressBar={true}
                        />
                    </>
                }
                {
                    (location.pathname === routePath.SELECT_PAYMENT) && <DisplayHeader />
                }
                <div className="payment-options-wrapper">
                    {
                        paymentOptionsResp.data.length > 0 && paymentOptionsResp.data.map((val) => (
                        <div className={`payment-options-list ${PAYMENT_OPTION_PAGE_TYPE}`} onClick={() => redirectToPaymentPage(val.paymentMethodName)}>
                            <div className="list-details-wrapper">
                                <div className="list-name">
                                    <p>{paymentOptionsNameTranslation(val.paymentMethodName)}</p>
                                </div>
                            { val.paymentMethodName === PAYMENT_OPTIONS.CREDIT_CARD && <div className="card-icons">
                                    <img src={visaCard} alt="visa"/>
                                    <img src={masterCard} alt="master"/>
                                    <img src={americanExpressCard} alt="american-express"/>
                                </div> }
                            </div>
                            <div className="right-arrow">
                                <img src={rightArrow} alt="right-arrow"/>
                            </div>
                        </div>
                        ))
                    }
                </div>
            </div> 
            ) : ( <Loader></Loader>) 
            }
        </>
    )
}

const DisplayHeader = () => {
    const { t } = useTranslation('translation');
    const history = useHistory();

    const moveToPreviousPage = () => {
        history.push(routePath.ACCOUNT);
    }
    return(
        <>
            <SubHeader
                currentStep="4"
                totalStep="4"
                stepName={t("SUB_HEADER.STEP_4.STEP_NAME")}
                stepInfo={t("SUB_HEADER.STEP_4.STEP_INFO")}
                stepInfoSub={t("SUB_HEADER.STEP_4.STEP_INFO_SUB")}
                cname="payment-option-mobile-step-header"
            />

            <WebSubHeader
                currentStep="4"
                totalStep="4"
                stepName={t("SUB_HEADER.STEP_4.STEP_NAME")}
                stepInfo={t("SUB_HEADER.STEP_4.STEP_INFO")}
                moveToPreviousPage={moveToPreviousPage}
                previousBtnText={t('CUSTOMER_WEB.PAYMENT.ACCOUNT_INFO_BACK_NAV_TEXT')}
                stepInfoSub={t("SUB_HEADER.STEP_4.STEP_INFO_SUB")}
                cname="payment-option-desktop-step-header"
            />
        </>
    )
}
export default PaymentOptions;