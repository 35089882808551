import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { fetchFAQContent } from "../../../../actions/FAQ";
import FAQ from "../../../../components/FAQ/FAQ";
import Loader from "../../../../components/shared/loader/Loader";
import ErrorPage from "../../../../components/shared/errorPage/ErrorPage";
import { refreshBtn } from "../../../../constants/AppConstants";
import "./Content.css";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const FAQContent = () => {
    let { cat_id, sub_cat_id } = useParams();
    const dispatch = useDispatch();
    const FAQContent = useSelector(state => state.FAQContent);
    const location = useLocation();
    const translatedFAQTitle = location?.state?.translatedFAQTitle || {};
    useEffect(() => {
        dispatch(fetchFAQContent(cat_id, sub_cat_id));
    }, [dispatch, cat_id, sub_cat_id]);
    
    if(FAQContent.loading){
        <Loader></Loader>
     }
     if(FAQContent.error){
         <ErrorPage errorInfo={FAQContent.error} showRefreshBtn={refreshBtn.SHOW}></ErrorPage>
     }
     return (
         <Container className="FAQ-wrapper mt-40">
             <FAQ FAQContent={FAQContent.FAQContent} FAQType="content" FAQMainTitle = {cat_id.charAt(0).toUpperCase() + cat_id.slice(1)} translatedFAQTitle={translatedFAQTitle}></FAQ>
         </Container>
     )
}

export default FAQContent;