import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AuthenticatedTemplate, UnauthenticatedTemplate,useMsal } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { Redirect, useLocation } from "react-router-dom";
import { BOLAPICall } from "../../actions/SignIn";
import Loader from "../../components/shared/loader/Loader";
import { useQuery } from "../../utils/CustomHooks/UseQuery";
import { setLocalSessionData } from "../../utils/Authentication/handleUserSession";
import { setLangCode } from "../../utils/Internationalization/handleAppInternationalization";
import Profile from "../../components/profile/profile";
import { USER_LANGUAGE_CODE_TYPE, LOCALSESSIONDATAKEYS, SIGNUPTYPE } from "../../constants/AppConstants";
import ErrorPage from "../../components/shared/errorPage/ErrorPage";
import { AzureADB2CSignInRedirect } from "../../utils/Authentication/Azure/MSALInstanceInit";
import { WebCustomerRoutePaths } from "../../constants/Routes";

const MobileHome = (props) => {
    const { inProgress } = useMsal();
    const location = useLocation();
    const dispatch = useDispatch();
    const isAuthenticated = useIsAuthenticated();
    const queryParams = useQuery();
    const loginToken = queryParams.get("login_token");
    const UID = queryParams.get("userid");
    const deviceid= queryParams.get("deviceid");
    const language= queryParams.get("language");
    const country= queryParams.get("country");
    const domain = window.location.href;
    const BOLDetails = useSelector(state => state.BOLAPI);
    const [BOLAPICallStatus, setBOLAPICallStatus] = useState(true);
    const MULTIPLE_ATTEMPT_ERR_CODE = "AADB2C90157";

   useEffect(() => {
    // Dispatch event after setting Bearer token immediately after login
    if(inProgress === InteractionStatus.None && !isAuthenticated ){
        if(domain.includes('?') && loginToken && country && language && BOLAPICallStatus){
            dispatch(BOLAPICall(loginToken));
            setBOLAPICallStatus(false);
            if(country){
                setLocalSessionData(LOCALSESSIONDATAKEYS.ECOPROCTRYCODE, country.toUpperCase());
            }
            if(language){
                setLangCode(language, USER_LANGUAGE_CODE_TYPE);
            }
        } else if (domain.includes('?') && UID && deviceid && language && country){
             setLocalSessionData(LOCALSESSIONDATAKEYS.UID, UID);
             setLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID, deviceid);
            if(country){
                setLocalSessionData(LOCALSESSIONDATAKEYS.ECOPROCTRYCODE, country.toUpperCase());
            }
            if(language){
                setLangCode(language, USER_LANGUAGE_CODE_TYPE);
            }
            AzureADB2CSignInRedirect();
        } else{
            AzureADB2CSignInRedirect();
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgress]);
        /**
         * Condition to handle cancel and multiple attempt error
         * ADB2C new account registration for web signup
         */
        if(location.hash.includes('error') && location.hash.split("%7c")[1] === SIGNUPTYPE.WEB){
            if(location.hash.includes(MULTIPLE_ATTEMPT_ERR_CODE)){
                return <Redirect to={WebCustomerRoutePaths.MULTIPLE_ATTEMPT_ERROR}></Redirect>
            } else {
                return <Redirect to={WebCustomerRoutePaths.SELECT_PRINTER}></Redirect>
            }
        }
        if(BOLDetails.loader){
            return <Loader></Loader>
        }
        if(BOLDetails.error){
            return <ErrorPage errorInfo={BOLDetails.error}></ErrorPage>
        }
        if(BOLDetails.success){
            AzureADB2CSignInRedirect();
        } 
        return (
            <>  <UnauthenticatedTemplate>
                    <Loader></Loader>
                </UnauthenticatedTemplate>
                <AuthenticatedTemplate>
                  <Profile></Profile>
                </AuthenticatedTemplate>
            </>
        )
}

export default MobileHome;