import React from "react";
import MobileHeader from "../../Mobile/header/MobileHeader";
import WebHeader from "../../Web/header/WebHeader";

const CustomerPageHeader = ({WebHeaderCName, isSubscriptionCompletedRoute}) => {
    return (
        <>
            <WebHeader cname={`customer-header-desktop ${WebHeaderCName}`} hideUserProfile={!isSubscriptionCompletedRoute} role="customer" isSubscriptionCompletedRoute={isSubscriptionCompletedRoute}/> 
            <MobileHeader cname="customer-header-mobile"/>
        </>
    )
}

export default CustomerPageHeader;