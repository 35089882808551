import React from "react";
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { routePath } from "../../../../constants/Routes";
import { EXTERNAL_LINK_ENDPOINT } from "../../../../constants/ENVSettings";
import './MobileFooter.css';
import { getCountryCode } from "../../../../utils/Internationalization/handleAppInternationalization";
import { useEffect } from "react";
import { useState } from "react";
import { languageCodes } from "../../../../constants/Country";

const MobileFooter = (props) => {
    const { footerID = 'mobile-footer', cname } = props;
    const { t } =  useTranslation(["translation"]);
    const location = useLocation();
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear();
    const countryCode = getCountryCode();
    const [privacyURL, setPrivacyURL] = useState("");
    const [termsOfUseURL, setTermsOfUseURL] = useState("")

    useEffect(() => {
        if(!countryCode){
            const AppDomain = window.location.host;
            const splittedDomain = AppDomain.split(".");
            let obtainCountryCode = splittedDomain.filter(element => Object.keys(languageCodes).includes(element));
            setPrivacyURL(`${window.env[`
            MAIN_BASEURL_${obtainCountryCode.toString().toUpperCase()}`]}${EXTERNAL_LINK_ENDPOINT.footer_privacyPolicy}`);
            setTermsOfUseURL(`${window.env[`MAIN_BASEURL_${obtainCountryCode.toString().toUpperCase()}`]}${EXTERNAL_LINK_ENDPOINT.footer_termsOfUse}`)
        } else {
            if(countryCode === "DE" || countryCode === "AT"){
                setPrivacyURL(`${window.env[`MAIN_BASEURL_${countryCode}`]}${EXTERNAL_LINK_ENDPOINT.footer_de_at_privacyPolicy}`);
                setTermsOfUseURL(`${window.env[`MAIN_BASEURL_${countryCode}`]}${EXTERNAL_LINK_ENDPOINT.footer_de_at_termsOfUse}`);
            }
            else if(countryCode === "IT"){
                setPrivacyURL(`${window.env[`MAIN_BASEURL_${countryCode}`]}${EXTERNAL_LINK_ENDPOINT.footer_it_privacyPolicy}`);
                setTermsOfUseURL(`${window.env[`MAIN_BASEURL_${countryCode}`]}${EXTERNAL_LINK_ENDPOINT.footer_it_termsOfUse}`);
            }
            else{
                setPrivacyURL(`${window.env[`MAIN_BASEURL_${countryCode}`]}${EXTERNAL_LINK_ENDPOINT.footer_privacyPolicy}`);
                setTermsOfUseURL(`${window.env[`MAIN_BASEURL_${countryCode}`]}${EXTERNAL_LINK_ENDPOINT.footer_termsOfUse}`);
            }
        }
    }, [countryCode])
    return (
        location.pathname === routePath.BENEFIT_OF_SUPPLIES?
        null
        :
        <div id={footerID} className={`${cname} footer`}>
            <div className='f-12 privacy-terms'>
                <span className='privacy-policy'><a href={privacyURL} target = "_blank"  rel="noreferrer">{t("FOOTER.PRIVACY_POLICY")}</a></span> | <span className='terms-use'><a href={termsOfUseURL} target = "_blank"  rel="noreferrer">{t("FOOTER.TERMS_OF_USE")}</a></span>
            </div>
            <div className='f-12'>{t("FOOTER.COPY_RIGHT",{currentYear:currentYear})}</div>
        </div>
    );
} 

export default MobileFooter;