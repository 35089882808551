import jwt_decode from "jwt-decode";
import { ECOPROTOKEN_CLAIMS, GB_COUNTRY, LOCALSESSIONDATAKEYS, UK_COUNTRY } from "../../constants/AppConstants";

/**
 * Method to store BOL user data
 * @param {object} data 
 * @returns void
 */
export const setUserData = (data) => {
    localStorage.setItem('USERDATA', JSON.stringify(data));
}
/**
 * Method to get BOL user data
 * @returns object
 */
export const getUserData = () => {
    return JSON.parse(localStorage.getItem('USERDATA'));
}
/**
 * Method to obtain BOL user data by key
 * @param {string} key 
 * @returns string
 */
export const getUserDataByKey = (key, parent = null) => {
    let result = "";
    const data = JSON.parse(localStorage.getItem('USERDATA'));
    if(data){
        if(parent){
            result =  data[parent]?.[key]??""
        } else {
            result =  (data[key]) ? data[key] : "";
        }
    }
    return result;
   
}
/**
 * Method to remove BOL userdata from storage.
 */
export const removeUserData = () => {
    localStorage.removeItem('USERDATA');
}

export const getFeatureSwitchByKey = (key) => {
    let featureSwitchDetails = getUserSessionData(ECOPROTOKEN_CLAIMS.FEATURESWITCHITEMS);
        if(featureSwitchDetails){
        const splittedData = featureSwitchDetails.split(",");
        const res = splittedData.filter((value) => {
            return value.split(":")[0] === key;
        });
        return parseInt(res.toString().split(":")[1]) || 0;
    } else {
        return 0;
    }
}

const decodeEcoProSessionToken = (token) => {
   return  jwt_decode(token);
}
/**
 * Method to get the used session data from token stored in cookie
 * KEYS: ADB2CUserId, UserId, Roles, DeviceId, LanguageCode, CountryCode
 * @param {string} key 
 * @returns string
 */
export const getUserSessionData = (key) => {
  const token = getLocalSessionData(LOCALSESSIONDATAKEYS.ECOPROID);
    if(token){
        const decodedToken = decodeEcoProSessionToken(token);
        return (decodedToken && decodedToken.hasOwnProperty(key)) ? decodedToken[key] : "";
    }
}

export const setLocalSessionData = (key, value) => {
    removeLocalSessionData(key);
    if(key === LOCALSESSIONDATAKEYS.ECOPROCTRYCODE){
        value === UK_COUNTRY ? localStorage.setItem(key, GB_COUNTRY) : localStorage.setItem(key, value);
    }
    else{
        localStorage.setItem(key, value);
    }
}

export const getLocalSessionData = (key) => {
    return localStorage.getItem(key);
}

export const removeLocalSessionData = (key) => {
    localStorage.removeItem(key);
}

export const WEB_handleSelectedPlanLocalData = (action, value) => {
    let res = null;
    switch (action) {
        case 'Set':
            localStorage.setItem(LOCALSESSIONDATAKEYS.WEB_SELECTED_PLAN, JSON.stringify(value)); 
            break;
        case 'Get':
            res =  JSON.parse(localStorage.getItem(LOCALSESSIONDATAKEYS.WEB_SELECTED_PLAN));
            break;
        case "Remove":
            localStorage.removeItem(LOCALSESSIONDATAKEYS.WEB_SELECTED_PLAN);
            break;
        default:
            console.log(`Invalid input`);
    }
    return res;
}

export const WEB_handleLocalData = (action, key, value) => {
    let res = null;
    switch (action) {
        case 'Set':
            localStorage.setItem(key, JSON.stringify(value)); 
            break;
        case 'Get':
            res =  JSON.parse(localStorage.getItem(key));
            break;
        case "Remove":
            localStorage.removeItem(key);
            break;
        default:
            console.log(`Invalid input`);
    }
    return res;
}