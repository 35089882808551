import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetDeviceList } from "../actions/SelectDevice";
import Profile from "../components/profile/profile";

const SubscriptionNetworkErrorValidation = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
      dispatch(resetDeviceList());
  }, [dispatch])
  return (
    <Profile RouterLinkStateProp="SUBSCRIPTION_NETWORK_ERR_VALIDATION"></Profile>
  )
}

export default SubscriptionNetworkErrorValidation;