import * as actionTypes from '../../../constants/ActionTypes/Web/Customer/SignUp';

const initialState = {
    'success': false,
    'loading': false,
    'error': false,
    'bocDeviceCapability': null
}

const GetBOCWebDeviceCapability = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.GET_BOC_WEB_DEVICE_CAPABILITY:
            return {
                ...state,
                loading: true,
                'success': false,
                'error': false,
                'bocDeviceCapability': null
            }
        case actionTypes.GET_BOC_WEB_DEVICE_CAPABILITY_SUCCESS:
            return {
                ...state,
                success: true,
                loading: false,
                bocDeviceCapability: action.bocDeviceCapability,
                'error': false
            }
        case actionTypes.GET_BOC_WEB_DEVICE_CAPABILITY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.message,
                bocDeviceCapability:null
            }
        case actionTypes.RESET_ONE_TIME_CODE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: false,
            }
        case actionTypes.RESET_WEB_CONNECTION_API_DATA:
            return {
                ...state,
                'success': false,
                'loading': false,
                'error': false,
                'bocDeviceCapability': null
            }
        case actionTypes.RESET_CODE_SUBMISSION_DATA:
            return {
                ...state,
                'success': false,
                'loading': false,
                'error': false,
                'bocDeviceCapability': null
            }
            
        default:
            return {...state};
    }
}

export default GetBOCWebDeviceCapability;