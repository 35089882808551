import * as actionTypes from '../../constants/ActionTypes/FAQ';

const initialState = {
    'FAQContent': {},
    'loading': false,
    'error': false
  }

  const FAQContent = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.FAQ_FETCH_CONTENT_INIT:
            return {
                ...state,
                loading: true
            }
        case actionTypes.FAQ_FETCH_CONTENT_SUCCESS:
            return {
                ...state,
                FAQContent: action.FAQContent,
                loading: false
            }
        case actionTypes.FAQ_FETCH_CONTENT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message
        }
        default:
            return {...state};
    }

}

export default FAQContent;