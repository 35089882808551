import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../../../constants/ActionTypes/Web/Customer/SignUp';
import { _CALLPOSTAPI } from '../../../api/apiHandler'
import { WEB_CUSTOMER_API_ENDPOINT, SUCCESS } from '../../../constants/AppConstants';
import i18next from 'i18next';

function _PLACEORDERCALL(payload){
    const endpoint = WEB_CUSTOMER_API_ENDPOINT.PRE_ENROLLMENT;
    return _CALLPOSTAPI(endpoint, payload )
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* handleEnrollmentProcess(action){
    try{
        const enrollmentDetails = yield call(_PLACEORDERCALL, action.payload);
        if(enrollmentDetails.actionStatus.toUpperCase() === SUCCESS){
            yield put({type: actionTypes.PRE_ENROLLMENT_SUCCESS, enrollmentResponse: enrollmentDetails});
        } else {
            yield put({type: actionTypes.PRE_ENROLLMENT_ERROR, message: i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')});
        }
    }catch(e){
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
        if(e instanceof TypeError === false && e.message !== "401"){
            yield put({type: actionTypes.PRE_ENROLLMENT_ERROR, message: e.message});
        }
    }
}
function* PreEnrollment(){
    yield takeEvery(actionTypes.PRE_ENROLLMENT_INIT, handleEnrollmentProcess);
}

export default PreEnrollment;