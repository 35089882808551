import React from 'react';
import { useTranslation } from "react-i18next";

const PersonalDetails = (props) => {
    const { t } = useTranslation(["translation"]);
    const userPersonalData = (props.myAccountData.myAccount && Object.keys(props.myAccountData.myAccount).length > 0)  
    ? props.myAccountData.myAccount : {};
    let userName = "";
    if(userPersonalData.firstName){
      userName = (userPersonalData.lastName) ? `${userPersonalData.firstName} ${userPersonalData.lastName}` : userPersonalData.firstName;
    }
    return (
        <React.Fragment>
        <div className="row my-account-content plan-border">
          <div className="f-16 fc-g fw-b ta-left mb-30">
            {t("DASHBOARD.MY_ACCOUNT_LANDING.PERSONAL_DETAILS_HEADER")}
          </div>
          <div
            className="row"
          >
            <div className="col-10 pb-15 plan-type">
              <div className="plan-type-header">
                {t("DASHBOARD.MY_ACCOUNT_LANDING.NAME")}
              </div>
              {userPersonalData.firstName && <div className="plan-occasional">{userName}</div>}
              {!userPersonalData.firstName && <div className="plan-occasional"></div>}
            </div>
          </div>
          <div
            className="row"
          >
            <div className="col-10 mt-15 pb-15">
              <div className="plan-type-header">
                {t("DASHBOARD.MY_ACCOUNT_LANDING.EMAIL")}
              </div>
              {userPersonalData.emailAddress && <div className="plan-occasional">
                {userPersonalData.emailAddress}
              </div>}
              {!userPersonalData.emailAddress && <div className="plan-occasional"></div>}
            </div>
          </div>
          <div
            className="row"
          >
            <div className="col-10 mt-15 pb-15">
              <div className="plan-type-header">
                {t("DASHBOARD.MY_ACCOUNT_LANDING.MOBILE")}
              </div>
              {userPersonalData.mobileNumber && <div className="plan-occasional">
               {userPersonalData.mobileNumber}
              </div>}
              {!userPersonalData.mobileNumber && <div className="plan-occasional"></div>}
            </div>
          </div>
          <div
            className="row"
          >
            <div className="col-10 mt-15">
              <div className="plan-type-header">
                {t("DASHBOARD.MY_ACCOUNT_LANDING.TELEPHONE")}
              </div>
             { userPersonalData.phoneNumber &&  <div className="plan-occasional">
              {userPersonalData.phoneNumber}
              </div>}
              { !userPersonalData.phoneNumber &&  <div className="plan-occasional"></div>}
            </div>
          </div>
        </div>
      
      </React.Fragment>
    );
};

export default PersonalDetails;