import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { UpdateSubscriptionPlan } from "../../../actions/Dashboard";
import { fetchPlans } from "../../../actions/MyPlan";
import DisplayPlanList from "../../../components/EditMyAccount/DisplayPlanList/DisplayPlanList";
import ErrorPage from "../../../components/shared/errorPage/ErrorPage";
import Loader from "../../../components/shared/loader/Loader";
import {
  ECOPROTOKEN_CLAIMS,
  LOCALSESSIONDATAKEYS,
  tryAgainBtn,
} from "../../../constants/AppConstants";
import { routePath } from "../../../constants/Routes";
import {
  getLocalSessionData,
  getUserSessionData,
} from "../../../utils/Authentication/handleUserSession";
import { scrollToTop } from "../../../constants/AppMethod";

const ChangePlan = () => {
  const planListResult = useSelector((state) => state.MyPlan);
  const deviceInfoResult = useSelector((state) => state.DashboardDeviceInfo);
  const updatePlanResult = useSelector((state) => state.UpdatePlan);
  const dispatch = useDispatch();
  const [selectedChangePlan, setSelectedChangePlan] = useState("");
  const [formContent, setFormContent] = useState({changePlanTerms:false});
  const history = useHistory();
  const { t } = useTranslation(['translation']);

  useEffect(() => {
     /** This was implemented to avoid page auto scrolling down in some browser scenarios eg:Firefox */
      scrollToTop('.change-plan-container');

    const model = deviceInfoResult?.deviceInfo?.model;
    const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
    const deviceId = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID);
    dispatch(fetchPlans(model,UID,deviceId,null, 'changeplan'));

    if (deviceInfoResult?.deviceInfo?.service?.currentPlan?.planId) {
      setSelectedChangePlan(
        deviceInfoResult?.deviceInfo?.service?.currentPlan
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectChangePlan = (plan) => {
    setSelectedChangePlan(plan);
  };

  const confirmChangePlan = () => {
    const updatedPlanDetails = selectedChangePlan;
    dispatch(UpdateSubscriptionPlan(updatedPlanDetails));
  };

  const handleCheckChange = (event) => {
    const { name, checked } = event.target;
    setFormContent({
      ...formContent,
      [name]: checked,
    });
  }

  if (updatePlanResult.error) {
    return <ErrorPage errorInfo={updatePlanResult.error}></ErrorPage>;
  }
  if(planListResult.error) {
    return <ErrorPage errorInfo={planListResult.error}></ErrorPage>;
  }
  if (updatePlanResult.success === true) {
    history.push({ pathname: routePath.MY_ACCOUNT });
  }

  if (planListResult.loading || updatePlanResult.loading) {
    return <Loader></Loader>;
  }

  if(!deviceInfoResult?.deviceInfo?.model){
    return <ErrorPage errorInfo={t('ERROR_CONTENT.INFO_GENERAL')} showTryAgain={tryAgainBtn.SHOW} to={routePath.MY_ACCOUNT}></ErrorPage>
  }

  return (
    <DisplayPlanList
      planList={planListResult}
      selectedPlan={deviceInfoResult?.deviceInfo?.service?.currentPlan}
      onSelectChangePlan={onSelectChangePlan}
      confirmChangePlan={confirmChangePlan}
      selectedPlanDuringSignUp={
        deviceInfoResult?.deviceInfo?.service?.currentPlan?.planId
      }
      selectedChangePlan={selectedChangePlan}
      handleCheckChange={handleCheckChange}
      formContent={formContent}
    />
  );
};

export default ChangePlan;
