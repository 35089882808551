import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from "reactstrap/es/Container";


const DesktopDisplayListOfDevice = ({className, deviceList, goToPlansPage}) => {
    const { t } = useTranslation(["translation"]);
    return (
        <div className={className} >
            <Container className="mt-40 printer-wrapper variable-width">
            <div className="select-printer-models-wrapper mt-40">
                <div className="printer-model-list-wrapper">
                    {
                        deviceList && deviceList.map((device) => (
                        <div className={`${(device.model) ? "item" : "invisible-item"}`} key={device.id} id={`select_model_${device.model}`} onClick={() => goToPlansPage(device.deviceId,device.model, device.serialNumber, device.networkStatus)}>
                            { device.model && <img src={device.imagePath} alt="printer"/> }
                            <p className='model'>{device?.model}</p>
                            <p className='serial-number'>{t("DASHBOARD.MY_ACCOUNT_LANDING.SERIAL_NUMBER")}: {device?.serialNumber}</p>
                        </div>      
                        ))  
                    }
                </div>
            </div>
            </Container>
        </div>
    );
};

export default DesktopDisplayListOfDevice;