import React from "react";
import EcoProButton from "../../shared/button/button";
import { useTranslation } from "react-i18next";
import './LoginBanner.css';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { routePath } from "../../../constants/Routes";

const LoginBanner = () => {
    const { t } = useTranslation(["translation"]);
    const history = useHistory();
    const handleSubmit = () => {
       history.push(routePath.SHENLONG_REDIRECT);
    }
    return (
        <div className="landing-page-login-banner">
            <p className="login-text">{t('LANDING_PAGE.ALREADY_AN_ECOPRO_CUSTOMER')}</p>
            <EcoProButton 
                btnID="landing-page-login-btn"
                btnType="submit"
                cname='default epic-blue-btn landing-page-btn web-ecopro-btn'
                onBtnClick={() => handleSubmit()}>
                    {t('LANDING_PAGE.LOGIN')}
            </EcoProButton>
        </div>
    )
}

export default LoginBanner;