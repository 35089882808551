import React from 'react';

const HardwareSignupCard = ({onHardwarePlanSelection, selectedCard, toCompareSelectedCardWith, cardRightData, cardLeftData}) => {

   
    return (
        <div className={`cp-card-wrapper row ${selectedCard === toCompareSelectedCardWith?'cp-card-selected':""}`} onClick={()=>onHardwarePlanSelection(toCompareSelectedCardWith)}>
            <div className='col-7'>{cardRightData}</div>
            <div className='col-5 cp-card-price'>{cardLeftData}</div>
        </div>
    );
};

export default HardwareSignupCard;