import React from "react";
import ADB2CAdminProtectedRoute from "../ADB2CAdminProtectedRoute";
import ADB2CCSAgentProtectedRoutes from "../ADB2CCSAgentProtectedRoutes";
import ADB2CCustomerProtectedRoute from "../ADB2CCustomerProtectedRoute";
import CustomerUnProtectedRoute from "../CustomerUnProtectedRoute";
import WebCustomerProtectedRoute from "../WebCustomerProtectedRoute";
import WebCustomerUnProtectedRoute from "../WebCustomerUnProtectedRoute";

export const CustomerUnProtectedRouteComp = ({ path, component: Component, ...rest }) => (
  <CustomerUnProtectedRoute
    exact
    path={path} 
    component={(props) => <Component {...props} {...rest} />}
  />
);
export const ADB2CCustomerProtectedRouteComp = ({ path, component: Component, ...rest }) => (
    <ADB2CCustomerProtectedRoute
      exact
      path={path} 
      isSubscribed={rest.isSubscribed}
      dontTrack={rest.dontTrack}
      component={(props) => <Component {...props} {...rest}
      />}
    />
);
export const WebCustomerProtectedRouteComp = ({ path, component: Component, ...rest }) => (
  <WebCustomerProtectedRoute
    exact
    path={path}
    isSubscribed={rest.isSubscribed}
    component={(props) => <Component {...props} {...rest} />}
  />
);
export const WebCustomerUnProtectedRouteComp = ({ path, component: Component, ...rest }) => (
  <WebCustomerUnProtectedRoute
    exact
    path={path} 
    component={(props) => <Component {...props} {...rest} />}
  />
);
export const ADB2CCSAgentProtectedRouteComp = ({path, component: Component, ...rest}) => (
  <ADB2CCSAgentProtectedRoutes
    exact
    path={path} 
    component={(props) => <Component {...props} {...rest} />}
  />
)
export const ADB2CAdminProtectedRouteComp = ({path, component: Component, ...rest}) => (
  <ADB2CAdminProtectedRoute
    exact
    path={path} 
    component={(props) => <Component {...props} {...rest} />}
  />
)

