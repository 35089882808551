import React, { useState } from "react";
import { Container } from "react-bootstrap";
import unFilledStar from "../../assets/images/ratingstarsunfilled.svg";
import filledStar from "../../assets/images/ratingstarsfilled.svg";
import "./SignUpFeedback.css";
import RatingStars from "../shared/ratingstars/RatingStars";
import { useTranslation } from "react-i18next";
import EcoProButton from "../shared/button/button";
import { useDispatch } from "react-redux";
import { blockSpecialCharacterRegex } from "../../constants/AppConstants";
import { SignUpUserFeedback } from "../../actions/Dashboard";


const SignUpFeedback = (props) => {
    const {issuesOptionListArr, header, rateYourExperience, skipThisSectionVisibility, feedbackSource, showFeedbackSubheader = true, feedbackType} = props;
    const { t } = useTranslation(['translation']);
    const dispatch = useDispatch();
    const SKIPPED = "skipped";
    const ratingsTxtArr = [
        t('SIGN_UP_FEEDBACK.RATINGS_TXTS.RATING_1'), 
        t('SIGN_UP_FEEDBACK.RATINGS_TXTS.RATING_2'), 
        t('SIGN_UP_FEEDBACK.RATINGS_TXTS.RATING_3'), 
        t('SIGN_UP_FEEDBACK.RATINGS_TXTS.RATING_4'),
        t('SIGN_UP_FEEDBACK.RATINGS_TXTS.RATING_5')
    ];
    
    const [selectedRating, setSelectedRating] = useState({});
    const [starClicked, setStarClicked] = useState([false, false, false, false, false]);
    const [issueTypes, setIssueTypes] = useState([]);
    const [additionalComment, setAdditionalComment] = useState(null);
    const setRatings = (key) => {
        let clickStates = [...starClicked];
        for (let i = 0; i < ratingsTxtArr.length; i++) {
          if (i <= key) clickStates[i] = true;
          else clickStates[i] = false;
        }
        setStarClicked(clickStates);
        setSelectedRating({...selectedRating, 
            'rating': key + 1, 
            'ratingContent': ratingsTxtArr[key]
        });
    }
    const handleIssueTypes = (val) => {
        if(issueTypes.includes(val)){
            const newArr = removeArrItem(issueTypes, val);
            setIssueTypes(newArr);
        } else {
            setIssueTypes([...issueTypes, val]);
        }
    }
    const removeArrItem = (arr, item) => {
        var index = arr.indexOf(item);
        return [
            ...arr.slice(0, index),
            ...arr.slice(index + 1)
        ];
    }
    const sendFeedback = (type = null) => {
        let rating, issuesTypes, additionalInfo;
        if(type && type === SKIPPED){
            rating = issuesTypes = additionalInfo = SKIPPED;
        } else {
            rating = selectedRating["rating"].toString() || SKIPPED;
            issuesTypes = issueTypes.toString() || SKIPPED;
            additionalInfo = additionalComment || SKIPPED;
        }
        const feedbackPayload = {
            "Rating": rating,
            "IssueTypes": issuesTypes,
            "AdditionalInformation": additionalInfo,
            "Source": feedbackSource
        }
        dispatch(SignUpUserFeedback(feedbackPayload, feedbackType))
    }
    const setComment =  (e) => {
        const {value} = e.target
        if (value === "" || blockSpecialCharacterRegex.test(value)) {
            setAdditionalComment(value);
        }
    }
    return(
        <Container className="signup-feedback-wrapper mt-40">
            <div className="header">
                <h1>{header}</h1>
                { showFeedbackSubheader && <h5>{t('SIGN_UP_FEEDBACK.SUB_HEADER')}</h5> }
            </div>
            <div className="signup-feedback-content-wrapper">
                <p>{rateYourExperience}</p>
                <div className="signup-feedback-rating-stars-wrapper">
                    <RatingStars 
                    ratingStarsArr = {ratingsTxtArr}
                    setRatings = {setRatings}
                    starClicked = {starClicked}
                    filledStar = {filledStar}
                    unFilledStar = {unFilledStar}
                    />
                    <div className="rating-star-content">
                        <p>{selectedRating['ratingContent']}</p>
                    </div>
                </div>
                {selectedRating['rating'] > 0 && selectedRating['rating'] < 5 && <div className="signup-feedback-issues-list-wrapper mt-20">
                    <p>{t('SIGN_UP_FEEDBACK.SELECT_ISSUE')}</p>
                        <div className="signup-feedback-issues-options-wrapper row">
                            {
                                issuesOptionListArr.map((val) => (
                                    <div 
                                    className={`${
                                        issueTypes.includes(val)
                                        ? "selected-issuetype"
                                        : null
                                    }`}
                                    onClick={() => handleIssueTypes(val)}  key={val}>
                                        <span>{val}</span>
                                    </div>
                                ))
                            }
                        </div>
                </div>}
                <div className="signup-feedback-comments-wrapper mt-40">
                    <p>{t('SIGN_UP_FEEDBACK.COMMENT_YOUR_EXPERIENCE')}</p>
                    <div className="text-area">
                        <textarea
                        value={additionalComment}
                        key="additionalInfo"
                        name="additionalInfo"
                        placeholder={t("SIGN_UP_FEEDBACK.LEAVE_FEEDBACK")}
                        onChange={(e)=>setComment(e)}
                        maxLength={200}
                        ></textarea>
                    </div>
                </div>
            </div>
            <div className="signup-feedback-buttons-wrapper mt-30 mb-30">
                <EcoProButton cname="default" disabled={selectedRating.hasOwnProperty('rating') && selectedRating['rating'] > 0 ? false : true} onBtnClick={() => sendFeedback()}>{t('SIGN_UP_FEEDBACK.SUBMIT_BUTTON')}</EcoProButton>
                {skipThisSectionVisibility && <p className="mt-20" onClick={() => sendFeedback(SKIPPED)}>{t('SIGN_UP_FEEDBACK.SKIP_SECTION')}</p>}
            </div>
        </Container>
    )
}

export default SignUpFeedback;