import React from "react";
import { useTranslation } from "react-i18next";
import HardwareSignupCard from "../../../HardwareSignupCard/HardwareSignupCard";
import { useState } from "react";
import LearnMorePrinterFeature from "../../LearnMorePrinterFeature/LearnMorePrinterFeature";

const DisplayPlanLength = (props) => {
  const { handleSelectionChange, selectedData, planLengths } = props;

  const { t } = useTranslation(["translation"]);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  // @@@@@TODO   const DisplayLearnMore = () => setShow(true);
  return (
    <div className="cp-printer-feature-wrapper">
      <div className="cp-subtitle">
        {t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.PLAN_LENGTH")}
      </div>
      {/* @@@@@@@@@@@@@@@@@@@@@ TODO
        <div className='cp-extra-title-list-header'>{t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.WHAT_RIGHT_PLAN_LENGTH")}</div>
    */}

      {planLengths.map((length, index) => (
        <HardwareSignupCard
          key={index}
          onHardwarePlanSelection={handleSelectionChange}
          selectedCard={selectedData}
          toCompareSelectedCardWith={`${length}`}
          cardRightData={`${length} ${t(
            "HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.SELECTED_FEATURES.MONTHS"
          )}`}
        />
      ))}

      <LearnMorePrinterFeature
        show={show}
        handleClose={handleClose}
      ></LearnMorePrinterFeature>
    </div>
  );
};

export default DisplayPlanLength;
