import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getPersonalOrBusinessTerms, getPlanTranslation, formatNumberOnLocale } from '../../../constants/AppMethod';
import currencies from '../../../constants/CurrencyTypes';
import checkWithBackground from '../../../assets/images/checkWithBackground.svg';
import './DisplayPlanList.css'
import EcoProButton from '../../shared/button/button';
import FormCheck from '../../shared/formElements/FormCheck';

const DisplayPlanList = (props) => {
    const {planList,selectedPlan,onSelectChangePlan,confirmChangePlan,selectedChangePlan,handleCheckChange,formContent} = props
    const { t } = useTranslation(["translation"]);
    const termsAndConditionLink = getPersonalOrBusinessTerms()
    
    return (
        /**
         * Modifying the classname 'change-plan-container' should be updated in 'scrollToTop' method in useEffect of parent component
         * */
        <div className='change-plan-container'>
            <div className='change-plan-header'>{t("CHANGE_PLAN.HEADER")}</div>
            {planList.hasOwnProperty('plans') && planList?.plans?.length > 0 && planList?.plans?.map((plan)=> (
                <div className={`change-plan-list-wrapper pointer-cursor ${selectedChangePlan.planId === plan.planId? "change-plan-selected":undefined}`} key={plan.planId} onClick={()=>onSelectChangePlan(plan)}>
                    <div className='change-plan-name-recommened'>
                        <div className="change-plan-name">{getPlanTranslation(plan.planName)}</div>
                        {plan.isRecommended &&
                        <div className='change-plan-recommened'>{t("CHANGE_PLAN.RECOMMENDED")}</div>}
                    </div>
                    <div className='change-plan-pages-per-month'>{t('CHANGE_PLAN.PAGES_PRINTED', {planPages: plan?.planPages})}</div> 
                    <div className='change-plan-price-selected'>
                        {plan?.price && 
                            <div className="change-plan-price">{formatNumberOnLocale(plan?.price, currencies[plan?.currency]?.code)} {t('ECOPROCAROUSEL_CONTENT.PER_MONTH')}</div>
                        }
                        {selectedPlan?.planId === plan?.planId &&
                        <div className='change-plan-selected-img'>
                            <img src={checkWithBackground} alt="plan-selected"/>
                        </div>
                        }
                    </div>
                     
                </div>
            ))}
            <div className='change-plan-terms'>
                <div className='change-plan-renew'>{t("CHANGE_PLAN.RENEW")}</div>
                <div>
                <FormCheck
                  label={<Trans i18nKey="CHANGE_PLAN.TERMS">
                        By selecting confirm you are agreeing to all pricing changes based on plan selected. To read more about this please view our
                        <a href={termsAndConditionLink} target = "_blank"  rel="noreferrer">terms & conditions policy</a>.
                    </Trans>}
                  name="changePlanTerms"
                  id="changePlanTerms"
                  handleCheck={handleCheckChange}
                  formContent={formContent}
                  required={false}
                />
                </div>
            </div>
            <div className='change-plan-btn'>
                <EcoProButton cname="default" disabled={selectedPlan?.planId===selectedChangePlan.planId || !formContent.changePlanTerms} onBtnClick={() => confirmChangePlan()}>{t('ECOPRO_BTNS.CONFIRM_CHANGES')}</EcoProButton>            
            </div>
        </div>
    );
};

export default DisplayPlanList;