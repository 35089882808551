import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import PageHeader from "../../../../components/AdminPortal/Shared/PageHeader/PageHeader.js";
import FeatureSwitch from "../../../../components/AdminPortal/FeatureSwitch/FeatureSwitch.js";
import "./ManageCountry.css"
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../components/shared/loader/Loader.js"
import AdminAlert from "../../../../components/AdminPortal/AdminAlert/AdminAlert.js";
import { featureSwitchResetState } from "../../../../actions/AdminPortal/FeatureSwitch.js";

const ManageCountry = () => {
  const { t } = useTranslation("Admintranslation");
  const dispatch = useDispatch();
  const featuresList = useSelector(state => state.ADMIN.FeatureSwitchCountry);
  const updateFeatureListResp = useSelector(state => state.ADMIN.UpdateFeatureSwitch);
  let displayList = null;
  useEffect(() => {
    dispatch(featureSwitchResetState());
  }, [dispatch]);

  const setFeatureList = (data) => {
    return ({ 
      id: data.featureSwitchId,
      name : data.featureName,
      isEnabled: data.isEnabled,
      code: data.countryCode,
      isChangeEventFired: false,
      saveBtnDisabled : true
    })
  }
  if(featuresList.success){
    displayList = Object.values(featuresList?.data).map(setFeatureList)
  }
  return(
      <Container>
          {(featuresList.loading || updateFeatureListResp.loading) && <Loader setOverlay={true}></Loader>}
          <div className="feature-switch-manage-country-header-container">
            <PageHeader pageTitle={t('FEATURE_SWITCH.PAGE_TITLE')} pageSubTitle={t('FEATURE_SWITCH.PAGESUBTITLE_COUNTRY')}/>
          </div>
          { featuresList.error && <AdminAlert alert_variant="alert-danger">{featuresList.error}</AdminAlert> }
          <FeatureSwitch 
            featureSwitchType='COUNTRY' 
            displayList={displayList}
            updateFeatureSwitchResp = {updateFeatureListResp}
          />
      </Container>
  );
}

export default ManageCountry;