import React from "react";
import "./FormSelect.css";

const AdminFormSelect = (props) => {
    const { 
        cname, 
        name, 
        options, 
        value,
        onChange, 
        placeholder = "Select",
        disabled = false,
        multiple = false
    } = props;
    return (
        <select 
            id={name} 
            multiple = {multiple} 
            disabled = {disabled} 
            className={`admin-form-select form-select ${cname?.split(",").join("")}`} 
            name={name} 
            onChange={onChange}
            value={value}
        >
            <option defaultValue className="admin-form-select-default-value">
                --- {placeholder} ---
            </option>
            {options}
        </select>
    )
}

export default AdminFormSelect;