import React from "react";
import { Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import { getLocalSessionData } from "../../../utils/Authentication/handleUserSession";
import { DISCOUNT_PRICE, LOCALSESSIONDATAKEYS } from "../../../constants/AppConstants";
import currencies from "../../../constants/CurrencyTypes";
import './paymentSummary.css';
import { Is_HWInclusive_Signup, formatNumberOnLocale, getPlanTranslation } from "../../../constants/AppMethod";
import { Link } from "react-router-dom";
import { WebCustomerRoutePaths, WebHardwareInclusiveRoutePath } from "../../../constants/Routes";

const PaymentSummary = (props) => {
    const { t } = useTranslation(["translation"]);
    const { planDetails, goTo } = props;
    const {planName, price, currency, discountPlanIds, HWPlans = null} = planDetails || {};
    const DEVICE_MODEL = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_MODEL);
    const DEVICEID = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID)
    const SERIALNUMBER = getLocalSessionData(LOCALSESSIONDATAKEYS.SERIAL_NUMBER)
    const NETWORKSTATUS = getLocalSessionData(LOCALSESSIONDATAKEYS.NETWORK_STATUS)
    const IsHWInclusive_Signup = Is_HWInclusive_Signup();

    const handleChangePlanLink = (goTo) => {
        let goToURL;
        if(goTo === WebHardwareInclusiveRoutePath.SELECT_PRINTER){
            goToURL = `${goTo}?fieldtestpass=a3230b5f-cf13-5883-90f6-256bf540ae03&request-id=c46afda9-f417-5563-b543-9a7d245ec161`
        } else{
            goToURL = goTo;
        }
        return goToURL;
    }
    return(
        <div className="summary-outer-wrapper">
            <Container className="payment-summary-wrapper">
                <div className="payment-summary-content-wrapper">
                    <div className="top-section">
                        <div className="left-content">
                            <h5>{t("PAYMENT_SUMMARY.TITLE")}</h5>
                            <div class="plan-details">
                                <p className="plan-name">
                                    {t("PAYMENT_SUMMARY.PLAN_NAME", {plan_name: getPlanTranslation(planName)})}
                                </p>
                                <p className="printer-model-type">
                                    {
                                        t("PAYMENT_SUMMARY.DEVICE_MODEL", {device_model: DEVICE_MODEL})
                                    }
                                </p>
                            </div>
                        </div>
                        <div className="right-content">
                            <p class="change-plan-link">
                                { (goTo === WebCustomerRoutePaths.SELECT_PLAN || goTo === WebHardwareInclusiveRoutePath.SELECT_PRINTER)  ? 
                                    ( <a href={handleChangePlanLink(goTo)} id="change-plan">{t("PAYMENT_SUMMARY.CHANGE_PLAN_LINK")}</a> ) : ( 
                                        <Link to={{
                                            pathname: goTo,
                                            state : {
                                                    deviceId:DEVICEID, 
                                                    device_model: DEVICE_MODEL, 
                                                    serialNumber: SERIALNUMBER, 
                                                    networkStatus: NETWORKSTATUS
                                                }
                                            }} >
                                            {t("PAYMENT_SUMMARY.CHANGE_PLAN_LINK")}
                                        </Link>
                                    )
                                }
                            </p>
                            <div className="plan-details">
                                <p className="current-due-price-txt">{t("PAYMENT_SUMMARY.DUE_TODAY_TXT")}</p>
                                <p className="current-due-price">
                                    {
                                        discountPlanIds ? (
                                            t("PAYMENT_SUMMARY.DUE_PRICE", {
                                                due_price: formatNumberOnLocale(DISCOUNT_PRICE, currencies[currency]?.code)
                                            })
                                        ) : (
                                            t("PAYMENT_SUMMARY.DUE_PRICE", {
                                                due_price: (IsHWInclusive_Signup) ? formatNumberOnLocale(HWPlans?.price, currencies[currency]?.code) : formatNumberOnLocale(price, currencies[currency]?.code)
                                            }) 
                                        )
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-section">
                        <ul>
                            <li>
                            { discountPlanIds ? 
                                    (
                                        t("PAYMENT_SUMMARY.BOTTOM_SECTION_POINTS.FREE_TRAIL_POINT_1", {
                                            plan_price: formatNumberOnLocale(price, currencies[currency]?.code)
                                        })
                                    )
                                : (
                                        t("PAYMENT_SUMMARY.BOTTOM_SECTION_POINTS.NON_FREE_TRAIL_POINT_1", {
                                            plan_price: formatNumberOnLocale(price, currencies[currency]?.code)
                                        })
                                    )
                                }
                            </li>
                            <li>{t("PAYMENT_SUMMARY.BOTTOM_SECTION_POINTS.POINT_2")}</li>
                            {
                                (IsHWInclusive_Signup)
                                ? <li>{t("PAYMENT_SUMMARY.BOTTOM_SECTION_POINTS.POINT_3_FOR_HWINCLUSIVE")}</li>
                                : <li>{t("PAYMENT_SUMMARY.BOTTOM_SECTION_POINTS.POINT_3")}</li>
                            }   
                        </ul>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default PaymentSummary;