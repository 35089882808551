import React from 'react';
import { useTranslation } from 'react-i18next';
import HardwareSignupCard from '../../../HardwareSignupCard/HardwareSignupCard';
import currencies from '../../../../../../constants/CurrencyTypes';
import { formatNumberOnLocale, getPlanTranslation } from '../../../../../../constants/AppMethod';

const DisplayMonthlyPageAllowance = (props) => {
    const {handleSelectionChange, selectedData,  servicePlans} = props

    const {t} = useTranslation(["translation"])

    return (
        <div className='cp-printer-feature-wrapper'>
                <div className='cp-hardware-plan-header'>{t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.SUPPLY_PLAN")}</div>
                <div className='cp-hardware-plan-border'></div>

                <div className='cp-subtitle'>{t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.MONTHLY_ALLOWANCE")}</div>
                <div className='cp-extra-title-list-header'>{t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.WHAT_RIGHT_PLAN")}</div>

                {servicePlans?.map(plan => (
                    <HardwareSignupCard 
                        id={plan.planId}
                        onHardwarePlanSelection={handleSelectionChange}
                        selectedCard={selectedData}
                        toCompareSelectedCardWith={plan.planPages}
                        cardRightData={`${getPlanTranslation(plan.planName)} - ${t('ECOPROCAROUSEL_CONTENT.NO_OF_PAGES',{planPages:plan.planPages})}`} 
                        cardLeftData={t('HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.BASE_PACKAGE.PLAN_PRICE',{planPrice:formatNumberOnLocale(plan.price, currencies[plan.currency]?.code)})} 
                    ></HardwareSignupCard>
                ))}
            </div>
    );
};

export default DisplayMonthlyPageAllowance;