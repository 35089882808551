import * as actionTypes from '../../constants/ActionTypes/FAQ';

const initialState = {
    'FAQSublist': {},
    'loading': false,
    'error': false
  }

  const FAQSublist = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.FAQ_FETCH_SUBLIST_INIT:
            return {
                ...state,
                loading: true
            }
        case actionTypes.FAQ_FETCH_SUBLIST_SUCCESS:
            return {
                ...state,
                FAQSublist: action.FAQSublist,
                loading: false
            }
        case actionTypes.FAQ_FETCH_SUBLIST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message
        }
        default:
            return {...state};
    }

}

export default FAQSublist;