import { call, put, takeEvery } from "redux-saga/effects";
import i18next from "i18next";
import * as actionTypes from "../../constants/ActionTypes/Dashboard";
import { _CALLGETAPI } from "../../api/apiHandler";
import { SUCCESS, _HARDWARE_API_ENDPOINTS } from "../../constants/AppConstants";

function _GETCANCELLATIONFEE(UID, deviceID) {
  const endpoint = _HARDWARE_API_ENDPOINTS.GET_CANCELLATION_FEE;
  const payload = {
    params: {
      UserId: UID,
      deviceId: deviceID,
    },
  };
  return _CALLGETAPI(endpoint, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function* getCancellationFee(action) {
  try {
    const deviceID = action.deviceId;
    const UID = action.uid;
    if(UID && deviceID){
      const cancellationFee = yield call(
        _GETCANCELLATIONFEE,
        UID,
        deviceID
      );
      if(cancellationFee.actionStatus.toUpperCase() === SUCCESS){
        yield put({
          type: actionTypes.GET_CANCELLATION_FEES_SUCCESS,
          cancellationFee: cancellationFee,
        });
      }else{
        yield put({
          type: actionTypes.GET_CANCELLATION_FEES_ERROR,
          message: i18next.t('ERROR_CONTENT.ERR_INFO.SUBSCRIPTION_REQUIRED'),
        });
      }
    } else {
      yield put({
        type: actionTypes.GET_CANCELLATION_FEES_ERROR,
        message: i18next.t('ERROR_CONTENT.INFO_GENERAL')
      });
    }
  } catch (e) {
    /**
     * This conditional statement is added to avoid
     * displaying error message in view while logout from app
     */
    if(e instanceof TypeError === false && e.message !== "401"){
      yield put({
        type: actionTypes.GET_CANCELLATION_FEES_ERROR,
        message: e.message,
      });
    }
  }
}

function* GetCancellationFees() {
  yield takeEvery(actionTypes.GET_CANCELLATION_FEES, getCancellationFee);
}

export default GetCancellationFees;
