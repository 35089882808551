export const routePrefix = {
    'ADMIN' : "admin",
    'CSAGENT': 'csagent',
    'CUSTOMER': 'mobileconnect/customer',
    'WEB_CUSTOMER': 'web',
    'WEB_HARDWARE': 'web/hardware-inclusive'
}

export const routePath = {
    "HOME":"/",
    "SHENLONG_REDIRECT":"/shenlong/customer/redirect",
    "BENEFIT_OF_SUPPLIES":"/shenlong/customer/benefitofsupplies",
    "REDIRECTION":`/redirect`,
    "HELP_SUPPORT":`/${routePrefix.CUSTOMER}/help-and-support`,
    "HELP_SUPPORT_CAT":`/${routePrefix.CUSTOMER}/help-and-support/:cat_id`,
    "HELP_SUPPORT_SUBCAT":`/${routePrefix.CUSTOMER}/help-and-support/:cat_id/:sub_cat_id`,
    "PLANS":`/${routePrefix.CUSTOMER}/plans`,
    "ERROR":`/${routePrefix.CUSTOMER}/error`,
    "ACCOUNT":`/${routePrefix.CUSTOMER}/account`,
    "PAYMENT":`/${routePrefix.CUSTOMER}/payment`,
    "DASHBOARD":`/dashboard`,
    "CONNECT_DEVICE_DASHBOARD": `/dashboard/connect-device`,
    "ORDERS":`/${routePrefix.CUSTOMER}/orders`,
    "ORDER_ID":`/${routePrefix.CUSTOMER}/order/:id`,
    "ORDER":`/${routePrefix.CUSTOMER}/order`,
    "MY_ACCOUNT":`/${routePrefix.CUSTOMER}/my-account`,
    "EDIT_ACCOUNT":`/${routePrefix.CUSTOMER}/edit-my-account`,
    "CANCEL_PLAN_FEEDBACK":`/${routePrefix.CUSTOMER}/cancel-plan/feedback`,
    "CANCEL_PLAN_CONFIRM":`/${routePrefix.CUSTOMER}/cancel-plan/confirm`,
    "CANCEL_PLAN_SUCCESS":`/${routePrefix.CUSTOMER}/cancel-plan/success`,
    "CONFIRM_DEVICE":`/${routePrefix.CUSTOMER}/confirm-device`,
    "DEVICE_LIST":`/${routePrefix.CUSTOMER}/device-list`,
    "LEGAL_TNC":`/${routePrefix.CUSTOMER}/legal-documents/terms-and-conditions`,
    "CARBON_OFFSETTING":`/${routePrefix.CUSTOMER}/carbon-offsetting`,
    "HOW_CO2_CALCULATED":`/${routePrefix.CUSTOMER}/how-co2-calculated`,
    "UPDATE_ACCOUNT_CONFIRMATION":`/${routePrefix.CUSTOMER}/confirm`,
    "UPDATE_ACCOUNT_SUCCESS":`/${routePrefix.CUSTOMER}/success`,
    "CHANGE_DEVICE":`/${routePrefix.CUSTOMER}/change-devices`,
    "CHANGE_DEVICE_CONFIRM":`/${routePrefix.CUSTOMER}/change-device/confirm`,
    "CHANGE_DEVICE_SUCCESS":`/${routePrefix.CUSTOMER}/change-device/success`,
    "ADD_DEVICE":`/${routePrefix.CUSTOMER}/add-device`,
    "LEGAL_TNC_BLANK":'/legal-documents/terms-and-conditions',
    "CHANGE_PLAN":`/${routePrefix.CUSTOMER}/change-plan`,
    "USER_PROFILE_UPDATE": `/profile-update`,
    "CORRECT_DEVICE_FOUND":`/${routePrefix.CUSTOMER}/add-my-device`,
    "MORE_DEVICE_FOUND":`/${routePrefix.CUSTOMER}/add-eligible-device`,
    "NO_DEVICE_FOUND":`/${routePrefix.CUSTOMER}/no-device-found`,
    "CONNECT_DEVICE": `/${routePrefix.CUSTOMER}/connect-device`,
    "WEB_PLACE_ORDER": `/${routePrefix.CUSTOMER}/place-order`,
    "ENROLLMENT_FEEDBACK": `/${routePrefix.CUSTOMER}/enrollment-feedback`,
    "SELECT_PAYMENT": `/${routePrefix.CUSTOMER}/select-payment`,
    "ECOPRO_FEEDBACK":`/${routePrefix.CUSTOMER}/ecopro-feedback`,
    "PRINT_ACTIVITY":`/${routePrefix.CUSTOMER}/print-activity`,
    "SUBSCRIPTION_NETWORK_ERROR_VALIDATION": `/${routePrefix.CUSTOMER}/verify-subscription`,
    "EDIT_PAYMENT":`/${routePrefix.CUSTOMER}/edit-payment-details`,

}

export const adminRoutePaths = {
    "DASHBOARD": `/${routePrefix.ADMIN}/dashboard`,
    "FEATURE_SWITCH":{
        "BY_COUNTRY": `/${routePrefix.ADMIN}/feature-switch/country`,
        "BY_FEATURE": `/${routePrefix.ADMIN}/feature-switch/feature`
    },
    "ADMIN_USERS":{
        "VIEW_USERS": `/${routePrefix.ADMIN}/admin-users`,
        "ADD_USER": `/${routePrefix.ADMIN}/admin-user/add`,
        "EDIT_USER": `/${routePrefix.ADMIN}/admin-user/edit`,
        "REVOKE_ROLE_ACCESS": `/${routePrefix.ADMIN}/admin-user/revoke-access`
    },
    "RETRY_INVOICE_POSTING":`/${routePrefix.ADMIN}/invoice-posting`,
    "SETTINGS": `/${routePrefix.ADMIN}/settings`,
    "MAINTENANCE":"/under-maintenance",
    "PRINTER_MODELS":{
        "BY_COUNTRY": `/${routePrefix.ADMIN}/printer-models/country`,
        "BY_MODELS": `/${routePrefix.ADMIN}/printer-models/models`
    }
}

export const CSAgentRoutePaths = {
    "CUSTOMER_SEARCH": `/${routePrefix.CSAGENT}/customer-search`,
    "CUSTOMER_SERVICE_DASHBOARD": `/${routePrefix.CSAGENT}/customer-dashboard`
}

export const WebCustomerRoutePaths = {
    "SELECT_PRINTER": `/${routePrefix.WEB_CUSTOMER}/select-printer`,
    "SELECT_PLAN": `/${routePrefix.WEB_CUSTOMER}/plans`,
    "WEB_REDIRECT": `/${routePrefix.WEB_CUSTOMER}/redirect`,
    "CREATE_ACCOUNT": `/${routePrefix.WEB_CUSTOMER}/create-account`,
    "ACCOUNT": `/${routePrefix.WEB_CUSTOMER}/account`,
    "ADD_PERSONAL_DETAILS":`/${routePrefix.WEB_CUSTOMER}/account`,
    "SUBSCRIPTION_CONFIRMATION":`/${routePrefix.WEB_CUSTOMER}/subscription-confirmation`,
    "CONNECT_DEVICE":`/${routePrefix.WEB_CUSTOMER}/connect-device`,
    "PAYMENT": `/${routePrefix.WEB_CUSTOMER}/payment`,
    "MULTIPLE_ATTEMPT_ERROR": `/${routePrefix.WEB_CUSTOMER}/multiple-attepmt-error`,
    "SELECT_PAYMENT": `/${routePrefix.WEB_CUSTOMER}/select-payment`,
    "USER_ACCOUNT_INFO": `/${routePrefix.WEB_CUSTOMER}/account-information`,
    "VERIFY_ACCOUNT": `/${routePrefix.WEB_CUSTOMER}/verify-account`,
    "PROCESS_ACCOUNT": `/${routePrefix.WEB_CUSTOMER}/process-account`
}

export const easySetupRoutePath = {
    "BANNER":"/easysetup/customer/benefitofsupplies"
}

export const WebHardwareInclusiveRoutePath = {
    "SELECT_PRINTER": `/${routePrefix.WEB_HARDWARE}/select-printer`,
    "CUSTOMIZE_PRINTER": `/${routePrefix.WEB_HARDWARE}/customize-printer`
}

/**
 * Any route path to adapt to browsers preferred language
 * will be added here
 */
export const allowedWebRoutesToAdaptToBrowserLangSettings = [
    WebCustomerRoutePaths.SELECT_PRINTER, 
    WebCustomerRoutePaths.SELECT_PLAN, 
    WebCustomerRoutePaths.WEB_REDIRECT,
    WebCustomerRoutePaths.CREATE_ACCOUNT, 
    WebCustomerRoutePaths.ACCOUNT,
    WebCustomerRoutePaths.ADD_PERSONAL_DETAILS,
    WebCustomerRoutePaths.SUBSCRIPTION_CONFIRMATION,
    WebCustomerRoutePaths.PAYMENT,
    WebCustomerRoutePaths.MULTIPLE_ATTEMPT_ERROR,
    WebCustomerRoutePaths.SELECT_PAYMENT,
    WebCustomerRoutePaths.USER_ACCOUNT_INFO,
    WebCustomerRoutePaths.VERIFY_ACCOUNT,
    WebCustomerRoutePaths.PROCESS_ACCOUNT,
    routePath.HOME,
    WebHardwareInclusiveRoutePath.SELECT_PRINTER,
    WebHardwareInclusiveRoutePath.CUSTOMIZE_PRINTER
]

export const  allowedRoutesToAdaptToQueryString = [routePath.BENEFIT_OF_SUPPLIES, easySetupRoutePath.BANNER]