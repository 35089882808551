import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import convertHtmlToReact from '@hedgedoc/html-to-react';
import Loader from "../../components/shared/loader/Loader";
import { getLangCode } from "../../utils/Internationalization/handleAppInternationalization";
import DisplayContent from "../../components/DisplayContent/DisplayContent";
import { FALLBACK_LNG } from "../../constants/AppConstants";
import { Container } from "reactstrap";
import { routePath } from "../../constants/Routes";
import { useHistory } from "react-router-dom";
import { ReactComponent as ChevronLeft } from "../../assets/images/chevron_left.svg";


const LegalDocs_TermsNConditions = () => {
    const countryCode = getLangCode();
    const { t } = useTranslation();
    const [content, setContent] = useState(null);
    const history = useHistory()
    
    const handleFallbackData = () => {
      const fallbackLanguage = FALLBACK_LNG
      import(`../../contents/${fallbackLanguage}/HowCo2Calculated.json`)
      .then((data) => {
        setContent(data.HOWCO2CALCULATED);
      })
      .catch((error) => {
        throw error
      })
    }
    useEffect(() => {
      new Promise((res, rej) => {
        import(`../../contents/${countryCode}/HowCo2Calculated.json`)
        .then((data) => {
          if(data.HOWCO2CALCULATED){
          setContent(data.HOWCO2CALCULATED);
          }
          else{
            handleFallbackData()
          }
        })
        .catch((error) => {
          handleFallbackData()
        });
      });
    }, [countryCode])

    const moveToPreviousPage = () => {
      history.push(routePath.CARBON_OFFSETTING);
    }

    if(!content){
      return <Loader></Loader>
    }
    return (
      <>
        <Container className="web-subheader desktop-app-back-btn">
            <div className="page-title-back-btn" onClick={()=>moveToPreviousPage()}>
                <ChevronLeft fill="#545454" />
                <div className="f-12 fw-b">{t("NAVIGATION_MENU.ECO_SAVINGS")}</div>
            </div>
        </Container>
        <DisplayContent 
        title={t("CARBON_OFFSETTING.HOW_CO2_CALCULATED.PAGE_TITLE")}
        subtitle={t("CARBON_OFFSETTING.HOW_CO2_CALCULATED.PAGE_SUB_TITLE")}
        content={convertHtmlToReact(content, [true])}
        ></DisplayContent>
      </>
    ); 
}

export default LegalDocs_TermsNConditions;