import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import './BOCDeviceConnection.css'
import { useDispatch, useSelector } from "react-redux";
import { fetchBOCDeviceConnectionRequest } from "../../../../../store/CSAgentPortal/BOCDeviceConnectionServiceSlice";
import Loader from "../../../../shared/loader/Loader";

const BOCDeviceConnection = (props) => {

    const dispatch = useDispatch();
    const { t } = useTranslation(["CSAgenttranslation"]);
    
    const deviceSerialNumber = props?.deviceInfo?.serialNumber;
    const countryCode = props?.userAccount?.countryCode;
    
    useEffect(() => {
        if (deviceSerialNumber && countryCode) {
            dispatch(fetchBOCDeviceConnectionRequest({ deviceSerialNumber, countryCode }));
        }
    }, [dispatch, deviceSerialNumber, countryCode]); 
 
    const BOCDeviceConnectionResponse = useSelector((state) => state.CSAGENT_RTK.BOCDeviceConnectionService.BOCDeviceConnectionService);
    const loading = useSelector((state) => state.CSAGENT_RTK.BOCDeviceConnectionService.loading);
    console.log(loading,"Loading value");
    const error = useSelector((state) => state.CSAGENT_RTK.BOCDeviceConnectionService.error);
    console.log(error,"Error value");
  
    if (loading) {
        return <Loader />;
    }
    if (error) {
        return <div className="error-message">{t("CUSTOMER_DASHBOARD.BOC_DEVICE_CONNECTION.ERROR_MESSAGE", { error })}</div>;
    }
    return (
        <div>
            {BOCDeviceConnectionResponse && (
                <div className="BOCcontents">
                    <h2 className="mt-40">{t("CUSTOMER_DASHBOARD.BOC_DEVICE_CONNECTION.TITLE")}</h2>
                    <div className="device-info">
                        <p>{t("CUSTOMER_DASHBOARD.BOC_DEVICE_CONNECTION.SERIAL_NUMBER")}: <span>{BOCDeviceConnectionResponse.serialNumber}</span></p>
                        <p>{t("CUSTOMER_DASHBOARD.BOC_DEVICE_CONNECTION.FIRMWARE_VERSION")}: <span>{BOCDeviceConnectionResponse.firmwareVersion}</span></p>
                        <p>{t("CUSTOMER_DASHBOARD.BOC_DEVICE_CONNECTION.MODEL")}: <span>{BOCDeviceConnectionResponse.model}</span></p>
                        <p>{t("CUSTOMER_DASHBOARD.BOC_DEVICE_CONNECTION.NETWORK_STATUS")}: <span>{BOCDeviceConnectionResponse.networkStatus}</span></p>
                    </div>

                    <div className="subscribed-user-info mt-40">
                        <h2>{t("CUSTOMER_DASHBOARD.BOC_DEVICE_CONNECTION.SUBSCRIBED_USER_TITLE")}</h2>
                        <p>{t("CUSTOMER_DASHBOARD.BOC_DEVICE_CONNECTION.DISPLAY_NAME")}: <span>{BOCDeviceConnectionResponse.subscribedUser?.displayName}</span></p>
                        <p>{t("CUSTOMER_DASHBOARD.BOC_DEVICE_CONNECTION.EMAIL_ID")}: <span>{BOCDeviceConnectionResponse.subscribedUser?.emailId}</span></p>
                        <p>{t("CUSTOMER_DASHBOARD.BOC_DEVICE_CONNECTION.FIRST_NAME")}: <span>{BOCDeviceConnectionResponse.subscribedUser?.firstName}</span></p>
                        <p>{t("CUSTOMER_DASHBOARD.BOC_DEVICE_CONNECTION.LAST_NAME")}: <span>{BOCDeviceConnectionResponse.subscribedUser?.lastName}</span></p>
                        <p>{t("CUSTOMER_DASHBOARD.BOC_DEVICE_CONNECTION.SAP_BP_ID")}: <span>{BOCDeviceConnectionResponse.subscribedUser?.sapBpId}</span></p>
                        <p>{t("CUSTOMER_DASHBOARD.BOC_DEVICE_CONNECTION.USER_ID")}: <span>{BOCDeviceConnectionResponse.subscribedUser?.userId}</span></p>
                    </div>

                    <div className="subscribed-services-info mt-40">
                        <h2>{t("CUSTOMER_DASHBOARD.BOC_DEVICE_CONNECTION.SUBSCRIBED_SERVICES_TITLE")}</h2>
                        {BOCDeviceConnectionResponse.subscribedServices?.length > 0 ? (
                            BOCDeviceConnectionResponse.subscribedServices.map((service, index) => (
                                <div key={index}>
                                    <p>{t("CUSTOMER_DASHBOARD.BOC_DEVICE_CONNECTION.SERVICE_NAME")}: <span>{service.serviceName}</span></p>
                                    <p>{t("CUSTOMER_DASHBOARD.BOC_DEVICE_CONNECTION.SERVICE_TYPE")}: <span>{service.serviceType}</span></p>
                                </div>
                            ))
                        ) : (
                            <p>{t("CUSTOMER_DASHBOARD.BOC_DEVICE_CONNECTION.NO_SUBSCRIBED_SERVICES")}</p>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default BOCDeviceConnection;
