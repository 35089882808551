import { call, put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "../../constants/ActionTypes/FAQ";
import { FALLBACK_LNG } from "../../constants/AppConstants";
import { getLangCode } from "../../utils/Internationalization/handleAppInternationalization";

function _FETCHSublist(catKey) {
  const countryCode = getLangCode();
  catKey = catKey.charAt(0).toUpperCase() + catKey.slice(1);
  const fallbackLanguage = FALLBACK_LNG
  return new Promise((res, rej) => {
    import(`../../contents/${countryCode}/FAQ.json`)
      .then((data) => {
        if (data.FAQ) {
          const filtered = data["FAQ"][catKey];
          res(filtered);
          return filtered;
        } 
        else {
          import(`../../contents/${fallbackLanguage}/FAQ.json`)
            .then((data) => {
              const filtered = data["FAQ"][catKey];
              res(filtered);
              return filtered;
            })
        }
      })
      .catch((error) => {
        import(`../../contents/${fallbackLanguage}/FAQ.json`)
            .then((data) => {
              const filtered = data["FAQ"][catKey];
              res(filtered);
              return filtered;
            })
      });
  });
}
function* handleFAQSublist(action) {
  try {
    const FAQSublist = yield call(_FETCHSublist, action.key);
    yield put({
      type: actionTypes.FAQ_FETCH_SUBLIST_SUCCESS,
      FAQSublist: FAQSublist,
    });
  } catch (e) {
    yield put({
      type: actionTypes.FAQ_FETCH_SUBLIST_ERROR,
      message: e.message,
    });
  }
}
function* FAQSublist() {
  yield takeEvery(actionTypes.FAQ_FETCH_SUBLIST_INIT, handleFAQSublist);
}

export default FAQSublist;
