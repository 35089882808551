import React from "react";
import FreeDelivery from "../../../../../../assets/images/hardware-inclusive/free-delivery.svg";
import AutomatedSupply from "../../../../../../assets/images/hardware-inclusive/automated-supply.svg";
import "./DisplaySelectedPrinterFeature.css";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import CloseIcon from '../../../../../../assets/images/close.svg'
import { getHWPrinterFeatureTranslation } from "../../../../../../utils/HardwareInclusive/HardwareInclusive";


const DisplaySelectedPrinterFeature = ({
  modalState,
  toggleModal,
  featureSelections,
  monthlyPaymentPrice, dueTodayPrice, currency
}) => {

  const { t } = useTranslation(["translation"]);

  const hardwarePlanMapping = {
    device: "Device",
    typeOfPrinting: "Print Output",
    basePackage: "Device Functionality",
  };

  const supplyPlanMapping = {
    monthlyPageAllowance: "Monthly Allowance",
    planLength: "Plan Length",
  };

  const closeBtn = <img src={CloseIcon} className = "pointer-cursor" onClick={()=>toggleModal()} alt="alert-close" /> 

  return (
    <>
      <Modal 
        isOpen={modalState}
        toggle={() => toggleModal()}
        backdrop={true}
        size="xl"
        className="hardware-inclusive-selected-feature-modal"
        centered={true}
      >
        <ModalHeader close={closeBtn}>
          <div className="cp-subtitle">
            {t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.TOTAL_AMOUNT.HEADER")}
          </div>
          <div className="cp-total-price">
            <div>
              {t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.TOTAL_AMOUNT.DUE_PRICE", {
                due_price: dueTodayPrice,
              })}
            </div>
            <div className="price-with-info">
              {t(
                "HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.TOTAL_AMOUNT.PER_MONTH_PRICE",
                {
                  plan_price: monthlyPaymentPrice,
                }
              )}
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="selected-printer-feature-wrapper">
            <div>
              <div>{t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.HARDWARE_PLAN")}</div>
              {Object.entries(hardwarePlanMapping).map(([key, label]) => (
                featureSelections[key] && (<div key={key}>
                  <div></div>
                  <div>
                    {t(
                      `HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.SELECTED_FEATURES.${label
                        .toUpperCase()
                        .replace(/ /g, "_")}`
                    )}
                  </div>
                  <div>
                    {key === "basePackage"
                        ? getHWPrinterFeatureTranslation(featureSelections["basePackage"], featureSelections["device"])
                        :
                        t(`HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.SELECTED_FEATURES.${featureSelections[key].toUpperCase().replace(/ /g, "_")}`)
                    }
                  </div>
                </div>
                )
              ))}
            </div>
            <div>
            <div>{t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.SUPPLY_PLAN")}</div>
              {Object.entries(supplyPlanMapping).map(([key, label]) => (
                featureSelections[key] && (<div key={key}>
                  <div></div>
                  <div>
                    {t(
                      `HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.SELECTED_FEATURES.${label
                        .toUpperCase()
                        .replace(/ /g, "_")}`
                    )}
                  </div>
                  <div>
                    {key === "monthlyPageAllowance"
                        ? `${featureSelections[key]} ${t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.SELECTED_FEATURES.PAGES")}`
                        :
                    key === "planLength" 
                        ? `${featureSelections[key]} ${t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.SELECTED_FEATURES.MONTHS")}`
                        : t(`HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.SELECTED_FEATURES.${featureSelections[key].toUpperCase().replace(/ /g, "_")}`)}
                  </div>
                </div>
                )
              ))}
            </div>
            <div>
              <div>
                {t(
                  "HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.SELECTED_FEATURES.DELIVERY"
                )}
              </div>
              <div>
                <div></div>
                <div className="spf-image-wrapper">
                  <img src={FreeDelivery} alt="" />
                  <div>
                    {t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.FREE_DELIVERY")}
                  </div>
                </div>
              </div>
              <div>
                <div></div>
                <div className="spf-image-wrapper">
                  <img src={AutomatedSupply} alt="" />
                  <div>
                    {t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.AUTOMATED_SUPPLY")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default DisplaySelectedPrinterFeature;
