import * as actionTypes from '../../constants/ActionTypes/Dashboard';

const initialState = {
    userSubscription:{},
    loading: false,
    error: false
  }

const GetUserSubscription = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_USER_SUBSCRIPTION:
            return {
                ...state,
                loading: true,
                success: false
            }
        
        case actionTypes.GET_USER_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                success:true,
                userSubscription: action.userSubscription,
                loading: false
            }
        
        case actionTypes.GET_USER_SUBSCRIPTION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message,
                success: false
            }
        default:
            return {...state}
    }
}

export default GetUserSubscription