import { ECOPROTOKEN_CLAIMS, LOCALSESSIONDATAKEYS } from "../../constants/AppConstants";
import { determineLanguageCodeForPayload } from "../../constants/AppMethod";
import { WEB_handleSelectedPlanLocalData, getLocalSessionData, getUserSessionData } from "../Authentication/handleUserSession";

const HW_INCLUSIVE_InstantPaymentPayload = (preEnrollId, accountInfo) =>  {
    const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
    const deviceModel = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_MODEL);
    const langCode = determineLanguageCodeForPayload();
    const planInfo = WEB_handleSelectedPlanLocalData("Get");
    const {
        address,
        addressLine2 = "",
        city,
        province = "",
        postcode,
        country           

    } = accountInfo?.subscriptionPayload?.accountDetails?.shippingAddress;
    return {
        "userId": UID,
        "upfrontFeePlanIds": [
          planInfo?.HWPlans?.planId
        ],
        "shippingAddress": {
          "street1": address,
          "street2": addressLine2 || "",
          "city": city,
          "state": province || "",
          "postalCode": postcode,
          "country": country,
          languageCode: langCode
        },
        "preEnrollId": preEnrollId,
        "ModelNumber": deviceModel,
        "DeviceUsageTypeId": 2 // This attribute value is subject to change when 'New' device usage type is included.
      }
      
}

export default HW_INCLUSIVE_InstantPaymentPayload;