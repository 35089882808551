import React from "react";
import { Link } from "react-router-dom";
import './button.css'


function EcoProButton(props) {
       const {
              cname,
              btnID,
              btnType,
              disabled,
              children,
              onBtnClick,
              type = 'button',
              redirect_URL = null,
              target= "_self" 
       } = props;

       const classNames = (cname) ? cname.split(',').join('') : '';
       return(
              <>
                     {
                            (type === 'link')
                            ? <Link id={(btnID) ? btnID : ''} className={`ecopro_btn pointer ${classNames}`} to={redirect_URL} type={btnType} disabled={disabled} target={target}>{children}</Link>
                            : <button id={(btnID) ? btnID : ''} className={`ecopro_btn ${classNames}`} onClick={(event) => onBtnClick(event)} type={btnType} disabled={disabled}>{children}</button>
                     }
              </>
       )
}
export default EcoProButton;