import * as actionTypes from "../../constants/ActionTypes/HardwareInclusive/HardwareInclusive";

const initialState = {
    servicePlan: {},
    loading: false,
    error: "",
    success: false,
}

const GetHWServicePlan = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.GET_HW_SERVICE_PLAN:
            return {
                ...state,
                loading: true,
                success: false,
                error: "",
                servicePlan:{}
            };
        case actionTypes.GET_HW_SERVICE_PLAN_SUCCESS:
            return {
                ...state,
                servicePlan: action.servicePlan,
                loading: false,
                success: true,
                error: "",
            };
        case actionTypes.GET_HW_SERVICE_PLAN_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message,
                success: false,
                servicePlan:{}
            };
        case actionTypes.GET_HW_PRINTER_BY_FEATURES_RESET:
            return {
                ...state,
                success: false,
                error: "",
                servicePlan:{}
            };
        default:
            return {...state};
    }
}

export default GetHWServicePlan;