import { call, put, takeEvery } from 'redux-saga/effects';
import i18next from "i18next";
import * as actionTypes from '../../../constants/ActionTypes/AdminPortal/FeatureSwitch';
import { _CALLGETAPI } from '../../../api/apiHandler'
import { _ADMINISTRATOR_API_ENDPOINTS } from '../../../constants/AppConstants';

function _FETCHCOUNTRIES_FOR_FEATURELIST(featureName){
    const endpoint = _ADMINISTRATOR_API_ENDPOINTS.FEATURE_SWITCH_BY_FEATURE;

    const payload = {
        params: {
            featureName: featureName
        }
      }
     return _CALLGETAPI(endpoint, payload)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* handleInfo(action){
    try {
        const featureName = action.featureName;
        if(featureName){
            const result = yield call(_FETCHCOUNTRIES_FOR_FEATURELIST, featureName);
            if(Object.values(result).length > 0) {
                yield put({type: actionTypes.MANAGE_FEATURE_SWITCH_BY_FEATURE_SUCCESS, data: result});
            } else {
                yield put({
                    type: actionTypes.MANAGE_FEATURE_SWITCH_BY_FEATURE_ERROR, 
                    message: i18next.t('Admintranslation:ADMIN_COMMON_ERRORS.NO_RECORDS_FOUND')
                });
            }
        } else {
            yield put({
                type: actionTypes.MANAGE_FEATURE_SWITCH_BY_FEATURE_ERROR, 
                message: i18next.t('Admintranslation:ADMIN_COMMON_ERRORS.INVALID_INPUT')
            });
        }
    } catch (e) {
         yield put({type: actionTypes.MANAGE_FEATURE_SWITCH_BY_FEATURE_ERROR, message: i18next.t('Admintranslation:ADMIN_COMMON_ERRORS.TECHNICAL_ERROR')});
    }
}

function* FeatureSwitchFeatureList(){
    yield takeEvery(actionTypes.MANAGE_FEATURE_SWITCH_BY_FEATURE_INIT, handleInfo);
}

export default FeatureSwitchFeatureList;