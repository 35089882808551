import { call, put, takeEvery } from 'redux-saga/effects';
import i18next from 'i18next';
import * as actionTypes from '../../constants/ActionTypes/MyAccount';
import { _CALLPUTAPI } from '../../api/apiHandler'
import { _API_ENDPOINT, SUCCESS } from '../../constants/AppConstants';
import UpdateCustomerProfilePayload from '../../utils/MyAccount/UpdateCustomerProfilePayload';

function _UPDATECUSTOMERPROFILE(payload){
    const endpoint = _API_ENDPOINT.CUSTOMER_UPDATE_PROFILE;
    return _CALLPUTAPI(endpoint, payload )
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    }); 
}
function* handleError(message){
    yield put({
        type: actionTypes.UPDATE_USER_PROFILE_ERROR, 
        message: message
    });
}
function* handleProfileUpdate(action){
    try{
        const payload = UpdateCustomerProfilePayload(action.payload);
        const profileDetails = yield call(_UPDATECUSTOMERPROFILE, payload);
        if(profileDetails.actionStatus.toUpperCase() === SUCCESS){
            yield put({type: actionTypes.UPDATE_USER_PROFILE_SUCCESS, profileDetails: profileDetails})        
        }
        else{
          yield* handleError(i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR'))
        }
    } catch(e) {
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
        if(e instanceof TypeError === false && e.message !== "401"){
           yield* handleError(e.message)
        }
    }
}

function* UpdateCustomerProfile(){
    yield takeEvery(actionTypes.UPDATE_USER_PROFILE_INIT, handleProfileUpdate)
}

export default UpdateCustomerProfile;