import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDeviceList, getDeviceRegitration } from '../../../../../../actions/SelectDevice';
import DisplayUpdateConfirmationWithList from '../../../../../../components/EditMyAccount/DisplayUpdateConfirmationWithList/DisplayUpdateConfirmationWithList';
import { routePath } from '../../../../../../constants/Routes';
import deviceOffline from '../../../../../../assets/images/printer-offline.svg';
import { pushDataLayer } from '../../../../../../constants/AppMethod';
import Loader from '../../../../../../components/shared/loader/Loader';
import { ECOPROTOKEN_CLAIMS, LOCALSESSIONDATAKEYS, refreshBtn } from '../../../../../../constants/AppConstants';
import DisplayUpdateAccountConfirmation from '../../../../../../components/EditMyAccount/DisplayUpdateAccountConfirmation/DisplayUpdateAccountConfirmation';
import ErrorPage from '../../../../../../components/shared/errorPage/ErrorPage';
import { getLocalSessionData, getUserSessionData, setLocalSessionData } from '../../../../../../utils/Authentication/handleUserSession';
import { useHistory } from 'react-router-dom';

const WebAddMyDevice = (props) => {
    const { t } = useTranslation(["translation"]);
    const dispatch = useDispatch();
    const history = useHistory();
    const deviceCapabilityResult = useSelector((state) => state.DeviceCapability)
    const deviceListRecheckResult = useSelector((state) => state.SelectDevice);
    const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
    const deviceId = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID);
    const { location } = props;
    const deviceListResult = location?.state?.deviceListResult;
    const { model, serialNumber, imagePath} = deviceListResult?.deviceList?.devices[0];
    let { networkStatus } = deviceListResult?.deviceList?.devices[0];
    const [offlineStatus, setOfflineStatus] = useState(false)
    const deviceRegistrationResult = useSelector((state) => state.DeviceRegistration)
    const [oneTimeRecheck, setOneTimeRecheck] = useState(false)
    const [onConfirmDeviceClick, setConfirmDeviceClick] = useState(false)


    useEffect(() => {
      setLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_MODEL, model);
      setLocalSessionData(LOCALSESSIONDATAKEYS.SERIAL_NUMBER, serialNumber);
    }, [model, serialNumber, dispatch]);
   

    const onConnectThisDevice = () => {
      if(networkStatus === "online"){
        dispatch(getDeviceRegitration(UID, deviceId))
      }
      else if(networkStatus === "offline"){
        setOfflineStatus(true)
      }
      setConfirmDeviceClick(true)
      pushDataLayer('connect_device',"device_id",deviceId)
    }

    const recheckNetworkStatus = () => {
      setOfflineStatus(false)
      setOneTimeRecheck(true)
      setConfirmDeviceClick(false)
      dispatch(fetchDeviceList(UID));
    }

    useEffect(()=>{
      if(deviceRegistrationResult.success && onConfirmDeviceClick){
        setConfirmDeviceClick(false)
        history.push(routePath.WEB_PLACE_ORDER)
      }
    },[deviceRegistrationResult.success,history, onConfirmDeviceClick])

    if(deviceRegistrationResult.error){
      return (
        <ErrorPage
          errorInfo={deviceRegistrationResult.error}
          showRefreshBtn={refreshBtn.HIDE}
          ></ErrorPage>
      )
    }
    if (deviceListRecheckResult.error) {
      return (
        <ErrorPage
          errorInfo={deviceListRecheckResult.error}
          showRefreshBtn={refreshBtn.HIDE}
        ></ErrorPage>
      );
    }
    if (deviceListRecheckResult.deviceList?.devices && deviceListRecheckResult.deviceList?.devices.length === 0) {
      return (
        <ErrorPage
          errorInfo={t("ERROR_CONTENT.NO_DEVICES")}
          showRefreshBtn={refreshBtn.HIDE}
        ></ErrorPage>
      );
    }
  
    if (deviceListRecheckResult.success){
      deviceListRecheckResult.deviceList?.devices.map((device) =>{
          let selectedDeviceId;
          if(deviceId){
            selectedDeviceId = deviceId
          }
          else{
            selectedDeviceId = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID)
          }
          if(device.deviceId === selectedDeviceId){
            networkStatus = device.networkStatus
            if(device.networkStatus === "online" && oneTimeRecheck){
              dispatch(getDeviceRegitration(UID, deviceId))
              setOneTimeRecheck(false)
            }
          }
          return device;
        })
    }

   

    if(deviceCapabilityResult.loading || deviceListRecheckResult.loading || deviceRegistrationResult.loading) {
      return <Loader></Loader>
    }

    let deviceDataForPlans = {'deviceId':deviceId,'device_model': model,'serialNumber':serialNumber,'networkStatus':networkStatus}

    if(offlineStatus){
      return <DisplayUpdateAccountConfirmation
        updateAddressHeader={t("DEVICE_STATUS_CHECK.OFFLINE_STATUS_HEADER")}
        updateAddressInfo={t("DEVICE_STATUS_CHECK.OFFLINE_STATUS_INFO")}
        updateAddressBtn={t("ECOPRO_BTNS.RETRY")}
        onUpdateClick = {()=>recheckNetworkStatus()}
        image={deviceOffline}
        ></DisplayUpdateAccountConfirmation>
    }
    return (
        <div>
           <DisplayUpdateConfirmationWithList
            account="swapDevice"
            header={t("CUSTOMER_WEB.ADD_MY_DEVICE.HEADER")}
            subHeader={t("CUSTOMER_WEB.ADD_MY_DEVICE.SUB_HEADER", {model:model})}
            imagePath={imagePath}
            model={model}
            serialNumber={serialNumber}
            btnText={t("CUSTOMER_WEB.ADD_MY_DEVICE.CONNECT_DEVICE")}
            linkUrl={routePath.PLANS}
            linkWithState={deviceDataForPlans}
            linkText={t("CUSTOMER_WEB.ADD_MY_DEVICE.CHANGE_SUBSCRIPTION")}
            onBtnClick={onConnectThisDevice}
            onUpdateBtnID="connect_device"
          ></DisplayUpdateConfirmationWithList>  
        </div>
    );
};

export default WebAddMyDevice;