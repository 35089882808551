import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomerRedirection from "../../components/Redirection/CustomerRedirection";
import AdminRedirection from "../../components/Redirection/AdminRedirection";
import CSAgentRedirection from "../../components/Redirection/CSAgentRedirection";
import { ValidateRoles } from "../../constants/UserRoles";
import ErrorPage from "../../components/shared/errorPage/ErrorPage";
import { currentUserRolesArr } from "../../constants/AppMethod";

const Redirection = (props) => {
  const { t } = useTranslation(["translation"]);
  const RouterLinkStateProp = props?.location?.state?.RouterLinkStateProp;
  const [loggedInUserRole, setLoggedInUserRole] = useState({'isAdmin': false, 'isCSAgent': false, 'isCustomer': false});
 
  useEffect(() => {
    const currentUserRoles = currentUserRolesArr();
    const roles = ValidateRoles(currentUserRoles);
    setLoggedInUserRole((prevRoles) => ({
      ...prevRoles,
      isAdmin: roles.isAdmin,
      isCSAgent: roles.isCSAgent,
      isCustomer: roles.isCustomer,
    }));
  }, []);

  if(loggedInUserRole.isAdmin){
    return <AdminRedirection></AdminRedirection>
  }
  if(loggedInUserRole.isCSAgent) {
    return <CSAgentRedirection></CSAgentRedirection>
  }
  if(loggedInUserRole.isCustomer){
    return <CustomerRedirection RouterLinkStateProp = {RouterLinkStateProp}></CustomerRedirection>
  }
  return (
    <ErrorPage errorInfo={t('ERROR_CONTENT.ERR_INFO.UNAUTHORIZED_ACCESS')}></ErrorPage>
  )
 
};

export default Redirection;
