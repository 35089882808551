import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Container, Nav, Navbar, Offcanvas, Row } from "react-bootstrap";
import ecopro_logo from "../../../assets/images/teaser_ecopro_logo.png";
import { ReactComponent as RightArrowImg } from "../../../assets/images/rightArrow.svg";
import { NavLink } from "react-router-dom";
import EcoProFeedbackMobileConnect from "../ecoprofeedback/ecoprofeedbackMobileConnect";
import HamburgerNavItems from "../../../utils/Dashboard/HamburgerNavItems";
import "./HamburgerMenu.css";


const HamburgerMenu = () => {
  const [navExpanded, setNavExpanded] = useState(false);
  const enrollmentStatus = useSelector(state => state.EnrollmentStatus);
  const navItems = HamburgerNavItems();

  const sethandleNavExpanded = () => {
    setNavExpanded(!navExpanded);
  };

  const closeNav = () => {
    setNavExpanded(!navExpanded);
  };

  return (
    <>
      <Navbar
        expand={false}
        onToggle={sethandleNavExpanded}
        expanded={navExpanded}
      >
        <Navbar.Toggle className="hamburger-menu">
          <div className="menu__btn">
            <span></span>
          </div>
        </Navbar.Toggle>
        <Container fluid>
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="start"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel">
                <img
                  src={ecopro_logo}
                  alt="EcoPro Logo"
                  className="ecopro_logo"
                />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav>
                <Container>
                  { Object.values(navItems).map((val) => (
                    val.item_enabled ?
                  <NavLink
                    onClick={closeNav}
                    activeClassName="active-link"
                    to={val.item_link}
                    id={val.item_id}
                    className = {`${(enrollmentStatus.enrollment_completed) ? 'navigation-enabled' : 'navigation-disabled'}`}
                  >
                    <Row className="row-margin">
                      <div className="col-10 row">
                        <div className="col">{val.item_logo}</div>
                        <span className="col-10">{val.item_name}</span>
                      </div>
                      <div className="col arrow">
                        <RightArrowImg />
                      </div>
                    </Row>
                  </NavLink>
                  :
                  null
                  ))}
                </Container>
              </Nav>
              <EcoProFeedbackMobileConnect />
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};

export default HamburgerMenu;
