import React from "react";
import { DisplayDate } from "../../../../../utils/DisplayDate";
import { useTranslation } from "react-i18next";
import './EmailLogs.css'
import Loader from "../../../../shared/loader/Loader";

const EmailLogs = ({ emailLogRes }) => {
  const { t } = useTranslation(["CSAgenttranslation"])
  return (
    emailLogRes.loading ? <Loader></Loader> :
    emailLogRes.success ? 
    <div className="email-log-container">
      <table className="table">
        <thead>
          <tr>
            <th scope="col">{t("CUSTOMER_DASHBOARD.EMAIL_LOGS.EMAIL_TYPE")}</th>
            <th scope="col">{t("CUSTOMER_DASHBOARD.EMAIL_LOGS.TIME")} </th>
            <th scope="col">{t("CUSTOMER_DASHBOARD.EMAIL_LOGS.RECEIVED_BY")} </th>
          </tr>
        </thead>
        <tbody>
          {emailLogRes?.emailLogs?.result?.map((emailLog) => (
            <tr>
              <td>{emailLog.emailType}</td>
              <td>{DisplayDate(emailLog.timeStamp)}</td>
              <td>{emailLog.isCustomerCentric?t("CUSTOMER_DASHBOARD.EMAIL_LOGS.CUSTOMER") :t("CUSTOMER_DASHBOARD.EMAIL_LOGS.DSC")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div> :
    emailLogRes.error ? 
    <div className="email-log-error">{emailLogRes.error}</div>
    : null

  );
};

export default EmailLogs;
