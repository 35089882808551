import { call, put, takeEvery } from 'redux-saga/effects';
import i18next from "i18next";
import * as actionTypes from "../../../constants/ActionTypes/Web/Customer/SignUp";
import { WEB_CUSTOMER_API_ENDPOINT } from '../../../constants/AppConstants';
import { _CALLGETAPI } from '../../../api/apiHandler';

function _FETCHPRINTERTYPES(){
    const endpoint = WEB_CUSTOMER_API_ENDPOINT.GET_PRINTERS_TYPE_LIST;
    return _CALLGETAPI(endpoint)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}
function* fetchPrinterTypes(action){
    try {
            const printerTypes = yield call(_FETCHPRINTERTYPES);
            yield put({type: actionTypes.FETCH_PRINTER_TYPES_SUCCESS,printerTypes:printerTypes});
    } catch (e) {
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
        if(e instanceof TypeError === false && e.message !== "401"){
            const msg = (e?.response?.status === 404) ? i18next.t('CUSTOMER_WEB.ERROR_CONTENT.NO_RESULTS_FOUND') : e.message;
            yield put({type: actionTypes.FETCH_PRINTER_TYPES_ERROR, message: msg});
        }
    }
}
function* PrinterTypes(){
    yield takeEvery(actionTypes.FETCH_PRINTER_TYPES, fetchPrinterTypes);
}
export default PrinterTypes;