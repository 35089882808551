import React, { useEffect, useState } from "react";
import convertHtmlToReact from '@hedgedoc/html-to-react';
import Loader from "../../../components/shared/loader/Loader";
import { getCountryCode, getLangCode } from "../../../utils/Internationalization/handleAppInternationalization";
import "./TermsAndConditions.css"
import DisplayContent from "../../../components/DisplayContent/DisplayContent";
import { languageCodes } from "../../../constants/Country";
import { FALLBACK_LNG, LOCALSESSIONDATAKEYS } from "../../../constants/AppConstants";
import { getLocalSessionData } from "../../../utils/Authentication/handleUserSession";

const LegalDocs_TermsNConditions = () => {
    const webSignupLangCode = getLocalSessionData(LOCALSESSIONDATAKEYS.ECOPROWEBSIGNUPLANGCODE);
    const languageCode = (webSignupLangCode) ? webSignupLangCode : getLangCode();
    const countryCode = getCountryCode();
    const [content, setContent] = useState(null);

    const getENGBTranslation = () => {
      let fallbackLanguage = FALLBACK_LNG
      import(`../../../contents/${fallbackLanguage}/termsNConditions.json`)
      .then((data) => {
          setContent(data.TERMSNCONDITIONS);
      })
      .catch((error) => {
        throw error
      })
    }

    const handleFallbackData = () => {
      let fallbackLanguage = languageCodes[countryCode.toLowerCase()];
      import(`../../../contents/${fallbackLanguage}/termsNConditions.json`)
      .then((data) => {
          setContent(data.TERMSNCONDITIONS);
      })
      .catch((error) => {
        getENGBTranslation()
      })
    }

    useEffect(() => {
      new Promise((res, rej) => {
        import(`../../../contents/${languageCode}/termsNConditions.json`)
        .then((data) => {
          if(data.TERMSNCONDITIONS){
            setContent(data.TERMSNCONDITIONS);
          }
          else{
            handleFallbackData()
          }
        })
        .catch((error) => {
          handleFallbackData()
        });
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languageCode])

    if(!content){
      return <Loader></Loader>
    }
    return (
      <DisplayContent 
        content={convertHtmlToReact(content, [true])}
      ></DisplayContent>
    ); 
}

export default LegalDocs_TermsNConditions;