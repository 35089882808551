import { call, put, takeEvery } from 'redux-saga/effects';
import i18next from "i18next";
import * as actionTypes from '../../../constants/ActionTypes/CSAgentPortal/CustomerDashboard';
import { _CALLPUTAPI } from '../../../api/apiHandler'
import { FAILURE, USER_ACTIVITY_LOGS, _CSAGENT_API_ENDPOINTS } from '../../../constants/AppConstants';
import UserActivityLogsPayload from '../../../utils/AdminPortal/UserActivityLogsPayload';

async function _CALLSWAPDEVICEAPI(customerId, customerDeviceId, swapStatus){
    const endpoint = _CSAGENT_API_ENDPOINTS.ENABLE_DEVICE_SWAP;
    const logDetails = {
        action: (swapStatus) 
            ? USER_ACTIVITY_LOGS.DEVICE_SWAP.ENABLED
            : USER_ACTIVITY_LOGS.DEVICE_SWAP.CANCELED,
        description: (swapStatus) 
            ? `Device swap enabled for the customer with the device id ${customerDeviceId}`
            : `Device swap cancelled for the customer with the device id ${customerDeviceId}`
    }
    const payload = {
        userId: customerId,
        deviceId: customerDeviceId,
        enableSwap: swapStatus,
        servicePortalUser: await UserActivityLogsPayload(logDetails)
      }
    return _CALLPUTAPI(endpoint, payload)
        .then((response) => {
        return response;
        })
        .catch((error) => {
        throw error;
        });
}

function* handleDeviceSwap(action){
    try {
        const customerId = action.customerID;
        const customerDeviceId = action.customerDeviceId;
        const swapStatus = action.swapStatus;
        const isSwapEligible = action.isSwapEligible;

        if(customerId && customerDeviceId){
           let deviceSwapstatus;
           if(swapStatus && !isSwapEligible){
               deviceSwapstatus = true;
           } else if(!swapStatus && !isSwapEligible){
                deviceSwapstatus = false;
           }else if(!swapStatus && isSwapEligible){
                deviceSwapstatus = true;
           }else{
               deviceSwapstatus = false;
           }
            const res =  yield call(_CALLSWAPDEVICEAPI, customerId, customerDeviceId, swapStatus);
           if(res.actionStatus.toUpperCase() !== FAILURE){
                yield put({type: actionTypes.ENABLE_DEVICE_SWAP_SUCCESS, deviceSwapResponse: deviceSwapstatus, swap_status_requested: swapStatus});
           } else {
                yield put({
                    type: actionTypes.ENABLE_DEVICE_SWAP_ERROR, 
                    message: i18next.t('CSAgenttranslation:CS_AGENT_ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')
                });
           }
        } else {
            yield put({
                type: actionTypes.ENABLE_DEVICE_SWAP_ERROR, 
                message: i18next.t('CSAgenttranslation:CS_AGENT_ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')
            });
        }
    } catch (e) {
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
        if(e instanceof TypeError === false && e.message !== "401"){
            yield put({type: actionTypes.ENABLE_DEVICE_SWAP_ERROR, message: e.message});
        }
    }
}

function* EnableDeviceSwap(){
    yield takeEvery(actionTypes.ENABLE_DEVICE_SWAP_INIT, handleDeviceSwap);
}

export default EnableDeviceSwap;