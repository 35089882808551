import React from 'react';
import { useLocation } from 'react-router-dom';
import { routePrefix, WebHardwareInclusiveRoutePath } from '../../../constants/Routes';
import MobileFooter from '../Mobile/footer/MobileFooter';
import WebFooter from '../Web/footer/WebFooter';
import './PageFooter.css'


const PageFooter = ({cname = 'none'}) => {
    const location = useLocation();
    if(location.pathname.indexOf(routePrefix.CSAGENT) > -1 || location.pathname.indexOf(routePrefix.WEB_CUSTOMER) > -1) {
        return <WebFooter customClassName={location.pathname.indexOf(WebHardwareInclusiveRoutePath.CUSTOMIZE_PRINTER) > -1 ?'hardware-customize-printer-footer':''}/>
    }
    return <MobileFooter cname={cname}/>
};

export default PageFooter;