import axios from "axios";
import { InteractionRequiredAuthError, BrowserAuthError } from "@azure/msal-browser";
import i18next from "i18next";
import { ENVSettings } from "../constants/ENVSettings";
import { logoutProcess } from "../utils/Authentication/AppLogout";
import store from "../store";
import { SignOut } from "../actions/Dashboard";
import { MSALInstance } from "../utils/Authentication/Azure/MSALInstanceInit";
import { retrieveAzureAuthToken } from "../utils/Authentication/Azure/ADB2CAuthToken";
import { loginRequest } from "../utils/Authentication/Azure/AuthConfig";
import { GB_COUNTRY, LOCALSESSIONDATAKEYS, UK_COUNTRY } from "../constants/AppConstants";
import { getLocalSessionData, setLocalSessionData } from "../utils/Authentication/handleUserSession";
import { extractLangcodeFromDomain } from "../utils/Internationalization/handleAppInternationalization";
import ApiEndpoints_ExcludeAuthTokenValidation from "./ApiEndpoints_ExcludeAuthTokenValidation";


const baseURL = ENVSettings.API_BASE_URL;
let countryCodeFromDomain =  extractLangcodeFromDomain()
let countryCodeFromStorage = getLocalSessionData(LOCALSESSIONDATAKEYS.ECOPROCTRYCODE)
if(countryCodeFromDomain.toUpperCase() === UK_COUNTRY){
  countryCodeFromDomain = GB_COUNTRY
}
const countryCode =  countryCodeFromStorage ? countryCodeFromStorage : countryCodeFromDomain.toUpperCase()

const headersConfig = () => {
  return {headers:{
    "X-COUNTRY-CODE": countryCode
  }}
};
const headersConfigWithSCMSToken = (headerToken) => {
  return {headers:{
    "X-COUNTRY-CODE": countryCode,
    "SCMSToken": headerToken
  }}
}
export const _HTTPclient = axios.create({
  baseURL: baseURL
});
_HTTPclient.interceptors.request.use(
  async(request) => {
    const api_endpoints_without_adb2c_authtoken = ApiEndpoints_ExcludeAuthTokenValidation;
    if(api_endpoints_without_adb2c_authtoken.indexOf(request.url.split('?')[0]) === -1){
      let currentAuthAccount = MSALInstance.getAllAccounts();
      try {
        const tokenResponse =  await retrieveAzureAuthToken(currentAuthAccount[0]);
        const accessToken = (tokenResponse.accessToken) ? tokenResponse.accessToken : tokenResponse.idToken;
        const bearer = `Bearer ${accessToken}`;
        request.headers.Authorization = bearer;
      } catch(error) {
        if (error instanceof BrowserAuthError) {
          throw new Error(i18next.t("ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR"));
        } else {
          throw error;
        }
      }
    }
   const ecoProToken = getLocalSessionData(LOCALSESSIONDATAKEYS.ECOPROID);
    if(ecoProToken){
      request.headers.ecoprotoken = `Bearer ${ecoProToken}`;
    }
    return request;
 },
 (error) => {
  let currentAuthAccount = MSALInstance.getAllAccounts();
  if (error instanceof InteractionRequiredAuthError) {
    MSALInstance.acquireTokenRedirect({
        ...loginRequest,
        account: currentAuthAccount[0]
    });
  } else {
    throw error;
  }
 }
);
/**
 * Interceptor to handle errors from  response
 * return void
 */
_HTTPclient.interceptors.response.use(
  response => {
    if (response && response.data) {
      const ecoProToken = response.headers['ecoprotoken'];
      if(ecoProToken){
        setLocalSessionData(LOCALSESSIONDATAKEYS.ECOPROID, ecoProToken)
      }
      return response.data;
    } else {
      throw new Error("Unexpected Error!");
    }
  },
  error => {
    if (error.response && error.response.status === 401) {
      signOutUser();
      throw new TypeError('401');
    }else {
      throw error;
    }
  });
  
const constructQueryString = (payload) => {
  const params = payload.params;
  return Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
}
const callAPI = (endpoint, payload, headerToken=null) => {
  const QS = (payload && payload.hasOwnProperty('params')) ? `?${constructQueryString(payload)}`: "";
  if(headerToken){
    return _HTTPclient.get(`${endpoint}${QS}`, headersConfigWithSCMSToken(headerToken));
  }
  return _HTTPclient.get(`${endpoint}${QS}`, headersConfig());
};
const signOutUser = () => {
  store.dispatch(SignOut());
  logoutProcess(); 
}
export const _CALLGETAPI = (endpoint, payload, headerTokenRequired) => {
  return callAPI(endpoint, payload, headerTokenRequired)
};

export const _CALLPOSTAPI = (endpoint, payload) => {
  return _HTTPclient
    .post(`${endpoint}`, payload, headersConfig())
};

export const _CALLPOSTAPI_WITHQS = (endpoint, payload, queryString) => {
  const QS = (queryString) ? `?${constructQueryString(queryString)}`: "";
  return _HTTPclient
    .post(`${endpoint}${QS}`, payload, headersConfig())
};

export const _CALLPUTAPI = (endpoint, payload) => {
  return _HTTPclient
    .put(`${endpoint}`, payload, headersConfig())
};

export const _CALLPUTAPI_WITHQS = (endpoint, payload, queryString) => {
  const QS = (queryString) ? `?${constructQueryString(queryString)}`: "";
  return _HTTPclient
    .put(`${endpoint}${QS}`, payload, headersConfig())
};

export const _CALLDELETEAPI = (endpoint, payload) => {
  return _HTTPclient
    .delete(`${endpoint}`, payload, headersConfig())
};