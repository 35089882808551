import * as actionTypes from '../../../constants/ActionTypes/Web/Customer/SignUp';

const initialState = {
    models: {},
    loading: false,
    error: false,
  }

const PrinterModels = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PRINTER_MODELS:
            return {
                ...state,
                loading: true,
                error: false,
                models: {}
            }
        
        case actionTypes.FETCH_PRINTER_MODELS_SUCCESS:
            return {
                ...state,
                models: action.models,
                loading: false,
                error: false
            }
        
        case actionTypes.FETCH_PRINTER_MODELS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message,
                models: {}
            }
        default:
            return {...state}
    }
}

export default PrinterModels