import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import HardwareSignupCard from '../../../HardwareSignupCard/HardwareSignupCard';
import LearnMorePrinterFeature from '../../LearnMorePrinterFeature/LearnMorePrinterFeature';
import { useState } from 'react';

const DisplayTypeOfPrinting = (props) => {
    const { handleSelectionChange, selectedData} = props

    const {t} = useTranslation(["translation"])

    const [show, setShow] = useState(false);


    const handleClose = () => { 
        setShow(false)
    };
    const DisplayLearnMore = () => setShow(true);
    return (
        <div className='cp-printer-feature-wrapper'>
            
                <div className='cp-subtitle'>{t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.TYPE_OF_PRINTING.HEADER")}</div>
                <div className='cp-extra-title-list-header'>{t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.TYPE_OF_PRINTING.ABLE_TO_PRINT")}</div>

                <HardwareSignupCard 
                    onHardwarePlanSelection={handleSelectionChange}
                    selectedCard={selectedData}
                    toCompareSelectedCardWith="Mono" 
                    cardRightData={t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.TYPE_OF_PRINTING.BLACK_WHITE")}
                ></HardwareSignupCard>
                {/*@@@@@@@@@@@@@@@@@@@@@ TODO
                 <div className='cp-learn-more'><button className='cp-btn-link' onClick={()=>DisplayLearnMore()}>Learn more</button> about Flex plan lengths</div>
                */}

                 <div className='cp-learn-more'>
                 <Trans i18nKey="HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.TYPE_OF_PRINTING.LEARN_MORE" components={{ 1: <button className='cp-btn-link' onClick={DisplayLearnMore} /> }}>
                    <button className='cp-btn-link' onClick={DisplayLearnMore}>Learn more</button> about colour and black & white printing.
                </Trans>
                </div>
               
                <LearnMorePrinterFeature   
                    show={show}
                    handleClose={handleClose}
                ></LearnMorePrinterFeature>
        </div>
    );
};

export default DisplayTypeOfPrinting;