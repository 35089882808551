import * as actionTypes from '../constants/ActionTypes/FAQ';

export const fetchFAQCategory = (key) => ({
    type: actionTypes.FAQ_FETCH_CATEGORY_INIT,
    key
});

export const fetchFAQSublist = (key) => ({
    type: actionTypes.FAQ_FETCH_SUBLIST_INIT,
    key
});

export const fetchFAQContent = (catID, subCatID) => ({
    type: actionTypes.FAQ_FETCH_CONTENT_INIT,
    catID,
    subCatID
});