import React from 'react';
import { useTranslation } from 'react-i18next';
import { SD_TICKET_CATEGORY_OPTION } from '../../../../../constants/AppConstants';
import AdminFormLabel from '../../../../AdminPortal/Shared/FormElements/FormLabel/FormLable';
import AdminFormInput from '../../../../AdminPortal/Shared/FormElements/FormInput/FormInput';
import AdminFormSelect from '../../../../AdminPortal/Shared/FormElements/FormSelect/FormSelect';
import './SDTicketModalContent.css'

const SDTicketModalContent = ({handleChange, values, errors}) => {
    const { t } = useTranslation(["CSAgenttranslation"]);
    const DESCRIPTION_MAXLENGTH = 500;
    const categoryOptions  = SD_TICKET_CATEGORY_OPTION?.map((data) =>
        <option key={data.name} value={data.name}>
            {data.name}
        </option>
    );
    return (
        <div className='sd-ticket-wrapper'>
            <div className="col-12">
                <AdminFormLabel htmlFor="ticketSummary" required={true}>{t('SD_TICKET.MODAL.DESCRIPTION', {maxlength: DESCRIPTION_MAXLENGTH})}</AdminFormLabel>
                    <div className="text-area">
                        <textarea
                        value={values.ticketSummary || ''}
                        id="ticketSummary"
                        name="ticketSummary"
                        placeholder={t('SD_TICKET.MODAL.DESCRIPTION_PLACEHOLDER')}
                        onChange={handleChange} 
                        maxLength={DESCRIPTION_MAXLENGTH}
                        isRequired={true}
                        minlength={0}
                        className ={(errors.ticketSummary) && 'is-danger'}
                        ></textarea>
                    </div>
                    {errors.ticketSummary && <p className="admin-error is-danger">{errors.ticketSummary}</p>} 
            </div>
            <div className="col-12">
                <AdminFormLabel htmlFor="ticketC4CReference">{t('SD_TICKET.MODAL.SAP_C4C_REFERENCE')}</AdminFormLabel>
                <AdminFormInput 
                    id="ticketC4CReference"
                    name="ticketC4CReference"
                    placeholder={t('SD_TICKET.MODAL.SAP_C4C_REFERENCE_PLACEHOLDER')}
                    type = "text"
                    onChange={handleChange} 
                    value={values.ticketC4CReference || ''}
                    minlength={0}
                    maxlength={100}
                />
            </div>
            <div className="col-12">
                <AdminFormLabel htmlFor="ticketCategory" required={true}>{t('SD_TICKET.MODAL.CATEGORY')}</AdminFormLabel>
                <AdminFormSelect
                    id="ticketCategory"
                    name="ticketCategory"
                    options = {categoryOptions}
                    placeholder = {t('SD_TICKET.MODAL.CATEGORY_PLACEHOLDER')}
                    onChange={handleChange}
                    value={values.ticketCategory || 'Select'}
                    cname={(errors.ticketCategory) && 'is-danger'}
                />
                {errors.ticketCategory && <p className="admin-error is-danger">{errors.ticketCategory}</p>}
            </div>
        </div>
    );
};

export default SDTicketModalContent;