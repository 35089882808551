import React from "react";
import warning from "../../../../assets/images/warning.svg";
import swap from '../../../../assets/images/swap.svg';
import samplePrinter from '../../../../assets/images/nodevicefoundsampleprinter.svg';
import "./DisplayNoDevice.css";
import "../DisplayWebEligibleDevice/DisplayWebEligibleDevice.css";
import AddDeviceCard from "../../../AddDeviceCard/AddDeviceCard";
import EcoProButton from "../../../shared/button/button";

const DisplayNoDevice = (props) => {
  const {
    header,
    subheader,
    selectInfo,
    nonDetectedDevice,
    nonDetectedDeviceName,
    nonDetectedDeviceInfo,
    changeEnrolledDeviceName,
    changeEnrolledDeviceInfo,
    selectedreplacementDeviceId,
    onRelacementSelection,
    onConfirmEligibleDeviceSelection,
    btnText,
    btnId
  } = props;

  return (
    <div className="web-nodevice-container">
      <div className="web-nodevice-img">
        <img src={warning} alt="no device" />
      </div>
      <div className="eligible-device-header">{header}</div>
      <div className="eligible-device-subheader">{subheader}</div>
      <div className="eligible-device-list-header">{selectInfo}</div>
      <div>
        <div>
          <AddDeviceCard
            device={nonDetectedDevice}
            toSelectDeviceParameter="add"
            selectedreplacementDeviceId={selectedreplacementDeviceId}
            onRelacementSelection={onRelacementSelection}
            imagePath={samplePrinter}
            deviceName={nonDetectedDeviceName}
            deviceInfo={nonDetectedDeviceInfo}
            onClickDeviceParameter="add"
          ></AddDeviceCard>
        </div>
        <div>
        <AddDeviceCard
            device={null}
            imageclassName="swap-device-img-adddn"
            toSelectDeviceParameter="change"
            selectedreplacementDeviceId={selectedreplacementDeviceId}
            onRelacementSelection={onRelacementSelection}
            imagePath={swap}
            deviceName={changeEnrolledDeviceName}
            deviceInfo={changeEnrolledDeviceInfo}
            onClickDeviceParameter="change"
          ></AddDeviceCard>
        </div>
      </div>
      <div className='eligible-device-btn'><EcoProButton cname="default" btnID={btnId}  disabled={!selectedreplacementDeviceId} onBtnClick={() => onConfirmEligibleDeviceSelection()}>{btnText}</EcoProButton></div>
    </div>
  );
};

export default DisplayNoDevice;
