import React, { useEffect } from "react";
import AdminFormSelect from "../Shared/FormElements/FormSelect/FormSelect";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import AdminOverlayModal from "../AdminOverlayModal/AdminOverlayModal";
import { Trans, useTranslation } from "react-i18next";
import { getUserDataByKey } from "../../../utils/Authentication/handleUserSession";
import { extractCountryName } from "../../../utils/Internationalization/handleAppInternationalization";
import AdminAlert from "../AdminAlert/AdminAlert";
import AdminFormCheckSwitchType from "../Shared/FormElements/FormCheckSwitchType/FormCheckSwitchType";
import EcoProButton from "../../shared/button/button";
import { FetchPrinterModels, FetchPrinterTypes } from "../../../actions/Web/Customer/SignUp";
import { fetchCountryListByPrinterModel, fetchModelsListByCountry, managePrinterModelsResetState, updatePrinterModelsStateReset, updatePrinterModelsStatus } from "../../../actions/AdminPortal/PrinterModels";
import Loader from "../../shared/loader/Loader";

const PrinterModelsSwitch = ({properties, countryDropdownOptions}) => {
    const { t } = useTranslation("Admintranslation");
    const dispatch = useDispatch();
    const { featureSwitchType, selectOptionLabel, selectOptionElemName, displayedListTitle } = properties;
    const COUNTRY = "COUNTRY";
    const PRINTER_TYPE = "PRINTER_TYPE";
    const printerTypesResp = useSelector(state => state.PrinterTypes);
    const printerModelsList = useSelector(state => state.ADMIN.PrinterModels_ByCountry);
    const PrinterModelsCountryList = useSelector(state => state.ADMIN.PrinterModels_ByModels);
    const printerModelStatusUpdateResp = useSelector(state => state.ADMIN.UpdatePrinterModelStatus)
    const [modalProps, setModalProps] = useState({});
    const [dropDownItemSelected, setdropDownItemSelected] = useState(null);
    const [sortedLists, setSortedLists] = useState([]);
    const [alertMessage, setAlertMessage] = useState({'alertType': null, 'alertMessage': null});
    const [handleCallingListAPI, setHandlingListAPI] = useState(true);
    const [selectedPrinterModelName, setPrinterModelName] = useState(null);
    let printerTypesDropDownOptions;
    let isDisableFilterSubmitBtn = true;
  
   useEffect(() => {
            dispatch(FetchPrinterTypes());
            setSortedLists([]);
            setAlertMessage({...alertMessage, 'alertType': null, 'alertMessage': null});
            // eslint-disable-next-line 
   }, [dispatch]);


    if(featureSwitchType === COUNTRY){
        isDisableFilterSubmitBtn = (dropDownItemSelected && dropDownItemSelected.hasOwnProperty('country') && dropDownItemSelected.hasOwnProperty('printerTypeId')) ? false : true;
    } else {
        isDisableFilterSubmitBtn = (dropDownItemSelected && dropDownItemSelected.hasOwnProperty('printerModel') && dropDownItemSelected.hasOwnProperty('printerTypeId')) ? false : true;
    }
    const handleDropdownSelection = (event, dropdownType) => {
        const { name, value } = event.target;
            setdropDownItemSelected((prevValues) => ({
                ...prevValues,
                [name]: value,
            }));
          
            if(dropdownType === PRINTER_TYPE && featureSwitchType === 'PRINTER_MODELS'){
                dispatch(FetchPrinterModels(event.target.value));
            }
    }
    const getSelectedPrinterModelName = () => {
        const selectElement = document.getElementById('printerModel');
        const selectedOption = selectElement.options[selectElement.selectedIndex];
        return selectedOption.text;
    }
    const checkAndFetchList = () => {
        dispatch(managePrinterModelsResetState());
        setSortedLists([]);
        setAlertMessage({...alertMessage, 'alertType': null, 'alertMessage': null});
        if(featureSwitchType === COUNTRY){
            const { country, printerTypeId } = dropDownItemSelected;
            if (country && printerTypeId) {
                dispatch(fetchModelsListByCountry(country, printerTypeId));
            }
        } else {
            const { printerModel, printerTypeId} = dropDownItemSelected;
            const selectedPrinterModel = getSelectedPrinterModelName();
            if (printerModel && printerTypeId) {
                setPrinterModelName(selectedPrinterModel);
                dispatch(fetchCountryListByPrinterModel(selectedPrinterModel));
            }
        }
        setHandlingListAPI(true);
    }
    const handleCheckboxChange = (id) => {
        setSortedLists(
            sortedLists.map((item) => {
                const prevItemValues = item;
                if (item.id === id) {
                    item.isEnabled = !item.isEnabled;
                    item.isChangeEventFired = true
                    item.saveBtnDisabled = !item.saveBtnDisabled
                  } else {
                    if(item.isChangeEventFired){
                        item.isEnabled = prevItemValues.isEnabled;
                        item.isChangeEventFired = false
                    }
                    item.saveBtnDisabled = true
                  }
                  return item;
            })
        );
    }
    const toggleAdminOverlayModal = (listName) => {
        setModalProps(prevState => ({ isOpen: !prevState.isOpen }));
        setSortedLists(
            sortedLists.map((item) => {
                if (item.isChangeEventFired && item.name === listName) {
                    item.isEnabled = !item.isEnabled;
                    item.isChangeEventFired = false
                    item.saveBtnDisabled = true
                  }
                  return item;
            })
        );
    }
    const handleModalConfirm = (PrinterModelId, CountryId, isEnabled) => {
        const displayName = getUserDataByKey("displayName", "personal_details");
        const payload = {  
            "CountryId": CountryId,
            "PrinterId": PrinterModelId,
            "IsSupportEnabled": isEnabled,
            "UpdatedBy": displayName,
        }
        setAlertMessage({...alertMessage, 'alertType': null, 'alertMessage': null});
        dispatch(updatePrinterModelsStatus(payload));
    }
    const handleSave = (list) => {
        let PrinterModelId, CountryId, PrinterModelName = null;
        PrinterModelName = list.name;
        const elem = document.querySelector(`#${PrinterModelName.toLowerCase().split(" ").join("_")}`);
        const isEnabled = elem.checked;
        const status_text = (isEnabled) ? t("MANAGE_PRINTER_MODELS.ENABLED_STATUS_TXT") : t("MANAGE_PRINTER_MODELS.DISABLED_STATUS_TXT");
       
        let modal_content = "";
        if(featureSwitchType === COUNTRY){
            PrinterModelId = list.id;
            CountryId = list.countryId;
            const featureName = PrinterModelName;
            const countryName = extractCountryName(dropDownItemSelected.country);
            modal_content =   <Trans i18nKey="FEATURE_SWITCH.MODAL_CONTENT" featureName={featureName} status_text={status_text} countryName={countryName}>
                The printer model <b className="highlight-text">{featureName}</b> is <b className={(isEnabled) ? "feature-switch-enabled-text" : "feature-switch-disabled-text"}>{status_text}</b> for the country <b className="highlight-text">{countryName}</b>
            </Trans>
        } else {
            PrinterModelId = list.printerId;
            CountryId = list.id
            const countryName = list.countryName;
            const featureName = getSelectedPrinterModelName();
            modal_content =   <Trans i18nKey="FEATURE_SWITCH.MODAL_CONTENT" featureName={featureName} status_text={status_text} countryName={countryName}>
                The printer model <b className="highlight-text">{featureName}</b> is <b className={(isEnabled) ? "feature-switch-enabled-text" : "feature-switch-disabled-text"}>{status_text}</b> for the country <b className="highlight-text">{countryName}</b>
            </Trans>
        }
        setHandlingListAPI(true);
        setModalProps({
            isOpen : true, 
            toggleAdminOverlayModal: () => toggleAdminOverlayModal(PrinterModelName),
            modal_title: t('MANAGE_PRINTER_MODELS.MODAL.MODAL_TITLE'),
            modal_sub_title:  t('MANAGE_PRINTER_MODELS.MODAL.MODAL_SUB_TITLE'),
            modal_content: modal_content,
            showCancelBtn : true,
            cancel_btn_text: t('BTN_TEXTS.CANCEL'),
            showConfirmBtn: true,
            confirm_btn_text: t('BTN_TEXTS.CONFIRM_AND_SUBMIT'),
            handleModalConfirm:() => handleModalConfirm(PrinterModelId, CountryId, isEnabled)
        });
        dispatch(managePrinterModelsResetState());
    }
   
    const setModelsList = (data) => {
        return ({
            id: data.printerId,
            name : data.printerModel,
            isEnabled: data.isEnabled,
            code: null,
            isChangeEventFired: false,
            saveBtnDisabled: true,
            countryId: data.countryId,
        })
    }
    const setModelsCountryList = (data) => {
        return ({
            id: data.countryId,
            name : data.countryName,
            isEnabled: data.isEnabled,
            code: data.countryCode,
            isChangeEventFired: false,
            saveBtnDisabled: true,
            printerId: data.printerId,
            countryName: data.countryName
        })
    }
   if((printerModelStatusUpdateResp.success || printerModelStatusUpdateResp.error) && handleCallingListAPI){
        setModalProps({ isOpen: false });
        setAlertMessage({...alertMessage,
            'alertType': (printerModelStatusUpdateResp.error) ? 'error' : 'success',
            'alertMessage': (printerModelStatusUpdateResp.error) ? printerModelStatusUpdateResp.error : t("ADMIN_COMMON_SUCCESS_MSG.UPDATE_SUCCESS")
        });
        if(featureSwitchType === COUNTRY){
            dispatch(fetchModelsListByCountry(dropDownItemSelected.country, dropDownItemSelected.printerTypeId));
        } else {
            dispatch(fetchCountryListByPrinterModel(selectedPrinterModelName));
        }
        dispatch(updatePrinterModelsStateReset());
    }
    if(printerModelsList.success && handleCallingListAPI){
        const displayList = Object.values(printerModelsList?.data).map(setModelsList);
        setSortedLists(displayList);
        setAlertMessage({...alertMessage, 'alertType': 'success', 'alertMessage': t('ADMIN_COMMON_SUCCESS_MSG.FETCH_SUCCESS')})
        setHandlingListAPI(false);
    } 
    if(printerModelsList.error && handleCallingListAPI){
        setSortedLists([]);
        setAlertMessage({...alertMessage, 'alertType': 'error', 'alertMessage': printerModelsList.error})
        setHandlingListAPI(false);
    }
    if(PrinterModelsCountryList.success && handleCallingListAPI){
        const displayList = Object.values(PrinterModelsCountryList?.data).map(setModelsCountryList);
        setSortedLists(displayList);
        setAlertMessage({...alertMessage, 'alertType': 'success', 'alertMessage': t('ADMIN_COMMON_SUCCESS_MSG.FETCH_SUCCESS')})
        setHandlingListAPI(false);
    }
    if(PrinterModelsCountryList.error && handleCallingListAPI){
        setSortedLists([]);
        setAlertMessage({...alertMessage, 'alertType': 'error', 'alertMessage': PrinterModelsCountryList.error})
        setHandlingListAPI(false);
    }
    if(Object.keys(printerTypesResp?.printerTypes).length > 0) {
          printerTypesDropDownOptions = printerTypesResp?.printerTypes.map((data) =>
              <option key={data.printerTypeId} value={data.printerTypeId}>
                  {data.type}
              </option>
          );
      }
    return(
        <>
            { 
                (printerTypesResp.loading 
                || printerModelsList.loading 
                || PrinterModelsCountryList.loading
                || printerModelStatusUpdateResp.loading) 
                && <Loader setOverlay={true}/> 
            }
            { 
                printerTypesResp.error 
                && <AdminAlert alert_variant="alert-danger">{printerTypesResp.error}</AdminAlert> 
            }
            { 
                alertMessage 
                && alertMessage.alertType === 'error' 
                && <AdminAlert alert_variant="alert-danger">{alertMessage.alertMessage}</AdminAlert>
            }
            { 
                alertMessage
                && alertMessage.alertType === 'success' 
                && <AdminAlert alert_variant="alert-success">{alertMessage.alertMessage}</AdminAlert>
            }
            <div className="manage-feature-switch-country-body-wrapper">
                <div className="select-country-wrapper">
                    <p className="feature-switch-country-label">{t('MANAGE_PRINTER_MODELS.SELECT_OPTION_LABEL_PRINTER_TYPE')}</p>
                    <AdminFormSelect
                        cname = "feature-switch-country-list, printer-models-list"
                        name = 'printerTypeId'
                        options = {printerTypesDropDownOptions}
                        onChange = {(e) => handleDropdownSelection(e, PRINTER_TYPE)}
                        value = {dropDownItemSelected?.printerTypeId}
                    />
                    <p className="feature-switch-country-label">{selectOptionLabel}</p>
                    <AdminFormSelect
                        cname = "feature-switch-country-list"
                        name = {selectOptionElemName}
                        options = {countryDropdownOptions}
                        onChange = {(e) => handleDropdownSelection(e, featureSwitchType)}
                        value = {(featureSwitchType === COUNTRY) ? dropDownItemSelected?.country : dropDownItemSelected?.printerModel}
                    />
                    <EcoProButton cname="default manage-models-submit-button" onBtnClick={() => checkAndFetchList()} disabled ={isDisableFilterSubmitBtn}>{t('BTN_TEXTS.SUBMIT')}</EcoProButton>
                </div>
                { sortedLists && sortedLists.length > 0 && 
                <div className="country-wise-feature-list-wrapper">
                    <p className="feature-list-side-header">{displayedListTitle}</p>
                    <div className="grid-line"></div>
                        { sortedLists.length > 0 && sortedLists.map((list) => (
                                <div className="feature-list-outer-wrapper" key={list.id}>
                                    <div className="feature-list-item-wrapper">
                                        <p className="feature-name">{list.name}</p>
                                        <div className="enable-disable-wrapper">
                                            <AdminFormCheckSwitchType
                                            inputID = {list.name?.toLowerCase().split(" ").join('_')}
                                            inputName = {list.name?.toLowerCase().split(" ").join('_')}
                                            labelText = {(list.isEnabled) ? t('FEATURE_SWITCH.ENABLED_CHECKBOX_LABEL') : t('FEATURE_SWITCH.ENABLE_CHECKBOX_LABEL')}
                                            onChange={() => handleCheckboxChange(list.id)}
                                            isChecked = {list.isEnabled}
                                            key = {list.id}
                                            />
                                        </div>
                                        <div className="grid-action-btn-wrapper">
                                            <EcoProButton 
                                                cname="default" 
                                                onBtnClick= {() => handleSave(list)} 
                                                disabled={list.saveBtnDisabled}>
                                                    {t('BTN_TEXTS.SAVE')}
                                            </EcoProButton>
                                        </div>
                                    </div>
                                    <div className="grid-line"></div>
                                </div>
                            ))
                        }
                    </div>
                }
                <AdminOverlayModal {...modalProps}></AdminOverlayModal>
            </div>
        </>
    )
}

export default PrinterModelsSwitch;