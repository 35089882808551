import { call, put, takeEvery } from 'redux-saga/effects';
import i18next from 'i18next';
import * as actionTypes from '../../constants/ActionTypes/SelectDevice';
import { _CALLGETAPI } from '../../api/apiHandler'
import { _API_ENDPOINT, FAILURE, UNSUPPORTED_FIRMWARE_VERSION, DEVICE_OFFLINE, DEVICE_CARTRIDGE_TYPE, LOCALSESSIONDATAKEYS, UNSUPPORTED_MODEL } from '../../constants/AppConstants';
import { setLocalSessionData } from '../../utils/Authentication/handleUserSession';

function _GETDEVICECAPABILITY(UID,deviceId,model){
    const endpoint = _API_ENDPOINT.DEVICE_CAPABILITY;
    const payload = {
        params: {
            userId:UID,
            deviceId: deviceId,
            model: model
        }
      }
     return _CALLGETAPI(endpoint,payload)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* getDeviceCapability(action) {
    try {
        const UID = action.UID
        const deviceId = action.deviceId
        const model = action.model

        if(deviceId && model){
            const deviceCapability = yield call(_GETDEVICECAPABILITY,UID,deviceId,model);
            if(deviceCapability.actionStatus.toUpperCase() === FAILURE){
                if(deviceCapability.code === "904"){
                    yield put({type: actionTypes.GET_DEVICE_CAPABILITY_ERROR, message: UNSUPPORTED_FIRMWARE_VERSION})
                }
                else if(deviceCapability.code === "510"){
                    yield put({type: actionTypes.GET_DEVICE_CAPABILITY_ERROR, message: DEVICE_OFFLINE})
                } else if(deviceCapability.code === "905"){
                    yield put({type: actionTypes.GET_DEVICE_CAPABILITY_ERROR, message: UNSUPPORTED_MODEL})
                }
                else{
                    yield put({type: actionTypes.GET_DEVICE_CAPABILITY_ERROR, message: i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')})
                }
            } else {
                //set cartridge type, any cartridge type other than Hybrid or Subsc is considered standard.
                const cartridgeType = (
                    deviceCapability.hasOwnProperty('cartridgeType') 
                    && (deviceCapability.cartridgeType.toUpperCase() === DEVICE_CARTRIDGE_TYPE.HYBRID || deviceCapability.cartridgeType.toUpperCase() === DEVICE_CARTRIDGE_TYPE.SUBSC)
                ) ? DEVICE_CARTRIDGE_TYPE.HYBRID : DEVICE_CARTRIDGE_TYPE.STANDARD;
                setLocalSessionData(LOCALSESSIONDATAKEYS.CARTRIDGE_TYPE, cartridgeType);
                yield put({type: actionTypes.GET_DEVICE_CAPABILITY_SUCCESS, deviceCapability: deviceCapability});
            }
        } else {
            yield put({
                type: actionTypes.GET_DEVICE_CAPABILITY_ERROR, 
                message: i18next.t('ERROR_CONTENT.INFO_GENERAL')
            });
        }
    } catch (e) {
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
        if(e instanceof TypeError === false && e.message !== "401"){
            yield put({type: actionTypes.GET_DEVICE_CAPABILITY_ERROR, message: e.message});
        }
    }
}

function* DeviceCapability() {
    yield takeEvery(actionTypes.GET_DEVICE_CAPABILITY, getDeviceCapability);
 }
 
 export default DeviceCapability;