import { call, put, takeEvery } from 'redux-saga/effects';
import { _CALLGETAPI } from '../../../api/apiHandler'
import { WEB_CUSTOMER_API_ENDPOINT, SUCCESS } from '../../../constants/AppConstants';
import * as actionTypes from '../../../constants/ActionTypes/Web/Customer/SignUp';
import i18next from 'i18next';

function _GETPROMOTIONBANNERSTATUS(){
     const endpoint = WEB_CUSTOMER_API_ENDPOINT.GET_PROMOTION_BANNER_STATUS;
    const payload = {}
    return _CALLGETAPI(endpoint,payload)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* handlePromotionBanner(action){
    try{
            const promotionBannerStatus = yield call(_GETPROMOTIONBANNERSTATUS);
            if(promotionBannerStatus.actionStatus.toUpperCase() === SUCCESS){
                yield put({type: actionTypes.GET_PROMOTION_BANNER_STATUS_SUCCESS, promotionBannerStatus: promotionBannerStatus});
            } else {
                yield put({type: actionTypes.GET_PROMOTION_BANNER_STATUS_ERROR, message: i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')});
            }
    }catch(e){
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
        if(e instanceof TypeError === false && e.message !== "401"){
            yield put({type: actionTypes.GET_PROMOTION_BANNER_STATUS_ERROR, message: e.message});
        }
    }
}
function* GetPromotionBannerStatus(){
    yield takeEvery(actionTypes.GET_PROMOTION_BANNER_STATUS, handlePromotionBanner);
}

export default GetPromotionBannerStatus;