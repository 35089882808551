import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { WebCustomerRoutePaths } from "../constants/Routes";


const WebCustomerUnProtectedRoute = ({ component: Component, ...rest }) => {
    const { instance } = useMsal();
    const accounts = instance.getAllAccounts();
    const isAuthenticated = (accounts.length > 0) ? true : false;
    
    return (
        <Route {...rest} render={props => {
            if (!isAuthenticated) {
                return <Component {...props} />
            }
             return <Redirect to={{ pathname: WebCustomerRoutePaths.VERIFY_ACCOUNT, state: { from: props.location } }} />
        }} />
    )
}

export default WebCustomerUnProtectedRoute;