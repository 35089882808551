import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import Grid from "../../../../components/AdminPortal/Shared/Grid/Grid";
import PageHeader from "../../../../components/AdminPortal/Shared/PageHeader/PageHeader";
import EcoProButton from "../../../../components/shared/button/button";
import { adminRoutePaths } from "../../../../constants/Routes";
import AdminFormInput from "../../../../components/AdminPortal/Shared/FormElements/FormInput/FormInput";
import AdminAlert from "../../../../components/AdminPortal/AdminAlert/AdminAlert";
import Loader from "../../../../components/shared/loader/Loader";
import { addAdminUserReset, adminUserFetchList } from "../../../../actions/AdminPortal/AdminUsers";
import { extractCountryName } from "../../../../utils/Internationalization/handleAppInternationalization";
import NoRecords from "../../../../components/AdminPortal/Shared/Grid/NoRecords";
import AdminDefaultIcons from "../../../../utils/AdminPortal/DefaultIcons";
import './ViewUsers.css';
import { ADD, EDIT_ACCESS } from "../../../../constants/ActionTypes/AdminPortal/AdminUsers";

const ViewUsers = (props) => {
    const { t } = useTranslation("Admintranslation")
    const history = useHistory();
    const dispatch = useDispatch();
    const userList = useSelector(state => state.ADMIN.AdminUser_VIEW)
    const { location } = props;
    const stateInfo = location?.state?.stateInfo;
   
    useEffect(() => {
        dispatch(adminUserFetchList());
    }, [dispatch])
    const handleSearch = debounce((event) => fetchSearchResults(event));
    function debounce(func, timeout = 500){
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, timeout)
        };
    }
    const fetchSearchResults = (event) => {
        if(event.target.value){
            dispatch(adminUserFetchList(event.target.value));
        } else {
            dispatch(adminUserFetchList());
        }
    }

    const handleAddUser = () => {
        dispatch(addAdminUserReset());
        history.push({pathname:adminRoutePaths.ADMIN_USERS.ADD_USER, state: {'action': ADD}});
    }
    const handleAccessRevoke = (record) => {
        dispatch(addAdminUserReset());
        history.push({pathname: adminRoutePaths.ADMIN_USERS.EDIT_USER, state: {'action': EDIT_ACCESS, 'userRecord': record}});
      }
    return (
        <Container>
            { userList.loading && <Loader setOverlay={true}></Loader>}
            <div className="admin-users-header">
                <PageHeader pageTitle={t("MANAGE_ADMIN_USERS.PAGE_TITLE")}></PageHeader>
            </div>
            { userList.error && <AdminAlert alert_variant="alert-danger">{userList.error}</AdminAlert>}
            { userList.success && <AdminAlert alert_variant="alert-success">{t("ADMIN_COMMON_SUCCESS_MSG.FETCH_SUCCESS")}</AdminAlert>}
            <div className="admin-table-action-container">
                <div className="action-container"></div>
                <div className="button-container">
                    <div className="link-container">
                        <EcoProButton cname="default" type="button" onBtnClick={() => handleAddUser()}>{t("MANAGE_ADMIN_USERS.ADD_USER")}</EcoProButton>
                    </div>
                    <div className="search-container">
                        <AdminFormInput placeholder={t("MANAGE_ADMIN_USERS.SEARCH_BY_EMAIL_PLACEHOLDER")} onKeyUp={handleSearch}/>
                    </div>
                </div>
            </div>
            { stateInfo?.type === 'alert-success' && <AdminAlert alert_variant="alert-success">{stateInfo?.message}</AdminAlert>}
            { stateInfo?.type === 'alert-error' && <AdminAlert alert_variant="alert-error">{stateInfo?.message}</AdminAlert>}
            <div className="admin-user-view-wrapper">
                <Grid cname="admin-manage-users">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th>{t("MANAGE_ADMIN_USERS.COL_NAMES.NAME")}</th>
                            <th>{t("MANAGE_ADMIN_USERS.COL_NAMES.EMAIL")}</th>
                            <th>{t("MANAGE_ADMIN_USERS.COL_NAMES.COUNTRY")}</th>
                            <th>{t("MANAGE_ADMIN_USERS.COL_NAMES.ROLE")}</th>
                            <th>{t("COL_NAMES.CREATED_BY")}</th>
                            <th>{t("COL_NAMES.ACTIONS")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                             userList.success && userList.data && userList.data.map((record, index) => (
                                <tr key={index}>    
                                    <td>{index + 1}</td>
                                    <td className="text-left">{record.displayName}</td>
                                    <td className="text-left">{record.emailAddress}</td>
                                    <td>{extractCountryName(record.countryCode)}</td>
                                    <td>{record.roles?.toString()}</td>
                                    <td>{record.createdBy}</td>
                                    <td>
                                      <img alt="edit-role" title={t("MANAGE_ADMIN_USERS.EDIT_USER_ROLE")} className="edit-user-image" src={AdminDefaultIcons.ACCESS_REVOKE}  onClick={() => handleAccessRevoke(record)}/>
                                    </td>
                                </tr>
                            ))
                        }
                        {
                            ((userList.success && userList.data && userList.data.length === 0)
                            || (userList.error))
                            && <NoRecords colSpanNumber={7} />
                        }
                    </tbody>
                </Grid>
            </div>
        </Container>
    )
}

export default ViewUsers;