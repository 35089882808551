import { call, put, takeEvery } from "redux-saga/effects";
import i18next from "i18next";
import * as actionTypes from "../../constants/ActionTypes/Dashboard";
import { _CALLGETAPI } from "../../api/apiHandler";
import { _API_ENDPOINT } from "../../constants/AppConstants";

function _GETCANCELSUBSCRIPTIONSTATUS(UID, deviceId) {
  const endpoint = _API_ENDPOINT.GET_CANCEL_SUBSCRIPTION_STATUS;
  const payload = {
    params: {
      UserId: UID,
      deviceId: deviceId,
    },
  };
  return _CALLGETAPI(endpoint, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function* getCancelSubscriptionStatus(action) {
  try {
    const deviceID = action.deviceId;
    const UID = action.UID;
    if(UID && deviceID){
      const cancelSubscriptionStatus = yield call(
        _GETCANCELSUBSCRIPTIONSTATUS,
        UID,
        deviceID
      );
      if(cancelSubscriptionStatus){
        yield put({
          type: actionTypes.GET_CANCEL_SUBSCRIPTION_STATUS_SUCCESS,
          cancelSubscriptionStatus: cancelSubscriptionStatus,
        });
      }else{
        yield put({
          type: actionTypes.GET_CANCEL_SUBSCRIPTION_STATUS_ERROR,
          message: i18next.t('ERROR_CONTENT.ERR_INFO.SUBSCRIPTION_REQUIRED'),
        });
      }
    } else {
      yield put({
        type: actionTypes.GET_CANCEL_SUBSCRIPTION_STATUS_ERROR,
        message: i18next.t('ERROR_CONTENT.INFO_GENERAL')
      });
    }
  } catch (e) {
    /**
     * This conditional statement is added to avoid
     * displaying error message in view while logout from app
     */
    if(e instanceof TypeError === false && e.message !== "401"){
      yield put({
        type: actionTypes.GET_CANCEL_SUBSCRIPTION_STATUS_ERROR,
        message: e.message,
      });
    }
  }
}

function* GetCancelSubscriptionStatus() {
  yield takeEvery(actionTypes.GET_CANCEL_SUBSCRIPTION_STATUS, getCancelSubscriptionStatus);
}

export default GetCancelSubscriptionStatus;
