export const country = [
  {
    "name": "Afghanistan",
    "code": "AFG",
    "country-code": "004",
    "currency-code":"AFN"
  },
  {
    "name": "Åland Islands",
    "code": "ALA",
    "country-code": "248",
    "currency-code":"EUR"
  },
  {
    "name": "Albania",
    "code": "ALB",
    "country-code": "008",
    "currency-code":"ALL"
  },
  {
    "name": "Algeria",
    "code": "DZA",
    "country-code": "012",
    "currency-code":"DZD"
  },
  {
    "name": "American Samoa",
    "code": "ASM",
    "country-code": "016",
    "currency-code":"USD"
  },
  {
    "name": "Andorra",
    "code": "AND",
    "country-code": "020",
    "currency-code":"EUR"
  },
  {
    "name": "Angola",
    "code": "AGO",
    "country-code": "024",
    "currency-code":"AOA"
  },
  {
    "name": "Anguilla",
    "code": "AIA",
    "country-code": "660",
    "currency-code":"XCD"
  },
  {
    "name": "Antarctica",
    "code": "ATA",
    "country-code": "010",
  },
  {
    "name": "Antigua and Barbuda",
    "code": "ATG",
    "country-code": "028",
    "currency-code":"XCD"
  },
  {
    "name": "Argentina",
    "code": "ARG",
    "country-code": "032",
    "currency-code":"ARS"
  },
  {
    "name": "Armenia",
    "code": "ARM",
    "country-code": "051",
    "currency-code":"AMD"
  },
  {
    "name": "Aruba",
    "code": "ABW",
    "country-code": "533",
    "currency-code":"AWG"
  },
  {
    "name": "Australia",
    "code": "AUS",
    "country-code": "036",
    "currency-code":"AUD"
  },
  {
    "name": "Austria",
    "code": "AUT",
    "country-code": "040",
    "currency-code":"EUR"
  },
  {
    "name": "Azerbaijan",
    "code": "AZE",
    "country-code": "031",
    "currency-code":"AZN"
  },
  {
    "name": "Bahamas",
    "code": "BHS",
    "country-code": "044",
    "currency-code":"BSD"
  },
  {
    "name": "Bahrain",
    "code": "BHR",
    "country-code": "048",
    "currency-code":"BHD"
  },
  {
    "name": "Bangladesh",
    "code": "BGD",
    "country-code": "050",
    "currency-code":"BDT"
  },
  {
    "name": "Barbados",
    "code": "BRB",
    "country-code": "052",
    "currency-code":"BBD"
  },
  {
    "name": "Belarus",
    "code": "BLR",
    "country-code": "112",
    "currency-code":"BYR"
  },
  {
    "name": "Belgium",
    "code": "BEL",
    "country-code": "056",
    "currency-code":"EUR"
  },
  {
    "name": "Belize",
    "code": "BLZ",
    "country-code": "084",
    "currency-code":"BZD"
  },
  {
    "name": "Benin",
    "code": "BEN",
    "country-code": "204",
    "currency-code":"XOF"
  },
  {
    "name": "Bermuda",
    "code": "BMU",
    "country-code": "060",
    "currency-code":"BMD"
  },
  {
    "name": "Bhutan",
    "code": "BTN",
    "country-code": "064",
    "currency-code":"BTN"
  },
  {
    "name": "Bolivia (Plurinational State of)",
    "code": "BOL",
    "country-code": "068",
    "currency-code":"BOB"
  },
  {
    "name": "Bonaire, Sint Eustatius and Saba",
    "code": "BES",
    "country-code": "535",
    "currency-code":"USD"
  },
  {
    "name": "Bosnia and Herzegovina",
    "code": "BIH",
    "country-code": "070",
    "currency-code":"BAM"
  },
  {
    "name": "Botswana",
    "code": "BWA",
    "country-code": "072",
    "currency-code":"BWP"
  },
  {
    "name": "Bouvet Island",
    "code": "BVT",
    "country-code": "074",
    "currency-code":"NOK"
  },
  {
    "name": "Brazil",
    "code": "BRA",
    "country-code": "076",
    "currency-code":"BRL"
  },
  {
    "name": "British Indian Ocean Territory",
    "code": "IOT",
    "country-code": "086",
    "currency-code":"USD"
  },
  {
    "name": "Brunei Darussalam",
    "code": "BRN",
    "country-code": "096",
    "currency-code":"BND"
  },
  {
    "name": "Bulgaria",
    "code": "BGR",
    "country-code": "100",
    "currency-code":"BGN"
  },
  {
    "name": "Burkina Faso",
    "code": "BFA",
    "country-code": "854",
    "currency-code":"XOF"
  },
  {
    "name": "Burundi",
    "code": "BDI",
    "country-code": "108",
    "currency-code":"BIF"
  },
  {
    "name": "Cabo Verde",
    "code": "CPV",
    "country-code": "132",
    "currency-code":"CVE"
  },
  {
    "name": "Cambodia",
    "code": "KHM",
    "country-code": "116",
    "currency-code":"KHR"
  },
  {
    "name": "Cameroon",
    "code": "CMR",
    "country-code": "120",
    "currency-code":"XAF"
  },
  {
    "name": "Canada",
    "code": "CAN",
    "country-code": "124",
    "currency-code":"CAD"
  },
  {
    "name": "Cayman Islands",
    "code": "CYM",
    "country-code": "136",
    "currency-code":"KYD"
  },
  {
    "name": "Central African Republic",
    "code": "CAF",
    "country-code": "140",
    "currency-code":"XAF"
  },
  {
    "name": "Chad",
    "code": "TCD",
    "country-code": "148",
    "currency-code":"XAF"
  },
  {
    "name": "Chile",
    "code": "CHL",
    "country-code": "152",
    "currency-code":"CLP"
  },
  {
    "name": "China",
    "code": "CHN",
    "country-code": "156",
    "currency-code":"CNY"
  },
  {
    "name": "Christmas Island",
    "code": "CXR",
    "country-code": "162",
    "currency-code":"AUD"
  },
  {
    "name": "Cocos (Keeling) Islands",
    "code": "CCK",
    "country-code": "166",
    "currency-code":"AUD"
  },
  {
    "name": "Colombia",
    "code": "COL",
    "country-code": "170",
    "currency-code":"COP"
  },
  {
    "name": "Comoros",
    "code": "COM",
    "country-code": "174",
    "currency-code":"KMF"
  },
  {
    "name": "Congo",
    "code": "COG",
    "country-code": "178",
    "currency-code":"XAF"
  },
  {
    "name": "Congo, Democratic Republic of the",
    "code": "COD",
    "country-code": "180",
    "currency-code":"CDF"
  },
  {
    "name": "Cook Islands",
    "code": "COK",
    "country-code": "184",
    "currency-code":"NZD"
  },
  {
    "name": "Costa Rica",
    "code": "CRI",
    "country-code": "188",
    "currency-code":"CRC"
  },
  {
    "name": "Côte d'Ivoire",
    "code": "CIV",
    "country-code": "384",
    "currency-code":"XOF"
  },
  {
    "name": "Croatia",
    "code": "HRV",
    "country-code": "191",
    "currency-code":"HRK"
  },
  {
    "name": "Cuba",
    "code": "CUB",
    "country-code": "192",
    "currency-code":"CUP"
  },
  {
    "name": "Curaçao",
    "code": "CUW",
    "country-code": "531",
    "currency-code":"ANG"
  },
  {
    "name": "Cyprus",
    "code": "CYP",
    "country-code": "196",
    "currency-code":"EUR"
  },
  {
    "name": "Czechia",
    "code": "CZE",
    "country-code": "203",
    "currency-code":"CZK"
  },
  {
    "name": "Denmark",
    "code": "DNK",
    "country-code": "208",
    "currency-code":"DKK"
  },
  {
    "name": "Djibouti",
    "code": "DJI",
    "country-code": "262",
    "currency-code":"DJF"
  },
  {
    "name": "Dominica",
    "code": "DMA",
    "country-code": "212",
    "currency-code":"XCD"
  },
  {
    "name": "Dominican Republic",
    "code": "DOM",
    "country-code": "214",
    "currency-code":"DOP"
  },
  {
    "name": "Ecuador",
    "code": "ECU",
    "country-code": "218",
    "currency-code":"ECS"
  },
  {
    "name": "Egypt",
    "code": "EGY",
    "country-code": "818",
    "currency-code":"EGP"
  },
  {
    "name": "El Salvador",
    "code": "SLV",
    "country-code": "222",
    "currency-code":"SVC"
  },
  {
    "name": "Equatorial Guinea",
    "code": "GNQ",
    "country-code": "226",
    "currency-code":"XAF"
  },
  {
    "name": "Eritrea",
    "code": "ERI",
    "country-code": "232",
    "currency-code":"ERN"
  },
  {
    "name": "Estonia",
    "code": "EST",
    "country-code": "233",
    "currency-code":"EUR"
  },
  {
    "name": "Eswatini",
    "code": "SWZ",
    "country-code": "748",
    "currency-code":"SZL"
  },
  {
    "name": "Ethiopia",
    "code": "ETH",
    "country-code": "231",
    "currency-code":"ETB"
  },
  {
    "name": "Falkland Islands (Malvinas)",
    "code": "FLK",
    "country-code": "238",
    "currency-code":"FKP"
  },
  {
    "name": "Faroe Islands",
    "code": "FRO",
    "country-code": "234",
    "currency-code":"DKK"
  },
  {
    "name": "Fiji",
    "code": "FJI",
    "country-code": "242",
    "currency-code":"FJD"
  },
  {
    "name": "Finland",
    "code": "FIN",
    "country-code": "246",
    "currency-code":"EUR"
  },
  {
    "name": "France",
    "code": "FRA",
    "country-code": "250",
    "currency-code":"EUR"
  },
  {
    "name": "French Guiana",
    "code": "GUF",
    "country-code": "254",
    "currency-code":"EUR"
  },
  {
    "name": "French Polynesia",
    "code": "PYF",
    "country-code": "258",
    "currency-code":"XPF"
  },
  {
    "name": "French Southern Territories",
    "code": "ATF",
    "country-code": "260",
    "currency-code":"EUR"
  },
  {
    "name": "Gabon",
    "code": "GAB",
    "country-code": "266",
    "currency-code":"XAF"
  },
  {
    "name": "Gambia",
    "code": "GMB",
    "country-code": "270",
    "currency-code":"GMD"
  },
  {
    "name": "Georgia",
    "code": "GEO",
    "country-code": "268",
    "currency-code":"GEL"
  },
  {
    "name": "Germany",
    "code": "DEU",
    "country-code": "276",
    "currency-code":"EUR"
  },
  {
    "name": "Ghana",
    "code": "GHA",
    "country-code": "288",
    "currency-code":"GHS"
  },
  {
    "name": "Gibraltar",
    "code": "GIB",
    "country-code": "292",
    "currency-code":"GIP"
  },
  {
    "name": "Greece",
    "code": "GRC",
    "country-code": "300",
    "currency-code":"EUR"
  },
  {
    "name": "Greenland",
    "code": "GRL",
    "country-code": "304",
    "currency-code":"DKK"
  },
  {
    "name": "Grenada",
    "code": "GRD",
    "country-code": "308",
    "currency-code":"XCD"
  },
  {
    "name": "Guadeloupe",
    "code": "GLP",
    "country-code": "312",
    "currency-code":"EUR"
  },
  {
    "name": "Guam",
    "code": "GUM",
    "country-code": "316",
    "currency-code":"USD"
  },
  {
    "name": "Guatemala",
    "code": "GTM",
    "country-code": "320",
    "currency-code":"QTQ"
  },
  {
    "name": "Guernsey",
    "code": "GGY",
    "country-code": "831",
    "currency-code":"GBP"
  },
  {
    "name": "Guinea",
    "code": "GIN",
    "country-code": "324",
    "currency-code":"GNF"
  },
  {
    "name": "Guinea-Bissau",
    "code": "GNB",
    "country-code": "624",
    "currency-code":"CFA"
  },
  {
    "name": "Guyana",
    "code": "GUY",
    "country-code": "328",
    "currency-code":"GYD"
  },
  {
    "name": "Haiti",
    "code": "HTI",
    "country-code": "332",
    "currency-code":"HTG"
  },
  {
    "name": "Heard Island and McDonald Islands",
    "code": "HMD",
    "country-code": "334",
    "currency-code":"AUD"
  },
  {
    "name": "Holy See",
    "code": "VAT",
    "country-code": "336",
    "currency-code":"EUR"
  },
  {
    "name": "Honduras",
    "code": "HND",
    "country-code": "340",
    "currency-code":"HNL"
  },
  {
    "name": "Hong Kong",
    "code": "HKG",
    "country-code": "344",
    "currency-code":"HKD"
  },
  {
    "name": "Hungary",
    "code": "HUN",
    "country-code": "348",
    "currency-code":"HUF"
  },
  {
    "name": "Iceland",
    "code": "ISL",
    "country-code": "352",
    "currency-code":"ISK"
  },
  {
    "name": "India",
    "code": "IND",
    "country-code": "356",
    "currency-code":"INR"
  },
  {
    "name": "Indonesia",
    "code": "IDN",
    "country-code": "360",
    "currency-code":"IDR"
  },
  {
    "name": "Iran (Islamic Republic of)",
    "code": "IRN",
    "country-code": "364",
    "currency-code":"IRR"
  },
  {
    "name": "Iraq",
    "code": "IRQ",
    "country-code": "368",
    "currency-code":"IQD"
  },
  {
    "name": "Ireland",
    "code": "IRL",
    "country-code": "372",
    "currency-code":"EUR"
  },
  {
    "name": "Isle of Man",
    "code": "IMN",
    "country-code": "833",
    "currency-code":"GBP"
  },
  {
    "name": "Israel",
    "code": "ISR",
    "country-code": "376",
    "currency-code":"ILS"
  },
  {
    "name": "Italy",
    "code": "ITA",
    "country-code": "380",
    "currency-code":"EUR"
  },
  {
    "name": "Jamaica",
    "code": "JAM",
    "country-code": "388",
    "currency-code":"JMD"
  },
  {
    "name": "Japan",
    "code": "JPN",
    "country-code": "392",
    "currency-code":"JPY"
  },
  {
    "name": "Jersey",
    "code": "JEY",
    "country-code": "832",
    "currency-code":"GBP"
  },
  {
    "name": "Jordan",
    "code": "JOR",
    "country-code": "400",
    "currency-code":"JOD"
  },
  {
    "name": "Kazakhstan",
    "code": "KAZ",
    "country-code": "398",
    "currency-code":"KZT"
  },
  {
    "name": "Kenya",
    "code": "KEN",
    "country-code": "404",
    "currency-code":"KES"
  },
  {
    "name": "Kiribati",
    "code": "KIR",
    "country-code": "296",
    "currency-code":"AUD"
  },
  {
    "name": "Korea (Democratic People's Republic of)",
    "code": "PRK",
    "country-code": "408",
    "currency-code":"KPW"
  },
  {
    "name": "Korea, Republic of",
    "code": "KOR",
    "country-code": "410",
    "currency-code":"KRW"
  },
  {
    "name": "Kuwait",
    "code": "KWT",
    "country-code": "414",
    "currency-code":"KWD"
  },
  {
    "name": "Kyrgyzstan",
    "code": "KGZ",
    "country-code": "417",
    "currency-code":"KGS"
  },
  {
    "name": "Lao People's Democratic Republic",
    "code": "LAO",
    "country-code": "418",
    "currency-code":"LAK"
  },
  {
    "name": "Latvia",
    "code": "LVA",
    "country-code": "428",
    "currency-code":"LVL"
  },
  {
    "name": "Lebanon",
    "code": "LBN",
    "country-code": "422",
    "currency-code":"LBP"
  },
  {
    "name": "Lesotho",
    "code": "LSO",
    "country-code": "426",
    "currency-code":"LSL"
  },
  {
    "name": "Liberia",
    "code": "LBR",
    "country-code": "430",
    "currency-code":"LRD"
  },
  {
    "name": "Libya",
    "code": "LBY",
    "country-code": "434",
    "currency-code":"LYD"
  },
  {
    "name": "Liechtenstein",
    "code": "LIE",
    "country-code": "438",
    "currency-code":"CHF"
  },
  {
    "name": "Lithuania",
    "code": "LTU",
    "country-code": "440",
    "currency-code":"LTL"
  },
  {
    "name": "Luxembourg",
    "code": "LUX",
    "country-code": "442",
    "currency-code":"EUR"
  },
  {
    "name": "Macao",
    "code": "MAC",
    "country-code": "446",
    "currency-code":"MOP"
  },
  {
    "name": "Madagascar",
    "code": "MDG",
    "country-code": "450",
    "currency-code":"MGF"
  },
  {
    "name": "Malawi",
    "code": "MWI",
    "country-code": "454",
    "currency-code":"MWK"
  },
  {
    "name": "Malaysia",
    "code": "MYS",
    "country-code": "458",
    "currency-code":"MYR"
  },
  {
    "name": "Maldives",
    "code": "MDV",
    "country-code": "462",
    "currency-code":"MDR"
  },
  {
    "name": "Mali",
    "code": "MLI",
    "country-code": "466",
    "currency-code":"XOF"
  },
  {
    "name": "Malta",
    "code": "MLT",
    "country-code": "470",
    "currency-code":"EUR"
  },
  {
    "name": "Marshall Islands",
    "code": "MHL",
    "country-code": "584",
    "currency-code":"USD"
  },
  {
    "name": "Martinique",
    "code": "MTQ",
    "country-code": "474",
    "currency-code":"EUR"
  },
  {
    "name": "Mauritania",
    "code": "MRT",
    "country-code": "478",
    "currency-code":"MRO"
  },
  {
    "name": "Mauritius",
    "code": "MUS",
    "country-code": "480",
    "currency-code":"MUR"
  },
  {
    "name": "Mayotte",
    "code": "MYT",
    "country-code": "175",
    "currency-code":"EUR"
  },
  {
    "name": "Mexico",
    "code": "MEX",
    "country-code": "484",
    "currency-code":"MXN"
  },
  {
    "name": "Micronesia (Federated States of)",
    "code": "FSM",
    "country-code": "583",
    "currency-code":"USD"
  },
  {
    "name": "Moldova, Republic of",
    "code": "MDA",
    "country-code": "498",
    "currency-code":"MDL"
  },
  {
    "name": "Monaco",
    "code": "MCO",
    "country-code": "492",
    "currency-code":"EUR"
  },
  {
    "name": "Mongolia",
    "code": "MNG",
    "country-code": "496",
    "currency-code":"MNT"
  },
  {
    "name": "Montenegro",
    "code": "MNE",
    "country-code": "499",
    "currency-code":"EUR"
  },
  {
    "name": "Montserrat",
    "code": "MSR",
    "country-code": "500",
    "currency-code":"XCD"
  },
  {
    "name": "Morocco",
    "code": "MAR",
    "country-code": "504",
    "currency-code":"MAD"
  },
  {
    "name": "Mozambique",
    "code": "MOZ",
    "country-code": "508",
    "currency-code":"MZN"
  },
  {
    "name": "Myanmar",
    "code": "MMR",
    "country-code": "104",
    "currency-code":"MMR"
  },
  {
    "name": "Namibia",
    "code": "NAM",
    "country-code": "516",
    "currency-code":"NAD"
  },
  {
    "name": "Nauru",
    "code": "NRU",
    "country-code": "520",
    "currency-code":"AUD"
  },
  {
    "name": "Nepal",
    "code": "NPL",
    "country-code": "524",
    "currency-code":"NPR"
  },
  {
    "name": "Netherlands",
    "code": "NLD",
    "country-code": "528",
    "currency-code":"EUR"
  },
  {
    "name": "New Caledonia",
    "code": "NCL",
    "country-code": "540",
    "currency-code":"XPF"
  },
  {
    "name": "New Zealand",
    "code": "NZL",
    "country-code": "554",
    "currency-code":"NZD"
  },
  {
    "name": "Nicaragua",
    "code": "NIC",
    "country-code": "558",
    "currency-code":"NIO"
  },
  {
    "name": "Niger",
    "code": "NER",
    "country-code": "562",
    "currency-code":"XOF"
  },
  {
    "name": "Nigeria",
    "code": "NGA",
    "country-code": "566",
    "currency-code":"NGN"
  },
  {
    "name": "Niue",
    "code": "NIU",
    "country-code": "570",
    "currency-code":"NZD"
  },
  {
    "name": "Norfolk Island",
    "code": "NFK",
    "country-code": "574",
    "currency-code":"KPW"
  },
  {
    "name": "North Macedonia",
    "code": "MKD",
    "country-code": "807",
    "currency-code":"GBP"
  },
  {
    "name": "Northern Mariana Islands",
    "code": "MNP",
    "country-code": "580",
    "currency-code":"USD"
  },
  {
    "name": "Norway",
    "code": "NOR",
    "country-code": "578",
    "currency-code":"NOK"
  },
  {
    "name": "Oman",
    "code": "OMN",
    "country-code": "512",
    "currency-code":"OMR"
  },
  {
    "name": "Pakistan",
    "code": "PAK",
    "country-code": "586",
    "currency-code":"PKR"
  },
  {
    "name": "Palau",
    "code": "PLW",
    "country-code": "585",
    "currency-code":"USD"
  },
  {
    "name": "Palestine, State of",
    "code": "PSE",
    "country-code": "275",
  },
  {
    "name": "Panama",
    "code": "PAN",
    "country-code": "591",
    "currency-code":"PAB"
  },
  {
    "name": "Papua New Guinea",
    "code": "PNG",
    "country-code": "598",
    "currency-code":"PGK"
  },
  {
    "name": "Paraguay",
    "code": "PRY",
    "country-code": "600",
    "currency-code":"PYG"
  },
  {
    "name": "Peru",
    "code": "PER",
    "country-code": "604",
    "currency-code":"PEN"
  },
  {
    "name": "Philippines",
    "code": "PHL",
    "country-code": "608",
    "currency-code":"PHP"
  },
  {
    "name": "Pitcairn",
    "code": "PCN",
    "country-code": "612",
    "currency-code":"NZD"
  },
  {
    "name": "Poland",
    "code": "POL",
    "country-code": "616",
    "currency-code":"PLN"
  },
  {
    "name": "Portugal",
    "code": "PRT",
    "country-code": "620",
    "currency-code":"EUR"
  },
  {
    "name": "Puerto Rico",
    "code": "PRI",
    "country-code": "630",
    "currency-code":"USD"
  },
  {
    "name": "Qatar",
    "code": "QAT",
    "country-code": "634",
    "currency-code":"QAR"
  },
  {
    "name": "Réunion",
    "code": "REU",
    "country-code": "638",
    "currency-code":"EUR"
  },
  {
    "name": "Romania",
    "code": "ROU",
    "country-code": "642",
    "currency-code":"RON"
  },
  {
    "name": "Russian Federation",
    "code": "RUS",
    "country-code": "643",
    "currency-code":"RUB"
  },
  {
    "name": "Rwanda",
    "code": "RWA",
    "country-code": "646",
    "currency-code":"RWF"
  },
  {
    "name": "Saint Barthélemy",
    "code": "BLM",
    "country-code": "652",
    "currency-code":"EUR"
  },
  {
    "name": "Saint Helena, Ascension and Tristan da Cunha",
    "code": "SHN",
    "country-code": "654",
    "currency-code":"SHP",

  },
  {
    "name": "Saint Kitts and Nevis",
    "code": "KNA",
    "country-code": "659",
    "currency-code":"XCD"
  },
  {
    "name": "Saint Lucia",
    "code": "LCA",
    "country-code": "662",
    "currency-code":"XCD"
  },
  {
    "name": "Saint Martin (French part)",
    "code": "MAF",
    "country-code": "663",
    "currency-code":"EUR"
  },
  {
    "name": "Saint Pierre and Miquelon",
    "code": "SPM",
    "country-code": "666",
    "currency-code":"EUR"
  },
  {
    "name": "Saint Vincent and the Grenadines",
    "code": "VCT",
    "country-code": "670",
    "currency-code":"XCD"
  },
  {
    "name": "Samoa",
    "code": "WSM",
    "country-code": "882",
    "currency-code":"WST"
  },
  {
    "name": "San Marino",
    "code": "SMR",
    "country-code": "674",
    "currency-code":"EUR"
  },
  {
    "name": "Sao Tome and Principe",
    "code": "STP",
    "country-code": "678",
    "currency-code":"STD"
  },
  {
    "name": "Saudi Arabia",
    "code": "SAU",
    "country-code": "682",
    "currency-code":"SAR"
  },
  {
    "name": "Senegal",
    "code": "SEN",
    "country-code": "686",
    "currency-code":"XOF"
  },
  {
    "name": "Serbia",
    "code": "SRB",
    "country-code": "688",
    "currency-code":"RSD"
  },
  {
    "name": "Seychelles",
    "code": "SYC",
    "country-code": "690",
    "currency-code":"SCR"
  },
  {
    "name": "Sierra Leone",
    "code": "SLE",
    "country-code": "694",
    "currency-code":"SLL"
  },
  {
    "name": "Singapore",
    "code": "SGP",
    "country-code": "702",
    "currency-code":"SGD"
  },
  {
    "name": "Sint Maarten (Dutch part)",
    "code": "SXM",
    "country-code": "534",
    "currency-code":"ANG"
  },
  {
    "name": "Slovakia",
    "code": "SVK",
    "country-code": "703",
    "currency-code":"EUR"
  },
  {
    "name": "Slovenia",
    "code": "SVN",
    "country-code": "705",
    "currency-code":"EUR"
  },
  {
    "name": "Solomon Islands",
    "code": "SLB",
    "country-code": "090",
    "currency-code":"SBD"
  },
  {
    "name": "Somalia",
    "code": "SOM",
    "country-code": "706",
    "currency-code":"SOS"
  },
  {
    "name": "South Africa",
    "code": "ZAF",
    "country-code": "710",
    "currency-code":"ZAR"
  },
  {
    "name": "South Georgia and the South Sandwich Islands",
    "code": "SGS",
    "country-code": "239",
    "currency-code":"GBP"
  },
  {
    "name": "South Sudan",
    "code": "SSD",
    "country-code": "728",
    "currency-code":"SSP"
  },
  {
    "name": "Spain",
    "code": "ESP",
    "country-code": "724",
    "currency-code":"EUR"
  },
  {
    "name": "Sri Lanka",
    "code": "LKA",
    "country-code": "144",
    "currency-code":"LKR"
  },
  {
    "name": "Sudan",
    "code": "SDN",
    "country-code": "729",
    "currency-code":"SDG"
  },
  {
    "name": "Suriname",
    "code": "SUR",
    "country-code": "740",
    "currency-code":"SRD"
  },
  {
    "name": "Svalbard and Jan Mayen",
    "code": "SJM",
    "country-code": "744",
    "currency-code":"NOK"
  },
  {
    "name": "Sweden",
    "code": "SWE",
    "country-code": "752",
    "currency-code":"SEK"
  },
  {
    "name": "Switzerland",
    "code": "CHE",
    "country-code": "756",
    "currency-code":"CHF"
  },
  {
    "name": "Syrian Arab Republic",
    "code": "SYR",
    "country-code": "760",
    "currency-code":"SYP"
  },
  {
    "name": "Taiwan, Province of China",
    "code": "TWN",
    "country-code": "158",
    "currency-code":"TWD"
  },
  {
    "name": "Tajikistan",
    "code": "TJK",
    "country-code": "762",
    "currency-code":"TJS"
  },
  {
    "name": "Tanzania, United Republic of",
    "code": "TZA",
    "country-code": "834",
    "currency-code":"TZS"
  },
  {
    "name": "Thailand",
    "code": "THA",
    "country-code": "764",
    "currency-code":"THB"
  },
  {
    "name": "Timor-Leste",
    "code": "TLS",
    "country-code": "626",
    "currency-code":"USD"
  },
  {
    "name": "Togo",
    "code": "TGO",
    "country-code": "768",
    "currency-code":"XOF"
  },
  {
    "name": "Tokelau",
    "code": "TKL",
    "country-code": "772",
    "currency-code":"NZD"
  },
  {
    "name": "Tonga",
    "code": "TON",
    "country-code": "776",
    "currency-code":"TOP"
  },
  {
    "name": "Trinidad and Tobago",
    "code": "TTO",
    "country-code": "780",
    "currency-code":"TTD"
  },
  {
    "name": "Tunisia",
    "code": "TUN",
    "country-code": "788",
    "currency-code":"TND"
  },
  {
    "name": "Turkey",
    "code": "TUR",
    "country-code": "792",
    "currency-code":"TRY"
  },
  {
    "name": "Turkmenistan",
    "code": "TKM",
    "country-code": "795",
    "currency-code":"TMT"
  },
  {
    "name": "Turks and Caicos Islands",
    "code": "TCA",
    "country-code": "796",
    "currency-code":"USD"
  },
  {
    "name": "Tuvalu",
    "code": "TUV",
    "country-code": "798",
    "currency-code":"AUD"
  },
  {
    "name": "Uganda",
    "code": "UGA",
    "country-code": "800",
    "currency-code":"UGX"
  },
  {
    "name": "Ukraine",
    "code": "UKR",
    "country-code": "804",
    "currency-code":"UAH"
  },
  {
    "name": "United Arab Emirates",
    "code": "ARE",
    "country-code": "784",
    "currency-code":"AED"
  },
  {
    "name": "United Kingdom",
    "code": "GBR",
    "country-code": "826",
    "currency-code":"GBP"
  },
  {
    "name": "United States of America",
    "code": "USA",
    "country-code": "840",
    "currency-code":"USD"
  },
  {
    "name": "United States Minor Outlying Islands",
    "code": "UMI",
    "country-code": "581",
    "currency-code":"USD"
  },
  {
    "name": "Uruguay",
    "code": "URY",
    "country-code": "858",
    "currency-code":"UYU"
  },
  {
    "name": "Uzbekistan",
    "code": "UZB",
    "country-code": "860",
    "currency-code":"UZS"
  },
  {
    "name": "Vanuatu",
    "code": "VUT",
    "country-code": "548",
    "currency-code":"VUV"
  },
  {
    "name": "Venezuela (Bolivarian Republic of)",
    "code": "VEN",
    "country-code": "862",
    "currency-code":"VEF"
  },
  {
    "name": "Viet Nam",
    "code": "VNM",
    "country-code": "704",
    "currency-code":"VND"
  },
  {
    "name": "Virgin Islands (British)",
    "code": "VGB",
    "country-code": "092",
    "currency-code":"USD"
  },
  {
    "name": "Virgin Islands (U.S.)",
    "code": "VIR",
    "country-code": "850",
    "currency-code":"USD"
  },
  {
    "name": "Wallis and Futuna",
    "code": "WLF",
    "country-code": "876",
    "currency-code":"XPF"
  },
  {
    "name": "Western Sahara",
    "code": "ESH",
    "country-code": "732",
    "currency-code":"MAD"
  },
  {
    "name": "Yemen",
    "code": "YEM",
    "country-code": "887",
    "currency-code":"YER"
  },
  {
    "name": "Zambia",
    "code": "ZMB",
    "country-code": "894",
    "currency-code":"ZMW"
  },
  {
    "name": "Zimbabwe",
    "code": "ZWE",
    "country-code": "716",
    "currency-code":"ZWD"
  }
]
/**
 * NOTE: Any update/addition in languages consider site links
 * used in account forms and legal terms and conditions.
 */
export const languageCodes = {
    "uk": 'en-GB',
    "es": 'es-ES',
    "fi": "fi-FI",
    "be": "nl-BE",
    "fr": "fr-FR",
    "it": "it-IT",
    "no": "nb-NO",
    "nl": "nl-NL",
    "pl": "pl-PL",
    "pt": "pt-PT",
    "se": "sv-SE",
    "dk": "da-DK",
    "at": "de-AT",
    "de": "de-DE",
    "ie": "en-IE",
    "ch": "de-CH",
}

export const ctrylangCombinations = {
  "BE": ["nl-BE", "fr-BE"],  //'nl' is the default language for Belgium
  "CH": ["de-CH", "fr-CH"], //'de' is the default language for Switzerland
  "FI": ["fi-FI", "sv-FI"]
}

export const multiLingualCountries = {
  "BE": ["nl", "fr"], //'nl' is the default language for Belgium
  "CH": ["de", "fr"], //'de' is the default language for Switzerland
}