import * as actionTypes from '../../../constants/ActionTypes/Web/Customer/SignUp';

const initialState = {
    UID: null,
    loading: false,
    error: false,
    success: false
  }

const UpdateADB2CNewUserAccount = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_ADB2C_NEW_USER_INIT:
            return {
                ...state,
                loading: true,
                error: false,
                UID: null,
                success: false
            }
        
        case actionTypes.UPDATE_ADB2C_NEW_USER_SUCCESS:
            return {
                ...state,
                UID: action.UID,
                loading: false,
                error: false,
                success: true
            }
        
        case actionTypes.UPDATE_ADB2C_NEW_USER_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message,
                UID: null,
                success: false
            }
        default:
            return {...state}
    }
}

export default UpdateADB2CNewUserAccount;