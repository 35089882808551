import { useCallback, useEffect, useState } from "react";

const UseAndroidIosAppIcons = (langCode) => {
    const [appIconsImgSrc, setAppIconsSrc] = useState({ androidAppIcon: '', IOSAppIcon: '' });

    const loadAppIcons = useCallback(async () => {
        try {
            const appLangCode = langCode.split("-")[0].toUpperCase();
            const android = await import(`../../assets/images/android-app-icons/google-play-badge-${appLangCode}.png`);
            const ios = await import(`../../assets/images/ios-app-icons/App_Store_Badge_Black_${appLangCode}.svg`);
            
            setAppIconsSrc({
                'androidAppIcon': android.default,
                'IOSAppIcon': ios.default
            });
        } catch (error) {
          const android = await import(`../../assets/images/android-app-icons/google-play-badge-EN.png`);
          const ios = await import(`../../assets/images/ios-app-icons/App_Store_Badge_Black_EN.svg`);
          
          setAppIconsSrc({
            'androidAppIcon': android.default,
            'IOSAppIcon': ios.default
          });
        }
      },[langCode]);

      
    useEffect(() => {
      loadAppIcons();
  }, [loadAppIcons]);

      return appIconsImgSrc;
    
  };

  export default UseAndroidIosAppIcons;
