import React from "react";
import EcoProButton from "../../../../../shared/button/button";
import './DisplayOrderYourPrinter.css'
import { useTranslation } from "react-i18next";

const DisplayOrderYourPrinter = (props) => {
  const {handleCheckout, printerTypeId} = props
  const {t} = useTranslation(['translation'])

  return (
    <div className="cp-order-printer-container">
      <div className="cp-subtitle">
        {printerTypeId.toString() === "1" ? t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.ORDER_PRINTER.INKJET_HEADER") :
        printerTypeId.toString() === "2" ? t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.ORDER_PRINTER.LASER_HEADER") : ""}
      </div>
      <div className="cp-order-printer-btn-wrapper">
        <EcoProButton
          cname="web-signup, cp-order-printer-btn"
          btnID={`1`}
          onBtnClick={()=>handleCheckout()}
        >
          Continue
        </EcoProButton>
      </div>
    </div>
  );
};

export default DisplayOrderYourPrinter;
