import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { routePath } from '../../../constants/Routes';
import { fetchDeviceList } from '../../../actions/SelectDevice';
import { getUserSessionData } from '../../../utils/Authentication/handleUserSession';
import Loader from '../../../components/shared/loader/Loader';
import ErrorPage from '../../../components/shared/errorPage/ErrorPage';
import DisplaySwapDevice from '../../../components/DisplaySwapDevice/DisplaySwapDevice';
import { ECOPROTOKEN_CLAIMS, refreshBtn } from "../../../constants/AppConstants";
import { resetSwapDevice } from '../../../actions/Dashboard';


const SwapDevice = () => {
    const history = useHistory()
    const deviceListResult = useSelector((state) => state.SelectDevice);
    const devices = deviceListResult?.deviceList?.devices;
    const dispatch = useDispatch();
    const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
    let enrolledDevice = {};
    let replacementDevices = []
    const [selectedreplacementDeviceId, setSelectedreplacementDeviceId] = useState("")
    const [selectedreplacementDevice, setSelectedreplacementDevice] = useState("")

    useEffect(() => {
        dispatch(resetSwapDevice())
        dispatch(fetchDeviceList(UID));
    }, [dispatch, UID]);

    if (deviceListResult.loading) {
        return <Loader></Loader>;
    }
    if (deviceListResult.error) {
        return (
        <ErrorPage
            errorInfo={deviceListResult.error}
            showRefreshBtn={refreshBtn.HIDE}
        ></ErrorPage>
        );
    }
    if (deviceListResult?.success === true) {
        devices &&
          devices.map((device) => {
            if(device?.serviceProgram?.length>0){
                enrolledDevice = device
            }
            else{
                replacementDevices.push(device)
            }
            return device;
          });
    }

    const moveToConfirmDeviceChange = () => {
        if(selectedreplacementDeviceId === 'addNewDevice'){
            history.push({
                pathname:routePath.ADD_DEVICE,
                state:{
                    confirmStatus:true,
                }
            })
        }
        else{
        history.push({
            pathname:routePath.CHANGE_DEVICE_CONFIRM,
            state:{
                confirmStatus:true,
                oldDeviceId:enrolledDevice.deviceId,
                newDevice:selectedreplacementDevice
            }
        })
        }
    }
    const onRelacementSelection = (newDevice,type) => {
        if(type === "replacementDevice"){
            setSelectedreplacementDeviceId(newDevice.deviceId)
            setSelectedreplacementDevice(newDevice)
        }
        else{
            setSelectedreplacementDeviceId(type)
        }
    }
    return (
        <DisplaySwapDevice
            deviceList = {deviceListResult.deviceList}
            enrolledDevice = {enrolledDevice}
            replacementDevices = {replacementDevices}
            moveToConfirmDeviceChange={moveToConfirmDeviceChange}
            onRelacementSelection={onRelacementSelection}
            selectedreplacementDeviceId={selectedreplacementDeviceId}
        ></DisplaySwapDevice>
    );
};

export default SwapDevice;