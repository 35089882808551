import axios from "axios";
import { useEffect, useState } from "react";
import { ENVSettings } from "../../constants/ENVSettings";
import { _ADMINISTRATOR_API_ENDPOINTS } from "../../constants/AppConstants";

/**
 * This hook handles the maintenance service status for the application
 * @returns Object
 */
export function useMaintenance() {
   const [maintenanceServiceStatus, setMaintenanceServiceStatus] = useState(false);

   useEffect(() => {
        fetchMaintenanceServiceStatus();
   }, []);

   const fetchMaintenanceServiceStatus = () => {
        axios.get(`${ENVSettings.API_BASE_URL}${_ADMINISTRATOR_API_ENDPOINTS.SETTINGS.MAINTENENCE_SERVICE.FETCH}`)
        .then(response => {
            const decodeData = JSON.parse(atob(response.data));
            setMaintenanceServiceStatus(decodeData.ServiceKey);
        })
        .catch(error => {
          throw error;
        });
   }
   return { maintenanceServiceStatus };
}