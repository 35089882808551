import React from "react";
import { useTranslation } from "react-i18next";
import ErrorPage from "../../../../../components/shared/errorPage/ErrorPage";
import { tryAgainBtn } from "../../../../../constants/AppConstants";
import { WebCustomerRoutePaths } from "../../../../../constants/Routes";

const WebError = () => {
    const { t } = useTranslation(["translation"]);
    return (
        <ErrorPage errorInfo={t('CUSTOMER_WEB.ERROR_CONTENT.MULTIPLE_ATTEMPT_ERR_INFO')} showTryAgain={tryAgainBtn.SHOW} to={WebCustomerRoutePaths.SELECT_PRINTER}></ErrorPage>
    )
}

export default WebError