import React from 'react';
import { useTranslation } from 'react-i18next';
import swap from '../../assets/images/swap.svg'
import plus from '../../assets/images/plus.svg'
import EcoProButton from '../../components/shared/button/button';
import './DisplaySwapDevice.css'
import AddDeviceCard from '../AddDeviceCard/AddDeviceCard';

const DisplaySwapDevice = (props) => {
    const {enrolledDevice,replacementDevices, moveToConfirmDeviceChange, onRelacementSelection, selectedreplacementDeviceId} = props
    const { t } = useTranslation(["translation"]);
    return (
        <div>
            <div className='swap-device-header-content'>
                <div className="f-20 fw-b fc-e">{t("SWAP_DEVICE.HEADER")}</div>
                <div className='f-12 swap-device-subheader'>{t("SWAP_DEVICE.SUB_HEADER")}</div>
            </div>
            <div className='swap-device-info'>
                <div className='f-16 fw-b ta-left'>{t("SWAP_DEVICE.PREVIOUSLY_ENROLLED")}</div>
                <AddDeviceCard
                    noSelection={true}
                    imagePath={enrolledDevice.imagePath}
                    deviceName={enrolledDevice.model}
                    deviceInfo={`${t("DASHBOARD.DISPLAY_DEVICE.SERIAL_NUMBER")}: ${enrolledDevice.serialNumber}`}
                    onClickDeviceParameter="replacementDevice"
                    >
                </AddDeviceCard>
                <div className='swap-intermediate'>
                    <div className='swap-line'>
                        <div></div>
                    </div>
                    <img src={swap} alt="swap"/>
                    <div className='swap-line'>
                        <div></div>
                    </div>
                </div>
                <div className='f-16 fw-b ta-left'>{t("SWAP_DEVICE.SELECT_REPLACEMENT")}</div>
                {replacementDevices.map(device => (
                    <AddDeviceCard
                        device={device}
                        toSelectDeviceParameter={device.deviceId}
                        selectedreplacementDeviceId={selectedreplacementDeviceId}
                        onRelacementSelection={onRelacementSelection}
                        imagePath={device.imagePath}
                        deviceName={device.model}
                        deviceInfo={`${t("DASHBOARD.DISPLAY_DEVICE.SERIAL_NUMBER")}: ${device.serialNumber}`}
                        onClickDeviceParameter="replacementDevice"
                    >
                    </AddDeviceCard>
                ))}
                <AddDeviceCard
                        device={null}
                        imageclassName="swap-device-img-adddn"
                        toSelectDeviceParameter="addNewDevice"
                        selectedreplacementDeviceId={selectedreplacementDeviceId}
                        onRelacementSelection={onRelacementSelection}
                        imagePath={plus}
                        deviceName={t("SWAP_DEVICE.ADD_DEVICE")}
                        deviceInfo={t("SWAP_DEVICE.MOBILE_CONNECT")}
                        onClickDeviceParameter="addNewDevice"
                    >
                </AddDeviceCard>
                <div className='swap-device-btn'>
                <EcoProButton cname="default" disabled={selectedreplacementDeviceId?false:true} onBtnClick={() => moveToConfirmDeviceChange()}>{t('ECOPRO_BTNS.CONTINUE')}</EcoProButton>
                </div>
            </div>
        </div>
    );
};

export default DisplaySwapDevice;