import React from "react";
import rightArrow from "../../../assets/images/rightArrow.svg";
import giveFeedback from "../../../assets/images/give-feedback.svg"
import { routePath } from "../../../constants/Routes";
import { Redirect } from "react-router-dom";
import './ecoprofeedback.css';
import { useTranslation } from "react-i18next";
import EcoProOverallFeedbackModal from "../../EcoProOverallFeedbackModal/EcoProOverallFeedbackModal";
import { useState } from "react";
import Loader from "../loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { ResetUserFeedback } from "../../../actions/Dashboard";

const EcoProFeedback = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation(['translation']);
    const [isOpen, setModalOpenState] = useState(false);
    const [errorInfo, setErrInfo] = useState(null);
    const submitFeedbackResp = useSelector((state) => state.SignUpUserFeedback);
    const enrollmentStatus = useSelector(state => state.EnrollmentStatus);
    const ongiveFeedback = () => {
      setErrInfo(null);
      setModalOpenState(true);
    }
    const toggleModal = (event) => {
      setModalOpenState(!isOpen);
  }

  if(submitFeedbackResp.loading){
      return <Loader></Loader>
  }
  if(submitFeedbackResp.error_desktop){
    setErrInfo(submitFeedbackResp.error_desktop);
    dispatch(ResetUserFeedback());
  }
  if(submitFeedbackResp.success_desktop){
    setModalOpenState(false);
    dispatch(ResetUserFeedback());
    return <Redirect to={routePath.DASHBOARD}></Redirect>
  }
    return(
        <div className="feedback-menu-container">
        <div className={`feedback-menu-wrapper ${(!enrollmentStatus.enrollment_completed) ? 'navigation-disabled' : 'feedback-navigation-enabled'}`} onClick={()=>ongiveFeedback()}>
              <img src={giveFeedback} alt="give feedback"/>
            <div className="feedback-menu-content">
              <div className="feedback-menu-title">{t("DASHBOARD_FEEDBACK.MENU_TITLE")}</div>
              <div className="feedback-menu-info">{t("DASHBOARD_FEEDBACK.MENU_INFO")}</div>
            </div>
            <img src={rightArrow} alt="give feedback"/>
        </div>
        <EcoProOverallFeedbackModal isOpen={isOpen} toggleModal={toggleModal} errorInfo={errorInfo}/>
      </div>
    )
}

export default EcoProFeedback