import * as actionTypes from '../constants/ActionTypes/MyPlan'

export const fetchPlans = (deviceModel, UID, deviceID, promotion, planAction = null) => ({
    type: actionTypes.FETCH_PLANS,
    deviceModel,
    UID,
    deviceID,
    promotion,
    planAction
});

export const selectPlan = (selectedPlanDetails, deviceID = null) => ({
    type: actionTypes.SELECT_PLAN,
    selectedPlanDetails,
    deviceID
});

export const getPlansDataInLandingPage = () => ({
    type: actionTypes.FETCH_PLANS_LANDING_PAGE,
})

export const getBenefitOfSupplies = (deviceModel,promotion) => ({
    type: actionTypes.GET_BENEFIT_OF_SUPPLIES,
    deviceModel,
    promotion
})
