import React, { useState, useEffect }from "react";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import convertHtmlToReact from '@hedgedoc/html-to-react';
import { useHistory } from "react-router-dom";
import Loader from "../shared/loader/Loader";
import Payment from "../../assets/images/FAQ/creditcard-icon.svg";
import Account from "../../assets/images/FAQ/Account.svg";
import Hardware from "../../assets/images/FAQ/Hardware.svg";
import Supplies from "../../assets/images/FAQ/supplied-shipped-icon.svg";
import Other from "../../assets/images/FAQ/Others.svg";
import RightArrow from "../../assets/images/rightArrow.svg"
import "./FAQ.css";
import { routePath } from "../../constants/Routes";
import { UNIQUE_ID_GA } from "../../constants/AppConstants";
import { Container } from "reactstrap";
import { ReactComponent as ChevronLeft } from "../../assets/images/chevron_left.svg";

const FAQ = (props) => {
    const history = useHistory();
    const { t } = useTranslation(["translation"]);
    const {
        FAQCatList,
        FAQType,
        FAQContent,
        FAQMainTitle,
        translatedFAQTitle
    } = props;

    const FAQICons = {
        Payment: Payment,
        Account: Account,
        Hardware: Hardware,
        Supplies: Supplies,
        Other: Other
    }

    const [FAQDetailsTitle, setFAQDetailsTitle] = useState(null);
    const [FAQDetailContent, setFAQDetailContent] = useState(null);
    const [FAQDetailType, setFAQDetailType] = useState(null);
    const [FAQList, setFAQList] = useState([]);
    const [FAQTitle, setFAQTitle] = useState(null);

    useEffect(() => {
    switch (FAQType) {
        case "sub_list":
            if(FAQCatList){
                const title = filterData(FAQCatList, 'category_title');
                const list = filterData(FAQCatList, 'sub_category');
                setFAQList(list.sub_category);
                setFAQTitle(title.category_title);
            }
            break;
        case "content":
            if(Object.keys(FAQContent).length > 0) {
                const content = Object.values(FAQContent)[0];
                setFAQDetailsTitle(DOMPurify.sanitize(content.title));
                setFAQDetailContent(DOMPurify.sanitize(content.contents, {ADD_ATTR: ['target']}).split("\n"));
                setFAQDetailType(content.type);
            }
            break;
        default:
            if(FAQCatList && FAQCatList.hasOwnProperty('FAQ')){
                setFAQList(FAQCatList.FAQ);
            }
            break;
    }
    },[FAQCatList, FAQType, FAQContent]);

    const filterData = (data, findKey) => {
        const filtered = Object.keys(data)
            .filter(key => key === findKey)
            .reduce((obj, key) => {
                obj[key] = data[key];
                return obj;
              }, {});
            return filtered;
    }

    const FAQSubListNavigate = (FAQCategory) => {
        FAQCategory = FAQCategory.toLowerCase();
        history.push(`${routePath.HELP_SUPPORT}/${FAQCategory}`);

    }

    const FAQDetailsNavigate = (parentCategory, childCategory, translatedFAQTitle) => {
        parentCategory = parentCategory.toLowerCase();
        childCategory = childCategory.split("_").join("-").toLowerCase();
        history.push({
            pathname: `./${parentCategory}/${childCategory}`,
            state: { "translatedFAQTitle": translatedFAQTitle }
          });
    }

    const moveToPreviousPage = () => {
        window.history.go(-1);
        return false;
    };

    if(FAQType === "content" && FAQDetailContent) {
        return (
            <>
            <Container className="web-subheader desktop-app-back-btn">
                <div className="page-title-back-btn" onClick={()=>moveToPreviousPage()}>
                    <ChevronLeft fill="#545454" />
                    <div className="f-12 fw-b">{translatedFAQTitle}</div>
                </div>
            </Container>
            <div className="FQA-details-content-wrapper">
                <p>{t("FAQ_STATIC_TXT.HELP_AND_SUPPORT")}</p>
                <h1>{FAQDetailsTitle}</h1>
                <div className="content">
                    { FAQDetailType === "list" && <ul>
                        {
                            FAQDetailContent.map((content) => (
                                <li key={content}>{convertHtmlToReact(content, [true])}</li>
                            ))
                        }
                    </ul>
                    }
                    { FAQDetailType === "normal" && FAQDetailContent.map((content) => (
                            <p  key={content}>{convertHtmlToReact(content, [true])}</p>
                        ))
                    }
                </div>
            </div>
            </>
        )
    }
   if(FAQType === "sub_list" &&  FAQList && Object.keys(FAQList).length > 0) {
        return (
            <>
            <Container className="web-subheader desktop-app-back-btn">
                <div className="page-title-back-btn" onClick={()=>moveToPreviousPage()}>
                    <ChevronLeft fill="#545454" />
                    <div className="f-12 fw-b">{t("FAQ_STATIC_TXT.HELP_AND_SUPPORT")}</div>
                </div>
            </Container>
            <div className="sub-list-wrapper">
                <p className="sublist-page-title">{t("FAQ_STATIC_TXT.ANSWERS_BY_CATEGORY")}</p>
                <h1 className="faq-sublist-category-title">{FAQTitle}</h1>
                {Object.keys(FAQList).map((key, index) => (
                   
                    <div className="FQA-category-wrapper mt-40" key={key}>
                        { FAQList[key].hasOwnProperty('sub_category_title') 
                            && <h4 className="FAQ-subcat-list-title">{FAQList[key].sub_category_title}</h4>
                        }
                        {Object.values(FAQList[key].sub_content).map((catName) => (
                            <div className="FAQ-list-wrapper" key={catName.key} id={UNIQUE_ID_GA.helpAndSupport+"-"+catName.key}  onClick={() => FAQDetailsNavigate(FAQMainTitle, catName.key, FAQTitle)}>
                                <div className="FAQ-list">
                                    <div className="FAQ-list-title">
                                        <h5>{catName.title}</h5>
                                    </div>
                                </div>  
                                <div className="FAQ-list-next-arrow">
                                    <img src={RightArrow} alt="right-arrow" />
                                </div>
                            </div>
                        ))}
                    </div>
                 ))}
            </div>
            </>
        )
   }
   if(FAQType === "main_list"){
        return (
            <div className="main-list-wrapper">
                <h1>{t("FAQ_STATIC_TXT.HELP_YOU_TODAY")}</h1>
                <div className="FQA-category-wrapper mt-40">
                    <h5 className="FAQ-topcat-list-title FAQ-subcat-list-title">{t("FAQ_STATIC_TXT.BY_CATEGORY")}</h5>
            
                {
                    FAQList && Object.keys(FAQList).map((key, index) => (
                        <div className="FAQ-list-wrapper" key={key} id={UNIQUE_ID_GA.helpAndSupport+"-"+FAQList[key].category_title} onClick={() => FAQSubListNavigate(key)}>
                            <div className="FAQ-list">
                                <div className="FAQ-list-icon">
                                    <img src={FAQICons[key]} alt={FAQList[key].category_title} />
                                </div>
                                <div className="FAQ-list-title">
                                    <h5>{FAQList[key].category_title}</h5>
                                </div>
                            </div>  
                            <div className="FAQ-list-next-arrow">
                                <img src={RightArrow} alt="right-arrow"/>
                            </div>
                        </div>
                    ))
                }
                </div>
            </div>
        )
    }
    return (
        <Loader></Loader>
    )
}

export default FAQ;