import React from 'react';
import { Container } from "reactstrap";
import './DisplayContent.css';

const DisplayContent = (props) => {
    const {title,subtitle,content} = props

    return (
        <div className='display-content'>
            <Container className="legaldocs-content-wrapper">
                { title && <h1>{title}</h1> }
                { subtitle && <h2>{subtitle}</h2> }
                <div className="legaldocs-content">{content}</div>
            </Container>
        </div>
    );
};

export default DisplayContent;