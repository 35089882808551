import * as actionTypes from '../../constants/ActionTypes/Dashboard';

const initialState = {
    userSubscriptionAccountSummary: {},
    loading: false,
    error: false,
    success:false
}

const UserSubscriptionAccountSummary = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.GET_USER_SUBSCRIPTION_ACCOUNT_SUMMARY:
            return {
                ...state,
                loading: true
            }
        case actionTypes.GET_USER_SUBSCRIPTION_ACCOUNT_SUMMARY_SUCCESS:
            return {
                ...state,
                success:true,
                userSubscriptionAccountSummary: action.userSubscriptionAccountSummary,
                loading: false
            }
        case actionTypes.GET_USER_SUBSCRIPTION_ACCOUNT_SUMMARY_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message,
                success:false
            }
        default:
            return {...state};
    }
}

export default UserSubscriptionAccountSummary;