import React from "react";

const RatingStars = (props) => {
    const { ratingStarsArr, setRatings, starClicked, filledStar, unFilledStar} = props;
    return(
        <>
            {
                ratingStarsArr.map((val, key) => (
                    <div className="rating-stars" onClick={() => setRatings(key)} key={val}>
                        <img className="star" src={(starClicked[key]) ? filledStar : unFilledStar} alt={val} />
                    </div>
                ))
            }
        </>
    )
}

export default RatingStars;