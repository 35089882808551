import { getUserSessionData, getLocalSessionData } from "../Authentication/handleUserSession";
import { ECOPROTOKEN_CLAIMS, LOCALSESSIONDATAKEYS  } from "../../constants/AppConstants";
import { getPlanTranslation } from "../../constants/AppMethod";

const UpdateSubscriptionPlanPayload = (UpdatedPlanDetails) => {
    const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
    const deviceId = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID);
    const currency = getLocalSessionData(LOCALSESSIONDATAKEYS.CURRENCY);

    return {
        UserId: UID,
        DeviceId: deviceId,
        SelectedPlan: {
            planId: UpdatedPlanDetails.planId,
            discountPlanIds: UpdatedPlanDetails.discountPlanIds,
            overagePrice:UpdatedPlanDetails.overagePrice,
            currency: currency,
            price: UpdatedPlanDetails.price,
            name: UpdatedPlanDetails.name,
            description: UpdatedPlanDetails.description,
            planPages: UpdatedPlanDetails.planPages,
            planName: UpdatedPlanDetails.planName,
            translatedPlanName: (UpdatedPlanDetails.planName) ? getPlanTranslation(UpdatedPlanDetails.planName) : null,
            planGrade: UpdatedPlanDetails.planGrade,
            overageBlockPages: UpdatedPlanDetails.overageBlockPages,
            overageThreshold: UpdatedPlanDetails.overageThreshold,
            rolloverRule: UpdatedPlanDetails.rolloverRule,
            planCategory: UpdatedPlanDetails.planCategory,
            rollOverPages: UpdatedPlanDetails.rollOverPages,
            overageUnit: UpdatedPlanDetails.overageUnit,
            discount: UpdatedPlanDetails.discount
        }
    }
}

export default UpdateSubscriptionPlanPayload;