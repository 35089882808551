import { call, put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "../../constants/ActionTypes/Dashboard";
import { _CALLPUTAPI } from "../../api/apiHandler";
import { _API_ENDPOINT, SUCCESS, FAILURE } from "../../constants/AppConstants";
import i18next from "i18next";
import UpdateSubscriptionPlanPayload from "../../utils/Dashboard/UpdateSubscriptionPlanPayload";

function _UPDATEPLAN(payload) {
  const endpoint = _API_ENDPOINT.UPDATE_PLAN;

  return _CALLPUTAPI(endpoint,payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function* handleUpdatePlan(action) {
  try {
    if (action.updatedPlanDetails) {
      let payload = UpdateSubscriptionPlanPayload(action.updatedPlanDetails);
      let updatePlanSuccess = yield call(_UPDATEPLAN, payload);
      if (updatePlanSuccess.actionStatus.toUpperCase() === SUCCESS) {
        yield put({
          type: actionTypes.UPDATE_PLAN_SUCCESS,
          updatePlan: updatePlanSuccess,
        });
      } else if(updatePlanSuccess.actionStatus.toUpperCase() === FAILURE){
          if(updatePlanSuccess.customCode === "2005"){
            yield put({
              type: actionTypes.UPDATE_PLAN_ERROR,
              message: i18next.t("CHANGE_PLAN.UNABLE_TO_CHANGE_PLAN"),
            });
          }
          else if(updatePlanSuccess.customCode === "2006"){
            yield put({
              type: actionTypes.UPDATE_PLAN_ERROR,
              message: i18next.t("CHANGE_PLAN.PLAN_SAME_AS_CURRENT"),
            });
          }
          else if(updatePlanSuccess.customCode === "2004"){
            yield put({
              type: actionTypes.UPDATE_PLAN_ERROR,
              message: i18next.t("CHANGE_PLAN.NO_VALID_SUBSCRIPTION"),
            });
          }
          else{
            yield put({
              type: actionTypes.UPDATE_PLAN_ERROR,
              message: i18next.t("ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR"),
            });
          }
      }
      else{
        yield put({
          type: actionTypes.UPDATE_PLAN_ERROR,
          message: i18next.t("ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR"),
        });
      }
    } else {
      yield put({
        type: actionTypes.UPDATE_PLAN_ERROR,
        message: i18next.t("ERROR_CONTENT.INFO_GENERAL"),
      });
    }
  } catch (e) {
    /**
     * This conditional statement is added to avoid
     * displaying error message in view while logout from app
     */
     if(e instanceof TypeError === false && e.message !== "401"){
      yield put({type: actionTypes.UPDATE_PLAN_ERROR, message: e.message});
     }
  }
}

function* UpdatePlan() {
  yield takeEvery(actionTypes.UPDATE_PLAN, handleUpdatePlan);
}

export default UpdatePlan;
