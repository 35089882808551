import React from "react";
import { ReactComponent as RightArrowImg } from "../../assets/images/rightArrow.svg";

const UpdateOrCancelCard = (props) => {
  const { header, moveToEditPage, pointer, account } = props
  return (
    <div
      className={`row my-account-content ${pointer === false?null:'pointer-cursor'} update-personal
      ${account === "changeDevice"?'change-device-card':null}`}
      onClick={() => moveToEditPage()}
    >
      <div className="col-10">
        <div className="f-14 ta-left">{header}</div>
      </div>
      <div className="col my-account-edit-btn">
        <RightArrowImg
          className={account === 'changeDevice' ? "right-arrow-white":null}
        />
      </div>
    </div>
  );
};

export default UpdateOrCancelCard;
