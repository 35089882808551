import { call, put, takeEvery } from 'redux-saga/effects';
import i18next from "i18next";
import * as actionTypes from '../../../constants/ActionTypes/CSAgentPortal/CustomerDashboard';
import { _CALLPUTAPI } from '../../../api/apiHandler'
import { FAILURE, USER_ACTIVITY_LOGS, _CSAGENT_API_ENDPOINTS } from '../../../constants/AppConstants';
import UserActivityLogsPayload from '../../../utils/AdminPortal/UserActivityLogsPayload';

async function _CALLUPDATEORDERCONSUMABLESAPI(customerID,customerDeviceId,orderType,consumablesData){
    const endpoint = _CSAGENT_API_ENDPOINTS.UPDATE_ORDER_CONSUMABLES;
    const logDetails = {
        action: USER_ACTIVITY_LOGS.UPDATE_SUPPLY_ORDER_CONSUMABLES,
        description: `Ordered consumables: ${consumablesData.toString()} as order type:${orderType} for userID: ${customerID} and deviceId: ${customerDeviceId}`
    }
    const payload = {
        userId: customerID,
        deviceId: customerDeviceId,
        orderType: orderType,
        consumables: consumablesData,
        servicePortalUser: await UserActivityLogsPayload(logDetails)
      }
    return _CALLPUTAPI(endpoint,payload)
        .then((response) => {
        return response;
        })
        .catch((error) => {
        throw error;
        });
}

function* handleUpdateOrderConsumables(action){
    try {
        
        const customerId = action.customerID;
        const customerDeviceId = action.customerDeviceId;
        const orderType = "free"
        const consumablesData = action.consumablesData
        if(customerId && customerDeviceId && consumablesData){
            const updateOrderConsumables =  yield call(_CALLUPDATEORDERCONSUMABLESAPI, customerId, customerDeviceId,orderType,consumablesData);
           if(updateOrderConsumables.actionStatus.toUpperCase() !== FAILURE){
                yield put({type: actionTypes.UPDATE_ORDER_CONSUMABLES_SUCCESS, updateOrderConsumables: updateOrderConsumables});
           } else {
                yield put({
                    type: actionTypes.UPDATE_ORDER_CONSUMABLES_ERROR, 
                    message: i18next.t('CSAgenttranslation:CS_AGENT_ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')
                });
           }
        } else {
            yield put({
                type: actionTypes.UPDATE_ORDER_CONSUMABLES_ERROR, 
                message: i18next.t('CSAgenttranslation:CS_AGENT_ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')
            });
        }
    } catch (e) {
        yield put({type: actionTypes.UPDATE_ORDER_CONSUMABLES_ERROR, message: e.message});
    }
}

function* UpdateOrderConsumables(){
    yield takeEvery(actionTypes.UPDATE_ORDER_CONSUMABLES, handleUpdateOrderConsumables);
}

export default UpdateOrderConsumables;