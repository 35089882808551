import * as actionTypes from '../../constants/ActionTypes/MyAccount';

const initialState = {
  'subscriptionDetails': {},
  'success': false,
  'loading': false,
  'error': false
}

const ManageSubscription = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ACCOUNT_MANAGE_SUBSCRIPTION_INIT:
          return {
            ...state,
            loading: true,
          };
        case actionTypes.ACCOUNT_MANAGE_SUBSCRIPTION_SUCCESS:
        return {
          ...state,
          subscriptionDetails: action.subscriptionDetails,
          success: true,
          loading: false,
        };
        case actionTypes.ACCOUNT_MANAGE_SUBSCRIPTION_ERROR:
        return {
            ...state,
          success: false,
          loading: false,
          error: action.message
        };
        case actionTypes.MANAGE_SUBSCRIPTION_RESET_STATE:
          return { ...state, 
            success: false,
            error:'',
            subscriptionDetails: {},
          };
        default:
          return { ...state };
      }
}

export default ManageSubscription;