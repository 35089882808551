import * as actionTypes from '../../constants/ActionTypes/UserAccount';

const initialState = {
    userAccount:{},
    loading: false,
    success: false,
    error: false,
    redirectToLandingPage:false,
    redirectPage: null
}

const UserAccount = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_USER_ACCOUNT:
            return {
                ...state,
                loading: true
            }
        
        case actionTypes.FETCH_USER_ACCOUNT_SUCCESS:
            return {
                ...state,
                userAccount: action.userAccount,
                success: true,
                loading: false,
                redirectToLandingPage:false,
                redirectPage: null,
            }
        
        case actionTypes.FETCH_USER_ACCOUNT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message,
                redirectToLandingPage:action.redirectToLandingPage,
                redirectPage: action.redirectPage
            }
        case actionTypes.RESET_WEB_CONNECTION_API_DATA:
            return {
                ...state,
                'success': false,
                'loading': false,
                'error': false,
                'userAccount': {}
            }
        case actionTypes.RESET_CODE_SUBMISSION_DATA:
            return {
                ...state,
                'success': false,
                'loading': false,
                'error': false,
                'bocDeviceCapability': null
            }
        case actionTypes.FETCH_USER_ACCOUNT_RESET:
            return initialState
        default:
            return {...state}
    }
}

export default UserAccount
