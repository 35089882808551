import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import FormInput from "../../../../components/shared/formElements/FormInput";
import SubHeader from "../../../../components/shared/subheader/SubHeader";
import { checkEcoproSupportRegion, compareObjects, findMobileError, findzipCodeError, getPersonalOrBusinessTerms, postalCode_ErrorMsg, pushDataLayer, trimAddressFieldsLength, validateEmail, validateProvinceData } from "../../../../constants/AppMethod";
import FormSelect from "../../../../components/shared/formElements/FormSelect";
import { 
  changeAccountStatus, 
  showZipCodeError, 
  showMobileError, showEmailError, 
  showSDIError} from '../../../../actions/MyAccount';
import ErrorPage from "../../../../components/shared/errorPage/ErrorPage";
import { 
  refreshBtn, 
  tryAgainBtn, 
  LOCALSESSIONDATAKEYS, 
  UNIQUE_ID_GA, 
  _API_ENDPOINT, 
  SUCCESS, 
  BILLING_ADDR_ADDRESS1_INPUT_MAX_LENGTH, 
  ADDRESS_FIELDS_INPUT_MAX_LENGTH,
  COUNTRIES_WITH_LOCAL_TNC,
  alphaNumericRegex} from "../../../../constants/AppConstants";
import FormTel from "../../../../components/shared/formElements/FormTel";
import { useHistory } from "react-router-dom";
import FormBrother from "../../../../components/Form/FormBrother";
import { getUserData, getUserDataByKey, getLocalSessionData } from "../../../../utils/Authentication/handleUserSession";
import { routePath } from "../../../../constants/Routes";
import { ENVSettings , EXTERNAL_LINK_ENDPOINT } from "../../../../constants/ENVSettings";
import { getCountryCode } from "../../../../utils/Internationalization/handleAppInternationalization";
import { _CALLGETAPI } from "../../../../api/apiHandler";
import { ItalyProvinces } from "../../../../constants/Provinces/Italy";

const Account = ({className}) => {
  const userData = getUserData();
  const countryCode = getCountryCode();
  const signup_privacyPolicy = `${window.env[`BOL_BASEURL_${countryCode}`]}${EXTERNAL_LINK_ENDPOINT.signup_privacyPolicy}`;
  const termsAndConditionLink = getPersonalOrBusinessTerms()
  let initialPersonalDetails = {
    firstName: "",
    lastName: "",
    emailAddress: "",
    telephoneNumber: "",
    mobileNumber: "",
    offers: false,
  };
  let initialShippingAddress = {
    address: "",
    addressLine2: "",
    city: "",
    country: "",
    postcode: "",
    company: "",
    province:""
  };
  let initialBillingAddress = {
    address: "",
    addressLine2: "",
    city: "",
    country: "",
    postcode: "",
    company: "",
    eInvoiceId:"",
    sameAsShippingAddress: false,
    termsAndPrivacy: false,
    province: ""
  };

  if(userData && userData['personal_details']){
    initialPersonalDetails = {...initialPersonalDetails,
      firstName: getUserDataByKey('firstName', 'personal_details'),
      lastName:  getUserDataByKey('lastName', 'personal_details'),
      emailAddress:  getUserDataByKey('emailAddress', 'personal_details'),
      telephoneNumber:  getUserDataByKey('telephoneNumber', 'personal_details'),
      mobileNumber:  getUserDataByKey('mobileNumber', 'personal_details')
    }
  }
  if(userData && userData['billing_address']){
    initialBillingAddress = {...initialBillingAddress,
      address: trimAddressFieldsLength(getUserDataByKey('addressLine1', 'billing_address'), BILLING_ADDR_ADDRESS1_INPUT_MAX_LENGTH),
      addressLine2: trimAddressFieldsLength(getUserDataByKey('addressLine2', 'billing_address'), ADDRESS_FIELDS_INPUT_MAX_LENGTH),
      city: getUserDataByKey('cityTown', 'billing_address'),
      country: getUserDataByKey('country', 'billing_address'),
      postcode:getUserDataByKey('postCode', 'billing_address'),
      company: getUserDataByKey('companyName', 'billing_address'),
      eInvoiceId: getUserDataByKey('eInvoiceId', 'billing_address'),
      province: getUserDataByKey('state', 'billing_address'),
      sameAsShippingAddress: compareObjects(userData['billing_address'], userData['shipping_address'])? true : false
    }
}
if(userData && userData['shipping_address'] && userData['shipping_address']?.addressLine1){
  initialShippingAddress = {...initialShippingAddress,
    address: getUserDataByKey('addressLine1', 'shipping_address'),
    addressLine2: trimAddressFieldsLength(getUserDataByKey('addressLine2', 'shipping_address'), ADDRESS_FIELDS_INPUT_MAX_LENGTH),
    city: getUserDataByKey('cityTown', 'shipping_address'),
    country: getUserDataByKey('country', 'shipping_address'),
    postcode:getUserDataByKey('postCode', 'shipping_address'),
    company: getUserDataByKey('companyName', 'shipping_address'),
    province: getUserDataByKey('state', 'shipping_address'),
  }
}
else{
  initialShippingAddress = {...initialShippingAddress,
    address: getUserDataByKey('addressLine1', 'billing_address'),
    addressLine2: trimAddressFieldsLength(getUserDataByKey('addressLine2', 'billing_address'), ADDRESS_FIELDS_INPUT_MAX_LENGTH),
    city: getUserDataByKey('cityTown', 'billing_address'),
    country: getUserDataByKey('country', 'billing_address'),
    postcode:getUserDataByKey('postCode', 'billing_address'),
    company: getUserDataByKey('companyName', 'billing_address'),
    province: getUserDataByKey('state', 'billing_address'),
  }
}
  const { t } = useTranslation(["translation"]);
  const history = useHistory()
  const dispatch = useDispatch();
  const SelectedPlan = useSelector(state => state.MyPlan);
  const [validate, setValidate] = useState(false);
  const [personalDetailsI,setPersonalDetailsI] = useState(initialPersonalDetails)
  const [shippingAddressI,setShippingAddressI] = useState(initialShippingAddress)
  const [billingAddressI,setBillingAddressI] = useState(initialBillingAddress)
  const [regionUnSupportedZipError,setRegionUnSupportedZipError] = useState(false)
  const [dropdownSelectedCountry, setDropdownSelectedCountry] = useState("")
  const [loading, setLoading] = useState(false);
  const result = useSelector((state) => state.MyAccount);

  const displayAdditionalCheckbox = COUNTRIES_WITH_LOCAL_TNC.includes(getCountryCode().toUpperCase()) ? t("FORM_CONTENT.FORM_LABEL.CHECK_LOCAL_TERMS"):null
  let checkBoxContents = {
    "termsAndPrivacy": <Trans i18nKey="FORM_CONTENT.FORM_LABEL.CHECK_TERMS">
    I agree to the 
    <a href={termsAndConditionLink} target = "_blank"  rel="noreferrer" id={UNIQUE_ID_GA.signup+"-terms-condition"}>EcoPro terms and conditions</a>. 
    Your information will be processed in accordance with our 
    <a href={signup_privacyPolicy} target = '_blank'  rel="noreferrer" id={UNIQUE_ID_GA.signup+"-privacy-policy"}>privacy policy</a>.
  </Trans>,
    "topupFees": t("FORM_CONTENT.FORM_LABEL.CHECK_TOPUP_FEE"),
  };
  if(displayAdditionalCheckbox){
    checkBoxContents.localTerms= displayAdditionalCheckbox
  }
  const offersContent = { 
    "offers" : <Trans i18nKey="FORM_CONTENT.FORM_LABEL.CHECK_OFFERS">
      I agree that the Brother Group may send me information about its offers, products and services by email and telephone in accordance with our privacy policy. For further information:
      <a href="https://www.brother.fr/a-propos-de-brother/politique-de-confidentialite" target = "_blank"  rel="noreferrer">https://www.brother.fr/a-propos-de-brother/politique-de-confidentialite</a>. 
    </Trans>}
  useEffect(()=>{
    if(result.account.personalDetails.firstName.length>0){
      setPersonalDetailsI(result.account.personalDetails)
    }
    if(result?.account?.shippingAddress?.address?.length>0){
      setShippingAddressI(result.account.shippingAddress)
    }
    if(result.account.billingAddress.address.length>0){
      setBillingAddressI(result.account.billingAddress)
    }
  },[result])

  const submit = (e,formVal, accountClicked) => {
    setRegionUnSupportedZipError(false)
    e.preventDefault()
    let status = result.accountStatus;
    let formData = result.account;
    let forms = document.querySelectorAll(".needs-validation");
    let formValidity, zipError, mobileError, emailError, telephoneError, sdiError;

    Array.prototype.slice.call(forms).forEach((form) => {
      if (!form.checkValidity()) {
        formValidity = true;
      }
      formVal.province = validateProvinceData(formVal);
      if (formVal.country && formVal.postcode) {
        if (findzipCodeError(formVal.country, formVal.postcode)) {
          zipError = true;
          dispatch(showZipCodeError(true));
        } else {
          zipError = false;
          dispatch(showZipCodeError(false));
        }
      }
      if (formVal.hasOwnProperty("mobileNumber")) {
        if (
          findMobileError(formVal.mobileNumber) ||
          formVal?.mobileNumber?.length === 0
        ) {
          mobileError = true;
          dispatch(showMobileError("mobile",true));
        } else {
          mobileError = false;
          dispatch(showMobileError("mobile",false));
        }
      }
      if (formVal.hasOwnProperty("telephoneNumber")  &&
      formVal.telephoneNumber !== undefined ) {
        if (
          findMobileError(formVal.telephoneNumber) && formVal.telephoneNumber.length > 0
        ) {
          telephoneError = true;
          dispatch(showMobileError("telephone", true));
        } else {
          telephoneError = false;
          dispatch(showMobileError("telephone", false));
        }
      }
      if(formVal.hasOwnProperty("emailAddress")){
        if (
          validateEmail(formVal.emailAddress) ||
          formVal.emailAddress.length === 0
        ) {
          emailError = true;
          dispatch(showEmailError(true));
        } else {
          emailError = false;
          dispatch(showEmailError(false));
        }
      }
      if(formVal.hasOwnProperty("eInvoiceId") && formVal.eInvoiceId) {
        const hasSpaces = (str) => /\s/.test(str);
        const hasInvalidChars = (str) => !alphaNumericRegex.test(str); // Check for spaces or special characters
        if (hasInvalidChars(formVal.eInvoiceId) || hasSpaces(formVal.eInvoiceId)) {
          sdiError = true;
          dispatch(showSDIError(true));
        } else {
          sdiError = false;
          dispatch(showSDIError(false));
        }
      }
    });

    if (
      formValidity === true 
      || zipError === true 
      || mobileError === true 
      || emailError === true 
      || telephoneError === true
      || sdiError === true) {
      setValidate(true);
    } else {
      setValidate(false);
      if (accountClicked === "personalDetails") {
        pushDataLayer('add_personal_info')
        status["personalDetails"] = "completed";
        status["shippingAddress"] = "active";
        formData.personalDetails = formVal;
      }
      if (accountClicked === "shippingAddress") {
        formData.shippingAddress = formVal;
        const countryCode2 = ENVSettings.COUNTRIES.find(item => item.name === formVal.country);
        const endpoint = _API_ENDPOINT.COUNTRY_POST_CODE;
        const payload = {
          params: {
              countryCode: countryCode2.code2
          }
        }
        setLoading(true)
        _CALLGETAPI(endpoint, payload).then((response) => {
          setLoading(false);
            if(response?.actionStatus?.toUpperCase() === SUCCESS){
              const isRegionNotSupportedForDelivery = checkEcoproSupportRegion(formVal.postcode,response.postCodes)
              if(isRegionNotSupportedForDelivery){
                zipError = true;
                setRegionUnSupportedZipError(true)
                setDropdownSelectedCountry(countryCode2.code2)
                dispatch(showZipCodeError(true));
                
              }
              else{
                pushDataLayer('add_shipping_info')
                status["shippingAddress"] = "completed";
                status["billingAddress"] = "active";
                formData.shippingAddress = formVal;
                dispatch(changeAccountStatus(status, formData));
              }
            }
            else{
              pushDataLayer('add_shipping_info')
              status["shippingAddress"] = "completed";
              status["billingAddress"] = "active";
              formData.shippingAddress = formVal;
              dispatch(changeAccountStatus(status, formData));
            }
        });
      }
      if (accountClicked === "billingAddress") {
        pushDataLayer('add_billing_info')
        status["billingAddress"] = "completed";
        formData.billingAddress = formVal;
        history.push(routePath.PAYMENT);
      }
      dispatch(changeAccountStatus(status, formData));
    }
  };

  const showForm = (accountClicked) => {
    let status = result.accountStatus;
    if (accountClicked === "personalDetails") {
      status["personalDetails"] = "active";
      status["shippingAddress"] = "inactive";
      status["billingAddress"] = "inactive";
    }
    if (accountClicked === "shippingAddress") {
      status["personalDetails"] = "completed";
      status["shippingAddress"] = "active";
      status["billingAddress"] = "inactive";
    }
    if (accountClicked === "billingAddress") {
      status["personalDetails"] = "completed";
      status["shippingAddress"] = "completed";
      status["billingAddress"] = "active";
    }
    dispatch(changeAccountStatus(status, result.account));
  };
  if(Object.keys(SelectedPlan.selected_plan).length === 0 && className==="mobile-forms"){
    return <ErrorPage errorInfo={t("ERROR_CONTENT.ERR_INFO.SELECT_A_PLAN")} showRefreshBtn={refreshBtn.HIDE} showTryAgain={tryAgainBtn.SHOW} to={routePath.SHENLONG_REDIRECT}></ErrorPage>
  }
  return (
    <div className={`mt-40 ${className?className:""}`}>
      <SubHeader
          currentStep="3"
          totalStep="4"
          stepName={t("SUB_HEADER.STEP_3.STEP_NAME")}
          stepInfo={t("SUB_HEADER.STEP_3.STEP_INFO")}
        />
      <div>
        <FormBrother
          submit={submit}
          showForm={showForm}
          checkboxStatus={true}
          account="personalDetails"
          status={result.accountStatus.personalDetails}
          formData={result.account.personalDetails}
          title={t("FORM_CONTENT.FORM_TITLE.PERSONAL_DETAILS")}
          wasValidated={validate}
          checkboxLabel={offersContent}
          initialValues={personalDetailsI}
          btnTitle={t('ECOPRO_BTNS.NEXT')}
          btnID="personal_info_done"
          action="personalDetails_add"
        >
          <FormInput
            bsClassName="col-md-6"
            label={t("FORM_CONTENT.FORM_LABEL.FIRST_NAME")}
            name="firstName"
            id="firstName"
            placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.FIRST_NAME")}
            required={true}
            errorMsg={t("FORM_CONTENT.FORM_ERROR.FIRST_NAME")}
            minLength={1}
            maxLength={100}
            readOnly={personalDetailsI.firstName && "readonly"}
          />
          <FormInput
            bsClassName="col-md-6"
            label={t("FORM_CONTENT.FORM_LABEL.LAST_NAME")}
            name="lastName"
            id="lastName"
            placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.LAST_NAME")}
            required={true}
            errorMsg={t("FORM_CONTENT.FORM_ERROR.LAST_NAME")}
            minLength={1}
            maxLength={100}
            readOnly={personalDetailsI.lastName && "readonly"}
          />
          <FormInput
            bsClassName="col-12"
            label={t("FORM_CONTENT.FORM_LABEL.EMAIL")}
            name="emailAddress"
            id="emailAddress"
            placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.EMAIL")}
            required={true}
            errorMsg={t("FORM_CONTENT.FORM_ERROR.EMAIL")}
            minLength={1}
            maxLength={80}
            readOnly={personalDetailsI.emailAddress && "readonly"}
          />
          <FormTel
            bsClassName="col-12"
            label={t("FORM_CONTENT.FORM_LABEL.MOBILE")}
            name="mobileNumber"
            id="phone"
            placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.MOBILE")}
            required={true}
            errorMsg={t("FORM_CONTENT.FORM_ERROR.MOBILE")}
            defaultCountry={getLocalSessionData(LOCALSESSIONDATAKEYS.ECOPROCTRYCODE)}
          />
          <FormTel
            bsClassName="col-12"
            label={t("FORM_CONTENT.FORM_LABEL.TELEPHONE")}
            name="telephoneNumber"
            id="telephoneNumber"
            placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.TELEPHONE")}
            required={false}
            errorMsg={t("FORM_CONTENT.FORM_ERROR.TELEPHONE")}
            defaultCountry={getLocalSessionData(LOCALSESSIONDATAKEYS.ECOPROCTRYCODE)}
          />
        </FormBrother>
      </div>
      <div className="mt-10">
        <FormBrother
          submit={submit}
          showForm={showForm}
          account="shippingAddress"
          status={result.accountStatus.shippingAddress}
          formData={result.account.shippingAddress}
          title={t("FORM_CONTENT.FORM_TITLE.SHIPPING_ADDR")}
          wasValidated={validate}
          initialValues={shippingAddressI}
          btnTitle={t('ECOPRO_BTNS.NEXT')}
          btnID="shipping_info_done"
          action="shippingAddress_add"
        >
          <FormInput
            bsClassName="col-12"
            label={t("FORM_CONTENT.FORM_LABEL.ADDRESS", {maxlength: ADDRESS_FIELDS_INPUT_MAX_LENGTH})}
            name="address"
            id="address"
            placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.ADDRESS")}
            required={true}
            errorMsg={t("FORM_CONTENT.FORM_ERROR.ADDRESS")}
            minLength={1}
            maxLength={ADDRESS_FIELDS_INPUT_MAX_LENGTH}
          />
          <FormInput
            bsClassName="col-12"
            label={t("FORM_CONTENT.FORM_LABEL.ADDRESS_LINE_2_OPT", {maxlength: ADDRESS_FIELDS_INPUT_MAX_LENGTH})}
            name="addressLine2"
            id={countryCode === "IT"? "italyaddressLine2" : "addressLine2"}
            placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.ADDRESS_LINE_2_OPT")}
            required={false}
            minLength={0}
            maxLength={ADDRESS_FIELDS_INPUT_MAX_LENGTH}
          />
          <FormInput
            bsClassName="col-md-6"
            label={t("FORM_CONTENT.FORM_LABEL.TOWN/CITY")}
            name="city"
            id="city"
            placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.TOWN/CITY")}
            required={true}
            errorMsg={t("FORM_CONTENT.FORM_ERROR.TOWN/CITY")}
            minLength={1}
            maxLength={40}
          />
          <FormSelect
            bsClassName="col-md-6"
            label={t("FORM_CONTENT.FORM_LABEL.COUNTRY")}
            name="country"
            id="country"
            placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.COUNTRY")}
            required={true}
            errorMsg={t("FORM_CONTENT.FORM_ERROR.COUNTRY")}
            minLength={1}
            maxLength={100}
            optionData={ENVSettings.COUNTRIES}
          />
          <FormSelect
            bsClassName="col-md-12"
            label={t("FORM_CONTENT.FORM_LABEL.PROVINCE")}
            name="province"
            id="province"
            placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.PROVINCE")}
            required={true}
            errorMsg={t("FORM_CONTENT.FORM_ERROR.PROVINCE")}            
            minLength={1}
            maxLength={100}
            optionData={ItalyProvinces}
          />
          <FormInput
            bsClassName="col-12"
            label={t("FORM_CONTENT.FORM_LABEL.POSTCODE")}
            name="postcode"
            id="postcode"
            placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.POSTCODE")}
            required={true}
            errorMsg={postalCode_ErrorMsg(result.showZipError, regionUnSupportedZipError, dropdownSelectedCountry)}
            minLength={1}
            maxLength={20}
            postCodeLoading = {loading}
          />
          <FormInput
            bsClassName="col-12"
            label={t("FORM_CONTENT.FORM_LABEL.COMPANY_OPT")}
            name="company"
            id="company"
            placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.COMPANY_OPT")}
            required={false}
            minLength={0}
            maxLength={100}
          />
        </FormBrother>
      </div>
      <div className="mt-10">
        <FormBrother
          submit={submit}
          showForm={showForm}
          account="billingAddress"
          status={result.accountStatus.billingAddress}
          formData={result.account.billingAddress}
          title={t("FORM_CONTENT.FORM_TITLE.BILLING_ADDR")}
          wasValidated={validate}
          checkboxStatus={true}
          checkboxLabel={checkBoxContents}
          initialValues={billingAddressI}
          btnTitle={t('ECOPRO_BTNS.NEXT')}
          btnID="billing_info_done"
          action="billingAddress_add"
        >
          <FormInput
            bsClassName="col-12"
            label={t("FORM_CONTENT.FORM_LABEL.ADDRESS", {maxlength: BILLING_ADDR_ADDRESS1_INPUT_MAX_LENGTH})}
            name="address"
            id="address"
            placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.ADDRESS")}
            required={true}
            errorMsg={t("FORM_CONTENT.FORM_ERROR.ADDRESS")}
            minLength={1}
            maxLength={BILLING_ADDR_ADDRESS1_INPUT_MAX_LENGTH}
          />
          <FormInput
            bsClassName="col12"
            label={t("FORM_CONTENT.FORM_LABEL.ADDRESS_LINE_2_OPT", {maxlength: ADDRESS_FIELDS_INPUT_MAX_LENGTH})}
            name="addressLine2"
            id={countryCode === "IT"? "italyaddressLine2" : "addressLine2"}
            placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.ADDRESS_LINE_2_OPT")}
            required={false}
            minLength={0}
            maxLength={ADDRESS_FIELDS_INPUT_MAX_LENGTH}
          />
          <FormInput
            bsClassName="col-md-6"
            label={t("FORM_CONTENT.FORM_LABEL.TOWN/CITY")}
            name="city"
            id="city"
            placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.TOWN/CITY")}
            required={true}
            errorMsg={t("FORM_CONTENT.FORM_ERROR.TOWN/CITY")}
            minLength={1}
            maxLength={40}
          />
          <FormSelect
            bsClassName="col-md-6"
            label={t("FORM_CONTENT.FORM_LABEL.COUNTRY")}
            name="country"
            id="country"
            placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.COUNTRY")}
            required={true}
            errorMsg={t("FORM_CONTENT.FORM_ERROR.COUNTRY")}
            minLength={1}
            maxLength={100}
            optionData={ENVSettings.COUNTRIES}
          />
          <FormSelect
            bsClassName="col-md-12"
            label={t("FORM_CONTENT.FORM_LABEL.PROVINCE")}
            name="province"
            id="province"
            placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.PROVINCE")}
            required={true}
            errorMsg={t("FORM_CONTENT.FORM_ERROR.PROVINCE")}            
            minLength={1}
            maxLength={100}
            optionData={ItalyProvinces}
          />
          <FormInput
            bsClassName="col-12"
            label={t("FORM_CONTENT.FORM_LABEL.POSTCODE")}
            name="postcode"
            id="postcode"
            placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.POSTCODE")}
            required={true}
            errorMsg={
              result.showZipError === true
                ? t("FORM_CONTENT.FORM_ERROR.POSTCODE_VALID")
                : t("FORM_CONTENT.FORM_ERROR.POSTCODE_EMPTY")
            }
            minLength={1}
            maxLength={20}
          />
          <FormInput
            bsClassName="col-12"
            label={t("FORM_CONTENT.FORM_LABEL.COMPANY_OPT")}
            name="company"
            id="company"
            placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.COMPANY_OPT")}
            required={false}
            minLength={0}
            maxLength={100}
            requiredLabel={t("FORM_CONTENT.FORM_LABEL.COMPANY")}
            errorMsg={t("FORM_CONTENT.FORM_ERROR.COMPANY")}
          />
          <FormInput
            bsClassName="col-12"
            label={t("FORM_CONTENT.FORM_LABEL.SDI")}
            name="eInvoiceId"
            id="eInvoiceId"
            placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.SDI")}
            required={true}
            errorMsg={t("FORM_CONTENT.FORM_ERROR.SDI")}
            minLength={7}
            maxLength={7}
          />
        </FormBrother>
      </div>
    </div>
  );
};

export default Account;
