import {combineReducers} from 'redux';
import MyPlan from './MyPlan';
import ZuoraPaymentInfoRendering from './MyAccount/ZuoraPaymentInfoRendering';
import MyAccount from './MyAccount/MyAccount'
import ManageSubscription from './MyAccount/ManageSubscription';
import Enrollment from './MyAccount/Enrollment';
import DashboardDeviceInfo from './Dashboard/DeviceInfo';
import DashboardOrderHistory from './Dashboard/OrderHistory';
import FAQTopCategories from './FAQ/FetchTopCategory';
import FAQSublist from './FAQ/FetchSublist';
import FAQContent from './FAQ/FetchContent';
import UpdateShipping from './Dashboard/UpdateShipping';
import SelectDevice from './DeviceInfo/SelectDevice';
import BOLAPI from './Signin/BOLAPI';
import FetchMyAccount from './Dashboard/FetchMyAccount';
import UserAccount from './UserAccount/UserAccount';
import CancelOrder from './Dashboard/CancelOrder';
import CarbonSaving from './CarbonSaving/CarbonSaving';
import SwapDevice from './Dashboard/SwapDevice';
import DeviceCapability from './DeviceInfo/DeviceCapability';
import DownloadOrderInvoice from './Dashboard/DownloadOrderInvoice';
import UpdatePlan from './Dashboard/UpdatePlan';
import PrinterModels from './Web/Customer/PrinterModels';
import WebServicePlan from './Web/Customer/ServicePlan';
import UpdateADB2CNewUserAccount from './Web/Customer/UpdateADB2CNewUserAccount';
import PreEnrollment from './Web/Customer/PreEnrollment';
import UserSubscriptionInfo from './Web/Customer/UserSubscriptionInfo';
import GetPreEnrollmentInfo from './Web/Customer/GetPreEnrollmentInfo';
import VerifyPreEnrollmentInfo from './Web/Customer/VerifyPreEnrollmentInfo';
import PrinterTypes from './Web/Customer/PrinterTypes';
import SignUpUserFeedback from './Dashboard/SignUpUserFeedback';
import PaymentOptions from './MyAccount/PaymentOptions';
import UserSubscriptionAccountSummary from './Dashboard/UserAccountSubscriptionSummary';
import GetUserBOCIdByADB2CId from './Web/Customer/GetUserBOCIdByADB2CId';
import GetUserPaymentDetails from './Web/Customer/GetUserPaymentDetails';
import PrintActivity from './PrintActivity/PrintActivity';
import adminReducerIndex from './adminReducerIndex';
import csAgentReducerIndex from './csAgentReducerIndex';
import PlansLandingPage from './PlansLandingPage';
import UpdateCustomerProfile from './MyAccount/UpdateCustomerProfile';
import DeviceRegistration from './DeviceInfo/DeviceRegistration';
import GetDashboardPreEnrollInfo from './Web/Customer/GetDashboardPreEnrollInfo';
import GetBenefitOfSupplies from './GetBenefitOfSupplies';
import EnrollmentStatus from './Dashboard/EnrollmentStatus';
import AsyncEnrollmentRetry from './Dashboard/AsyncEnrollmentRetry';
import GetPromotionBannerStatus from './Web/Customer/GetPromotionBannerStatus';
import GetRegistrationType from './Web/Customer/GetRegistrationType';
import SCMSTokenAuthentication from './Web/Customer/SCMSTokenAuthentication';
import GetDeviceRegistration from './Web/Customer/GetDeviceRegistration';
import GetBOCWebDeviceCapability from './Web/Customer/GetBOCWebDeviceCapability';
import HWInclusive_ManageInstantPayment from './HardwareInclusive/HWInclusive_ManageInstantPayment';
import GetHWPrinterFeaturesByPrinterType from './HardwareInclusive/GetHWPrinterFeaturesByPrinterType';
import GetHWServicePlan from './HardwareInclusive/GetHWServicePlan';
import GetHWPrinterByFeatures from './HardwareInclusive/GetHWPrinterByFeatures';
import GetUserSubscription from './Dashboard/GetUserSubscription';
import GetCancellationFees from './Dashboard/GetCancellationFees';
import brotherPlusReducerIndex from '../store/brotherPlusReducerIndex';
import GetCancelSubscriptionStatus from './Dashboard/GetCancelSubscriptionStatus';
import ReactivateSubscription from './Dashboard/ReactivateSubscription';
import CSAgentPortalReducerRTKIndex from '../store/CSAgentPortalReducerRTKIndex';

const appReducer = combineReducers({
  MyPlan: MyPlan,
  ZuoraPaymentInfoRendering: ZuoraPaymentInfoRendering,
  MyAccount:MyAccount,
  ManageSubscription: ManageSubscription,
  Enrollment: Enrollment,
  DashboardDeviceInfo: DashboardDeviceInfo,
  DashboardOrderHistory: DashboardOrderHistory,
  FAQTopCategories: FAQTopCategories,
  FAQSublist: FAQSublist,
  FAQContent: FAQContent,
  FetchMyAccount:FetchMyAccount,
  UpdateShipping:UpdateShipping,
  SelectDevice:SelectDevice,
  BOLAPI: BOLAPI,
  UserAccount:UserAccount,
  CancelOrder:CancelOrder,
  CarbonSaving:CarbonSaving,
  SwapDevice:SwapDevice,
  DeviceCapability:DeviceCapability,
  DownloadOrderInvoice:DownloadOrderInvoice,
  UpdatePlan:UpdatePlan,
  PrinterTypes: PrinterTypes,
  PrinterModels: PrinterModels,
  WebServicePlan: WebServicePlan,
  UpdateADB2CNewUserAccount: UpdateADB2CNewUserAccount,
  PreEnrollment: PreEnrollment,
  UserSubscriptionInfo: UserSubscriptionInfo,
  GetPreEnrollmentInfo: GetPreEnrollmentInfo,
  VerifyPreEnrollmentInfo: VerifyPreEnrollmentInfo,
  SignUpUserFeedback:SignUpUserFeedback,
  PaymentOptions: PaymentOptions,
  UserSubscriptionAccountSummary:UserSubscriptionAccountSummary,
  GetUserBOCIdByADB2CId: GetUserBOCIdByADB2CId,
  GetUserPaymentDetails: GetUserPaymentDetails,
  PrintActivity: PrintActivity,
  PlansLandingPage: PlansLandingPage,
  UpdateCustomerProfile: UpdateCustomerProfile,
  DeviceRegistration: DeviceRegistration,
  GetDashboardPreEnrollInfo: GetDashboardPreEnrollInfo,
  GetBenefitOfSupplies: GetBenefitOfSupplies,
  EnrollmentStatus: EnrollmentStatus,
  AsyncEnrollmentRetry: AsyncEnrollmentRetry,
  GetPromotionBannerStatus: GetPromotionBannerStatus,
  ADMIN: adminReducerIndex,
  CSAGENT: csAgentReducerIndex,
  GetRegistrationType: GetRegistrationType,
  GetDeviceRegistration: GetDeviceRegistration,
  SCMSTokenAuthentication: SCMSTokenAuthentication,
  GetBOCWebDeviceCapability: GetBOCWebDeviceCapability,
  HWInclusive_ManageInstantPayment: HWInclusive_ManageInstantPayment,
  GetHWPrinterFeaturesByPrinterType: GetHWPrinterFeaturesByPrinterType,
  GetHWServicePlan: GetHWServicePlan,
  GetHWPrinterByFeatures: GetHWPrinterByFeatures,
  GetUserSubscription: GetUserSubscription,
  GetCancellationFees: GetCancellationFees,
  BROTHERPLUS: brotherPlusReducerIndex,
  GetCancelSubscriptionStatus: GetCancelSubscriptionStatus,
  ReactivateSubscription: ReactivateSubscription,
  CSAGENT_RTK : CSAgentPortalReducerRTKIndex
});

const reducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action)
  }
  return appReducer(state, action);
}
  
  export default reducer;