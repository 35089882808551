import * as actionTypes from '../../constants/ActionTypes/Dashboard';

const initialState = {
    retryInfo: {},
    loading: false,
    error: "",
    success:false
}

const AsyncEnrollmentRetry = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.ASYNC_ENROLLMENT_RETRY_INIT:
            return {
                ...state,
                loading: true
            }
        case actionTypes.ASYNC_ENROLLMENT_RETRY_SUCCESS:
            return {
                ...state,
                retryInfo: action.retryInfo,
                loading: false,
                success:true
            }
        case actionTypes.ASYNC_ENROLLMENT_RETRY_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message
            }
        default:
            return {...state};
    }
}

export default AsyncEnrollmentRetry;