import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { routePath } from "../constants/Routes";
import LandingPage from "../pages/LandingPage/LandingPage";
import CustomerUnProtectedRoute from "./CustomerUnProtectedRoute";
import { AdminProtectedRoutePath } from "./RoutePaths/AdminRoutePath";
import { CSAgentProtectedRoutePath } from "./RoutePaths/CSAgentRoutePath";
import {
  customerProtectedRoutePaths,
  customerUnProtectedRoutePaths,
} from "./RoutePaths/CustomerRoutePaths";
import { 
    webCustomerProtectedRoutePath, 
    webCustomerUnProtectedRoutePath, 
    webUnProtectedRoutePathsWithCustomerUPLayout } from "./RoutePaths/WebCustomerRoutePath";
import {
  ADB2CAdminProtectedRouteComp,
  ADB2CCSAgentProtectedRouteComp,
  ADB2CCustomerProtectedRouteComp,
  CustomerUnProtectedRouteComp,
  WebCustomerProtectedRouteComp,
  WebCustomerUnProtectedRouteComp, } from "./RouteWithSubRouteComp/RoutesWithSubRoutes";
import MaintenanceRoutes from "./MaintenanceRoutes";
import { useMaintenance } from "../utils/CustomHooks/UseMaintenance";

const AppRoutes = () => {
  const { maintenanceServiceStatus } = useMaintenance();
  if(maintenanceServiceStatus){
    return <MaintenanceRoutes />
  }
  return (
    <Switch>
      {customerUnProtectedRoutePaths.map((route, i) => (
        <CustomerUnProtectedRouteComp key={i} {...route} />
      ))}
      {customerProtectedRoutePaths.map((route, i) => (
          <ADB2CCustomerProtectedRouteComp key={i} {...route} />
      ))}
      {webUnProtectedRoutePathsWithCustomerUPLayout.map((route, i) => (
        <CustomerUnProtectedRouteComp key={i} {...route} />
      ))}
      {webCustomerProtectedRoutePath.map((route, i) => (
        <WebCustomerProtectedRouteComp key={i} {...route} />
      ))}
      {webCustomerUnProtectedRoutePath.map((route, i) => (
        <WebCustomerUnProtectedRouteComp key={i} {...route} />
      ))}
      {CSAgentProtectedRoutePath.map((route, i) => (
        <ADB2CCSAgentProtectedRouteComp key={i} {...route} />
      ))}
      {AdminProtectedRoutePath.map((route, i) => (
        <ADB2CAdminProtectedRouteComp key={i} {...route} />
      ))}
      <CustomerUnProtectedRoute exact path={routePath.HOME} component={LandingPage}/>
      <Redirect to={routePath.HOME}></Redirect>
    </Switch>
  );
};
export default AppRoutes;
