import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { getAuthToken } from "../../utils/Authentication/handleAPIAuthToken";
import Loader from "../shared/loader/Loader";
import ErrorPage from "../shared/errorPage/ErrorPage";
import { FetchUserAccount, ResetUserAccount } from "../../actions/UserAccount";
import { setLocalSessionData, getLocalSessionData } from "../../utils/Authentication/handleUserSession";
import { routePath, WebCustomerRoutePaths } from "../../constants/Routes";
import { LOCALSESSIONDATAKEYS, SIGNUPTYPE } from "../../constants/AppConstants";
import i18next from "i18next";
import { getLangCode } from "../../utils/Internationalization/handleAppInternationalization";

const Profile = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { RouterLinkStateProp } = props;
    const { inProgress, accounts } = useMsal();
    const authToken = getAuthToken();
    const UserAccount = useSelector((state) => state.UserAccount);
    const [callUserAccount, setCallUserAccount] = useState(null);
    const AZURE_AD_B2C_UID = getLocalSessionData(LOCALSESSIONDATAKEYS.AZURE_AD_B2C_UID);
    const UID = getLocalSessionData(LOCALSESSIONDATAKEYS.UID);
    const DEVICE_ID = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID);
    const SIGNUP_TYPE = getLocalSessionData(LOCALSESSIONDATAKEYS.SIGNUP_TYPE);

    const handleSignUpType = (redirectPage) => {
        if(redirectPage === WebCustomerRoutePaths.SELECT_PRINTER){
            setLocalSessionData(LOCALSESSIONDATAKEYS.SIGNUP_TYPE, SIGNUPTYPE.WEB);
        }
    }
  
    useEffect(() => {
        // Dispatch event after setting Bearer token immediately after login
        if(inProgress === InteractionStatus.None && !authToken ){
           setLocalSessionData(LOCALSESSIONDATAKEYS.AZURE_AD_B2C_UID, accounts[0].idTokenClaims.sub);
           setCallUserAccount(true);
        }
        if(RouterLinkStateProp === "SUBSCRIPTION_NETWORK_ERR_VALIDATION"){
            setCallUserAccount(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [inProgress]);
        if(callUserAccount){
            dispatch(FetchUserAccount(AZURE_AD_B2C_UID, UID, DEVICE_ID, SIGNUP_TYPE));
            setCallUserAccount(false);
            return <Loader></Loader>
        }
        if(UserAccount.error){
            if(UserAccount.redirectToLandingPage){
                dispatch(ResetUserAccount());
                handleSignUpType(UserAccount.redirectPage);
                history.push(UserAccount.redirectPage);
            }
            else{
                return <ErrorPage errorInfo={UserAccount.error}></ErrorPage>
            }
        }
        if(UserAccount.success){
            i18next.changeLanguage(getLangCode());
            return <Redirect to={{pathname: routePath.REDIRECTION, state: {"RouterLinkStateProp": RouterLinkStateProp} }}></Redirect>
        }
        if(authToken && inProgress === InteractionStatus.None && callUserAccount === null){
            return <Redirect to={{pathname: routePath.REDIRECTION, state: {"RouterLinkStateProp": RouterLinkStateProp} }}></Redirect>
        }
        return(
            <Loader></Loader> 
        )
}

export default Profile;