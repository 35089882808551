import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../constants/ActionTypes/MyPlan';
import { _CALLGETAPI } from '../api/apiHandler'
import { SUCCESS, _API_ENDPOINT } from '../constants/AppConstants';
import i18next from 'i18next';

function _GETBENEFITOFSUPPLIES(deviceModel,promotion){
    const endpoint = _API_ENDPOINT.GET_BENEFIT_OF_SUPPLIES;
    let payload;
    if(promotion){
        payload = {
            params: {
                plantype: 'new',
                model: deviceModel,
                promotions:promotion
            }
          }
    }
    else{
        payload = {
            params: {
                plantype: 'new',
                model: deviceModel,
            }
        }
    }
     return _CALLGETAPI(endpoint, payload)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* GetBenefitOfSupplies(action) {
    try {
        const deviceModel = action.deviceModel;
        const promotion = action.promotion;
        if(deviceModel){
            const servicePlan =  yield call(_GETBENEFITOFSUPPLIES, deviceModel, promotion&&promotion);
            if(servicePlan.actionStatus.toUpperCase() === SUCCESS){
                yield put({type: actionTypes.GET_BENEFIT_OF_SUPPLIES_SUCCESS, plans: servicePlan});
            }
            else{
                yield put({type: actionTypes.GET_BENEFIT_OF_SUPPLIES_ERROR, message: i18next.t('ERROR_CONTENT.INFO_GENERAL')});
            }
        } else {
            yield put({
                type: actionTypes.GET_BENEFIT_OF_SUPPLIES_ERROR, 
                message: i18next.t('ERROR_CONTENT.INFO_GENERAL')
            });
        }
    } catch (e) {
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
        if(e instanceof TypeError === false && e.message !== "401"){
            yield put({type: actionTypes.GET_BENEFIT_OF_SUPPLIES_ERROR, message: e.message});
        }
    }
}

function* BenefitOfSupplies() {
    yield takeEvery(actionTypes.GET_BENEFIT_OF_SUPPLIES, GetBenefitOfSupplies);
 }
 
 export default BenefitOfSupplies;