import React from "react";
import './FormCheckBoxDefault.css';

const AdminFormCheckboxDefault = (props) => {
    const { 
        cname,
        name,
        label,
        isChecked,
        disabled,
        onChange,
        value,
        displayInline = false, // This groups checkboxes in same horizontal row by adding .form-check-inline class.
        gridBulkAction = false //This adds specific class to checkbox used in grid for bulk actions (multiselect)
    } = props;
    return (
        <div className={`form-check ${(gridBulkAction) ? 'admin-table-bulk-action-checkbox-wrapper' : 'no-grid'} ${(displayInline) ? 'form-check-inline' : 'form-check-block-display'}`}>
            <input 
                id={name} 
                className={`admin-form-check-input form-check-input ${(gridBulkAction) && 'admin-table-bulkaction-checkbox'} ${cname}`} 
                type="checkbox" 
                name={name}
                value={value}
                checked={isChecked} 
                disabled={disabled} 
                onChange={onChange} 
            />
            <label className="form-check-label" htmlFor={name}>{label}</label>
        </div>
    )
}

export default AdminFormCheckboxDefault;