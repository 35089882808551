import { call, put, select, takeEvery } from 'redux-saga/effects';
import { _CALLGETAPI } from '../../../api/apiHandler'
import { WEB_CUSTOMER_API_ENDPOINT, SUCCESS } from '../../../constants/AppConstants';
import * as actionTypes from '../../../constants/ActionTypes/Web/Customer/SignUp';
import i18next from 'i18next';
import { handleTokenAuthentication } from './TokenAuthentication';


function _GETREGISTRATIONTYPE(deviceModel, token){
    const endpoint = WEB_CUSTOMER_API_ENDPOINT.GET_REGISTRATION_TYPE

    const payload = {
        params: {
            model: deviceModel,
        }
    }
    const headerTokenRequired = token?token:null

    return _CALLGETAPI(endpoint,payload,headerTokenRequired)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* handleRegistrationType(action){

    const deviceModel = action.deviceModel
    const UID = action.UID
    let token = yield select(state => state.SCMSTokenAuthentication.scmsToken);
    let expiryTime = yield select(state => state.SCMSTokenAuthentication.tokenExpiryTime);

    
    try{
        let newToken ;
        if (!token || expiryTime<new Date()) {
            // Token is not available or expired, fetch a new one
            try {
                yield call(handleTokenAuthentication, { UID: UID });
                newToken  = yield select(state => state.SCMSTokenAuthentication.scmsToken);
            }
            catch(e){
                yield put({type: actionTypes.GET_SCMS_TOKEN_ERROR, message: i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')});
            }
        }

        const tokenAPIActionStatus  = yield select(state => state.SCMSTokenAuthentication.success)
        if(tokenAPIActionStatus){
            const registrationType = yield call(_GETREGISTRATIONTYPE, deviceModel, newToken);
            if(registrationType.actionStatus.toUpperCase() === SUCCESS){
                yield put({type: actionTypes.GET_REGISTRATION_TYPE_SUCCESS, registrationType: registrationType});
            } else {
                yield put({type: actionTypes.GET_REGISTRATION_TYPE_ERROR, message: i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')});
            }
        }
        else{
            yield put({type: actionTypes.GET_SCMS_TOKEN_ERROR, message: i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')});
        }
        
    }catch(e){
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
        if(e instanceof TypeError === false && e.message !== "401"){
            yield put({type: actionTypes.GET_REGISTRATION_TYPE_ERROR, message: e.message});
        }
    }
}

function* GetRegistrationType(){
    yield takeEvery(actionTypes.GET_REGISTRATION_TYPE, handleRegistrationType);
}

export default GetRegistrationType;