import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SignUpFeedback from "../../../components/SignUpFeedback/SignUpFeedback";
import { FEEDBACK_TYPES } from "../../../constants/AppConstants";
import { useSelector } from "react-redux";
import Loader from "../../../components/shared/loader/Loader";
import ErrorPage from "../../../components/shared/errorPage/ErrorPage";
import { Redirect } from "react-router-dom";
import { routePath } from "../../../constants/Routes";
import { pushDataLayer } from "../../../constants/AppMethod";

const EnrollmentFeedback = () => {
    const { t } = useTranslation(['translation']);

    useEffect(() => {
        pushDataLayer('mobile_connect_subscription_completed');
    }, []);

    const issuesOptionListArr = [
        t('SIGN_UP_FEEDBACK.ISSUES_LIST.DEVICE_SELECTION'), 
        t('SIGN_UP_FEEDBACK.ISSUES_LIST.PLAN_SELECTION'),   
        t('SIGN_UP_FEEDBACK.ISSUES_LIST.ACCOUNT_SIGN_IN_OR_SIGN_UP'), 
        t('SIGN_UP_FEEDBACK.ISSUES_LIST.PERSONAL_DETAILS'), 
        t('SIGN_UP_FEEDBACK.ISSUES_LIST.DELIVERY_DETAILS'), 
        t('SIGN_UP_FEEDBACK.ISSUES_LIST.PAYMENT_DETAILS'), 
        t('SIGN_UP_FEEDBACK.ISSUES_LIST.OTHER')
    ];
    const submitFeedbackResp = useSelector((state) => state.SignUpUserFeedback);
    if(submitFeedbackResp.loading){
        return <Loader></Loader>
    }
    if(submitFeedbackResp.error){
        return <ErrorPage errorInfo={submitFeedbackResp.error}></ErrorPage>
    }
    if(submitFeedbackResp.success){
        return <Redirect to={routePath.DASHBOARD}></Redirect>
    }
    return (
        <SignUpFeedback 
            issuesOptionListArr={issuesOptionListArr}
            header={t('SIGN_UP_FEEDBACK.HEADER')}
            rateYourExperience={t('SIGN_UP_FEEDBACK.RATE_YOUR_EXPERIENCE')}
            skipThisSectionVisibility={true}
            feedbackSource={FEEDBACK_TYPES.SIGNUP_USER_FEEDBACK}
        ></SignUpFeedback>
    );
}

export default EnrollmentFeedback;