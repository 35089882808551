import { call, put, takeEvery } from 'redux-saga/effects';
import i18next from 'i18next';
import { _CALLGETAPI } from '../../api/apiHandler';
import * as actionTypes from '../../constants/ActionTypes/HardwareInclusive/HardwareInclusive';
import { SUCCESS, _HARDWARE_API_ENDPOINTS } from '../../constants/AppConstants';

function _GETPRINTERBYFEATURES(printerTypeId, deviceUsageTypeId, featureIds){
    const endpoint = _HARDWARE_API_ENDPOINTS.GET_HW_PRINTER_BY_FEATURES
    const payload = {
        params: {
            printerTypeId:printerTypeId,
            deviceUsageTypeId: deviceUsageTypeId,
            featureIds: featureIds
        }
    } 
    return _CALLGETAPI(endpoint, payload)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* handlePrinterByFeatures(action){
    try{
        const printerTypeId = action.printerTypeId
        const deviceUsageTypeId = action.deviceUsageTypeId
        const featureIds = action.featureIds

        if(printerTypeId && deviceUsageTypeId && featureIds){
            const printerModel = yield call(_GETPRINTERBYFEATURES, printerTypeId, deviceUsageTypeId, featureIds);
            if(printerModel.actionStatus.toUpperCase() === SUCCESS){
                yield put({type: actionTypes.GET_HW_PRINTER_BY_FEATURES_SUCCESS, printerModel: printerModel});
            } else {
                yield put({type: actionTypes.GET_HW_PRINTER_BY_FEATURES_ERROR, message: i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')});
            }
        }
        else{
            yield put({type: actionTypes.GET_HW_PRINTER_BY_FEATURES_ERROR, message: i18next.t('ERROR_CONTENT.INFO_GENERAL')})
        }
    }catch(e){
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
        if(e instanceof TypeError === false && e.message !== "401"){
            yield put({type: actionTypes.GET_HW_PRINTER_BY_FEATURES_ERROR, message: e.message});
        }
    }
}

function* GetHWPrinterByFeatures(){
    yield takeEvery(actionTypes.GET_HW_PRINTER_BY_FEATURES, handlePrinterByFeatures);
}

export default GetHWPrinterByFeatures;