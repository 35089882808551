import i18next from "i18next";

export const getHardwarePlan = (servicePlan, selectedPrinterTypeId) => {
    let hardwarePlanBasedOnType = []
    servicePlan?.forEach(product => {
        product?.hardwarePlans?.forEach(hardwarePlan => {
            if (hardwarePlan.printerTypeId?.toString() === selectedPrinterTypeId?.toString()) {
                hardwarePlanBasedOnType.push(hardwarePlan);
            }
        });
    });

    return hardwarePlanBasedOnType[0]
}

export const getCPPrinterHeader = (printerTypeId) => {
    if (printerTypeId === 1) {
        return i18next.t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.HEADER_INKJET");
    } else if (printerTypeId === 2) {
        return i18next.t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.HEADER_LASER");
    }
}

export const getCPPrinterSubHeader = (printerTypeId) => {
    if (printerTypeId === 1) {
        return i18next.t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.SUB_HEADER_INKJET");
    } else if (printerTypeId === 2) {
        return i18next.t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.SUB_HEADER_LASER");
    }
}

export const getPrinterImage = (printerTypeFeatures, selectedPrinterTypeId) => {
    const printer = printerTypeFeatures?.find(pt => pt.printerTypeId?.toString() === selectedPrinterTypeId?.toString());
    return printer ? printer.deviceTypeImageUrl : null;
};

export const getPrinterFeatures = (printerTypeFeatures, selectedPrinterTypeId) => {
    const printer = printerTypeFeatures?.find(pt => pt.printerTypeId?.toString() === selectedPrinterTypeId?.toString());
    if (printer) {
        return printer.features.sort((a, b) => b.sortOrder - a.sortOrder);
    }
    return [];
};

export const getFeatureIdFromFeature = (featureName,feature) => {
    const featureObj = feature?.find(f => f.featureName === featureName);
    const featureId = featureObj ? featureObj?.featureId : null;
    return featureId
}

export const filterPlanDetails = (servicePlan, selectedPrinterTypeId) => {
    const typeMap = {
        "1": "InkJet",
        "2": "Mono Laser"
    };
    const planType = typeMap[selectedPrinterTypeId?.toString()];
    if (!planType) return [];

    const filteredPlanDetails = [];
    servicePlan?.forEach(product => {
        product.basicPlans.forEach(basicPlan => {
            if (basicPlan.type === planType) {
                basicPlan.planDetails.forEach(planDetail => {
                    if (planDetail.planPages === "300" || planDetail.planPages === "500") {
                        filteredPlanDetails.push(planDetail);
                    }
                });
            }
        });
    });
    return filteredPlanDetails;
};

export const getUniquePlanLengths = (filteredPlans) => {
    const planLengths = filteredPlans.map(plan => plan.planLength);
    return [...new Set(planLengths)].sort((a, b) => a - b);
};

//@@@@future scope@@@@@@@@@
// const findLowestPrice = (planDetails) => {
//     if (!Array.isArray(planDetails) || planDetails.length === 0) return null;
//     return planDetails.reduce((min, plan) => {
//         const minPrice = parseFloat(min.price);
//         const currentPrice = parseFloat(plan.price);
//         return currentPrice < minPrice ? plan : min;
//     });
// };

export const getHWStartingPrice = (servicePlan, selectedPrinterTypeId) => {
    const typeMap = {
        "1": "InkJet",
        "2": "Mono Laser"
    };
    const planType = typeMap[selectedPrinterTypeId?.toString()];
    if (!planType) return [];
    
    const planDetails = [];
    servicePlan?.forEach(product => {
        product.basicPlans.forEach(basicPlan => {
            if (basicPlan.type === planType) {
                planDetails.push(...basicPlan.planDetails);
            }
        });
    });
    return findLowestPrice(planDetails);
};

export const findLowestPrice = (planDetails) => {
    if (!planDetails.length) return null;
    return planDetails.reduce((min, plan) => (parseFloat(plan.price) < parseFloat(min.price) ? plan : min));
};

export const getPlanFromPlanName = (servicePlan, selectedPlanPage, selectedPrinterTypeId) => {
    const planDetails = filterPlanDetails(servicePlan, selectedPrinterTypeId)    
    const numericSelectedPlanPage = selectedPlanPage.match(/\d+/)[0];
    let selectedPlanObject = null;
    planDetails?.forEach(plan => {
        const numericPlanPages = plan.planPages.toString().match(/\d+/)[0];
        if(numericPlanPages.toString() === numericSelectedPlanPage.toString()){
            selectedPlanObject = plan;
        }
    })
    return selectedPlanObject;
}

export const sortFeaturesInDescendingOrder = (features) => {
    return features.sort((a, b) => b.sortOrder - a.sortOrder);
};

export const getHWPrinterFeatureTranslation = (featureId, printerType, featureName) => {
    if(i18next.t(`HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.SELECTED_FEATURES.${featureId?.toString()?.toUpperCase()}_${printerType.toUpperCase().replace(/ /g, "_")}`).includes('HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.SELECTED_FEATURES')){
        return featureName
      }
      else{
        return i18next.t(`HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.SELECTED_FEATURES.${featureId?.toString()?.toUpperCase()}_${printerType.toUpperCase().replace(/ /g, "_")}`)
    }
}


