import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import { GetPrintActivity } from "../../actions/PrintActivity";
import { getLocalSessionData, getUserSessionData } from "../../utils/Authentication/handleUserSession";
import { ECOPROTOKEN_CLAIMS, LOCALSESSIONDATAKEYS, refreshBtn } from "../../constants/AppConstants";
import Loader from "../../components/shared/loader/Loader";
import ErrorPage from "../../components/shared/errorPage/ErrorPage";
import PagesUsed from "../../components/PrintActivity/PagesUsed/PagesUsed";
import "./PrintActivity.css";
import AveragePagesUsed from "../../components/PrintActivity/AveragePagesUsed/AveragePagesUsed";
import EcoproProgressBar from "../../components/shared/EcoproProgressBar/EcoproProgressBar";
import currencies from "../../constants/CurrencyTypes";
import { formatNumberOnLocale } from "../../constants/AppMethod";
import { userRoles } from "../../constants/UserRoles";

const PrintActivity = (props) => {
    /**
     * This component is used to display print activity in CSAgent Dashboard also.
     */
    const { role = null, CustomerUID = null, customerDeviceID = null, customerCountryCode = null } = props;
    const IS_CSAGENT = (role === userRoles.CSAGENT) ? true : false;
    const dispatch = useDispatch();
    const pageTranslation = (IS_CSAGENT) ? "CSAgenttranslation" : "translation"
    const { t } = useTranslation([pageTranslation]);
    const [isInitialLoading, setIsinitialLoading] = useState(false);
    const [pagesUsedData, setPagesUsedData] = useState({
        planName : null,
        planPages :0,
        printedPlanPages : 0,
        printedTotalPages: 0,
        printedRolloverPages: 0,
        givenRolloverPages: 0,
        printedOveragePages: 0,
        purchasedOveragePages: 0,
        printerType: null,
        dateDropdownArr : [],
        rollOverPages:0,
        overageBlockPages:0,
        planCurrency:null,
        planPrice:0
    })
    const [printHistoryAPIFlag, setPrintHistoryAPIFlag] = useState(false);
    const printActivityResult = useSelector((state) => state.PrintActivity)
    const deviceID = (IS_CSAGENT) ? customerDeviceID : getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID);
    const UID = (IS_CSAGENT) ? CustomerUID : getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
    useEffect(() => {
        setIsinitialLoading(true);
        dispatch(GetPrintActivity(UID, deviceID, IS_CSAGENT, customerCountryCode));
        setPrintHistoryAPIFlag(true)
        return () => {
            localStorage.removeItem("PRINT_ACTIVITY_SUBSC_DATES")
          };
    }, [dispatch, UID, deviceID, IS_CSAGENT, customerCountryCode]);

    const onSUBSCDateChange = (date) => {
        setIsinitialLoading(false);
        const filtereData = Object.values(printActivityResult?.printActivity?.printHistory).filter((val) => {
            return (val.startDate === date) ? val : "" ;
        });
        setPagesUsedData({
            planName : filtereData[0].planName,
            planPages : filtereData[0].planPages,
            printedPlanPages : filtereData[0].printedPlanPages,
            printedTotalPages: filtereData[0].printedTotalPages,
            printedRolloverPages: filtereData[0].printedRolloverPages,
            givenRolloverPages: filtereData[0].givenRolloverPages,
            printedOveragePages: filtereData[0].printedOveragePages,
            purchasedOveragePages: filtereData[0].purchasedOveragePages,
            printerType: printActivityResult?.printActivity?.printerType,
            rollOverPages:filtereData[0].plan.rollOverPages,
            overageBlockPages:filtereData[0].plan.overageBlockPages,
            planCurrency:filtereData[0].plan.currency,
            planPrice:filtereData[0].plan.overagePrice,
            dateDropdownArr : Object.values(printActivityResult.printActivity.printHistory).map((val) => {
                return val.startDate;
            })
         });
    }

    if(printActivityResult.loading && isInitialLoading) {
        return <Loader></Loader>
    }

    if(printActivityResult.error && isInitialLoading && !IS_CSAGENT) {
        return <ErrorPage errorInfo={printActivityResult.error} showRefreshBtn={refreshBtn.HIDE}></ErrorPage>
    }

    if(printActivityResult.error && isInitialLoading && IS_CSAGENT) {
        return <div class="print-activity-csagent-error error-info f-16 fc-g">{printActivityResult.error}</div>
    }

    if(printHistoryAPIFlag && printActivityResult.success && printActivityResult.printActivity.printHistory.length > 0){
        setPagesUsedData({
            planName : printActivityResult?.printActivity?.printHistory[0].planName,
            planPages : printActivityResult?.printActivity?.printHistory[0].planPages,
            printedPlanPages : printActivityResult?.printActivity?.printHistory[0].printedPlanPages,
            printedTotalPages: printActivityResult?.printActivity?.printHistory[0].printedTotalPages,
            printedRolloverPages: printActivityResult?.printActivity?.printHistory[0].printedRolloverPages,
            givenRolloverPages: printActivityResult?.printActivity?.printHistory[0].givenRolloverPages,
            printedOveragePages: printActivityResult?.printActivity?.printHistory[0].printedOveragePages,
            purchasedOveragePages: printActivityResult?.printActivity?.printHistory[0].purchasedOveragePages,
            printerType: printActivityResult?.printActivity?.printerType,
            rollOverPages:printActivityResult?.printActivity?.printHistory[0].plan.rollOverPages,
            overageBlockPages:printActivityResult?.printActivity?.printHistory[0].plan.overageBlockPages,
            planCurrency:printActivityResult?.printActivity?.printHistory[0].plan.currency,
            planPrice:printActivityResult?.printActivity?.printHistory[0].plan.overagePrice,
            dateDropdownArr : Object.values(printActivityResult.printActivity.printHistory).map((val) => {
                     return val.startDate;
                 })
            });
        setPrintHistoryAPIFlag(false);
    }
    return(   
        <Container className="print-activity-wrapper">
            <h1 className="mt-40">{t("PRINT_ACTIVITY.PRINT_ACTIVITY_HEADER")}</h1>
            
             <PagesUsed 
                planName = {pagesUsedData.planName}
                planPages = {pagesUsedData.planPages}
                printedPlanPages = {pagesUsedData.printedPlanPages}
                printedTotalPages= {pagesUsedData.printedTotalPages}
                printedRolloverPages= {pagesUsedData.printedRolloverPages}
                givenRolloverPages= {pagesUsedData.givenRolloverPages}
                printedOveragePages= {pagesUsedData.printedOveragePages}
                purchasedOveragePages= {pagesUsedData.purchasedOveragePages}
                printerType= {pagesUsedData.printerType}
                dateDropdownArr = {pagesUsedData.dateDropdownArr}
                dateFilter =  {onSUBSCDateChange}
                IS_CSAGENT = {IS_CSAGENT}
            />
            { (!IS_CSAGENT) ? (
                    <>
                        {
                            printActivityResult.success 
                            && printActivityResult?.printActivity?.printHistory?.length > 0 
                            &&
                            <>
                                <div className="pa-progress-bar-wrapper">
                                    <EcoproProgressBar 
                                        value={(pagesUsedData.givenRolloverPages*100/pagesUsedData.rollOverPages) || 0}
                                        header={t("PRINT_ACTIVITY.ROLLOVER.HEADER")}
                                        info={t("PRINT_ACTIVITY.ROLLOVER.INFO")}
                                        initialLabel={t("PRINT_ACTIVITY.ROLLOVER.INITIAL")}
                                        initialValue={`${pagesUsedData.givenRolloverPages} ${t("PRINT_ACTIVITY.PAGES")}`}
                                        finalLabel={t("PRINT_ACTIVITY.ROLLOVER.LIMIT")}
                                        finalValue={`${pagesUsedData.rollOverPages} ${t("PRINT_ACTIVITY.PAGES")}`}
                                    ></EcoproProgressBar>
                                </div>
                                {pagesUsedData?.planCurrency && pagesUsedData?.purchasedOveragePages ?
                                    <div className="pa-progress-bar-wrapper">
                                        <EcoproProgressBar 
                                            value={(pagesUsedData.printedOveragePages*100/pagesUsedData.purchasedOveragePages) || 0}
                                            header={t("DASHBOARD.OVERAGES")}
                                            info={t("DASHBOARD.PAGES_PRICE", {
                                                plan_pages: pagesUsedData.overageBlockPages,
                                                plan_price: formatNumberOnLocale(pagesUsedData.planPrice, currencies[pagesUsedData?.planCurrency]?.code),
                                            })}
                                            initialLabel={`${pagesUsedData.printedOveragePages}/${pagesUsedData.purchasedOveragePages}`}
                                        ></EcoproProgressBar>
                                    </div>
                                    :null
                                }
                            </>
                        }
                        {
                            printActivityResult.success 
                            && printActivityResult?.printActivity?.printHistory?.length > 0 
                            && <AveragePagesUsed printHistoryResult={printActivityResult?.printActivity}/>
                        }
                    </>
                ) : null
            }
        </Container>
    );
};

export default PrintActivity;
