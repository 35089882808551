export const FETCH_USER_ACCOUNT = 'FETCH_USER_ACCOUNT'
export const FETCH_USER_ACCOUNT_SUCCESS = 'FETCH_USER_ACCOUNT_SUCCESS'
export const FETCH_USER_ACCOUNT_ERROR = 'FETCH_USER_ACCOUNT_ERROR'
export const FETCH_USER_ACCOUNT_RESET = 'FETCH_USER_ACCOUNT_RESET'
export const FETCH_USER_ACCOUNT_SIGNUP_TYPE = 'FETCH_USER_ACCOUNT_SIGNUP_TYPE'

export const GET_FEATURE_SWITCH = 'GET_FEATURE_SWITCH'
export const GET_FEATURE_SWITCH_SUCCESS = 'GET_FEATURE_SWITCH_SUCCESS'
export const GET_FEATURE_SWITCH_ERROR = 'GET_FEATURE_SWITCH_ERROR'

export const RESET_WEB_CONNECTION_API_DATA = 'RESET_WEB_CONNECTION_API_DATA'
export const RESET_CODE_SUBMISSION_DATA = 'RESET_CODE_SUBMISSION_DATA'
