import i18n from 'i18next';
import Backend from "i18next-http-backend";
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { LOCALSESSIONDATAKEYS } from '../../constants/AppConstants';
import { allowedWebRoutesToAdaptToBrowserLangSettings, allowedRoutesToAdaptToQueryString } from '../../constants/Routes';
import { getLocalSessionData, setLocalSessionData } from '../Authentication/handleUserSession';
import { extractLangcodeFromDomain } from '../Internationalization/handleAppInternationalization';
import { languageCodes } from '../../constants/Country';


const domain = (new URL(window.location.href));

const NordicDomains = () => {
  const nordicDomains = ["fi", "se", "dk"];
  const extractedDomain = extractLangcodeFromDomain();
  return (nordicDomains.indexOf(extractedDomain) > -1 ) ? true : false
}

const getLangFromQS = () => {
    const params = new URLSearchParams(domain.search);
    const language = params.get("language");
    const country = params.get("country");
    
    if (language && country) {
      return `${language}-${country}`;
    }
    
    return language;
}

const loadAppLanguage = () => {
  let appLang;
  if((allowedWebRoutesToAdaptToBrowserLangSettings.indexOf(domain.pathname) > -1) && !NordicDomains()){
    appLang = navigator.language || navigator.userLanguage;
    setLocalSessionData(LOCALSESSIONDATAKEYS.ECOPROWEBSIGNUPLANGCODE, appLang);
} else if(allowedRoutesToAdaptToQueryString.indexOf(domain.pathname)>-1){
    appLang = getLangFromQS();
  } else {
    appLang = getLocalSessionData(LOCALSESSIONDATAKEYS.ECOPROLANGUAGECODE);
    if(!appLang){
      const langCode = extractLangcodeFromDomain();
      appLang = languageCodes[langCode];
    }
  }
  return appLang;
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
      lng: loadAppLanguage(),
      load: "currentOnly",
      debug: false,
      fallbackLng: 'en-GB',
      ns: ['translation'],
      defaultNS: ['translation'],
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      react: {
          useSuspense: true
      }
  });


export default i18n;