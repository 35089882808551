import React from 'react';
import { Container } from 'reactstrap';
import { Trans } from 'react-i18next';
import './SubHeader.css'

const SubHeader = ({ currentStep, totalStep, stepName, stepInfo, stepInfoSub, cname = 'none' }) => {

  return (
    <Container className={`subheader ${cname}`}>
      <div className="step">
        <Trans i18nKey="SUB_HEADER.STEPS_COUNT_INFO" currentStep={currentStep} totalStep={totalStep}>
            STEP <strong>{{currentStep}}</strong> of <strong>{{totalStep}}</strong>
        </Trans>
      </div>
      <h1 className="f-20 fc-e">{stepName}</h1>
      <p className="f-12 fc-g">{stepInfo}</p>
      {stepInfoSub && <p className=" sub-step-info fc-g">{stepInfoSub}</p>}
    </Container>
  );
};

export default SubHeader;