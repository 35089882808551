import React from "react";
import { useTranslation } from "react-i18next";
import SubHeader from "../../../../components/shared/subheader/SubHeader";
import "./DeviceList.css";
import { useDispatch, useSelector } from "react-redux";
import ErrorPage from "../../../../components/shared/errorPage/ErrorPage";
import { refreshBtn, LOCALSESSIONDATAKEYS } from "../../../../constants/AppConstants";
import { emptyObjectCheck, pushDataLayer } from "../../../../constants/AppMethod";
import DisplayListOfDevices from "../../../../components/DisplayDevices/DisplayListOfDevices/DisplayListOfDevices";
import { getLocalSessionData } from "../../../../utils/Authentication/handleUserSession";
import { routePath } from "../../../../constants/Routes";
import { resetDeviceCapability } from "../../../../actions/SelectDevice";
import { useHistory } from "react-router-dom";
import DesktopDisplayListOfDevice from "../../../../components/DisplayDevices/DesktopDisplayListOfDevice/DesktopDisplayListOfDevice";

const SelectDifferentDevice = () => {
  const { t } = useTranslation(["translation"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const deviceListResult = useSelector((state) => state.SelectDevice);
  const devices = deviceListResult.deviceList.devices;
  const deviceId = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID);
  let deviceList = []

  const goToPlansPage = (deviceId, deviceModel,serialNumber,networkStatus) => {
    pushDataLayer('different_device_selected','device_id',deviceId)
    dispatch(resetDeviceCapability())
    history.push({
      pathname: routePath.PLANS,
      state: { deviceId:deviceId, device_model: deviceModel, serialNumber: serialNumber, networkStatus:networkStatus, from: 'device_selection_page'},
    });
  };

  if (
    (devices && devices.length === 1) ||
    emptyObjectCheck(deviceListResult.deviceList)
  ) {
    return (
      <ErrorPage
        errorInfo={t("ERROR_CONTENT.NO_DEVICES")}
        showRefreshBtn={refreshBtn.HIDE}
      ></ErrorPage>
    );
  }
  if (deviceListResult.success === true) {
    devices &&
      devices.map((device) => {
        if(device.deviceId !== deviceId){
          deviceList.push(device)
        }
        return device;
      });
  }
  return (
    <div className="mt-40">
      <div className="select-different-device">
        <SubHeader
          currentStep="1"
          totalStep="4"
          stepName={t("SUB_HEADER.STEP_1.STEP_NAME")}
          stepInfo={t("SUB_HEADER.STEP_1.STEP_INFO")}
        />
        {deviceList &&
        <DisplayListOfDevices className ="mobile-device-list" deviceList={deviceList} goToPlansPage={goToPlansPage} />}
        {deviceList &&
        <DesktopDisplayListOfDevice className ="desktop-device-list" deviceList={deviceList} goToPlansPage={goToPlansPage} />}
      </div>
    </div>
  );
};

export default SelectDifferentDevice;
