import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ecopro_color_logo from "../../../assets/images/admin-portal/ecopro-content-logo.svg";
import EcoProButton from "../../../components/shared/button/button";
import { CSAgentRoutePaths } from "../../../constants/Routes";
import './CustomerSearch.css';
import { FetchCustomerData, FetchCustomerDataResetState, ResetOrderConsumable, ResetSwapDevice } from "../../../actions/CSAgentPortal/CustomerDashboard";
import Loader from "../../../components/shared/loader/Loader";
import { blockSpecialCharacterRegex } from "../../../constants/AppConstants";

const CustomerSearch = () => {
    const {t} = useTranslation("CSAgenttranslation");
    const history = useHistory();
    const dispatch = useDispatch()
    const [profileIdErr, setProfileIdErr] = useState(false);
    const [searchTerms, setSearchTerms] = useState({});
    const [navigateToDashboard,setNavigateToDashaboard] = useState(null)
    const dashboardInfo = useSelector(state => state.CSAGENT.CustomerDashboardInfo);
    const MAILID = 'MailId';
    const BPID = "BusinessPartnerId"
    const submit = () => {
         if(Object.keys(searchTerms).length === 0){
            setProfileIdErr(true);
         } else {
            setNavigateToDashaboard(true)
            dispatch(ResetOrderConsumable())
            dispatch(ResetSwapDevice())
            if(Object.keys(searchTerms).includes(MAILID) || Object.keys(searchTerms).includes(BPID)){
                dispatch(FetchCustomerData(null, Object.keys(searchTerms).toString(), Object.values(searchTerms).toString()))
            } else {
                dispatch(FetchCustomerData(Object.values(searchTerms).toString()));
            }
         }
    }
    const handleInputChange  = (event, type) => {
        const {value} = event.target
        if (value === "" || blockSpecialCharacterRegex.test(value)) {
             setSearchTerms({[type]: value});
        }
        dispatch(FetchCustomerDataResetState());
        setProfileIdErr(false);
    }
    if(dashboardInfo.loading){
        return <Loader></Loader>
    }
    if(Object.keys(dashboardInfo.customer_data).length > 0 && navigateToDashboard){
        setNavigateToDashaboard(false)
        history.push({pathname: CSAgentRoutePaths.CUSTOMER_SERVICE_DASHBOARD, 
            state: {
               'searchTerms': searchTerms,
                'dashboardInfo': dashboardInfo
            }});
    }
    
    return (
       <div className="customer-lookup-box">
           <div className="lookup-box-logo text-center">
                <img src={ecopro_color_logo} alt="logo"/>
           </div>
           <div className="lookup-box-content mt-40">
                <h5>{t('CUSTOMER_SEARCH.BOX_TITLE')}</h5>
                <p>{t('CUSTOMER_SEARCH.BOX_SUBCONTENT')}</p>
                <p id="input-field-help-text">(Please provide only any one of the details)</p>
           </div>
           <div className="lookup-box-input-wrapper mt-40">
                <div className="forms">
                    <div className="form-container">
                       <div className={`invalid-feedback ${(dashboardInfo.error) ? "display-error"  : null }`}>{dashboardInfo.error}</div>
                        <form className="row" noValidate="" method="post" onSubmit={() => submit()}>
                            { /** Serial number Input */}
                            <div className={`"col-12 customer-profile-input-wrapper customer-profile-serial-number-input-wrapper ${ (!searchTerms['serial_number']) ? "hidden" : "input-color-change" } ${ (profileIdErr) ? "search-error-wrapper"  : ""}`}>
                                <input type="text" 
                                className={`form-control ${(profileIdErr)?"customer-search-input-error":null}` }
                                id="customerProfileId" name="customerProfileId" placeholder={t("CUSTOMER_SEARCH.INPUT_PLACEHOLDER")} required="" min-length="1" max-length="200" value= {searchTerms['serial_number'] || ""} onChange={(e)=>handleInputChange(e, 'serial_number')}/>
                                <div className={`invalid-feedback ${(profileIdErr) ? "display-error"  : null }`} >
                                    {
                                        t("CUSTOMER_SEARCH.SERIAL_NUMBER_REQUIRED")
                                    }
                                </div> 
                            </div>

                            <span className="or-text">(OR)</span>
                            { /** Email Input */}
                            <div className={`"col-12 customer-profile-input-wrapper customer-profile-email-input-wrapper ${ (!searchTerms[MAILID]) ? "hidden" : "input-color-change" } ${ (profileIdErr) ? "search-error-wrapper"  : ""}`}>
                                <input type="text" 
                                className={`form-control ${(profileIdErr)?"customer-search-input-error":null}` }
                                id="customerProfileId" name="customerProfileId" placeholder={t("CUSTOMER_SEARCH.EMAIL_INPUT_PLACEHOLDER")} required="" min-length="1" max-length="200" value= {searchTerms[MAILID] || ""} onChange={(e)=>handleInputChange(e, MAILID)}/>
                                <div className={`invalid-feedback ${(profileIdErr) ? "display-error"  : null }`} >
                                    {
                                        t("CUSTOMER_SEARCH.EMAIL_ID_REQUIRED")
                                    }
                                </div> 
                            </div>
                            
                            <span className="or-text">(OR)</span>
                            { /** BPID input */}
                            <div className={`"col-12 customer-profile-input-wrapper customer-profile-bpid-input-wrapper ${ (!searchTerms[BPID]) ? "hidden" : "input-color-change" } ${ (profileIdErr) ? "search-error-wrapper"  : ""}`}>
                                <input type="text" 
                                className={`form-control ${(profileIdErr)?"customer-search-input-error":null}` }
                                id="customerProfileId" name="customerProfileId" placeholder={t("CUSTOMER_SEARCH.BPID_INPUT_PLACEHOLDER")} required="" min-length="1" max-length="200" value= {searchTerms[BPID] || ""} onChange={(e)=>handleInputChange(e, BPID)}/>
                                <div className={`invalid-feedback ${(profileIdErr) ? "display-error"  : null }`} >
                                    {
                                        t("CUSTOMER_SEARCH.BPID_REQUIRED")
                                    }
                                </div> 
                            </div>

                        </form>
                    </div>
                    <div className="form-btn">
                        <EcoProButton
                            btnType="submit"
                            cname="default"
                            onBtnClick={() => submit()}
                        >{t("CUSTOMER_SEARCH.BTN_TEXT")}</EcoProButton>
                    </div>
                </div>
           </div>
       </div>
    )
}

export default CustomerSearch;