import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../../../constants/ActionTypes/AdminPortal/Settings';
import { SUCCESS, _ADMINISTRATOR_API_ENDPOINTS } from '../../../constants/AppConstants';
import { _CALLGETAPI } from '../../../api/apiHandler';
import i18next from 'i18next';

function _fetchMaintenanceServiceStatus(){
    const endpoint = _ADMINISTRATOR_API_ENDPOINTS.SETTINGS.MAINTENENCE_SERVICE.FETCH;
  
    const payload = null
    return _CALLGETAPI(endpoint, payload)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* handleError(msg = null){
    yield put({
        type: actionTypes.GET_MAINTENANCE_SERVICE_STATUS_FAILURE,
        message: (msg) ? msg :i18next.t("Admintranslation:ADMIN_COMMON_ERRORS.NO_RECORDS_FOUND")
    })
}

function* handleInfo(){
    try{
        const result =  yield call(_fetchMaintenanceServiceStatus);
        const decodedResult = JSON.parse(atob(result));
        if(decodedResult.ActionStatus.toUpperCase() === SUCCESS){
            yield put({type: actionTypes.GET_MAINTENANCE_SERVICE_STATUS_SUCCESS, data: decodedResult.ServiceKey});
        } else {
            yield* handleError();
        }
    } catch (e){
        yield* handleError()
    }
}

function* MaintenanceServiceStatus_FETCH(){
    yield takeEvery(actionTypes.GET_MAINTENANCE_SERVICE_STATUS_INIT, handleInfo);
}

export default MaintenanceServiceStatus_FETCH;