import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../../../constants/ActionTypes/Web/Customer/SignUp';
import { _CALLGETAPI } from '../../../api/apiHandler'
import { WEB_CUSTOMER_API_ENDPOINT } from '../../../constants/AppConstants';
import i18next from 'i18next';

function _USERSUBSCRIPTIONINFOCALL(UID){
    const endpoint = WEB_CUSTOMER_API_ENDPOINT.GET_USER_SUBSCRIPTION_ACCOUNT;
    const payload = {
        params: {
            userId:UID
        }
    }
    return _CALLGETAPI(endpoint, payload )
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* handleUserSubscriptionInfo(action){
    try{
        const UID = action.UID;
        if(UID){
            const subscriptionResponse = yield call(_USERSUBSCRIPTIONINFOCALL, action.UID);
            if(subscriptionResponse.actionStatus.toUpperCase() === "SUCCESS") {
                yield put({type: actionTypes.FETCH_USER_SUBSCRIPTION_INFO_ERROR, message: i18next.t('CUSTOMER_WEB.ERROR_CONTENT.SUBSCRIPTION_ALREADY_EXISTS')});
            } else {
                yield put({type: actionTypes.FETCH_USER_SUBSCRIPTION_INFO_SUCCESS, subscriptionResponse: "No Subscription found"});
            }
        } else {
            yield put({type: actionTypes.FETCH_USER_SUBSCRIPTION_INFO_ERROR, message: i18next.t('ERROR_CONTENT.INFO_GENERAL')})
        }
    }catch(e){
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
         if(e?.response?.status === 404){
            yield put({type: actionTypes.FETCH_USER_SUBSCRIPTION_INFO_SUCCESS, subscriptionResponse: "No Subscription found"});
         }
        if(e instanceof TypeError === false && e.message !== "401"){
            yield put({type: actionTypes.FETCH_USER_SUBSCRIPTION_INFO_ERROR, message: e.message});
        }
    }
}
function* UserSubscriptionInfo(){
    yield takeEvery(actionTypes.FETCH_USER_SUBSCRIPTION_INFO_INIT, handleUserSubscriptionInfo);
}

export default UserSubscriptionInfo;