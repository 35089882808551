import { useContext } from "react";
import { FormContext } from "../../Form/FormBrother";
import i18next from "i18next";
import "./FormSelect.css";

function FormSelect(props) {
  const {
    bsClassName,
    label,
    name,
    id,
    required,
    errorMsg,
    optionData,
    placeholder,
    webClassName,
  } = props;

  const formContext = useContext(FormContext);
  const { formContent, handleFormChange, showProvince } = formContext;

  const getCountryTranslation = (countryName) => {
    if(i18next.t(`COUNTRY_TRANSLATION.${countryName}`).includes('COUNTRY_TRANSLATION')){
      return countryName
    }
    else{
      return i18next.t(`COUNTRY_TRANSLATION.${countryName}`)
    }
  }

  return (
    <div className={`${bsClassName} ${webClassName} ${name === 'province' && !showProvince ? 'd-none' : 'show'}`}>
      {!webClassName && (
        <label htmlFor={id} className="form-label f-12 fc-g">
          {label}
        </label>
      )}
      <select
        id={id}
        className={`ecopro-form-select form-select ${
          !formContent[name] ? "dropdown-placeholder" : ""
        }`}
        value={formContent[name]}
        onChange={handleFormChange}
        name={name}
        required={(name === 'province' && !showProvince) ? "" : required}
      >
        <option defaultValue className="choose" value="">
          {placeholder}
        </option>
        {name === 'province' && optionData &&
          optionData.map((data) => {
            return (
              <option key={data.ISO_code} value={data.ISO_code}>
                {data.Province}
              </option>
            );
          })}
        {name !== 'province' && optionData &&
          optionData.map((data) => {
            return (
              <option key={data.name} value={data.name}>
                {getCountryTranslation(data.name)}
              </option>
            );
          })}
      </select>
      {webClassName && (
        <label htmlFor={id} className="form-label f-12 fc-g">
          {label}
        </label>
      )}
      <div className="invalid-feedback">{errorMsg}</div>
    </div>
  );
}

export default FormSelect;
