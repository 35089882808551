import { LogLevel } from "@azure/msal-browser";
import { ENVSettings } from "../../../constants/ENVSettings";
import { extractLangcodeFromDomain } from "../../Internationalization/handleAppInternationalization";
import { languageCodes } from "../../../constants/Country";
import { getLocalSessionData } from "../handleUserSession";
import { LOCALSESSIONDATAKEYS, USER_PROFILE_EDIT_TYPE } from "../../../constants/AppConstants";
import { routePath } from "../../../constants/Routes";
// Config object to be passed to Msal on creation
export const MSAL_CONFIG = {
    auth: {
        clientId: ENVSettings.AZURE_MSAL_CLIENT_ID, // Registered Application ID
        authority: ENVSettings.AZURE_MSAL_B2C_POLICIES.authorities.signUpSignIn.authority, // Use a sign-up/sign-in user-flow as a default authority
        knownAuthorities: [ENVSettings.AZURE_MSAL_B2C_POLICIES.authorityDomain], // B2C Tenant's Domain name
        redirectUri:`${window.location.origin}/${ENVSettings.AZURE_MSAL_REDIRECT_URI}`, // Registered URI on App Registration in Azure portal
        postLogoutRedirectUri:`${routePath.SHENLONG_REDIRECT}`, // Indicates the page to navigate after logout
        navigateToLoginRequestUrl: true // If "true", will navigate back to the original request location
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {	
                    return;	
                }
                if (process.env.NODE_ENV === 'development') {
                    switch (level) {	
                        case LogLevel.Error:	
                            console.error(message);	
                            return;	
                        case LogLevel.Info:	
                            console.info(message);	
                            return;	
                        case LogLevel.Verbose:	
                            console.debug(message);	
                            return;	
                        case LogLevel.Warning:	
                            console.warn(message);	
                            return;	
                        default:
                            return;
                    }
                }
            }
        }
    }
};


// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me"
};


let locale = 'en-GB';
const urlParams = new URLSearchParams(window.location.search);
let ctryCode = extractLangcodeFromDomain();
ctryCode = (ctryCode === 'uk') ? "GB": ctryCode;

if(urlParams.has('country') && urlParams.has('language')){
    locale = `${urlParams.get('language').toLowerCase()}-${urlParams.get('country').toUpperCase()}`;
} else {
    const logout_lng = getLocalSessionData(LOCALSESSIONDATAKEYS.LOGOUT_LNG_CODE)
    if(logout_lng){
        locale = logout_lng
    }
    else{
        const langCode = extractLangcodeFromDomain();
        locale = languageCodes[langCode];
    }
}

export const loginRequest = {
    scopes: [ENVSettings.AZURE_MSAL_SCOPE],
    extraQueryParameters: { ui_locales: locale, country: ctryCode.toUpperCase(), language: locale }
};
export const AZURE_ADB2C_TENANT_NAME = ENVSettings.AZURE_TENANT_NAME;

export const updateUserProfileRequest = {
    p: ENVSettings.AZURE_MSAL_B2C_POLICIES.names.editProfile,
    redirect_uri: `${window.location.origin}/${ENVSettings.AZURE_MSAL_B2C_POLICIES.authorities.editProfile.redirect_uri}`,
    state: USER_PROFILE_EDIT_TYPE.profile
}

export const updateUserPasswordRequest = {
    p: ENVSettings.AZURE_MSAL_B2C_POLICIES.names.editPasswordProfile,
    redirect_uri: `${window.location.origin}/${ENVSettings.AZURE_MSAL_B2C_POLICIES.authorities.editProfile.redirect_uri}`,
    state: USER_PROFILE_EDIT_TYPE.password
}

export const profileEditRequest = {
    client_id: ENVSettings.AZURE_MSAL_CLIENT_ID,
    nonce: 'defaultNonce',
    scope: 'openid',
    response_type: 'id_token',
    prompt: 'none',
    ui_locales: locale,
}
