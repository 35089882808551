import * as actionTypes from '../../constants/ActionTypes/Dashboard';

const initialState = {
    cancelSubscriptionStatus:{},
    loading: false,
    error: false
  }

const GetCancelSubscriptionStatus = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CANCEL_SUBSCRIPTION_STATUS:
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        
        case actionTypes.GET_CANCEL_SUBSCRIPTION_STATUS_SUCCESS:
            return {
                ...state,
                cancelSubscriptionStatus: action.cancelSubscriptionStatus,
                loading: false,
                error: false,
                success: true
            }
        
        case actionTypes.GET_CANCEL_SUBSCRIPTION_STATUS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message,
                success: false
            }
        case actionTypes.RESET_CANCEL_SUBSCRIPTION:
            return {
                ...state,
                loading: true,
                error: false,
                success: false,
                cancelSubscriptionStatus:{}
            }
        default:
            return {...state}
    }
}

export default GetCancelSubscriptionStatus