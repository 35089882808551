import * as actionTypes from '../../constants/ActionTypes/Dashboard';

const initialState = {
    enrollment_completed: true,
  }

const EnrollmentStatus = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ENROLLMENT_STATUS_CHECK:
            return {
                ...state,
                enrollment_completed: action.enrollmentStatus
            }
            default:
            return {...state}
    }
}

export default EnrollmentStatus