import { all } from "@redux-saga/core/effects";
import CustomerDashboardInfo from "./CustomerDashboard/CustomerDashboardInfo";
import UpdateOrderConsumables from "./CustomerDashboard/UpdateOrderConsumables";
import EnableDeviceSwap from "./CustomerDashboard/EnableDeviceSwap";
import FetchEmailLogs from "./CustomerDashboard/FetchEmailLogs";
import SupplyOrderLogs from "./CustomerDashboard/SupplyOrderLogs";

export default function* CsAgentSagas() {
    yield all([
        CustomerDashboardInfo(),
        EnableDeviceSwap(),
        UpdateOrderConsumables(),
        FetchEmailLogs(),
        SupplyOrderLogs()
    ])
}
