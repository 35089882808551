import React from "react";
import { useTranslation } from "react-i18next";
import { getPrinterTypeTranslation } from "../../../../constants/AppMethod";
import './ListPrinterType.css';

const ListPrinterType = (props) =>{
    const { listPrinterModels, selectedType, printerTypes } = props;
    const { t } = useTranslation(['translation']);
   
    return (
        <div className="select-printer-type-wrapper-i">
            <h5 className="side-heading">{t('CUSTOMER_WEB.SELECT_PRINTER.PRINTER_TYPE_HEADER')}</h5>
            <div className="printer-type-list-wrapper-i">
                {
                    Object.values(printerTypes).map((val) => (
                        <div key={val.printerTypeId} className={`item-i ${selectedType === val.printerTypeId ? "pre-selected-i" : null}`} id={`printer_selected_${val.type}`} onClick={() => listPrinterModels(val.printerTypeId, val.type)}>
                            <img src={val.deviceTypeImageUrl} alt="printer"/>
                            <p className={`pt-name ${selectedType === val.printerTypeId ? "pre-selected-text-i" : null}`}>{getPrinterTypeTranslation(val.type)}</p>
                            <p className="pt-description">{getPrinterTypeTranslation(val.type, "description")}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default ListPrinterType;