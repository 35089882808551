import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import currencies from "../../constants/CurrencyTypes";
import { DisplayDate } from "../../utils/DisplayDate";
import ErrorPage from "../shared/errorPage/ErrorPage";
import PaymentRecievedIcon from "../../assets/images/update-billing-as-shipping-confirmation-icon.svg";
import "./DashboardOrderDetails.css";
import { DownloadOrderInvoice } from "../../actions/Dashboard";
import { getUserSessionData } from "../../utils/Authentication/handleUserSession";
import Loader from "../shared/loader/Loader";
import { getOrderTypeTranslation, formatNumberOnLocale } from "../../constants/AppMethod";
import { ORDER_TYPES } from "../../constants/AppConstants";

const dateDisplayOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };

const DashboardOrderDetails = () => {
  const location = useLocation();
  const { orderDetails } = location.state || {};
  const DownloadOrderInvoiceResult = useSelector((state)=>state.DownloadOrderInvoice) 

  if(!orderDetails){
    return <ErrorPage></ErrorPage>
  }

  if (DownloadOrderInvoiceResult.loading) {
    return <Loader></Loader>
  }
  return (
    <>
      {
        (orderDetails?.orderType === ORDER_TYPES.SUPPLIES_SHIPPED)
        ? <SuppliesShipped orderDetails={orderDetails}/>
        : <InvoiceRaised orderDetails={orderDetails}/>
      }
    </>
  )
};

const InvoiceRaised = (props) => {
  const { orderDetails } = props;
  const { t } = useTranslation(["translation"]);
  const dispatch = useDispatch();
  const UID = getUserSessionData('UserId');
  const onDownloadOrderInvoice = () => {
    dispatch(DownloadOrderInvoice(UID,orderDetails?.invoiceId,orderDetails?.date))
  }
  return (
    <div className="order-details">
      <div className="order-details-card">
        <div className="supplies-shipped-img">
            <img src={PaymentRecievedIcon} alt="payment-recieved" />
        </div>
        <div className="supplies-shipped-header">
          {getOrderTypeTranslation(orderDetails?.orderType)}
        </div>
        <div className="supplies-shipped-info container f-14 ta-left">
          <div className="row">
              <div className="col">
                {t("DASHBOARD.ORDER_DETAILS.DATE_ORDERED")}
              </div>
              <div className="col">{DisplayDate(orderDetails?.date, dateDisplayOptions)}</div>
            </div>
            <div className="row fw-b">
              <div className="col f-14 ta-left fw-b">{t("DASHBOARD.ORDER_DETAILS.TOTAL")}</div>
              <div className="col f-18">
                <div>{formatNumberOnLocale(orderDetails?.charge, currencies[orderDetails?.currency]?.code)}</div>
                {orderDetails?.invoiceId && orderDetails?.date ?
                <div className="invoice pointer-cursor f-14 ta-left" onClick={()=>onDownloadOrderInvoice()}>
                  {t("DASHBOARD.ORDER_DETAILS.VIEW_INVOICE")}
                </div>
                :
                null}
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

const SuppliesShipped = (props) => {
  const { orderDetails } = props;
  const { t } = useTranslation(["translation"]);
 
  const formatAddrs = (addressObj) => {
    //convert into array
    const addressArr = Object.values(addressObj);
    const addressStr = addressArr.filter(Boolean).join(',');
    return addressStr.split(',').join(',\n');
  }
  return (
    <>
      <div className="order-details">
        <div className="order-details-card">
          <div className="supplies-shipped-img">
            <img src={PaymentRecievedIcon} alt="payment-recieved" />
          </div>
          <div className="supplies-shipped-header"> {getOrderTypeTranslation(orderDetails?.orderType)} </div>
          <div className="supplies-shipped-info container f-14 ta-left">
            <div className="row">
              <div className="col"> {t("DASHBOARD.ORDER_DETAILS.DATE_ORDERED")} </div>
              <div className="col">{orderDetails?.date}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="order-details set-margin">
        <div className="order-details-card">
          <div className="supplies-shipped-info container f-14 ta-left">
              <div className="row">
                <div className="col f-14 fw-b">{t("DASHBOARD.ORDER_DETAILS.PRODUCT_CODE")}</div>
                <div className="col f-14 fw-b">{t("DASHBOARD.ORDER_DETAILS.PRODUCT_QUANTITY")}</div>
              </div>
              {
                orderDetails.supplyShippedProducts.map((value, key) => (
                  <>
                    <div className="row">
                      <div className="col f-14"><div> {value.productCode}</div></div>
                      <div className="col f-14"><div> {value.productQuantity}</div></div>
                    </div>
                  </>
                  
                ))
              }
            </div>
        </div>
      </div>
      <div className="order-details set-margin">
        <div className="order-details-card">
          <div className="supplies-shipped-info container f-14 ta-left">
            <div className="row">
              <div className="col f-14 ta-left fw-b">{t("DASHBOARD.ORDER_DETAILS.SHIPPING_ADDRESS")}</div>
              <div className="col f-18">
                <div> {formatAddrs(orderDetails.shippingAddress)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DashboardOrderDetails;
