import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { routePath } from "../../../constants/Routes";
import addNewDevice from '../../../assets/images/addDevice.svg'
import DisplayUpdateAccountConfirmation from "../../../components/EditMyAccount/DisplayUpdateAccountConfirmation/DisplayUpdateAccountConfirmation";
import { EXTERNAL_LINK_ENDPOINT } from "../../../constants/ENVSettings";
import { UNIQUE_ID_GA } from "../../../constants/AppConstants";

const AddNewDevice = () => {
    const { t } = useTranslation(["translation"]);
    const history = useHistory()
    const changeDeviceConfirmed = () => {
      // @ to be tested in ios
      window.location.href = EXTERNAL_LINK_ENDPOINT.mobileConnectApp
    }
    const onSelectRegisteredDevice = () =>{
      history.push(routePath.CHANGE_DEVICE)
    }
   
  return (
    <div>
      <DisplayUpdateAccountConfirmation
        updateAddressHeader={t("SWAP_DEVICE.ADD_NEW_DEVICE.HEADER")}
        updateAddressInfo={t("SWAP_DEVICE.ADD_NEW_DEVICE.INFO")}
        subInfo={t("SWAP_DEVICE.ADD_NEW_DEVICE.SUB_INFO")}
        updateAddressBtn={t("SWAP_DEVICE.ADD_NEW_DEVICE.BTN_TEXT")}
        onUpdateClick={() => changeDeviceConfirmed()}
        onRejectClick={() => onSelectRegisteredDevice()}
        noThanksStatus={t("SWAP_DEVICE.ADD_NEW_DEVICE.LINK_TEXT")}
        image={addNewDevice}
        onUpdateBtnID={UNIQUE_ID_GA.addNewDeviceMobileConnect}
      ></DisplayUpdateAccountConfirmation>
    </div>
    );
};

export default AddNewDevice;
