import React from "react";
import { useTranslation } from "react-i18next";
import ListPlan from "../../../../components/Web/Customer/ListPlan/ListPlan";
import WebSubHeader from "../../../../components/WebSubHeader/WebSubHeader";
import check from "../../../../assets/images/planFeaturecheck.svg";
import { useHistory } from "react-router-dom";
import { routePath } from "../../../../constants/Routes";
import { useDispatch } from "react-redux";
import { pushDataLayer } from "../../../../constants/AppMethod";
import { setLocalSessionData } from "../../../../utils/Authentication/handleUserSession";
import { LOCALSESSIONDATAKEYS } from "../../../../constants/AppConstants";
import { selectPlan } from "../../../../actions/MyPlan";

const SelectPlanDesktop = (props) => {
    const { plans, flowType, showBackNavArrow = false, isPromotionBannerEnabled } = props;
    const dispatch = useDispatch();
    const { t }  = useTranslation("translation");
    const history = useHistory();


    const planFeature = (
        <div className="plan-feature-wrapper">
          <div className="plan-feature">
            <li>
              <img src={check} alt="plan-point" />
              <span>{t("CUSTOMER_WEB.SELECT_PLAN.EXTENDED_WARRANTY")}</span>
            </li>
            <li>
              <img src={check} alt="plan-point" />
              <span>{t("CUSTOMER_WEB.SELECT_PLAN.CANCEL_ANYTIME")}</span>
            </li>
            <li>
              <img src={check} alt="plan-point" />
              <span>{t("CUSTOMER_WEB.SELECT_PLAN.FREE_DELIVERY")}</span>
            </li>
          </div>
        </div>
       );

    const moveToPreviousPage = () => {
        history.push(routePath.SHENLONG_REDIRECT);
    }

    const moveToAccountPage = (selectedPlan) => {
        pushDataLayer('plan_selected','plan_id',selectedPlan.planId)
        setLocalSessionData(LOCALSESSIONDATAKEYS.CURRENCY,selectedPlan.currency)
        dispatch(selectPlan(selectedPlan));
        history.push({pathname:routePath.ACCOUNT, state:{selectedPlan:selectedPlan}});
      }
    /**
     * @todo handle back navigation for plans if it has device selection page
     * change header as per media query.
     */
    return (
        <div className="planview-desktop">
            <WebSubHeader
                currentStep="2"
                totalStep="4"
                stepName={t('SUB_HEADER.STEP_2.STEP_NAME')}
                stepInfo={t('SUB_HEADER.STEP_2.STEP_INFO')}
                moveToPreviousPage={moveToPreviousPage}
                previousBtnText={t('CUSTOMER_WEB.SELECT_PLAN.DEVICE_SELECTION')}
                showBackNavArrow = {showBackNavArrow}
            />
            {planFeature}
            <ListPlan plansData={plans} moveToAccountPage={moveToAccountPage} flowType={flowType} isPromotionBannerEnabled={isPromotionBannerEnabled}/>
        </div>
    );
}

export default SelectPlanDesktop;