/**
 * Method to validate and format date.
 * @param {string} dateString 
 * @param {object} dateFormat 
 * @param {string} locales
 * @returns string
 */

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function DisplayDate(dateString, dateFormat, locales = 'en-GB'){
    let date = "";
    if(new Date(dateString).toString() !== 'Invalid Date'){
        date = new Date(dateString).toLocaleString(locales, dateFormat)
    }

    return date;
}