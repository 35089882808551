import * as actionTypes from "../../constants/ActionTypes/PrintActivity";

const initialState = {
    printActivity: {},
    loading: false,
    error: "",
    success: false,
}

const PrintActivity = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.GET_PRINT_ACTIVITY_INIT:
            return {
                ...state,
                loading: true,
                error: ""
            };
        case actionTypes.GET_PRINT_ACTIVITY_SUCCESS:
            return {
                ...state,
                printActivity: action.printActivity,
                loading: false,
                success: true,
                error: "",
            };
        case actionTypes.GET_PRINT_ACTIVITY_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message
            };
        default:
            return {...state};
    }
}

export default PrintActivity;