import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { fetchFAQSublist } from "../../../../actions/FAQ";
import ErrorPage from "../../../../components/shared/errorPage/ErrorPage";
import Loader from "../../../../components/shared/loader/Loader";
import { refreshBtn } from "../../../../constants/AppConstants";
import FAQ from "../../../../components/FAQ/FAQ";
import "./SubList.css"

const FAQSublist = () => {
    let { cat_id } = useParams();
    const dispatch = useDispatch();
    const FAQSublist = useSelector(state => state.FAQSublist);
    
    useEffect(() => {
        dispatch(fetchFAQSublist(cat_id));
    }, [dispatch, cat_id]);

    if(FAQSublist.loading){
       <Loader></Loader>
    }
    if(FAQSublist.error){
        <ErrorPage errorInfo={FAQSublist.error} showRefreshBtn={refreshBtn.SHOW}></ErrorPage>
    }
    return (
        <Container className="FAQ-wrapper mt-40">
            <FAQ FAQCatList={FAQSublist.FAQSublist} FAQType="sub_list" FAQMainTitle = {cat_id.charAt(0).toUpperCase() + cat_id.slice(1)}></FAQ>
        </Container>
    )
}

export default FAQSublist;