import React from "react";
import { useTranslation } from "react-i18next";

const CustomerDetails = (props) => {
    const {t} = useTranslation("CSAgenttranslation");
    const emailID = props?.emailAddress;
    const telephoneNo = props?.phoneNumber;
    const mobileNo = props?.mobileNumber;
    const shippingAddress = {
        "address" : props?.shippingAddress?.addressLine1,
        "address2": props?.shippingAddress?.addressLine2,
        "city": props?.shippingAddress?.cityTown,
        "country": props?.shippingAddress?.country,
        "postcode": props?.shippingAddress?.postCode,
    }
    const billingAddress = {
        "address" : props?.billingAddress?.addressLine1,
        "address2": props?.billingAddress?.addressLine2,
        "city": props?.billingAddress?.cityTown,
        "country": props?.billingAddress?.country,
        "postcode": props?.billingAddress?.postCode,
    }
    return(
        <div className="overview-container customer-details-wrapper mt-40">
            <div id="personal-details">
                <div className="header">
                    <div className="title">
                        <h1>{t('CUSTOMER_DASHBOARD.CUSTOMER_DETAILS.PERSONAL_INFORMATION')}</h1>
                    </div>
                    {/* <div className="last-updated-date-info">
                        <p>Last updated: 07/03/2022  - 17:19pm</p>
                    </div> */}
                </div>
                <div className="device-details-info mt-15">
                    <p>{t('CUSTOMER_DASHBOARD.CUSTOMER_DETAILS.EMAIL_ADDRESS')}: <span>{emailID}</span></p>
                    <p>{t('CUSTOMER_DASHBOARD.CUSTOMER_DETAILS.MOBILE_TELEPHONE')}: <span>{mobileNo}</span></p>
                    <p>{t('CUSTOMER_DASHBOARD.CUSTOMER_DETAILS.TELEPHONE')}: <span>{telephoneNo}</span></p>
                </div>
            </div>
            <div id="shipping-details" className="mt-40">
                <div className="header">
                    <div className="title">
                        <h1>{t('CUSTOMER_DASHBOARD.CUSTOMER_DETAILS.SHIPPING_ADDRESS')}</h1>
                    </div>
                    {/* <div className="last-updated-date-info">
                        <p>Last updated: 07/03/2022  - 17:19pm</p>
                    </div> */}
                </div>
                <div className="device-details-info mt-15">
                    <p>{t('CUSTOMER_DASHBOARD.CUSTOMER_DETAILS.ADDRESS')}: <span>{shippingAddress.address}</span></p>
                    <p>{t('CUSTOMER_DASHBOARD.CUSTOMER_DETAILS.ADDRESS_LINE _2')}: <span>{shippingAddress.address2}</span></p>
                    <p>{t('CUSTOMER_DASHBOARD.CUSTOMER_DETAILS.TOWN_CITY')}: <span>{shippingAddress.city}</span></p>
                    <p>{t('CUSTOMER_DASHBOARD.CUSTOMER_DETAILS.COUNTRY')}: <span>{shippingAddress.country}</span></p>
                    <p>{t('CUSTOMER_DASHBOARD.CUSTOMER_DETAILS.POSTCODE')}: <span>{shippingAddress.postcode}</span></p>
                </div>
            </div>
            <div id="billing-details" className="mt-40">
                <div className="header">
                    <div className="title">
                        <h1>{t('CUSTOMER_DASHBOARD.CUSTOMER_DETAILS.BILLING_ADDRESS')}</h1>
                    </div>
                    {/* <div className="last-updated-date-info">
                        <p>Last updated: 07/03/2022  - 17:19pm</p>
                    </div> */}
                </div>
                <div className="device-details-info mt-15">
                <p>{t('CUSTOMER_DASHBOARD.CUSTOMER_DETAILS.ADDRESS')}: <span>{billingAddress.address}</span></p>
                    <p>{t('CUSTOMER_DASHBOARD.CUSTOMER_DETAILS.ADDRESS_LINE _2')}: <span>{billingAddress.address2}</span></p>
                    <p>{t('CUSTOMER_DASHBOARD.CUSTOMER_DETAILS.TOWN_CITY')}: <span>{billingAddress.city}</span></p>
                    <p>{t('CUSTOMER_DASHBOARD.CUSTOMER_DETAILS.COUNTRY')}: <span>{billingAddress.country}</span></p>
                    <p>{t('CUSTOMER_DASHBOARD.CUSTOMER_DETAILS.POSTCODE')}: <span>{billingAddress.postcode}</span></p>
                </div>
            </div>
        </div>
    )
}

export default CustomerDetails;