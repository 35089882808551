import React, { useCallback, useEffect, useState } from "react";
import './EcoProSDTicket.css'
import EcoProButton from "../../../shared/button/button";
import { DisplayDate } from "../../../../utils/DisplayDate";
import { BROTHER_SERVICE_DESK_BASE_URL } from "../../../../constants/AppConstants";
import EcoProOverlayModal from "../../../shared/modal/OverlayModal/EcoProOverlayModal";
import { useTranslation } from "react-i18next";
import SDTicketModalContent from "./SDTicketModalContent/SDTicketModalContent";

const EcoProSDTicket = (props) => {
    const {t} = useTranslation("CSAgenttranslation");
    const { customerInfo } = props;
    const NOT_AVAILABLE = 'NA';
    const { userId, deviceId, businessPartnerId = null } = customerInfo?.userAccount || {};
    const {model, serialNumber } = customerInfo?.deviceInfo || {};
    const modalPropsInitialState = {
        modal_title: "",
        modal_sub_title: "",
        modal_content: "",
        cancel_btn_text: t("MODAL_BUTTON_TXT.CANCEL"),
        confirm_btn_text: t("MODAL_BUTTON_TXT.CREATE"),
        toggleModal: "",
        customClassName: "sd-ticket-container",
        confirm_btn_onClick: "",
        type: "normal",
        modal_size: ""
    }
    const formValuesInitialState = {
        ticketSummary: '',
        ticketC4CReference: '',
        ticketCategory: ''
    }
    const [formValues, setFormValues] = useState(formValuesInitialState);
    const [errors, setErrors] = useState({});
    const [modalState, setModalState] = useState(false);
    const [modalProps, setModalProps] = useState(modalPropsInitialState)

    const toggleModal = () => {
        setModalState(false);
        setErrors({});
        setModalProps(modalPropsInitialState);
        setFormValues(formValuesInitialState);
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: ""
        }));
    };
    const handleDates = (date) => {
        return DisplayDate(date, { day: '2-digit', month: 'long', year: 'numeric' });
    }
    const handleServiceCancellationData = (serviceCancellationDates) => {
        let result;
        if(!serviceCancellationDates){
            result = NOT_AVAILABLE
        } else {
            result = `  
                        ** Cancellation Requested Date: ${handleDates(customerInfo?.serviceCancellationDates?.cancellationRequestedDate) || NOT_AVAILABLE}
                        ** Cancellation Date: ${handleDates(customerInfo?.serviceCancellationDates?.cancellationDate) || NOT_AVAILABLE}
                    `
        }

        return result;
    }
    /**
     * Method to construct summary for SD tickets
     * @param {string} ticketCategory 
     * @returns string
     */
    const constructTicketSummary = useCallback((ticketCategory) => {
        return encodeURIComponent(`${ticketCategory} - ${userId}`);
    }, [userId]);

    /**
     * Method to construct description for SD tickets
     * @param {string} description 
     * @param {string} SAPC4CRef 
     * @returns string
     */
    const constructTicketDescription = useCallback((description, SAPC4CRef) => {
        const txt = `
            ${description} \n
            * Customer ID: ${userId}
            * Business Partner Id: ${businessPartnerId}
            * SAP C4C Reference: ${SAPC4CRef || NOT_AVAILABLE}
            * Device ID: ${deviceId}
            * Device Model: ${model}
            * Device Serial Number: ${serialNumber}
            * Subscription Status: ${customerInfo?.subscriptionStatus}
            * Subscription Activated Date: ${handleDates(customerInfo?.subscriptionActivatedDate) || NOT_AVAILABLE}
            * Subscription Cancellation Dates: ${ handleServiceCancellationData(customerInfo?.serviceCancellationDates)}
        `;
        // Encode the string
        return encodeURIComponent(txt);
         // eslint-disable-next-line
    }, [userId, deviceId, serialNumber, customerInfo]);
    
    const handleCreateTicket = useCallback((formValues) => {
        const ticketCategory = formValues?.ticketCategory;
        const description = formValues.ticketSummary;
        const SAPC4CRef = formValues.ticketC4CReference;

        let validationErrors = {};
        if (!ticketCategory) {
            validationErrors.ticketCategory = t('SD_TICKET.MODAL.ERRORS.TICKET_CATEGORY');
        }
        if (!description) {
            validationErrors.ticketSummary = t('SD_TICKET.MODAL.ERRORS.TICKET_DESCRIPTION');
        }

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            const ticketSummary = constructTicketSummary(ticketCategory);
            const ticketDescription = constructTicketDescription(description, SAPC4CRef);
            const baseUrl = BROTHER_SERVICE_DESK_BASE_URL;
            const fullUrl = `${baseUrl}?summary=${ticketSummary}&description=${ticketDescription}`;
            window.open(fullUrl, "_blank");
        }
    }, [constructTicketDescription, constructTicketSummary, t]);

    useEffect(() => {
        setModalProps({
            modal_title: t('SD_TICKET.MODAL.TITLE'),
            modal_content: <SDTicketModalContent handleChange={handleChange} values={formValues} errors={errors} />,
            toggleModal: toggleModal,
            customClassName: "sd-ticket-container",
            confirm_btn_onClick: ()=>handleCreateTicket(formValues),
            cancel_btn_text: t("MODAL_BUTTON_TXT.CANCEL"),
            confirm_btn_text: t("MODAL_BUTTON_TXT.CREATE"),
            modal_size: 'lg'
        });
         // eslint-disable-next-line
    }, [formValues, modalState, errors,handleCreateTicket, t]);

    const handleSDTicketModal = () =>{
        setModalState(true);
    }
        
    return(
        <>
        <EcoProButton btnID="SDticket-btn" cname="default create-ticket-btn" onBtnClick={() => handleSDTicketModal()}>
           {t('SD_TICKET.CREATE_TICKET_BTN_TXT')}
        </EcoProButton>
        <EcoProOverlayModal {...modalProps} modal_state={modalState}></EcoProOverlayModal>
        </>
    );
}

export default EcoProSDTicket;