import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../../../constants/ActionTypes/Web/Customer/SignIn';
import { _CALLGETAPI } from '../../../api/apiHandler'
import { WEB_CUSTOMER_API_ENDPOINT } from '../../../constants/AppConstants';
import i18next from 'i18next';

function _GETPAYMENTDETAILS(UID){
    const endpoint = WEB_CUSTOMER_API_ENDPOINT.GET_PAYMENT_DETAILS;
    const payload = {
        params: {
            userId: UID
        }
      }
    return _CALLGETAPI(endpoint, payload )
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* handleUserPaymentDetails(action){
    try{
        const UID = action.UID;
        if(UID){
            const paymentDetails = yield call(_GETPAYMENTDETAILS, UID);
            yield put({type: actionTypes.FETCH_USER_PAYMENT_DETAILS_SUCCESS, paymentDetails: paymentDetails});
        } else {
            yield put({type: actionTypes.FETCH_USER_PAYMENT_DETAILS_ERROR, message: i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')});
        }
    }catch(e){
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
        if(e instanceof TypeError === false && e.message !== "401"){
            yield put({type: actionTypes.FETCH_USER_PAYMENT_DETAILS_ERROR, message: e.message});
        }
    }
}
function* GetUserPaymentDetails(){
    yield takeEvery(actionTypes.FETCH_USER_PAYMENT_DETAILS_INIT, handleUserPaymentDetails);
}

export default GetUserPaymentDetails;