import { CSAgentRoutePaths } from "../../constants/Routes";
import CustomerDashboard from "../../pages/CSAgent/CustomerDashboard/CustomerDashboard";
import CustomerSearch from "../../pages/CSAgent/CustomerSearch/CustomerSearch";

export const CSAgentProtectedRoutePath = [
    {
      path: CSAgentRoutePaths.CUSTOMER_SEARCH,
      component: CustomerSearch,
    },
    {
      path: CSAgentRoutePaths.CUSTOMER_SERVICE_DASHBOARD,
      component: CustomerDashboard,
    }
];