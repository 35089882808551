import React from "react";

const AdminFormCheckSwitchType = (props) => {
    const {wrapperCName, inputID, inputCName, inputName, labelCName, labelText, onChange, isChecked } = props;
  
    return (
        <div className={`admin-form-check form-check form-switch ${(wrapperCName) ? wrapperCName.split(',').join('') : "custom-none"}`}>
            <input id={inputID} className={`form-check-input  pointer-cursor ${ (inputCName) ? inputCName.split(',').join('') : "custom-none"}`} name={inputName} onChange={onChange} type="checkbox" checked={isChecked} />
            <label className={`form-check-label ${(labelCName) ? labelCName.split(',').join('') : "custom-none"}`} for="flexSwitchCheckDefault">{labelText}</label>
        </div>
    )
}

export default AdminFormCheckSwitchType;