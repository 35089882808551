import React, { useEffect } from "react";
import banner_EN from "../../../assets/images/easySetup/banner/banner_GB.png";
import banner_DE from "../../../assets/images/easySetup/banner/banner_DE.png";
import banner_FR from "../../../assets/images/easySetup/banner/banner_FR.png";
import banner_IT from "../../../assets/images/easySetup/banner/banner_IT.png";
import banner_NL from "../../../assets/images/easySetup/banner/banner_NL.png";
import sixMonthBannerImg from "../../../assets/images/easySetup/six-month-trial.png"
import "./EasySetupBanner.css";
import { Trans, useTranslation } from "react-i18next";
import { useQuery } from "../../../utils/CustomHooks/UseQuery";
import { useDispatch, useSelector } from "react-redux";
import { GetPromotionBannerStatus } from "../../../actions/Web/Customer/SignUp";
import Loader from "../../../components/shared/loader/Loader";

const EasySetupBanner = () => {
  const { t } = useTranslation(["translation"]);
  const queryParams = useQuery();
  const dispatch = useDispatch();
  const languageCode = queryParams.get("language");
  const bannerResult = useSelector((state) => state.GetPromotionBannerStatus);

  const imageUrlCountry = {
    EN: banner_EN,
    DE: banner_DE,
    FR: banner_FR,
    IT: banner_IT,
    NL: banner_NL,
  };

  const getImagesOnCountryCode = () => {
    if (languageCode) {
      return imageUrlCountry[languageCode.toUpperCase()];
    }
  };

  useEffect(() => {
    dispatch(GetPromotionBannerStatus());
  }, [dispatch]);

  if (bannerResult?.loading) {
    return <Loader></Loader>;
  }

  return bannerResult?.data?.displayPromotionBanner ? (
    <>
      <div className="easy-setup-six-months-banner-container">
        <div className="column-a">
          <img src={sixMonthBannerImg} alt="banner" />
        </div>
        <div className="column-b-six-month-promotion">
        <h1 className="header-1">
          <Trans i18nKey="EASY_SETUP.SIX_MONTH_PROMOTION.HEADER_1">
            SAVE MORE <span className="on-printing">on printing</span>
          </Trans>
        </h1>
          <h1 className="header-2">
          <Trans i18nKey="EASY_SETUP.SIX_MONTH_PROMOTION.HEADER_2">
            Get a 6 months
            <span className="free-trial">FREE EcoPro trial</span>
          </Trans></h1>
          <p>{t("EASY_SETUP.SIX_MONTH_PROMOTION.SUB_HEADER")}</p>
          <ul className="bullet-points">
            <li>{t("EASY_SETUP.BANNER.LINE2")}</li>
            <li>{t("EASY_SETUP.BANNER.LINE3")}</li>
            <li>{t("EASY_SETUP.BANNER.LINE4")}</li>
          </ul>
        </div>
      </div>
    </>
  ) : (
    <div className="easy-setup-banner-container">
      <div className="column-a">
        <img src={getImagesOnCountryCode()} alt="banner" />
      </div>
      <div className="column-b-banner">
        <h1>{t("EASY_SETUP.BANNER.HEADER")}</h1>
        <p>{t("EASY_SETUP.BANNER.LINE1")}</p>
        <p>{t("EASY_SETUP.BANNER.LINE2")}</p>
        <p>{t("EASY_SETUP.BANNER.LINE3")}</p>
        <p>{t("EASY_SETUP.BANNER.LINE4")}</p>
        <div className="learn-more-link">
          <a href="/#" target="_blank" rel="noreferrer">
            {t("EASY_SETUP.BANNER.LEARN_MORE")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default EasySetupBanner;
