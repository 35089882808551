import React, { useEffect } from "react";
import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { FetchUserBOCIdByADB2CId } from "../../../../../actions/Web/Customer/SignUp";
import ErrorPage from "../../../../../components/shared/errorPage/ErrorPage";
import Loader from "../../../../../components/shared/loader/Loader";
import { LOCALSESSIONDATAKEYS } from "../../../../../constants/AppConstants";
import { WebCustomerRoutePaths } from "../../../../../constants/Routes";
import { setLocalSessionData } from "../../../../../utils/Authentication/handleUserSession";

const VerifyUserAccount = () => {
    const dispatch = useDispatch();
    const {inProgress, accounts} = useMsal();
    const BOCUserDetails = useSelector(state => state.GetUserBOCIdByADB2CId);

    useEffect(() => {
        if(inProgress === InteractionStatus.None){
            setLocalSessionData(LOCALSESSIONDATAKEYS.AZURE_AD_B2C_UID, accounts[0].idTokenClaims.sub);
            dispatch(FetchUserBOCIdByADB2CId());
       }
       // eslint-disable-next-line
    }, [inProgress])
    if(BOCUserDetails.error){
        return <ErrorPage errorInfo={BOCUserDetails.error}></ErrorPage>
    }
    if(BOCUserDetails.success && !BOCUserDetails.data.userId){
        return <Redirect to={WebCustomerRoutePaths.CREATE_ACCOUNT}></Redirect>
    }
    if(BOCUserDetails.success && BOCUserDetails.data.userId){
        return <Redirect to={{ pathname: WebCustomerRoutePaths.PROCESS_ACCOUNT, state: { BOCUserId: BOCUserDetails.data.userId }}}></Redirect>
    }
    return (
        <Loader />
    );
}

export default VerifyUserAccount;