import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../../constants/ActionTypes/MyAccount';
import { _CALLGETAPI } from '../../api/apiHandler'
import { LOCALSESSIONDATAKEYS, _API_ENDPOINT } from '../../constants/AppConstants';
import { getLocalSessionData } from '../../utils/Authentication/handleUserSession';
import i18next from 'i18next';

function _OBTAINPAYMENTOPTIONS(){
     const endpoint = _API_ENDPOINT.PAYMENT_OPTIONS;
     const countryCode = getLocalSessionData(LOCALSESSIONDATAKEYS.ECOPROCTRYCODE);
     const payload = {
        params: {
            countryCode: countryCode
        }
      }
    return _CALLGETAPI(endpoint, payload)
    .then(response => {
        return response
    })
    .catch((error) => {
        throw error;
    });
}
function* handlePaymentOptionsCall(){
    try{
        const paymentOptions = yield call(_OBTAINPAYMENTOPTIONS);
        yield put({type: actionTypes.FETCH_PAYMENT_OPTIONS_SUCCESS, payment_options: paymentOptions})
    } catch(e) {
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
        if(e instanceof TypeError === false && e.message !== "401"){
            const msg = (e?.response?.status === 404) ? i18next.t('PAYMENT_OPTIONS.ERROR_INFO.PAYMENT_METHODS_NOT_AVAILABLE') : e.message;
            yield put({
                type: actionTypes.FETCH_PAYMENT_OPTIONS_ERROR, 
                message: msg
            });
        }
    }
}
function* PaymentOptions(){
    yield takeEvery(actionTypes.FETCH_PAYMENT_OPTIONS_INIT, handlePaymentOptionsCall);
}

export default PaymentOptions;