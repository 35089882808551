import { call, put, select, takeEvery } from 'redux-saga/effects';
import { _CALLGETAPI } from '../../../api/apiHandler'
import { WEB_CUSTOMER_API_ENDPOINT, SUCCESS, LOCALSESSIONDATAKEYS } from '../../../constants/AppConstants';
import * as actionTypes from '../../../constants/ActionTypes/Web/Customer/SignUp';
import i18next from 'i18next';
import { handleTokenAuthentication } from './TokenAuthentication';
import { setLocalSessionData } from '../../../utils/Authentication/handleUserSession';
import { pushDataLayer } from '../../../constants/AppMethod';

function _GETDEVICEREGISTRATION(token, UID, oneTimeCode){
    const endpoint = WEB_CUSTOMER_API_ENDPOINT.GET_WEB_DEVICE_REGISTRATION

    const payload = {
        params: {
            userId: UID.toUpperCase(),
            pin: oneTimeCode
        }
      }
    const headerTokenRequired = token?token:null
    return _CALLGETAPI(endpoint,payload, headerTokenRequired )
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* handleError(msg = null){
    pushDataLayer("ecopro_error", "error_type", "invalid code");
    yield put({
        type: actionTypes.GET_WEB_DEVICE_REGISTRATION_ERROR, 
        message: (msg) ? msg : i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')
    });
}

function* handleDeviceRegistration(action){
    let token = yield select(state => state.SCMSTokenAuthentication.scmsToken);
    let expiryTime = yield select(state => state.SCMSTokenAuthentication.tokenExpiryTime);

    try{
        const UID = action.UID
        const oneTimeCode = action.oneTimeCode
        if(UID && oneTimeCode){
            if (!token || expiryTime<new Date()) {
                // Token is not available or expired, fetch a new one
                yield call(handleTokenAuthentication, {UID:UID} );
            }
            const newToken = yield select(state => state.SCMSTokenAuthentication.scmsToken);

            const tokenAPIActionStatus  = yield select(state => state.SCMSTokenAuthentication.success)
            if(tokenAPIActionStatus){
                const deviceRegistration = yield call(_GETDEVICEREGISTRATION, newToken, UID, oneTimeCode);

                if(deviceRegistration.actionStatus.toUpperCase() === SUCCESS){

                    //updating the device details 
                    setLocalSessionData(LOCALSESSIONDATAKEYS.SERIAL_NUMBER, deviceRegistration.serialNumber);
                    setLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID, deviceRegistration.deviceId);
                    setLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_MODEL, deviceRegistration.modelName);
                    pushDataLayer("ecopro_code_submission", "status", "success");
                    yield put({type:  actionTypes.GET_WEB_DEVICE_REGISTRATION_SUCCESS, deviceRegistration: deviceRegistration});
                    yield put({ type: actionTypes.GET_BOC_WEB_DEVICE_CAPABILITY, UID:UID, deviceId: deviceRegistration.deviceId, modelName: deviceRegistration.modelName });
                
                } else {
                    yield* handleError();
                }
            }
            else{
                yield put({type: actionTypes.GET_SCMS_TOKEN_ERROR, message: i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')});
            }
        }
        else{
            yield* handleError();
        }
    }
    catch(e){
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
        if(e instanceof TypeError === false && e.message !== "401"){
            yield* handleError(e.message);
        }
    }
}
function* GetDeviceRegistration(){
    yield takeEvery(actionTypes.GET_WEB_DEVICE_REGISTRATION, handleDeviceRegistration);
}

export default GetDeviceRegistration;