import * as actionTypes from '../../constants/ActionTypes/SelectDevice';

const initialState = {
    deviceCapability: {},
    loading: false,
    error: null,
    success:false
  }

const DeviceCapability = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_DEVICE_CAPABILITY:
            return {
                ...state,
                loading: true
            }
        
        case actionTypes.GET_DEVICE_CAPABILITY_SUCCESS:
            return {
                ...state,
                deviceCapability: action.deviceCapability,
                loading: false,
                success: true
            }
        
        case actionTypes.GET_DEVICE_CAPABILITY_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message,
                success: false
            }
        case actionTypes.RESET_DEVICE_CAPABILITY:
            return{
                ...state,
                deviceCapability: {},
                loading: false,
                error: null,
                success:false
            }
        default:
            return {...state}
    }
}

export default DeviceCapability