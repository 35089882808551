export const FETCH_PLANS = 'FETCH_PLANS';
export const FETCH_PLANS_SUCCESS = 'FETCH_PLANS_SUCCESS';
export const FETCH_PLANS_ERROR = 'FETCH_PLANS_ERROR';

export const SELECT_PLAN = 'SELECT_PLAN';
export const SELECT_PLAN_SUCCESS = 'SELECT_PLAN_SUCCESS';
export const SELECT_PLAN_ERROR = 'SELECT_PLAN_ERROR';

export const FETCH_PLANS_LANDING_PAGE = 'FETCH_PLANS_LANDING_PAGE';
export const FETCH_PLANS_LANDING_PAGE_SUCCESS = 'FETCH_PLANS_LANDING_PAGE_SUCCESS';
export const FETCH_PLANS_LANDING_PAGE_ERROR = 'FETCH_PLANS_LANDING_PAGE_ERROR';

export const GET_BENEFIT_OF_SUPPLIES = 'GET_BENEFIT_OF_SUPPLIES'
export const GET_BENEFIT_OF_SUPPLIES_SUCCESS = 'GET_BENEFIT_OF_SUPPLIES_SUCCESS'
export const GET_BENEFIT_OF_SUPPLIES_ERROR = 'GET_BENEFIT_OF_SUPPLIES_ERROR'
