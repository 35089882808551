import * as actionTypes from '../../../constants/ActionTypes/Web/Customer/SignIn';

const initialState = {
    'success': false,
    'loading': false,
    'error': false,
    'data': {}
}

const GetUserPaymentDetails = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.FETCH_USER_PAYMENT_DETAILS_INIT:
            return {
                ...state,
                loading: true,
                'success': false,
                'error': false,
                'data': {}
            }
        case actionTypes.FETCH_USER_PAYMENT_DETAILS_SUCCESS:
            return {
                ...state,
                success: true,
                loading: false,
                data: action.paymentDetails,
                'error': false
            }
        case actionTypes.FETCH_USER_PAYMENT_DETAILS_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.message,
                'data': {}
            }
        case actionTypes.RESET_STATE:
            return {
                ...state,
                'success': false,
                'loading': false,
                'error': false,
                'data': {}
            }
        default:
            return {...state};
    }

}

export default GetUserPaymentDetails;