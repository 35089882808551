import { call, put, takeEvery } from 'redux-saga/effects';
import i18next from 'i18next';
import * as actionTypes from '../../constants/ActionTypes/SelectDevice';
import { _CALLGETAPI } from '../../api/apiHandler'
import { _API_ENDPOINT, FAILURE} from '../../constants/AppConstants';

function _GETDEVICEREGISTRATION(UID,deviceId){
    const endpoint = _API_ENDPOINT.DEVICE_REGISTRATION;
    const payload = {
        params: {
            userId:UID,
            deviceId: deviceId,
        }
      }
     return _CALLGETAPI(endpoint,payload)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* getDeviceRegistration(action) {
    try {
        const UID = action.UID
        const deviceId = action.deviceId

        if(deviceId && UID){
            const deviceRegistration = yield call(_GETDEVICEREGISTRATION,UID,deviceId);
            if(deviceRegistration.actionStatus.toUpperCase() === FAILURE){
                yield put({type: actionTypes.GET_DEVICE_REGISTRATION_ERROR, message: i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')})
            } else {
                yield put({type: actionTypes.GET_DEVICE_REGISTRATION_SUCCESS, deviceRegistration: deviceRegistration});
            }
        } else {
            yield put({
                type: actionTypes.GET_DEVICE_REGISTRATION_ERROR, 
                message: i18next.t('ERROR_CONTENT.INFO_GENERAL')
            });
        }
    } catch (e) {
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
        if(e instanceof TypeError === false && e.message !== "401"){
            yield put({type: actionTypes.GET_DEVICE_REGISTRATION_ERROR, message: e.message});
        }
    }
}

function* DeviceRegistration() {
    yield takeEvery(actionTypes.GET_DEVICE_REGISTRATION, getDeviceRegistration);
 }
 
 export default DeviceRegistration;