import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../../../constants/ActionTypes/Web/Customer/SignIn';
import { _CALLPOSTAPI } from '../../../api/apiHandler'
import { WEB_CUSTOMER_API_ENDPOINT, SUCCESS, _HARDWARE_API_ENDPOINTS } from '../../../constants/AppConstants';
import i18next from 'i18next';
import VerifyPreEnrollmentPayload from '../../../utils/MyAccount/VerifyPreEnrollmentPayload';
import { Is_HWInclusive_Signup } from '../../../constants/AppMethod';

function _VERIFYPREENROLLMENTINFO(payload){
    const Is_HWInclusive_SignupType = Is_HWInclusive_Signup();
    const endpoint = Is_HWInclusive_SignupType ? _HARDWARE_API_ENDPOINTS.VERIFY_HW_USER_PRE_ENROLL_INFO : WEB_CUSTOMER_API_ENDPOINT.VERIFY_USER_PRE_ENROLL_INFO;
    return _CALLPOSTAPI(endpoint, payload )
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* handleEnrollmentProcess(action){
    try{
        const verifyEnrollmentPayload = VerifyPreEnrollmentPayload(action.payload);
        const enrollmentDetails = yield call(_VERIFYPREENROLLMENTINFO, verifyEnrollmentPayload);
        if(enrollmentDetails.actionStatus.toUpperCase() === SUCCESS){
            yield put({type: actionTypes.VERIFY_PRE_ENROLLMENT_INFO_SUCCESS, enrollmentResponse: enrollmentDetails});
        } else {
            yield put({type: actionTypes.VERIFY_PRE_ENROLLMENT_INFO_ERROR, message: i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')});
        }
     }catch(e){
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
        if(e instanceof TypeError === false && e.message !== "401"){
            yield put({type: actionTypes.VERIFY_PRE_ENROLLMENT_INFO_ERROR, message: e.message});
        }
    }
}
function* VerifyPreEnrollmentInfo(){
    yield takeEvery(actionTypes.VERIFY_PRE_ENROLLMENT_INFO_INIT, handleEnrollmentProcess);
}

export default VerifyPreEnrollmentInfo;