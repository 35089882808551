import * as actionTypes from '../../constants/ActionTypes/Dashboard';

const initialState = {
    data: {},
    loading: false,
    error: "",
    success:false,
}

const ReactivateSubscription = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.REACTIVATE_SUBSCRIPTION_INIT:
            return {
                ...state,
                loading: true
            }
        case actionTypes.REACTIVATE_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                data: action.subscriptionResp,
                loading: false,
                success:true
            }
        case actionTypes.REACTIVATE_SUBSCRIPTION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message
            }
        case actionTypes.RESET_REACTIVATED_SUBSCRIPTION:
            return {
                ...state,
                loading: false,
                error: false,
                success: false,
                data: {}
            }
        default:
            return {...state};
    }
}

export default ReactivateSubscription;