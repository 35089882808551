import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DisplayNoDevice from '../../../../../../components/Web/Customer/DisplayNoDevice/DisplayNoDevice';
import { LOCALSESSIONDATAKEYS } from '../../../../../../constants/AppConstants';
import { getLocalSessionData } from '../../../../../../utils/Authentication/handleUserSession';
import { WebCustomerRoutePaths } from '../../../../../../constants/Routes';
import { navigateToMobileConnect, pushDataLayer } from '../../../../../../constants/AppMethod';
import { useHistory } from 'react-router-dom';


const WebNoDeviceFound = (props) => {
    const { t } = useTranslation(["translation"]);
    const history = useHistory();
    const model = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_MODEL);
    const nonDetectedDevice = {}
    const [selectedNonDetectedDeviceId, setSelectedNonDetectedDeviceId] = useState("")

    const onDeviceSelection = (device,type) => {
        setSelectedNonDetectedDeviceId(type);
    }
    const onConfirmEligibleDeviceSelection = () => {
        pushDataLayer('device_error','status','enrolled device not found')
        if(selectedNonDetectedDeviceId === 'add') {
            navigateToMobileConnect()
        } else{
            history.push(WebCustomerRoutePaths.SELECT_PRINTER);
        }
    }
    return (
        <div>
            <DisplayNoDevice 
                header={t("CUSTOMER_WEB.ADD_MY_DEVICE.CANNOT_DETECT_DEVICE",{model:model})}
                subheader={t("CUSTOMER_WEB.ADD_MY_DEVICE.CANNOT_DETECT_DEVICE_INFO")}
                selectInfo={t("CUSTOMER_WEB.ADD_MY_DEVICE.PLEASE_SELECT_OPTION")}
                nonDetectedDevice={nonDetectedDevice}
                nonDetectedDeviceName={t("CUSTOMER_WEB.ADD_MY_DEVICE.ADD_YOUR_DEVICE",{model:model})}
                nonDetectedDeviceInfo={t("CUSTOMER_WEB.ADD_MY_DEVICE.WITH_MOBILE_CONNECT")}
                selectedreplacementDeviceId={selectedNonDetectedDeviceId}
                changeEnrolledDeviceName={t("CUSTOMER_WEB.ADD_MY_DEVICE.CHANGE_ENROLLED_DEVICE")}
                changeEnrolledDeviceInfo={t("CUSTOMER_WEB.ADD_MY_DEVICE.AMEND_SUBSCRIPTION")}
                onRelacementSelection={onDeviceSelection}
                onConfirmEligibleDeviceSelection={onConfirmEligibleDeviceSelection}
                btnText = {t('ECOPRO_BTNS.CONTINUE')}
                btnId="enrolled_device_not_found"
            ></DisplayNoDevice>
        </div>
    );
};

export default WebNoDeviceFound;