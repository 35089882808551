import { call, put, takeLatest } from 'redux-saga/effects';
import i18next from 'i18next';
import * as actionTypes from '../../constants/ActionTypes/MyAccount';
import { _CALLGETAPI } from '../../api/apiHandler'
import { getLangCode } from '../../utils/Internationalization/handleAppInternationalization';
import { setClientParameterLocale } from '../../constants/AppMethod';
import { ECOPROTOKEN_CLAIMS, PAYMENT_OPTIONS, _API_ENDPOINT } from '../../constants/AppConstants';
import { getUserSessionData } from '../../utils/Authentication/handleUserSession';

function setEndPoint(paymentType){
    let endpoint;
    switch(paymentType) {
        case PAYMENT_OPTIONS.CREDIT_CARD:
            endpoint = _API_ENDPOINT.SETUP_BILLINGPAGE
            break;
        case PAYMENT_OPTIONS.DIRECT_BANK_TRANSFER:
            endpoint = _API_ENDPOINT.SEPA_BANk_TRANSFER_SETUP_BILLINGPAGE
            break;
        default:
            console.log(`Invalid input`);
    }
    return endpoint;
}

function _ZBILLINGRENDERCALL(paymentType){
    const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
    const endpoint = setEndPoint(paymentType);
    const payload = {
        params: {
            UserId: UID
        }
      }
    return _CALLGETAPI(endpoint, payload)
    .then(response => {
        let billingInfo = response;
        const languageCode = getLangCode()
        return {
            tenantId: billingInfo.tenantId,
            id: billingInfo.pageId,
            token: billingInfo.token,
            signature: billingInfo.signature,
            style: billingInfo.style,
            key: billingInfo.key,
            submitEnabled: true,
            url: billingInfo.uri,
            retainValues: true,
            field_accountId: billingInfo.fieldAccountId,
            locale:setClientParameterLocale(languageCode)
        }

    })
    .catch((error) => {
        throw error;
    });
}
function* handleZPaymentInfoRendering(action){
    try{
        const paymentType = action.paymentType;
        if(paymentType){
            const zrenderParams = yield call(_ZBILLINGRENDERCALL, paymentType);
            yield put({type: actionTypes.ACCOUNT_ZBILLING_INFO_RENDERING_SUCCESS, zrenderParams: zrenderParams})
        } else {
            yield put({
                type: actionTypes.ACCOUNT_ZBILLING_INFO_RENDERING_ERROR, 
                message: i18next.t('ERROR_CONTENT.INFO_GENERAL')
            }); 
        }
    } catch(e) {
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
        if(e instanceof TypeError === false && e.message !== "401"){
            yield put({
                type: actionTypes.ACCOUNT_ZBILLING_INFO_RENDERING_ERROR, 
                message: e.message
            });
        }
    }
}
function* ZuoraPaymentInfoRendering(){
    yield takeLatest(actionTypes.ACCOUNT_ZBILLING_INFO_RENDERING_INIT, handleZPaymentInfoRendering);
}

export default ZuoraPaymentInfoRendering;