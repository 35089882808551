import React from "react";
import { useTranslation } from "react-i18next";
import { ADDRESS_FIELDS_INPUT_MAX_LENGTH, BILLING_ADDR_ADDRESS1_INPUT_MAX_LENGTH, UNIQUE_ID_GA } from "../../../constants/AppConstants";
import { ItalyProvinces } from "../../../constants/Provinces/Italy";
import FormBrother from "../../Form/FormBrother";
import FormInput from "../../shared/formElements/FormInput";
import FormSelect from "../../shared/formElements/FormSelect";
import WebFormInput from "../../Web/Customer/Form/WebFormInput";
import { getCountryCode } from "../../../utils/Internationalization/handleAppInternationalization";

const EditBilling = ({submit,validate,initialValue,country}) => {
  return (
    <>
      <EditBillingMobileView
        submit = {submit}
        validate = {validate}
        initialValue = {initialValue}
        country = {country}
      />
      <EditBillingDesktopView
        submit = {submit}
        validate = {validate}
        initialValue = {initialValue}
        country = {country}
      />
      
    </>
  );
};
const EditBillingMobileView = ({submit,validate,initialValue,country}) => {
  const { t } = useTranslation(["translation"]);
  return (
      <div className="mt-15 address-edit-form-mobile-app-wrapper">
        <FormBrother
            submit={submit}
            account="billingAddress"
            status="active"
            title={t("FORM_CONTENT.FORM_TITLE.BILLING_ADDR")}
            wasValidated={validate}
            editTitle={t("EDIT_FORM.FORM_TITLE.BILLING_ADDR")}
            initialValues={initialValue}
            btnTitle={t("ECOPRO_BTNS.UPDATE")}
            btnID={UNIQUE_ID_GA.myAccount+"-billing-address"}
            action="billingAddress_edit"
            formType = "mobile-address-edit-form"
          >
            <FormInput
              bsClassName="col-12"
              label={t("FORM_CONTENT.FORM_LABEL.ADDRESS", {maxlength: BILLING_ADDR_ADDRESS1_INPUT_MAX_LENGTH})}
              name="address"
              id="address"
              placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.ADDRESS")}
              required={true}
              errorMsg={t("FORM_CONTENT.FORM_ERROR.ADDRESS")}
              minLength={1}
              maxLength={BILLING_ADDR_ADDRESS1_INPUT_MAX_LENGTH}
            />
            <FormInput
              bsClassName="col-12"
              label={t("FORM_CONTENT.FORM_LABEL.ADDRESS_LINE_2_OPT", {maxlength: ADDRESS_FIELDS_INPUT_MAX_LENGTH})}
              name="addressLine2"
              id={getCountryCode() === "IT"? "italyaddressLine2" : "addressLine2"}
              placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.ADDRESS_LINE_2_OPT")}
              required={false}
              minLength={0}
              maxLength={ADDRESS_FIELDS_INPUT_MAX_LENGTH}
            />
            <FormInput
              bsClassName="col-md-6"
              label={t("FORM_CONTENT.FORM_LABEL.TOWN/CITY")}
              name="city"
              id="city"
              placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.TOWN/CITY")}
              required={true}
              errorMsg={t("FORM_CONTENT.FORM_ERROR.TOWN/CITY")}
              minLength={1}
              maxLength={40}
            />
            <FormSelect
              bsClassName="col-md-6"
              label={t("FORM_CONTENT.FORM_LABEL.COUNTRY")}
              name="country"
              id="country"
              placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.COUNTRY")}
              required={true}
              errorMsg={t("FORM_CONTENT.FORM_ERROR.COUNTRY")}
              minLength={1}
              maxLength={100}
              optionData={country}
            />
            <FormSelect
                bsClassName="col-md-12"
                label={t("FORM_CONTENT.FORM_LABEL.PROVINCE")}
                name="province"
                id="province"
                placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.PROVINCE")}
                required={true}
                errorMsg={t("FORM_CONTENT.FORM_ERROR.PROVINCE")}
                minLength={1}
                maxLength={100}
                optionData={ItalyProvinces}
              />
            <FormInput
              bsClassName="col-12 address-edit-form postcode-input-wrapper"
              label={t("FORM_CONTENT.FORM_LABEL.POSTCODE")}
              name="postcode"
              id="postcode"
              placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.POSTCODE")}
              required={true}
              errorMsg={t("FORM_CONTENT.FORM_ERROR.POSTCODE_VALID")}
              minLength={1}
              maxLength={20}
            />
            <FormInput
              bsClassName="col-12 address-edit-form company-input-wrapper"
              label={t("FORM_CONTENT.FORM_LABEL.COMPANY_OPT")}
              name="company"
              id="company"
              placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.COMPANY_OPT")}
              required={false}
              minLength={0}
              maxLength={100}
              requiredLabel={t("FORM_CONTENT.FORM_LABEL.COMPANY")}
              errorMsg={t("FORM_CONTENT.FORM_ERROR.COMPANY")}
            />
            <FormInput
              bsClassName="col-12"
              label={t("FORM_CONTENT.FORM_LABEL.SDI")}
              name="eInvoiceId"
              id="eInvoiceId"
              placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.SDI")}
              required={true}
              errorMsg={t("FORM_CONTENT.FORM_ERROR.SDI")}
              minLength={7}
              maxLength={7}
            />
        </FormBrother>
      </div>
    )
}
const EditBillingDesktopView = ({submit,validate,initialValue,country}) => {
  const { t } = useTranslation(["translation"]);
  return(
    <div className="mt-15 address-edit-form-desktop-app-wrapper">
      <FormBrother
      web={true}
      submit={submit}
      account="billingAddress"
      status="active"
      title={t("FORM_CONTENT.FORM_TITLE.BILLING_ADDR")}
      wasValidated={validate}
      editTitle={t("EDIT_FORM.FORM_TITLE.BILLING_ADDR")}
      initialValues={initialValue}
      btnTitle={t("ECOPRO_BTNS.UPDATE")}
      btnID={UNIQUE_ID_GA.myAccount+"-billing-address"}
      action="billingAddress_edit"
      formType = "web-address-edit-form"
      validationclassName="web-needs-validation"
    >
      <WebFormInput
        bsClassName="col-12"
        label={t("FORM_CONTENT.FORM_LABEL.ADDRESS", {maxlength: BILLING_ADDR_ADDRESS1_INPUT_MAX_LENGTH})}
        name="address"
        id="address"
        placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.ADDRESS")}
        required={true}
        errorMsg={t("FORM_CONTENT.FORM_ERROR.ADDRESS")}
        minLength={1}
        maxLength={BILLING_ADDR_ADDRESS1_INPUT_MAX_LENGTH}
      />
      <WebFormInput
        bsClassName="col-12"
        label={t("FORM_CONTENT.FORM_LABEL.ADDRESS_LINE_2_OPT", {maxlength: ADDRESS_FIELDS_INPUT_MAX_LENGTH})}
        name="addressLine2"
        id={getCountryCode() === "IT"? "italyaddressLine2" : "addressLine2"}
        placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.ADDRESS_LINE_2_OPT")}
        required={false}
        minLength={0}
        maxLength={ADDRESS_FIELDS_INPUT_MAX_LENGTH}
      />
      <WebFormInput
        bsClassName="col-md-6"
        label={t("FORM_CONTENT.FORM_LABEL.TOWN/CITY")}
        name="city"
        id="city"
        placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.TOWN/CITY")}
        required={true}
        errorMsg={t("FORM_CONTENT.FORM_ERROR.TOWN/CITY")}
        minLength={1}
        maxLength={40}
      />
      <FormSelect
        webClassName = "form-floating"
        bsClassName="col-md-6"
        label={t("FORM_CONTENT.FORM_LABEL.COUNTRY")}
        name="country"
        id="country"
        placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.COUNTRY")}
        required={true}
        errorMsg={t("FORM_CONTENT.FORM_ERROR.COUNTRY")}
        minLength={1}
        maxLength={100}
        optionData={country}
      />
      <FormSelect
          webClassName = "form-floating"
          bsClassName="col-md-12"
          label={t("FORM_CONTENT.FORM_LABEL.PROVINCE")}
          name="province"
          id="province"
          placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.PROVINCE")}
          required={true}
          errorMsg={t("FORM_CONTENT.FORM_ERROR.PROVINCE")}          minLength={1}
          maxLength={100}
          optionData={ItalyProvinces}
        />
        <WebFormInput
          bsClassName="col-6 address-edit-form postcode-input-wrapper"
          label={t("FORM_CONTENT.FORM_LABEL.POSTCODE")}
          name="postcode"
          id="postcode"
          placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.POSTCODE")}
          required={true}
          errorMsg={t("FORM_CONTENT.FORM_ERROR.POSTCODE_VALID")}
          minLength={1}
          maxLength={20}
        />
        <WebFormInput
          bsClassName="col-6 address-edit-form company-input-wrapper"
          label={t("FORM_CONTENT.FORM_LABEL.COMPANY_OPT")}
          name="company"
          id="company"
          placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.COMPANY_OPT")}
          required={false}
          minLength={0}
          maxLength={100}
          requiredLabel={t("FORM_CONTENT.FORM_LABEL.COMPANY")}
          errorMsg={t("FORM_CONTENT.FORM_ERROR.COMPANY")}
        />
        <WebFormInput
          bsClassName="col-12"
          label={t("FORM_CONTENT.FORM_LABEL.SDI")}
          name="eInvoiceId"
          id="eInvoiceId"
          placeholder={t("FORM_CONTENT.FORM_PLACEHOLDER.SDI")}
          required={true}
          errorMsg={t("FORM_CONTENT.FORM_ERROR.SDI")}
          minLength={7}
          maxLength={7}
        />
      </FormBrother>
    </div>
  )
}

export default EditBilling;
