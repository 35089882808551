import { call, put, takeEvery } from "redux-saga/effects";
import { _CALLGETAPI } from "../../api/apiHandler";
import * as actionTypes from "../../constants/ActionTypes/PrintActivity"
import { LOCALSESSIONDATAKEYS, _API_ENDPOINT, _CSAGENT_API_ENDPOINTS } from "../../constants/AppConstants";
import i18next from "i18next";
import { SUCCESS } from "../../constants/AppConstants";
import { getLocalSessionData, getUserDataByKey } from "../../utils/Authentication/handleUserSession"

function _GETPRINTACTIVITY(UID, deviceId, IS_CSAGENT, customerCountryCode) {
    const endpoint = (IS_CSAGENT) ? _CSAGENT_API_ENDPOINTS.GET_PRINT_HISTORY :  _API_ENDPOINT.GET_PRINT_HISTORY;
    const personalOrBusinessAccount = getUserDataByKey('businessModel')
    const deviceModel = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_MODEL)
    let payload;
    if(deviceModel){
        payload = {
            params: {
                userId: UID,
                deviceId: deviceId,
                personalOrBusiness: personalOrBusinessAccount,
                deviceModel:getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_MODEL)
            }
        }
    }else{
        payload = {
            params: {
                userId: UID,
                deviceId: deviceId,
                personalOrBusiness: personalOrBusinessAccount
            }
        }
    }
    if(IS_CSAGENT){
        payload.params.countryCode = customerCountryCode;
    }
  
    return _CALLGETAPI(endpoint, payload)
    .then((response) => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}
function* handleError(message){
    yield put({
        type: actionTypes.GET_PRINT_ACTIVITY_ERROR, 
        message: message
    });
}

function* handle_CSAGENT_Error(message){
    yield put({
        type: actionTypes.GET_PRINT_ACTIVITY_ERROR, 
        message: message
    });
}

function* handlePrintActivity(action) {
    try {
        const UID = action.UID;
        const deviceId = action.deviceId;
        const IS_CSAGENT = action.IS_CSAGENT;
        const customerCountryCode = action.customerCountryCode;
        if (UID && deviceId) {
            const printActivity = yield call(_GETPRINTACTIVITY, UID, deviceId, IS_CSAGENT, customerCountryCode);
            if (printActivity.actionStatus.toUpperCase() === SUCCESS && printActivity.printHistory.length) {
                yield put({
                    type: actionTypes.GET_PRINT_ACTIVITY_SUCCESS,
                    printActivity: printActivity,
                });
            } else if (printActivity.actionStatus.toUpperCase() === SUCCESS && !printActivity.printHistory.length) {
                (IS_CSAGENT) 
                ? yield* handle_CSAGENT_Error(i18next.t('CSAgenttranslation:PRINT_ACTIVITY.NO_DATA_MSG')) 
                : yield* handleError(i18next.t('PRINT_ACTIVITY.NO_DATA_MSG'));
            } else {
                (IS_CSAGENT) 
                ? yield* handle_CSAGENT_Error(i18next.t('CSAgenttranslation:ERROR_CONTENT.TECHNICAL_ERROR')) 
                : yield* handleError(i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR'));
            }
        } else {
           (IS_CSAGENT) 
           ? yield* handle_CSAGENT_Error(i18next.t('CSAgenttranslation:ERROR_CONTENT.INFO_GENERAL')) 
           : yield* handleError( i18next.t('ERROR_CONTENT.INFO_GENERAL'));
        }
    } catch (e) {
        yield put({
            type: actionTypes.GET_PRINT_ACTIVITY_ERROR,
            message: e.message
        });
    }
}

function* GetPrintActivity() {
    yield takeEvery(actionTypes.GET_PRINT_ACTIVITY_INIT, handlePrintActivity);
}

export default GetPrintActivity;