import axios from "axios";
import { ECOPROTOKEN_CLAIMS } from "../../constants/AppConstants";
import { currentUserRoleByOrder } from "../../constants/AppMethod";
import { getUserDataByKey, getUserSessionData } from "../Authentication/handleUserSession";

const UserActivityLogsPayload = async (currentLoggedInUserDetails) => {
    const { action, description } = currentLoggedInUserDetails;
    let obj = {
        "UserId": getUserSessionData(ECOPROTOKEN_CLAIMS.USERID),
        "ADB2CId": getUserSessionData(ECOPROTOKEN_CLAIMS.ADB2CUSERID),
        "EmailId": getUserDataByKey('emailAddress', 'personal_details'),
        "RoleName": currentUserRoleByOrder(),
        "Action":action,
        "Description": description,
    };
    try {
        const response = await axios.get("https://api.ipify.org/?format=json");
        const res = await response;
        const IP_ADDRESS = (res?.data?.ip) ? res?.data?.ip : ""
        obj = {...obj, "ipAddress": IP_ADDRESS}
        return obj;
    } catch (e) {
        obj = {...obj, "ipAddress": ""}
        return obj;
    }
}

export default UserActivityLogsPayload;