import * as actionTypes from '../../../constants/ActionTypes/Web/Customer/SignIn';

const initialState = {
    'success': false,
    'loading': false,
    'error': false,
    'data': {}
}

const GetPreEnrollmentInfo = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.GET_PRE_ENROLLMENT_INFO_INIT:
            return {
                ...state,
                loading: true,
                'success': false,
                'error': false,
                'data': {}
            }
        case actionTypes.GET_PRE_ENROLLMENT_INFO_SUCCESS:
            return {
                ...state,
                success: true,
                loading: false,
                data: action.enrollmentResponse,
                'error': false
            }
        case actionTypes.GET_PRE_ENROLLMENT_INFO_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.message,
                'data': {}
            }
        case actionTypes.RESET_STATE:
            return {
                ...state,
                'success': false,
                'loading': false,
                'error': false,
                'data': {}
            }
        default:
            return {...state};
    }

}

export default GetPreEnrollmentInfo;