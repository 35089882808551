import React from 'react';
import './PromotionBanner.css'

const PromotionBanner = (props) => {
    const { toDisplayfreeTrialButton = false, StartFreeTrialButton, promotionText} = props
      
    return (
        <div className={`promotion-banner-wrapper ${toDisplayfreeTrialButton ? 'promotion-banner-with-button':""}`}>
            <div className='promotion-text'>{promotionText}</div>
            {toDisplayfreeTrialButton ? StartFreeTrialButton: null}
        </div>
    );
};

export default PromotionBanner;