import { combineReducers } from "redux";
import FeatureSwitchCountry from "./AdminPortal/FeatureSwitch/Country";
import UpdateFeatureSwitch from "./AdminPortal/FeatureSwitch/UpdateFeatureSwitch";
import FeatureSwitchFeatureList from "./AdminPortal/FeatureSwitch/FeatureList";
import AdminUser_ADD from "./AdminPortal/AdminUsers/add";
import AdminUser_VIEW from "./AdminPortal/AdminUsers/view";
import TESIInvoice_VIEW from "./AdminPortal/InvoicePosting/view";
import TESIInvoice_RETRY from "./AdminPortal/InvoicePosting/retry";
import MaintenanceServiceStatus_FETCH from "./AdminPortal/Settings/FetchMaintenanceServiceStatus";
import MaintenanceServiceStatus_UPDATE from "./AdminPortal/Settings/UpdateMaintenanceServiceStatus";
import PrinterModels_ByCountry from "./AdminPortal/PrinterModels/Country";
import PrinterModels_ByModels from "./AdminPortal/PrinterModels/Models";
import UpdatePrinterModelStatus from "./AdminPortal/PrinterModels/UpdatePrinterModelStatus";
import UserSessionLog from "./AdminPortal/UserActivityLogs/UserSessionLog";

export default combineReducers({
    FeatureSwitchCountry: FeatureSwitchCountry,
    UpdateFeatureSwitch: UpdateFeatureSwitch,
    FeatureSwitchFeatureList: FeatureSwitchFeatureList,
    AdminUser_ADD: AdminUser_ADD,
    AdminUser_VIEW: AdminUser_VIEW,
    TESIInvoice_VIEW: TESIInvoice_VIEW,
    TESIInvoice_RETRY: TESIInvoice_RETRY,
    MaintenanceServiceStatus_FETCH: MaintenanceServiceStatus_FETCH,
    MaintenanceServiceStatus_UPDATE: MaintenanceServiceStatus_UPDATE,
    PrinterModels_ByCountry: PrinterModels_ByCountry,
    PrinterModels_ByModels: PrinterModels_ByModels,
    UpdatePrinterModelStatus: UpdatePrinterModelStatus,
    UserSessionLog: UserSessionLog
});