import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import HardwareSignupCard from '../../../HardwareSignupCard/HardwareSignupCard';
import LearnMorePrinterFeature from '../../LearnMorePrinterFeature/LearnMorePrinterFeature';
import { useState } from 'react';

const DisplayHardwareType = (props) => {
    const { handleSelectionChange, selectedData, dueTodayPrice} = props

    const {t} = useTranslation(["translation"])

    const [show, setShow] = useState(false);


    const handleClose = () => { 
        setShow(false)
    };
    const DisplayLearnMore = () => setShow(true);
    return (
        <div>
            <div className='cp-subtitle'>{t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.HARDWARE_TYPE.HEADER")}</div>
            <HardwareSignupCard 
                onHardwarePlanSelection={handleSelectionChange}
                selectedCard={selectedData}
                toCompareSelectedCardWith={t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.HARDWARE_TYPE.REFURBISHED")}
                cardRightData="Refurbished" 
                cardLeftData={t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.HARDWARE_TYPE.REFURBISHED_PRICE", {price:dueTodayPrice})}
            ></HardwareSignupCard>
                <div className='cp-learn-more'>
                <Trans i18nKey="HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.HARDWARE_TYPE.LEARN_MORE" components={{ 1: <button className='cp-btn-link' onClick={DisplayLearnMore} /> }}>
                <button className='cp-btn-link' onClick={DisplayLearnMore}>Learn more</button> refurbished device by Brother
            </Trans>
                </div>
            <LearnMorePrinterFeature   
                show={show}
                handleClose={handleClose}
            ></LearnMorePrinterFeature>
        </div>
    );
};

export default DisplayHardwareType;