import React from "react";
import { Modal } from "reactstrap";
import EcoProButton from "../../shared/button/button";
import "./AdminOverlayModal.css"

const AdminOverlayModal = (props) => {
    const { 
        modalClassNames, 
        isOpen, 
        toggleAdminOverlayModal,
        modal_title,
        modal_sub_title,
        modal_content,
        showCancelBtn,
        cancel_btn_text,
        showConfirmBtn,
        confirm_btn_text,
        handleModalConfirm
    } = props;
    return (
        <Modal isOpen={isOpen} toggle={() => toggleAdminOverlayModal()} className={`admin-modal ${(modalClassNames) ? modalClassNames.split(",").join("") : 'custom-none'}`}>
             <div className="admin-overlay-modal-header ecopro-modal-header">
                <h5>{modal_title}</h5>
                { modal_sub_title && <p className="modal-sub-title">{modal_sub_title}</p> }
            </div>
            <div className="admin-overlay-modal-content ecopro-modal-content f-14 mt-30">
                {modal_content}
            </div>
            <div className="admin-overlay-modal-footer ecopro-modal-footer mt-40">
                {showCancelBtn && 
                <EcoProButton cname="default white-bg-btn" onBtnClick={() => toggleAdminOverlayModal()}>{cancel_btn_text}</EcoProButton>}
                {showConfirmBtn && 
                <EcoProButton cname="default" onBtnClick={() => handleModalConfirm()}>{confirm_btn_text}</EcoProButton>}
            </div>
        </Modal>
    )
}

export default AdminOverlayModal;