import { call, put, select, takeEvery } from 'redux-saga/effects';
import { _CALLGETAPI } from '../../../api/apiHandler'
import { WEB_CUSTOMER_API_ENDPOINT, SUCCESS, FAILURE, BOC_WEB_DEVICE_CAPABILITY_CODES } from '../../../constants/AppConstants';
import * as actionTypes from '../../../constants/ActionTypes/Web/Customer/SignUp';
import i18next from 'i18next';
import { handleTokenAuthentication } from './TokenAuthentication';

function _GETBOCDEVICECAPABILITY(UID, deviceId, modelName, token){
    const endpoint = WEB_CUSTOMER_API_ENDPOINT.GET_BOC_WEB_DEVICE_CAPABILITY

     const payload = {
        params:{
            userId:UID.toUpperCase(),
            deviceId: deviceId,
            model: modelName
        }
     }
     const headerTokenRequired = token?token:null
    return _CALLGETAPI(endpoint, payload, headerTokenRequired )
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

 function* handleBocWebDeviceCapability(action){
        const UID = action.UID
        const deviceId = action.deviceId
        const modelName = action.modelName
        let token = yield select(state => state.SCMSTokenAuthentication.scmsToken);
        let expiryTime = yield select(state => state.SCMSTokenAuthentication.tokenExpiryTime);
    
    try{
        if(UID && deviceId && modelName){
            if (!token || expiryTime<new Date()) {
                // Token is not available or expired, fetch a new one
                yield call(handleTokenAuthentication, {UID:UID});
            }
            const newToken = yield select(state => state.SCMSTokenAuthentication.scmsToken);

            const tokenAPIActionStatus  = yield select(state => state.SCMSTokenAuthentication.success)
            if(tokenAPIActionStatus){
                const bocDeviceCapability = yield call(_GETBOCDEVICECAPABILITY, UID, deviceId, modelName, newToken );
                if(bocDeviceCapability.actionStatus.toUpperCase() === SUCCESS){
                    yield put({type: actionTypes.GET_BOC_WEB_DEVICE_CAPABILITY_SUCCESS, bocDeviceCapability: bocDeviceCapability});

                }
                else if(bocDeviceCapability.actionStatus.toUpperCase() === FAILURE && bocDeviceCapability.customCode === BOC_WEB_DEVICE_CAPABILITY_CODES.UNSUPPORTED_FIRMWARE_VERSION.customeCode) {
                    yield put({type: actionTypes.GET_BOC_WEB_DEVICE_CAPABILITY_SUCCESS, bocDeviceCapability: bocDeviceCapability});
                }
                else if(bocDeviceCapability.actionStatus.toUpperCase() === FAILURE && bocDeviceCapability.customCode === BOC_WEB_DEVICE_CAPABILITY_CODES.DEVICE_OFFLINE.customeCode) {
                    yield put({type: actionTypes.GET_BOC_WEB_DEVICE_CAPABILITY_SUCCESS, bocDeviceCapability: bocDeviceCapability});
                }
                else {
                    yield put({type: actionTypes.GET_BOC_WEB_DEVICE_CAPABILITY_ERROR, message: i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')});
                }
            }
            else{
                yield put({type: actionTypes.GET_SCMS_TOKEN_ERROR, message: i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')});
            }

           
        }
        else{
            yield put({type: actionTypes.GET_BOC_WEB_DEVICE_CAPABILITY_ERROR, message: i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR')});
        }
    }catch(e){
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
        if(e instanceof TypeError === false && e.message !== "401"){
            yield put({type: actionTypes.GET_BOC_WEB_DEVICE_CAPABILITY_ERROR, message: e.message});
        }
    }
}
function* GetBOCWebDeviceCapability(){
    yield takeEvery(actionTypes.GET_BOC_WEB_DEVICE_CAPABILITY, handleBocWebDeviceCapability);
}

export default GetBOCWebDeviceCapability;