import React from "react";
import { useContext } from "react";
import { FormContext } from "../../Form/FormBrother";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useSelector } from "react-redux";
import { parsePhoneNumber } from "react-phone-number-input";
import { useTranslation } from "react-i18next";
import { ENVSettings } from "../../../constants/ENVSettings";
import "./FormTel.css";

const FormTel = (props) => {
  const  { t } = useTranslation("translation");
  const { bsClassName, label, name, placeholder, id, required, errorMsg, defaultCountry,webClassName } = props;
  let errorMessage = null;
  const formContext = useContext(FormContext);
  const { formContent, handleTel } = formContext;
  const result = useSelector((state) => state.MyAccount);
  const whiteListedCountries = ENVSettings.COUNTRIES.map((obj) => {return obj.code2});
  errorMessage = errorMsg;
  if(formContent.hasOwnProperty(name) && (result.mobileError || result.telephoneError) && formContent[name]){
      const checkCountryCodeExists = parsePhoneNumber(formContent[name]);
      if(!checkCountryCodeExists){
        const fieldType = (name === "mobileNumber") ? "mobile" : "telephone";
        if(fieldType === "mobile"){
          errorMessage = t("FORM_CONTENT.FORM_ERROR.MOBILE_WITHOUT_COUNTRY_CODE")
        }
        else{
          errorMessage = t("FORM_CONTENT.FORM_ERROR.TELEPHONE_WITHOUT_COUNTRY_CODE")
        }
      }
  }

  return (
    <div className={`${bsClassName} ${webClassName}`}>
     {!webClassName && <label htmlFor={id} className="form-label f-12 fc-g">
        {label}
      </label>}
      <PhoneInput
        international
        placeholder={placeholder}
        countryOptionsOrder={[...whiteListedCountries, "|", "..."]}
        className={`form-control ${
          (result.mobileError === true && name === "mobileNumber") || (result.telephoneError === true && name === "telephoneNumber")
            ? "mobile-err-input"
            : null
        }`}
        id={id}
        name={name}
        value={formContent[name]}
        onChange={(event)=>handleTel(event,name)}
        required={required}
        defaultCountry={defaultCountry}
      />
      {webClassName && <label htmlFor={id} className="f-12 fc-g">
        {label}
      </label>}
      <div
        className={`invalid-feedback ${
          (result.mobileError === true && name === "mobileNumber") || (result.telephoneError === true && name === "telephoneNumber")
            ? "mobile-err"
            : null
        }`}
      >
        {errorMessage}
      </div>
    </div>
  );
};

export default FormTel;
