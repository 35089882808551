import * as actionTypes from '../../../constants/ActionTypes/Web/Customer/SignUp';

const initialState = {
    webServicePlan: {},
    loading: false,
    error: false,
    success: false
  }

const WebServicePlan = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_WEB_SERVICE_PLAN:
            return {
                ...state,
                loading: true,
                error: false,
                success:false,
                webServicePlan: {}
            }
        
        case actionTypes.FETCH_WEB_SERVICE_PLAN_SUCCESS:
            return {
                ...state,
                webServicePlan: action.webServicePlan,
                success:true,
                loading: false,
                error: false
            }
        
        case actionTypes.FETCH_WEB_SERVICE_PLAN_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message,
                webServicePlan: {},
                success:false
            }
        default:
            return {...state}
    }
}

export default WebServicePlan