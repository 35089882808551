import { call, put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "../../constants/ActionTypes/Dashboard";
import { _CALLPUTAPI } from "../../api/apiHandler";
import { _API_ENDPOINT, SUCCESS } from "../../constants/AppConstants";
import i18next from "i18next";

function _SWAPDEVICE(payload) {
  const endpoint = _API_ENDPOINT.SWAP_DEVICE;

  return _CALLPUTAPI(endpoint, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function* handleSwapDevice(action) {
  try {
    if (action.UID && action.oldDeviceId && action.newDeviceId) {
      const swapDevicePayload = {
        "userId": action.UID,
        "oldDeviceId": action.oldDeviceId,
        "newDeviceId": action.newDeviceId
      } 
      let swapDeviceSuccess = yield call(_SWAPDEVICE, swapDevicePayload);

      if (swapDeviceSuccess.actionStatus.toUpperCase() === SUCCESS) {
        yield put({
          type: actionTypes.SWAP_DEVICE_SUCCESS,
          swapDevice: swapDeviceSuccess,
        });
      } else {
        yield put({
          type: actionTypes.SWAP_DEVICE_ERROR,
          message: i18next.t("ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR"),
        });
      }
    } else {
      yield put({
        type: actionTypes.SWAP_DEVICE_ERROR,
        message: i18next.t("ERROR_CONTENT.INFO_GENERAL"),
      });
    }
  } catch (e) {
    /**
     * This conditional statement is added to avoid
     * displaying error message in view while logout from app
     */
    if(e instanceof TypeError === false && e.message !== "401"){
      const msg = (e.response && e.response.status === 400) ? i18next.t("ERROR_CONTENT.ERR_INFO.CANCEL_SUBSCRIPTION_ERROR") : e.message;
      yield put({ type: actionTypes.SWAP_DEVICE_ERROR, message: msg });
    }
  }
}

function* SwapDevice() {
  yield takeEvery(actionTypes.SWAP_DEVICE, handleSwapDevice);
}

export default SwapDevice;
