import React from "react";
import "./EcoProOverallFeedbackModal.css";
import { Modal } from "reactstrap";
import SignUpFeedback from "../SignUpFeedback/SignUpFeedback";
import { FEEDBACK_TYPES } from "../../constants/AppConstants";
import { useTranslation } from "react-i18next";
import ErrorPage from "../shared/errorPage/ErrorPage";


const EcoProOverallFeedbackModal = (props) => {
    const { isOpen, toggleModal, errorInfo } = props;
    const { t } = useTranslation(["translation"]);
    const issuesOptionListArr = [
      t("DASHBOARD_FEEDBACK.ISSUES_LIST.DASHBOARD_FEATURES"),
      t("DASHBOARD_FEEDBACK.ISSUES_LIST.PRODUCT_QUALITY"),
      t("DASHBOARD_FEEDBACK.ISSUES_LIST.PRICE"),
      t("DASHBOARD_FEEDBACK.ISSUES_LIST.REPLEMISHMENT_AUTOMATION"),
      t("DASHBOARD_FEEDBACK.ISSUES_LIST.DELIVERY_EXPERIENCE"),
      t("DASHBOARD_FEEDBACK.ISSUES_LIST.DELIVERY_TIMING"),
      t("DASHBOARD_FEEDBACK.ISSUES_LIST.OTHER"),
    ];
    return (
        <Modal id="ecopro-overall-feedback-modal" isOpen={isOpen} toggle={() => toggleModal()} className={`ecopro-overall-feedback-modal-wrapper ${(errorInfo) ? 'error-displaying-modal' : 'no-error'}`}>
            { 
                !errorInfo ?  
                    (<SignUpFeedback
                    issuesOptionListArr={issuesOptionListArr}
                    header={t("DASHBOARD_FEEDBACK.HEADER")}
                    rateYourExperience={t("DASHBOARD_FEEDBACK.RATE_YOUR_EXPERIENCE")}
                    skipThisSectionVisibility={false}
                    feedbackSource={FEEDBACK_TYPES.DASHBOARD_USER_FEEDBACK}
                    showFeedbackSubheader = {false}
                    feedbackType= "ecopro-feedback-desktop"
                    ></SignUpFeedback>)
                    : (
                        <ErrorPage errorInfo={errorInfo}></ErrorPage>
                    )
            }
        </Modal>
    );
}

export default EcoProOverallFeedbackModal;