import { WebCustomerRoutePaths, WebHardwareInclusiveRoutePath } from "../../constants/Routes";
import WebHome from "../../pages/Home/WebHome";
import PaymentOptions from "../../pages/PaymentOptions/PaymentOptions";
import WebAddAccount from "../../pages/Web/Customer/Account/SignUp/AddAccountDetails/WebAddAccount";
import CreateUserAccount from "../../pages/Web/Customer/Account/SignUp/CreateUserAccount";
import WebPayment from "../../pages/Web/Customer/Account/SignUp/Payment/Payment";
import ProcessUserAccount from "../../pages/Web/Customer/Account/SignUp/ProcessUserAccount";
import SignUpRedirect from "../../pages/Web/Customer/Account/SignUp/Redirect";
import WebSelectPlan from "../../pages/Web/Customer/Account/SignUp/SelectPlan/WebSelectPlan";
import SelectPrinter from "../../pages/Web/Customer/Account/SignUp/SelectPrinter/SelectPrinter";
import UserAccountInformation from "../../pages/Web/Customer/Account/SignUp/UserAccountInformation";
import WebError from "../../pages/Web/Customer/Account/SignUp/WebError";
import ConnectDevice from "../../components/Web/Customer/ConnectDevice/ConnectDevice";
import ChooseYourPrinterType from "../../pages/Web/Customer/Account/HardwareSignUp/ChooseYourPrinterType/ChooseYourPrinterType";
import CustomizeYourPrinter from "../../pages/Web/Customer/Account/HardwareSignUp/CustomizeYourPrinter/CustomizeYourPrinter";

export const webUnProtectedRoutePathsWithCustomerUPLayout = [
    {
      path: WebCustomerRoutePaths.SELECT_PRINTER,
      component: SelectPrinter,
    },
    {
      path: WebCustomerRoutePaths.SELECT_PLAN,
      component: WebSelectPlan,
    },
    {
      path: WebCustomerRoutePaths.MULTIPLE_ATTEMPT_ERROR,
      component: WebError,
    },
    {
      path: WebHardwareInclusiveRoutePath.SELECT_PRINTER,
      component: ChooseYourPrinterType,
    },
    {
      path: WebHardwareInclusiveRoutePath.CUSTOMIZE_PRINTER,
      component: CustomizeYourPrinter,
    }
  ];
export const webCustomerProtectedRoutePath = [
  {
    path: WebCustomerRoutePaths.CREATE_ACCOUNT,
    component: CreateUserAccount
  },
  {
    path: WebCustomerRoutePaths.VERIFY_ACCOUNT,
    component: WebHome
  },
  {
    path: WebCustomerRoutePaths.PROCESS_ACCOUNT,
    component: ProcessUserAccount
  },
  {
    path: WebCustomerRoutePaths.ACCOUNT,
    component: WebAddAccount
  },
  {
    path: WebCustomerRoutePaths.CONNECT_DEVICE,
    component: ConnectDevice,
  },
  {
    path: WebCustomerRoutePaths.PAYMENT,
    component: WebPayment
  },
  {
    path: WebCustomerRoutePaths.SELECT_PAYMENT,
    component: PaymentOptions
  },
  {
    path: WebCustomerRoutePaths.USER_ACCOUNT_INFO,
    component: UserAccountInformation
  }
]

export const webCustomerUnProtectedRoutePath = [
  {
    path: WebCustomerRoutePaths.WEB_REDIRECT,
    component: SignUpRedirect,
  }
]