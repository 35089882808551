import * as actionTypes from '../../../constants/ActionTypes/CSAgentPortal/CustomerDashboard';

const initialState = {
    device_swap_status: false,
    loading: false,
    error: null,
    success:false,
    swap_status_requested: false
}

const EnableDeviceSwap = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ENABLE_DEVICE_SWAP_INIT:
            return {
                ...state,
                loading: true,
                success:false,
                error: null,
                device_swap_status: false,
                swap_status_requested: false
            }
        
        case actionTypes.ENABLE_DEVICE_SWAP_SUCCESS:
            return {
                ...state,
                success:true,
                device_swap_status: action.deviceSwapResponse,
                loading: false,
                swap_status_requested:action.swap_status_requested,
                error: null,
            }
        
        case actionTypes.ENABLE_DEVICE_SWAP_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message,
                success:false,
                device_swap_status: false,
                swap_status_requested: false
            }
        case actionTypes.SWAP_RESET_STATE:
            return { ...state, 
                loading: false,
                error:null,
                success:false,
                device_swap_status: false,
                swap_status_requested: false
            };
        default:
            return {...state}
    }
}

export default EnableDeviceSwap