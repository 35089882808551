import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import PageHeader from "../../../../components/AdminPortal/Shared/PageHeader/PageHeader";
import EcoProButton from "../../../../components/shared/button/button";
import { adminRoutePaths } from "../../../../constants/Routes";
import AdminFormCheckboxDefault from "../../../../components/AdminPortal/Shared/FormElements/FormCheckBoxDefault/FormCheckBoxDefault";
import AdminFormLabel from "../../../../components/AdminPortal/Shared/FormElements/FormLabel/FormLable";
import AdminForm from "../../../../components/AdminPortal/Shared/Form/AdminForm";
import UseAdminForm from "../../../../utils/CustomHooks/AdminPortal/UseAdminForm";
import validate from "../../../../utils/AdminPortal/ValidationRules/AdminAddEditUserFormValidationRules";
import { useDispatch, useSelector } from "react-redux";
import { addAdminUser, addAdminUserReset, viewAdminUsersReset } from "../../../../actions/AdminPortal/AdminUsers";
import Loader from "../../../../components/shared/loader/Loader";
import AdminAlert from "../../../../components/AdminPortal/AdminAlert/AdminAlert";
import { Redirect, useHistory } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { extractCountryName } from "../../../../utils/Internationalization/handleAppInternationalization";
import AdminOverlayModal from "../../../../components/AdminPortal/AdminOverlayModal/AdminOverlayModal";
import { EDIT_ACCESS } from "../../../../constants/ActionTypes/AdminPortal/AdminUsers";
import { adminUserRoles_CONFIG, AdminUsersRoleMapping, noUserRolesSelected, userRoles as userRoleCONST } from "../../../../constants/UserRoles";

const ManageUsersRoleAccess = (props) => {
    const { t } = useTranslation('Admintranslation');
    const history = useHistory();
    const dispatch = useDispatch();
    const addUserResp = useSelector(state => state.ADMIN.AdminUser_ADD);
    const action =  props?.location?.state?.action;
    const userRoles = props?.location?.state?.userRecord?.roles;
    const [modalProps, setModalProps] = useState({});
    const [userRoleType, setRoleType] = useState([]);
    const [adminUserRoles, setAdminUserRoles] = useState(adminUserRoles_CONFIG());
   
    const setUserRoles = (userRoles) => {
        if(userRoles.indexOf(userRoleCONST.CUSTOMER) > -1){
            const customerIndex = userRoles.indexOf(userRoleCONST.CUSTOMER);
            (customerIndex > -1) && setRoleType(userRoles.slice(0, customerIndex));
        } else {
            setRoleType(userRoles);
        }
     }
      useEffect(()=> {
        setUserRoles(userRoles);
        // eslint-disable-next-line
      }, [])

         
    const editUserRoleData = (roles)  => {
        return adminUserRoles.reduce((acc, role) => {
            const matchingRole = roles.find(r => r.toLowerCase() === role.name.toLowerCase());
            acc[role.name] = Boolean(matchingRole);
            return acc;
        }, {});
    }

    const initialUser_Info =  { name: "", email: "", country: ""};
    const initialUser_RoleInfo = editUserRoleData([]);
    let initialState = {...initialUser_Info, ...initialUser_RoleInfo};

    if(action === EDIT_ACCESS){
        const userData = props?.location?.state?.userRecord;
        const userInfo = { name: userData.displayName, email: userData.emailAddress, country: userData.countryCode};
        const userRoleInfo = editUserRoleData(userData.roles);
        initialState = {...userInfo, ...userRoleInfo}
    }

    const toggleAdminOverlayModal = () => {
        setModalProps(prevState => ({ isOpen: !prevState.isOpen }));
        resetSubmit();
    }
    const handleModalConfirm = (editUserRoleData, submitActionDisable, auditLogDescription) => {
        setModalProps({isOpen : false});
        resetSubmit();
        if(!submitActionDisable){
            dispatch(addAdminUser(values, action, editUserRoleData, auditLogDescription));
        }
       
    }
    
     
    const findCheckboxDifferences = (initialState, modifiedState) => {
        let addUserAccess = [];
        let removeUserAccess = [];
        for (const key in initialState) {
          if (initialState.hasOwnProperty(key) && modifiedState.hasOwnProperty(key)) {
            if (initialState[key] !== modifiedState[key]) {
                if(!modifiedState[key]){
                    removeUserAccess.push(AdminUsersRoleMapping[key])
                }
                if(modifiedState[key]){
                    addUserAccess.push(AdminUsersRoleMapping[key])
                }
            }
          }
        }
      
        return {addUserAccess, removeUserAccess};
      }

    const handleUserSubmit = () => {
        const editUserRoleResult = findCheckboxDifferences(initialState, values)
        const userEmail = values.email;
        const addedRoles = (editUserRoleResult.addUserAccess).join(', ');
        const removedRoles = (editUserRoleResult.removeUserAccess).join(', ');
        let modal_content, auditLogDescription;
        let submitActionDisable = false ;
        const noRolesSelected =  noUserRolesSelected(values);
        if(noRolesSelected){
            modal_content = <Trans i18nKey="MANAGE_ADMIN_USERS.MODAL.DELETE_USER" userEmail={userEmail}>
            Since you have revoked all the roles assigned to the user, user <b className="highlight-text">{userEmail}</b> will be deleted permanently. Do you confirm?
          </Trans>
          auditLogDescription = `All the roles assigned to the user ${userEmail} has been revoked`;
        }
        else if(editUserRoleResult?.addUserAccess?.length>0 && editUserRoleResult?.removeUserAccess?.length>0){
            modal_content = <Trans i18nKey="MANAGE_ADMIN_USERS.MODAL.ADD_AND_REVOKE_ROLE" addedRoles={addedRoles} removedRoles={removedRoles} userEmail={userEmail}>
               Confirm your action of adding <b className="highlight-text">{addedRoles}</b> and revoking <b className="highlight-text">{removedRoles}</b> access for the user <b className="highlight-text">{userEmail}</b>
             </Trans>
             auditLogDescription = `The user ${userEmail} has been provided the access for ${addedRoles} and revoked the access for ${removedRoles} role(s)`;
        }
        else if(editUserRoleResult?.addUserAccess?.length>0 && editUserRoleResult?.removeUserAccess?.length <= 0){
            modal_content = <Trans i18nKey="MANAGE_ADMIN_USERS.MODAL.ADD_ROLE" addedRoles={addedRoles} userEmail={userEmail}>
               Confirm your action of adding <b className="highlight-text">{addedRoles}</b> access for the user <b className="highlight-text">{userEmail}</b>
             </Trans>
             auditLogDescription = `The user ${userEmail} has been provided the access for ${addedRoles} role(s)`;
        }
        else if(editUserRoleResult?.addUserAccess?.length<=0 && editUserRoleResult?.removeUserAccess?.length>0){
            modal_content = <Trans i18nKey="MANAGE_ADMIN_USERS.MODAL.MODAL_CONTENT_SUCCESS" removedRoles={removedRoles} userEmail={userEmail}>
                 Confirm your action of revoking <b className="highlight-text">{removedRoles}</b> access for the user <b className="highlight-text">{userEmail}</b>
            </Trans>
            auditLogDescription = `The access for the role(s) ${removedRoles} has been revoked for the user ${userEmail}`;
        }
        else {
            submitActionDisable = true
            modal_content = t("MANAGE_ADMIN_USERS.MODAL.NO_ACTION");
            auditLogDescription = 'No action taken'
        }

        setModalProps({
            isOpen : true, 
            toggleAdminOverlayModal: () => toggleAdminOverlayModal(),
            modal_title: t('MANAGE_ADMIN_USERS.MODAL.MODAL_TITLE'),
            modal_sub_title:  "",
            modal_content: modal_content,
            showCancelBtn : true,
           showConfirmBtn:true,
            confirm_btn_text: t("BTN_TEXTS.OK"),
            cancel_btn_text: t("BTN_TEXTS.CANCEL"),
            handleModalConfirm:() => handleModalConfirm(editUserRoleResult, submitActionDisable, auditLogDescription),
            modalClassNames: "user-revoke-access-confirmation-modal",
          });
          dispatch(addAdminUserReset());
    }
    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        resetSubmit
    } = UseAdminForm(initialState, handleUserSubmit, validate, EDIT_ACCESS);

    useEffect(() => {
        const updatedRolesStatus = adminUserRoles.map(role => ({
          ...role,
          isChecked: (values[role.name] === false) ? false : true,
          isDisabled: (values[userRoleCONST.CSAGENT_VIEWER] === true && role.name !== userRoleCONST.CSAGENT_VIEWER) ? true : false,
        }));
        setAdminUserRoles(updatedRolesStatus);
        // eslint-disable-next-line
    }, [values]);

    const handleViewUser = () =>{
        dispatch(viewAdminUsersReset())
        history.push(adminRoutePaths.ADMIN_USERS.VIEW_USERS);
    }
    if(addUserResp.success){
        return <Redirect to={{pathname:adminRoutePaths.ADMIN_USERS.VIEW_USERS,
            state:{stateInfo: {type: "alert-success", "message":t('ADMIN_COMMON_SUCCESS_MSG.REVOKE_ACCESS_SUCCESS')}}}}></Redirect>
    }
    if(addUserResp.error){
        return <Redirect to={{pathname:adminRoutePaths.ADMIN_USERS.VIEW_USERS,
            state:{stateInfo: {type: "alert-error", "message":addUserResp.error}}}}></Redirect>
    }
    return (
        <Container>
            {addUserResp.loading && <Loader setOverlay={true}></Loader>}
            <div className="admin-users-add-header">
                <PageHeader pageTitle={t("MANAGE_ADMIN_USERS.PAGE_TITLE")}></PageHeader>
            </div>
            {addUserResp.error && <AdminAlert alert_variant="alert-danger" isVisible={true}>{addUserResp.error}</AdminAlert>}
            <div className="admin-user-add-edit-wrapper mt-20">
                <AdminForm 
                    cname = "admin-manage-users"
                    cardHeaderTitle = {t("MANAGE_ADMIN_USERS.EDIT_USER_ROLE_ACCESS")}
                    cardHeaderButtonInfo = {{buttonType: "button", btnTxt: t("MANAGE_ADMIN_USERS.VIEW_USERS"), onBtnClick: ()=>handleViewUser()}}
                >
                    <div className="row">
                        <div htmlFor="name" cname="col-1">{t('MANAGE_ADMIN_USERS.FORM_LABELS.NAME')}:</div>
                        <div className="col-6">
                            <div>
                                {values.name || ''}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div htmlFor="email" cname="col-1">{t('MANAGE_ADMIN_USERS.FORM_LABELS.EMAIL')}:</div>
                        <div className="col-6">
                            <div>
                                {values.email || ''}
                            </div> 
                        </div>
                    </div>
                    <div className="row">
                        <div htmlFor="country" cname="col-1">{t('MANAGE_ADMIN_USERS.FORM_LABELS.COUNTRY')}:</div>
                       <div className="col-6">{extractCountryName(values.country )}</div>
                    </div>
                    <div className="row">
                        <div className="col-1">
                            <div htmlFor="role" cname="admin-user-role-label">{t('MANAGE_ADMIN_USERS.FORM_LABELS.ROLES')}:</div>
                        </div>
                        <div className="col-6">{userRoleType.join(', ')}</div>
                    </div>
                        <div className="col-md-6">
                            <AdminFormLabel htmlFor="role" cname="admin-user-role-label">{t('MANAGE_ADMIN_USERS.FORM_LABELS.EDIT_ROLE')}</AdminFormLabel>
                            {
                                adminUserRoles.map((role) => (
                                    <AdminFormCheckboxDefault 
                                        name={role.name}
                                        isChecked = {role.isChecked}
                                        displayInline = {true}
                                        label = {role.label}
                                        onChange={handleChange}
                                        cname={(errors.role) && 'is-danger'}
                                        key={role.name}
                                        disabled = {role.isDisabled}
                                    />
                                ))
                            }
                        </div>
                    <div className="col-md-12 admin-revoke-form-submit-btn-wrapper">
                        <EcoProButton cname={`default ${(userRoleType.length) > 1 ? 'admin-form-submit-btn' : 'admin-revoke-submit-btn'}`} onBtnClick={(event)=>handleSubmit(event)}>
                            {t('BTN_TEXTS.SUBMIT')} 
                        </EcoProButton>
                        <EcoProButton cname={`default ${(userRoleType.length) > 1 ? 'admin-form-submit-btn' : 'admin-revoke-submit-btn'}`} onBtnClick={(event)=>handleViewUser(event)}>{t('BTN_TEXTS.CANCEL')}</EcoProButton>
                    </div>
                </AdminForm>
            </div>
            <AdminOverlayModal {...modalProps}/>
        </Container>
    )
}

export default ManageUsersRoleAccess;