import React from "react";
import { useTranslation } from "react-i18next";
import {DisplayDate} from "../../../../utils/DisplayDate";
import EcoProSDTicket from "../EcoProSDTicket/EcoProSDTicket";
import { currentUserRolesArr } from "../../../../constants/AppMethod";
import { canAccess_SDTicketButton } from "../../../../constants/UserRoles";

const CustomerInfo = (props) => {
    const {t} = useTranslation("CSAgenttranslation");
    const currentuserRoles = currentUserRolesArr();
    const CAN_ACCESS_SD_TICKET = canAccess_SDTicketButton(currentuserRoles);
    const displayName = `${props?.customer_data?.userAccount?.firstName} ${props.customer_data?.userAccount?.lastName}`;
    const planEnrolledDate = props?.customer_data?.enrollmentCreatedDate;
    const activatedDate = props?.customer_data?.subscriptionActivatedDate;
    const location = `${props?.customer_data?.userAccount?.billingAddress?.country}, ${props.customer_data?.userAccount?.billingAddress?.cityTown}`;
    const planName = props?.customer_data?.deviceInfo?.service?.currentPlan?.planName;
    const planPages = props?.customer_data?.deviceInfo?.service?.currentPlan?.planPages;
    const {cancellationRequestedDate, cancellationDate} = props?.customer_data?.serviceCancellationDates ?? {};
    let EnrollmentStatus = null;
    let SubscriptionStatus = null;
    let enrollmentTypeClass = null;
    let subscriptionTypeClass = null;
    let WebSignupPreEnrollementStatus = false;
    if( props?.customer_data?.preEnrollmentInfo && Object.keys(props?.customer_data?.preEnrollmentInfo).length > 0 ){
        EnrollmentStatus = t('CUSTOMER_DASHBOARD.CUSTOMER_INFO.SUBSCRIPTION_TYPES.PRE_ENROLLMENT_COMPLETED');
        enrollmentTypeClass = "preenrollment";
        WebSignupPreEnrollementStatus = true;
    } else {
        if(props?.customer_data?.enrollmentStatus?.indexOf("InActive") > -1){
            EnrollmentStatus = t(`CUSTOMER_DASHBOARD.CUSTOMER_INFO.SUBSCRIPTION_TYPES.INACTIVE`);
            enrollmentTypeClass = "inactive";
        } else {
            if(props?.customer_data?.enrollmentStatus){
                EnrollmentStatus = t(`CUSTOMER_DASHBOARD.CUSTOMER_INFO.SUBSCRIPTION_TYPES.${props?.customer_data?.enrollmentStatus}`)
                enrollmentTypeClass = props?.customer_data?.enrollmentStatus?.toLowerCase();
            } else {
                EnrollmentStatus =  'N/A';
                enrollmentTypeClass = 'inactive'
            }
        }   
    }

    if(props?.customer_data?.subscriptionStatus){
        SubscriptionStatus = t(`CUSTOMER_DASHBOARD.CUSTOMER_INFO.SUBSCRIPTION_TYPES.${props?.customer_data?.subscriptionStatus}`)
        subscriptionTypeClass = props?.customer_data?.subscriptionStatus?.toLowerCase();
    }
    else{
        SubscriptionStatus = 'N/A';
        subscriptionTypeClass = 'inactive';
    }
    return(
        <>
            <h1>{displayName}</h1>
            {
                (CAN_ACCESS_SD_TICKET)
                ? <EcoProSDTicket customerInfo = {props?.customer_data}/>
                : null
            }
            <div className="customer-details"> 
                {!WebSignupPreEnrollementStatus && <div className="subscription-date">
                    <p className="label">{t('CUSTOMER_DASHBOARD.CUSTOMER_INFO.SUBSCRIBED_SINCE')}</p>
                    <p className="label-value">{DisplayDate(planEnrolledDate, { day: '2-digit', month: 'long', year: 'numeric' })}</p>
                </div> }
                <div className="location">
                    <p className="label">{t('CUSTOMER_DASHBOARD.CUSTOMER_INFO.LOCATION')}</p>
                    <p className="label-value">{location}</p>
                </div>
                {/* <div className="language">
                    <p className="label">{t('CUSTOMER_DASHBOARD.CUSTOMER_INFO.LANGUAGE')}</p>
                    <p className="label-value">English</p>
                </div> */}
                {!WebSignupPreEnrollementStatus && <div className="plan-type">
                    <p className="label">{t('CUSTOMER_DASHBOARD.CUSTOMER_INFO.PLAN_TYPE')}</p>
                    <p className="label-value">{t('CUSTOMER_DASHBOARD.CUSTOMER_INFO.CURRENT_PLAN_NAME', { plan_name: planName, plan_pages: planPages })}</p>
                </div> }
                <div className="subscription-type">
                    <p className="label">{t('CUSTOMER_DASHBOARD.CUSTOMER_INFO.ENROLLMENT_STATUS')}</p>
                    <p className={`label-value subscription-type-text ${enrollmentTypeClass}`}>{EnrollmentStatus}</p>
                </div>
                <div className="subscription-type">
                    <p className="label">{t('CUSTOMER_DASHBOARD.CUSTOMER_INFO.SUBSCRIPTION_STATUS')}</p>
                    <p className={`label-value subscription-type-text ${subscriptionTypeClass}`}>{SubscriptionStatus}</p>
                </div>
                {cancellationRequestedDate && <div className="subscription-date">
                    <p className="label">{t('CUSTOMER_DASHBOARD.CUSTOMER_INFO.CANCELLATION_SERVICE_REQUESTED_DATE')}</p>
                    <p className="label-value">{DisplayDate(cancellationRequestedDate, { day: '2-digit', month: 'long', year: 'numeric' })}</p>
                </div> }
                {cancellationDate && <div className="subscription-date">
                    <p className="label">{t('CUSTOMER_DASHBOARD.CUSTOMER_INFO.CANCELLATION_SERVICE_END_DATE')}</p>
                    <p className="label-value">{DisplayDate(cancellationDate, { day: '2-digit', month: 'long', year: 'numeric' })}</p>
                </div> }
                {activatedDate && <div className="subscription-date">
                    <p className="label">{t('CUSTOMER_DASHBOARD.CUSTOMER_INFO.SUBSCRIPTION_ACTIVATION_DATE')}</p>
                    <p className="label-value">{DisplayDate(activatedDate, { day: '2-digit', month: 'long', year: 'numeric' })}</p>
                </div> }
            </div>
        </>
    )
}

export default CustomerInfo;