
import { PublicClientApplication } from "@azure/msal-browser";
import { LOCALSESSIONDATAKEYS } from "../../../constants/AppConstants";
import { MSAL_CONFIG } from "./AuthConfig";
import { loginRequest } from "./AuthConfig";
import { setLocalSessionData } from "../handleUserSession";

/**
 * Initialize MSAL instance for authentication
 */
export const MSALInstance = new PublicClientApplication(MSAL_CONFIG);

// Optional - This will update account state if a user signs in from another tab or window
MSALInstance.enableAccountStorageEvents();

MSALInstance.handleRedirectPromise().then((response) => {
    if (response && response.state) {
       const signupType = response.state;
       setLocalSessionData(LOCALSESSIONDATAKEYS.SIGNUP_TYPE, signupType);
    }
}).catch((error) => {
    console.error("Authentication error:", error);
});


export const AzureADB2CSignInRedirect = (type = null) => {
    let request;
    if(type){
        request = {...loginRequest, state:type};
    } else {
        request = loginRequest;
    }
    MSALInstance.loginRedirect(request);
}