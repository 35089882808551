import { call, put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "../../constants/ActionTypes/Dashboard";
import { _CALLPOSTAPI,_CALLPUTAPI,_CALLPUTAPI_WITHQS } from "../../api/apiHandler";
import { _API_ENDPOINT, _CSAGENT_API_ENDPOINTS, ECOPROTOKEN_CLAIMS, featureSwitchName, SUCCESS } from "../../constants/AppConstants";
import i18next from "i18next";
import CancelOrderPayload from "../../utils/MyAccount/CancelOrderPayload";
import { userRoles } from "../../constants/UserRoles";
import { getFeatureSwitchByKey, getUserSessionData } from "../../utils/Authentication/handleUserSession";

function _CANCELORDER(payload, role) {
  if(role && role === userRoles.CSAGENT_ADMIN){
    return handleSubscriptionCancellation_CSAGENT(payload);
  } else {
    return handleSubscriptionCancellation_CUSTOMER(payload);
  }
}
/**
 * This method handles the subcription cancellation attempted by CS Agent user
 * @param {Object} payload 
 * @returns {Promise}
 */
function handleSubscriptionCancellation_CSAGENT(payload){
  const endpoint = _CSAGENT_API_ENDPOINTS.CANCEL_SUBSCRIPTION;
  const queryString = {
    params: {
      serviceAgentUserId: getUserSessionData(ECOPROTOKEN_CLAIMS.USERID)
    }
  }
  return _CALLPUTAPI_WITHQS(endpoint, payload, queryString)
  .then(response => {
      return response;
  })
  .catch((error) => {
      throw error;
  });
}
/**
 * This method handles the subcription cancellation attempted by Customer
 * @param {Object} payload 
 * @returns {Promise}
 */
function handleSubscriptionCancellation_CUSTOMER(payload){
  const isdelayCancellationFeatureSwitch = getFeatureSwitchByKey(featureSwitchName.DELAY_CANCELLATION) ? true : false

  if(isdelayCancellationFeatureSwitch){
    const endpoint = _API_ENDPOINT.CANCEL_SERVICE
    return _CALLPOSTAPI(endpoint, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
  }
  else{
    const endpoint = _API_ENDPOINT.CANCEL_ORDER
    return _CALLPUTAPI(endpoint, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
  }
 
}
function* handleCancelOrder(action) {
  try {
    const uid = action.uid;
    const deviceId = action.deviceId;
    const role = action.role;
    const customerCountryCode = action.customerCountryCode
    if (uid && deviceId) {
      const cancelOrderPayload = CancelOrderPayload(
        action.feedbackSelection,
        action.otherReason,
        deviceId,
        customerCountryCode,
        uid
      );
      let calcelOrderSuccess = yield call(_CANCELORDER, cancelOrderPayload, role);

      if (calcelOrderSuccess.actionStatus.toUpperCase() === SUCCESS) {
        yield put({
          type: actionTypes.CANCEL_ORDER_SUCCESS,
          cancelOrder: calcelOrderSuccess,
        });
      } else {
        yield put({
          type: actionTypes.CANCEL_ORDER_ERROR,
          message: i18next.t("ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR"),
        });
      }
    } else {
      yield put({
        type: actionTypes.CANCEL_ORDER_ERROR,
        message: i18next.t("ERROR_CONTENT.INFO_GENERAL"),
      });
    }
  } catch (e) {
    const msg = (e.response && e.response.status === 400) ? i18next.t("ERROR_CONTENT.ERR_INFO.CANCEL_SUBSCRIPTION_ERROR") : e.message;
    yield put({ type: actionTypes.CANCEL_ORDER_ERROR, message: msg });
  }
}

function* CancelOrder() {
  yield takeEvery(actionTypes.CANCEL_ORDER, handleCancelOrder);
}

export default CancelOrder;
