import React from 'react';
import { Progress } from 'reactstrap';
import './EcoproProgressBar.css'

const EcoproProgressBar = (props) => {
    const {value, header, info, initialLabel, finalLabel, initialValue, finalValue} = props
    return (
        <div className='pa-progress-bar-container'>
            <div className='pa-progress-bar-header'>{header}</div>
            <div className='pa-progress-bar-info'>{info}</div>
            <div className='pa-progress-bar-data-wrapper'>
                <div className='left-div'>
                    <div className='pa-progress-bar-data'>{initialLabel}</div>
                    <div className='pa-progress-bar-value'>{initialValue}</div>
                </div>
                <div className='right-div'>
                    <div className='pa-progress-bar-data'>{finalLabel}</div>
                    <div className='pa-progress-bar-value'>{finalValue}</div>
                </div>
            </div>
            <div className='pa-progress-bar'>
                <Progress value = {value}/>
            </div>
        </div>
    );
};

export default EcoproProgressBar;