import * as actionTypes from "../../../constants/ActionTypes/Web/Customer/SignIn";

export const GetPreEnrollmentInfo = (UID) => ({
    type: actionTypes.GET_PRE_ENROLLMENT_INFO_INIT,
    UID
})

export const GetPreEnrollmentInfoResetState = () => ({
    type: actionTypes.RESET_STATE
})

export const VerifyPreEnrollmentInfo = (payload) => ({
    type: actionTypes.VERIFY_PRE_ENROLLMENT_INFO_INIT,
    payload
})

export const VerifyPreEnrollmentInfoResetState = () => ({
    type: actionTypes.RESET_STATE
})

export const GetUserPaymentDetails = (UID) => ({
    type: actionTypes.FETCH_USER_PAYMENT_DETAILS_INIT,
    UID
})

export const GetUserPaymentDetailsResetState = () => ({
    type: actionTypes.RESET_STATE
})