import { call, put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "../../constants/ActionTypes/FAQ";
import { FALLBACK_LNG } from "../../constants/AppConstants";
import { getLangCode } from "../../utils/Internationalization/handleAppInternationalization";

function _FETCHCONTENT(catID, subCatID) {
  const countryCode = getLangCode();
  catID = catID.charAt(0).toUpperCase() + catID.slice(1);
  subCatID = subCatID.split("-").join("_").toUpperCase();
  const fallbackLanguage = FALLBACK_LNG

  return new Promise((res, rej) => {
    import(`../../contents/${countryCode}/FAQ.json`)
      .then((data) => {
        if (data.FAQ) {
          let result = getSubCategoryData(data, countryCode, catID, subCatID);
          res(result);
          return result;
        } else {
          import(`../../contents/${fallbackLanguage}/FAQ.json`).then((data) => {
            let result = getSubCategoryData(data, countryCode, catID, subCatID);
            res(result);
            return result;
          });
        }
      })
      .catch((error) => {
        import(`../../contents/${fallbackLanguage}/FAQ.json`).then((data) => {
          let result = getSubCategoryData(data, countryCode, catID, subCatID);
          res(result);
          return result;
        });
      });
  });
}

const getSubCategoryData = (data, countryCode, catID, subCatID) => {
  const catList = data["FAQ"][catID];
  const filtered = Object.keys(catList)
    .filter((key) => key === "sub_category")
    .reduce((obj, key) => {
      obj[key] = catList[key];
      return obj;
    }, {});
  let mergeData = [];
  Object.values(filtered.sub_category).map((key, index) => {
    key["sub_content"].map((data) => (mergeData = [...mergeData, data]));
    return mergeData;
  });

  const result = mergeData.filter((key) => key.key === subCatID);
  return result;
};

function* handleFAQContent(action) {
  try {
    const FAQContent = yield call(_FETCHCONTENT, action.catID, action.subCatID);
    yield put({
      type: actionTypes.FAQ_FETCH_CONTENT_SUCCESS,
      FAQContent: FAQContent,
    });
  } catch (e) {
    yield put({
      type: actionTypes.FAQ_FETCH_CONTENT_ERROR,
      message: e.message,
    });
  }
}
function* FAQContent() {
  yield takeEvery(actionTypes.FAQ_FETCH_CONTENT_INIT, handleFAQContent);
}

export default FAQContent;
