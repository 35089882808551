import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../../constants/ActionTypes/Dashboard';
import { ECOPROTOKEN_CLAIMS, LOCALSESSIONDATAKEYS, SUCCESS, _API_ENDPOINT } from '../../constants/AppConstants';
import { _CALLPUTAPI } from '../../api/apiHandler';
import { getLocalSessionData, getUserSessionData } from '../../utils/Authentication/handleUserSession';
import i18next from 'i18next';

function _REACTIVATESUBSCRIPTION(){
    const endpoint = _API_ENDPOINT.REACTIVATE_SUBSCRIPTION;
    const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
    const deviceId = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID);

     const payload = {
        "userId": UID,
        "deviceId": deviceId
       }
    
     return _CALLPUTAPI(endpoint, payload )
     .then(response => {
         return response;
     })
     .catch((error) => {
         throw error;
     });
}

function* handlesubscriptionReactivation(){
    try {
        const subscriptionResp = yield call(_REACTIVATESUBSCRIPTION);
        if(subscriptionResp.actionStatus.toUpperCase() === SUCCESS) {
            yield put({type: actionTypes.REACTIVATE_SUBSCRIPTION_SUCCESS, subscriptionResp: subscriptionResp});
        } else {
            yield put({type: actionTypes.REACTIVATE_SUBSCRIPTION_ERROR, message: i18next.t("ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR")});
        }
    } catch(e) {
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
         if(e instanceof TypeError === false && e.message !== "401"){
            yield put({type: actionTypes.REACTIVATE_SUBSCRIPTION_ERROR, message: e.message});
        }
    }
}
function* ReactivateSubscription(){
    yield takeEvery(actionTypes.REACTIVATE_SUBSCRIPTION_INIT, handlesubscriptionReactivation)
}

export default ReactivateSubscription;