import React, { useEffect, useState } from "react";
import './AdminAlert.css';

/**
 * Reference link: https://getbootstrap.com/docs/5.0/components/alerts/
 * Alert Color Variants
 * alert-primary
 * alert-secondary
 * alert-success
 * alert-danger
 * alert-warning
 * alert-info
 * alert-light
 * alert-dark
 */
const AdminAlert = (props) => {
    const { alert_variant, children } = props;
    const [visible, setVisible] = useState(true);
    const onDismiss = () => setVisible(false);
    useEffect(() => {
       const timer =  setTimeout(() => {
            setVisible(false);
        }, 3000);
        return () => {
            clearTimeout(timer);
        }
      }, [visible]);

    return (
        <div className={`admin-alert alert ${alert_variant} alert-dismissible fade ${visible ? 'show' : 'hide'}`} role="alert">
            {children}
            <button onClick={onDismiss} type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
    )
}

export default AdminAlert;