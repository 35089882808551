import * as actionTypes from '../../constants/ActionTypes/HardwareInclusive/HardwareInclusive'

export const GetHardwareInclusivePrinterFeaturesByPrinterType = () => ({
    type: actionTypes.GET_HW_PRINTER_FEATURE_BY_PRINTER_TYPE
})

export const GetHardwareInclusiveServicePlan = (model) => ({
    type: actionTypes.GET_HW_SERVICE_PLAN,
    model
})

export const GetHardwareInclusivePrinterByFeatures = (printerTypeId, deviceUsageTypeId, featureIds) => ({
    type: actionTypes.GET_HW_PRINTER_BY_FEATURES,
    printerTypeId,
    deviceUsageTypeId,
    featureIds
})

export const HWInclusive_ManageInstantPayment = (payload) => ({
    type: actionTypes.HWINCLUSIVE_MANAGE_INSTANT_PAYMENT_INIT,
    payload
})

export const HWInclusive_ManageInstantPaymentReset = () => ({
    type: actionTypes.HWINCLUSIVE_MANAGE_INSTANT_PAYMENT_RESET
})

export const resetHWServicePlan = () => ({
    type: actionTypes.GET_HW_SERVICE_PLAN_RESET
})

export const resetHWPrinterFeatureByType = () => ({
    type: actionTypes.GET_HW_PRINTER_FEATURE_BY_PRINTER_TYPE_RESET
})

export const resetHWPrinterByFeature = () => ({
    type: actionTypes.GET_HW_PRINTER_BY_FEATURES_RESET
})