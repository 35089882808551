import { removeAuthToken } from "./handleAPIAuthToken";
import { MSALInstance } from "./Azure/MSALInstanceInit";
import { AzureADB2CSignInRedirect } from "./Azure/MSALInstanceInit";
import { removeUserData, removeLocalSessionData, setLocalSessionData, WEB_handleSelectedPlanLocalData } from "./handleUserSession";
import { getLangCode } from "../Internationalization/handleAppInternationalization";
import { LOCALSESSIONDATAKEYS } from "../../constants/AppConstants";


export const logoutProcess = () => {
    clearLocalStorage();
    MSALInstance.logoutRedirect(`${window.location.origin}/signout-oidc`).then(() => {
      AzureADB2CSignInRedirect();
    }).catch(e => {
      console.error(e);
    });
}

export const clearLocalStorage = () => {
  setLocalSessionData(LOCALSESSIONDATAKEYS.LOGOUT_LNG_CODE, getLangCode());
  removeAuthToken();
  removeUserData();
  removeLocalSessionData(LOCALSESSIONDATAKEYS.ECOPROLANGUAGECODE);
  removeLocalSessionData(LOCALSESSIONDATAKEYS.ECOPROLNGCODESTATUS);
  removeLocalSessionData(LOCALSESSIONDATAKEYS.UID);
  removeLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID);
  removeLocalSessionData(LOCALSESSIONDATAKEYS.AZURE_AD_B2C_UID);
  removeLocalSessionData(LOCALSESSIONDATAKEYS.USERROLES);
  removeLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_MODEL);
  removeLocalSessionData(LOCALSESSIONDATAKEYS.SERIAL_NUMBER);
  removeLocalSessionData(LOCALSESSIONDATAKEYS.ECOPROCTRYCODE);
  removeLocalSessionData(LOCALSESSIONDATAKEYS.CURRENCY);
  removeLocalSessionData(LOCALSESSIONDATAKEYS.PRE_ENROLLMENT_ID);
  removeLocalSessionData(LOCALSESSIONDATAKEYS.ECOPROID);
  removeLocalSessionData(LOCALSESSIONDATAKEYS.USER_SESSION_ACTIVITY_LOGS);
  sessionStorage.removeItem("USER_PATH_HISTORY");
  WEB_handleSelectedPlanLocalData('Remove');
}