export const ItalyProvinces = [
    {
      "Province": "Agrigento",
      "ISO_code": "AG"
    },
    {
      "Province": "Alessandria",
      "ISO_code": "AL"
    },
    {
      "Province": "Ancona",
      "ISO_code": "AN"
    },
    {
      "Province": "Aosta",
      "ISO_code": "AO"
    },
    {
      "Province": "Arezzo",
      "ISO_code": "AR"
    },
    {
      "Province": "Ascoli Piceno",
      "ISO_code": "AP"
    },
    {
      "Province": "Asti",
      "ISO_code": "AT"
    },
    {
      "Province": "Avellino",
      "ISO_code": "AV"
    },
    {
      "Province": "Bari",
      "ISO_code": "BA"
    },
    {
      "Province": "Barletta-Andria-Trani",
      "ISO_code": "BT"
    },
    {
      "Province": "Belluno",
      "ISO_code": "BL"
    },
    {
      "Province": "Benevento",
      "ISO_code": "BN"
    },
    {
      "Province": "Bergamo",
      "ISO_code": "BG"
    },
    {
      "Province": "Biella",
      "ISO_code": "BI"
    },
    {
      "Province": "Bologna",
      "ISO_code": "BO"
    },
    {
      "Province": "Bolzano",
      "ISO_code": "BZ"
    },
    {
      "Province": "Brescia",
      "ISO_code": "BS"
    },
    {
      "Province": "Brindisi",
      "ISO_code": "BR"
    },
    {
      "Province": "Cagliari",
      "ISO_code": "CA"
    },
    {
      "Province": "Caltanissetta",
      "ISO_code": "CL"
    },
    {
      "Province": "Campobasso",
      "ISO_code": "CB"
    },
    {
      "Province": "Carbonia-Iglesias",
      "ISO_code": "CI"
    },
    {
      "Province": "Caserta",
      "ISO_code": "CE"
    },
    {
      "Province": "Catania",
      "ISO_code": "CT"
    },
    {
      "Province": "Catanzaro",
      "ISO_code": "CZ"
    },
    {
      "Province": "Chieti",
      "ISO_code": "CH"
    },
    {
      "Province": "Como",
      "ISO_code": "CO"
    },
    {
      "Province": "Cosenza",
      "ISO_code": "CS"
    },
    {
      "Province": "Cremona",
      "ISO_code": "CR"
    },
    {
      "Province": "Crotone",
      "ISO_code": "KR"
    },
    {
      "Province": "Cuneo",
      "ISO_code": "CN"
    },
    {
      "Province": "Enna",
      "ISO_code": "EN"
    },
    {
      "Province": "Fermo",
      "ISO_code": "FM"
    },
    {
      "Province": "Ferrara",
      "ISO_code": "FE"
    },
    {
      "Province": "Firenze",
      "ISO_code": "FI"
    },
    {
      "Province": "Foggia",
      "ISO_code": "FG"
    },
    {
      "Province": "Forlì-Cesena",
      "ISO_code": "FC"
    },
    {
      "Province": "Frosinone",
      "ISO_code": "FR"
    },
    {
      "Province": "Genova",
      "ISO_code": "GE"
    },
    {
      "Province": "Gorizia",
      "ISO_code": "GO"
    },
    {
      "Province": "Grosseto",
      "ISO_code": "GR"
    },
    {
      "Province": "Imperia",
      "ISO_code": "IM"
    },
    {
      "Province": "Isernia",
      "ISO_code": "IS"
    },
    {
      "Province": "La Spezia",
      "ISO_code": "SP"
    },
    {
      "Province": "L'Aquila",
      "ISO_code": "AQ"
    },
    {
      "Province": "Latina",
      "ISO_code": "LT"
    },
    {
      "Province": "Lecce",
      "ISO_code": "LE"
    },
    {
      "Province": "Lecco",
      "ISO_code": "LC"
    },
    {
      "Province": "Livorno",
      "ISO_code": "LI"
    },
    {
      "Province": "Lodi",
      "ISO_code": "LO"
    },
    {
      "Province": "Lucca",
      "ISO_code": "LU"
    },
    {
      "Province": "Macerata",
      "ISO_code": "MC"
    },
    {
      "Province": "Mantova",
      "ISO_code": "MN"
    },
    {
      "Province": "Massa-Carrara",
      "ISO_code": "MS"
    },
    {
      "Province": "Matera",
      "ISO_code": "MT"
    },
    {
      "Province": "Medio Campidano",
      "ISO_code": "VS"
    },
    {
      "Province": "Messina",
      "ISO_code": "ME"
    },
    {
      "Province": "Milano",
      "ISO_code": "MI"
    },
    {
      "Province": "Modena",
      "ISO_code": "MO"
    },
    {
      "Province": "Monza e Brianza",
      "ISO_code": "MB"
    },
    {
      "Province": "Napoli",
      "ISO_code": "NA"
    },
    {
      "Province": "Novara",
      "ISO_code": "NO"
    },
    {
      "Province": "Nuoro",
      "ISO_code": "NU"
    },
    {
      "Province": "Ogliastra",
      "ISO_code": "OG"
    },
    {
      "Province": "Olbia-Tempio",
      "ISO_code": "OT"
    },
    {
      "Province": "Oristano",
      "ISO_code": "OR"
    },
    {
      "Province": "Padova",
      "ISO_code": "PD"
    },
    {
      "Province": "Palermo",
      "ISO_code": "PA"
    },
    {
      "Province": "Parma",
      "ISO_code": "PR"
    },
    {
      "Province": "Pavia",
      "ISO_code": "PV"
    },
    {
      "Province": "Perugia",
      "ISO_code": "PG"
    },
    {
      "Province": "Pesaro e Urbino",
      "ISO_code": "PU"
    },
    {
      "Province": "Pescara",
      "ISO_code": "PE"
    },
    {
      "Province": "Piacenza",
      "ISO_code": "PC"
    },
    {
      "Province": "Pisa",
      "ISO_code": "PI"
    },
    {
      "Province": "Pistoia",
      "ISO_code": "PT"
    },
    {
      "Province": "Pordenone",
      "ISO_code": "PN"
    },
    {
      "Province": "Potenza",
      "ISO_code": "PZ"
    },
    {
      "Province": "Prato",
      "ISO_code": "PO"
    },
    {
      "Province": "Ragusa",
      "ISO_code": "RG"
    },
    {
      "Province": "Ravenna",
      "ISO_code": "RA"
    },
    {
      "Province": "Reggio Calabria",
      "ISO_code": "RC"
    },
    {
      "Province": "Reggio Emilia",
      "ISO_code": "RE"
    },
    {
      "Province": "Rieti",
      "ISO_code": "RI"
    },
    {
      "Province": "Rimini",
      "ISO_code": "RN"
    },
    {
      "Province": "Roma",
      "ISO_code": "RM"
    },
    {
      "Province": "Rovigo",
      "ISO_code": "RO"
    },
    {
      "Province": "Salerno",
      "ISO_code": "SA"
    },
    {
      "Province": "Sassari",
      "ISO_code": "SS"
    },
    {
      "Province": "Savona",
      "ISO_code": "SV"
    },
    {
      "Province": "Siena",
      "ISO_code": "SI"
    },
    {
      "Province": "Sondrio",
      "ISO_code": "SO"
    },
    {
      "Province": "Siracusa",
      "ISO_code": "SR"
    },
    {
      "Province": "Taranto",
      "ISO_code": "TA"
    },
    {
      "Province": "Teramo",
      "ISO_code": "TE"
    },
    {
      "Province": "Terni",
      "ISO_code": "TR"
    },
    {
      "Province": "Trapani",
      "ISO_code": "TP"
    },
    {
      "Province": "Trento",
      "ISO_code": "TN"
    },
    {
      "Province": "Treviso",
      "ISO_code": "TV"
    },
    {
      "Province": "Trieste",
      "ISO_code": "TS"
    },
    {
      "Province": "Udine",
      "ISO_code": "UD"
    },
    {
      "Province": "Torino",
      "ISO_code": "TO"
    },
    {
      "Province": "Varese",
      "ISO_code": "VA"
    },
    {
      "Province": "Venezia",
      "ISO_code": "VE"
    },
    {
      "Province": "Verbano-Cusio-Ossola",
      "ISO_code": "VB"
    },
    {
      "Province": "Vercelli",
      "ISO_code": "VC"
    },
    {
      "Province": "Verona",
      "ISO_code": "VR"
    },
    {
      "Province": "Vibo Valentia",
      "ISO_code": "VV"
    },
    {
      "Province": "Vicenza",
      "ISO_code": "VI"
    },
    {
      "Province": "Viterbo",
      "ISO_code": "VT"
    }
]