export const GET_HW_PRINTER_FEATURE_BY_PRINTER_TYPE = 'GET_HW_PRINTER_FEATURE_BY_PRINTER_TYPE';
export const GET_HW_PRINTER_FEATURE_BY_PRINTER_TYPE_SUCCESS = 'GET_HW_PRINTER_FEATURE_BY_PRINTER_TYPE_SUCCESS';
export const GET_HW_PRINTER_FEATURE_BY_PRINTER_TYPE_ERROR = 'GET_HW_PRINTER_FEATURE_BY_PRINTER_TYPE_ERROR';
export const GET_HW_PRINTER_FEATURE_BY_PRINTER_TYPE_RESET = 'GET_HW_PRINTER_FEATURE_BY_PRINTER_TYPE_RESET';


export const GET_HW_SERVICE_PLAN = 'GET_HW_SERVICE_PLAN';
export const GET_HW_SERVICE_PLAN_SUCCESS = 'GET_HW_SERVICE_PLAN_SUCCESS';
export const GET_HW_SERVICE_PLAN_ERROR = 'GET_HW_SERVICE_PLAN_ERROR'
export const GET_HW_SERVICE_PLAN_RESET = 'GET_HW_SERVICE_PLAN_RESET'

export const GET_HW_PRINTER_BY_FEATURES = 'GET_HW_PRINTER_BY_FEATURES';
export const GET_HW_PRINTER_BY_FEATURES_SUCCESS = 'GET_HW_PRINTER_BY_FEATURES_SUCCESS';
export const GET_HW_PRINTER_BY_FEATURES_ERROR = 'GET_HW_PRINTER_BY_FEATURES_ERROR'
export const GET_HW_PRINTER_BY_FEATURES_RESET = 'GET_HW_PRINTER_BY_FEATURES_RESET';


export const HW_INCLUSIVE_SERVICE_AVAILABLE_STATUS_INIT = 'HW_INCLUSIVE_SERVICE_AVAILABLE_STATUS_INIT'
export const HW_INCLUSIVE_SERVICE_AVAILABLE_STATUS_ERROR = 'HW_INCLUSIVE_SERVICE_AVAILABLE_STATUS_ERROR'

export const HWINCLUSIVE_MANAGE_INSTANT_PAYMENT_INIT = 'HWINCLUSIVE_MANAGE_INSTANT_PAYMENT_INIT'
export const HWINCLUSIVE_MANAGE_INSTANT_PAYMENT_SUCCESS = 'HWINCLUSIVE_MANAGE_INSTANT_PAYMENT_SUCCESS'
export const HWINCLUSIVE_MANAGE_INSTANT_PAYMENT_ERROR = 'HWINCLUSIVE_MANAGE_INSTANT_PAYMENT_ERROR'
export const HWINCLUSIVE_MANAGE_INSTANT_PAYMENT_RESET = 'HWINCLUSIVE_MANAGE_INSTANT_PAYMENT_RESET'
