import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { FetchUserBOCIdByADB2CId } from "../../../../../actions/Web/Customer/SignUp";
import ErrorPage from "../../../../../components/shared/errorPage/ErrorPage";
import Loader from "../../../../../components/shared/loader/Loader";
import { AuthenticateUserByExternalRedirect } from "../../../../../constants/AppMethod";
import { WebCustomerRoutePaths } from "../../../../../constants/Routes";
import { useQuery } from "../../../../../utils/CustomHooks/UseQuery";

const UserAccountInformation = () => {
    const dispatch = useDispatch();
    const queryParams = useQuery();
    const externalAuthToken =  queryParams.get("token");
    const BOCUserDetails = useSelector(state => state.GetUserBOCIdByADB2CId);
    
    useEffect(() => {
         if(externalAuthToken){
            dispatch(FetchUserBOCIdByADB2CId());
        }
    }, [dispatch, externalAuthToken]);

    if(BOCUserDetails.error){
        return <ErrorPage errorInfo={BOCUserDetails.error}></ErrorPage>
    }
    if(BOCUserDetails.success && !BOCUserDetails.data.userId){
        AuthenticateUserByExternalRedirect();
    }
    if(BOCUserDetails.success && BOCUserDetails.data.userId){
        return <Redirect to={{ pathname: WebCustomerRoutePaths.PROCESS_ACCOUNT, state: { BOCUserId: BOCUserDetails.data.userId }}}></Redirect>
    }
    return (
        <Loader></Loader>
    )
}

export default UserAccountInformation;