import React from "react";
import { useTranslation } from "react-i18next";

const MyDevice = (props) => {
  const {deviceModel, deviceSerialNumber} = props
  const { t } = useTranslation(["translation"]);

  return (
    <div className="row my-account-content plan-border">
      <div className="f-16 fc-g fw-b ta-left mb-30">
        {t("DASHBOARD.MY_ACCOUNT_LANDING.MY_DEVICE_HEADER")}
      </div>
      <div className="row">
        <div className="col-10 pb-15 plan-type">
          <div className="plan-type-header">
            {t("DASHBOARD.MY_ACCOUNT_LANDING.MODEL_NUMBER")}
          </div>
          <div className="plan-occasional">{deviceModel}</div>
        </div>
      </div>
      <div className="row">
        <div className="col-10 mt-15">
          <div className="plan-type-header">
            {t("DASHBOARD.MY_ACCOUNT_LANDING.SERIAL_NUMBER")}
          </div>
          <div className="plan-occasional">{deviceSerialNumber}</div>
        </div>
      </div>
    </div>
  );
};

export default MyDevice;
