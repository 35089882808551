import * as actionTypes from "../../../constants/ActionTypes/Web/Customer/SignUp";

export const FetchPrinterModels = (printerTypeID) => ({
    type: actionTypes.FETCH_PRINTER_MODELS,
    printerTypeID
})

export const FetchPrinterTypes = () => ({
    type: actionTypes.FETCH_PRINTER_TYPES
})

export const FetchServicePlan = (deviceModel,promotion) => ({
    type: actionTypes.FETCH_WEB_SERVICE_PLAN,
    deviceModel,
    promotion
})

export const UpdateADB2CNewUserAccount = () => ({
    type: actionTypes.UPDATE_ADB2C_NEW_USER_INIT,
})

export const CallPreEnrollment = (payload) => ({
    type: actionTypes.PRE_ENROLLMENT_INIT,
    payload
})

export const FetchUserSubscriptionInfoInit = (UID) => ({
    type: actionTypes.FETCH_USER_SUBSCRIPTION_INFO_INIT,
    UID
})

export const FetchUserBOCIdByADB2CId = () => ({
    type: actionTypes.GET_USER_BOC_ID_BY_ADB2C_ID_INIT
})

export const FetchDashboardPreEnrollInfo = (personalOrBusiness, planType, model, promotions) => ({
    type: actionTypes.FETCH_DASHBOARD_PREENROLL_INFO_INIT,
    personalOrBusiness,
    planType,
    model,
    promotions
})

export const GetPromotionBannerStatus = () => ({
    type: actionTypes.GET_PROMOTION_BANNER_STATUS
})

export const GetRegistrationType = (deviceModel, UID) => ({
    type: actionTypes.GET_REGISTRATION_TYPE,
    deviceModel,
    UID
})

export const GetDeviceRegistration = (UID, oneTimeCode) => ({
    type: actionTypes.GET_WEB_DEVICE_REGISTRATION,
    UID,
    oneTimeCode
})

export const GetUserSCMSToken = (UID) => ({
    type: actionTypes.GET_SCMS_TOKEN,
    UID
})

export const GetBOCWebDeviceCapability = (UID, deviceId, modelName) => ({
    type: actionTypes.GET_BOC_WEB_DEVICE_CAPABILITY,
    UID,
    deviceId,
    modelName
})

export const ResetOneTimeCodeError = () => ({
    type: actionTypes.RESET_ONE_TIME_CODE_ERROR
})
export const ResetAPIsData = () => ({
    type: actionTypes.RESET_WEB_CONNECTION_API_DATA
})
export const ResetCodeSubmissionState = () => ({
    type: actionTypes.RESET_CODE_SUBMISSION_DATA
})