import { call, put, takeEvery } from 'redux-saga/effects';
import i18next from "i18next";
import * as actionTypes from '../../../constants/ActionTypes/AdminPortal/AdminUsers';
import { _CALLGETAPI } from '../../../api/apiHandler'
import { SUCCESS, _ADMINISTRATOR_API_ENDPOINTS } from '../../../constants/AppConstants';

function _VIEWUSER(emailID){
    const endpoint = (emailID)
    ? _ADMINISTRATOR_API_ENDPOINTS.MANAGE_ADMIN_USER.VIEW_USER_BY_EMAIL 
    : _ADMINISTRATOR_API_ENDPOINTS.MANAGE_ADMIN_USER.VIEW_USER;

    let payload = {};

    if(emailID) {
        payload = {
            params: {
                emailId: emailID
            }
          }
    }
    return _CALLGETAPI(endpoint, payload)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}
function* handleError(msg = null){
    yield put({
        type: actionTypes.ADMIN_USERS_FETCH_ERROR, 
        message: (msg) ? msg : i18next.t('Admintranslation:ADMIN_COMMON_ERRORS.TECHNICAL_ERROR')
    });
}
function* handleInfo(action){
    try {
        const emailID = ( action.emailID) ?  action.emailID : null;
        const result = yield call(_VIEWUSER, emailID);
        if(result) {
            let filteredData = null;
            if(emailID){
                if(result.actionStatus.toUpperCase() === SUCCESS){
                    const arrOfObj = [];
                    arrOfObj.push(result);
                    filteredData = arrOfObj;
                    yield put({type: actionTypes.ADMIN_USERS_FETCH_SUCCESS, data: filteredData});
                } else {
                  yield* handleError(i18next.t('Admintranslation:ADMIN_COMMON_ERRORS.NO_RECORDS_FOUND'));
                }
            } else {
                filteredData = result.filter((val) => {
                    return (val.actionStatus === 'Success');
                });
                yield put({type: actionTypes.ADMIN_USERS_FETCH_SUCCESS, data: filteredData});
            }
           
        } else {
            yield* handleError();
        }
    } catch (e) {
        yield* handleError();
    }
}

function* AdminUser_VIEW(){
    yield takeEvery(actionTypes.ADMIN_USERS_FETCH_INIT, handleInfo);
}

export default AdminUser_VIEW;