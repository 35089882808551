import * as actionTypes from "../../constants/ActionTypes/AdminPortal/AdminUsers";

export const adminUserFetchList = (emailID = null) => ({
    type: actionTypes.ADMIN_USERS_FETCH_INIT,
    emailID
})

export const addAdminUser = (userData, formAction, editUserRoleData = null, auditLogDescription = null) =>({
    type: actionTypes.ADMIN_USERS_ADD_INIT,
    userData,
    formAction,
    editUserRoleData,
    auditLogDescription
})

export const addAdminUserReset = () => ({
    type: actionTypes.ADMIN_USERS_ADD_RESET
})

export const viewAdminUsersReset = () => ({
    type: actionTypes.ADMIN_USERS_FETCH_RESET
})

