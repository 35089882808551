import { call, put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "../../constants/ActionTypes/FAQ";
import { FALLBACK_LNG } from "../../constants/AppConstants";
import { getLangCode } from "../../utils/Internationalization/handleAppInternationalization";

function _FETCHFQATOPCATEGORY() {
  const countryCode = getLangCode();
  const fallbackLanguage = FALLBACK_LNG
  return new Promise((res, rej) => {
    import(`../../contents/${countryCode}/FAQ.json`)
      .then((data) => {
        if (data.FAQ) {
          const result = getFAQTopCategory(data);
          res(result);
          return result;
        } else {
          import(`../../contents/${fallbackLanguage}/FAQ.json`).then((data) => {
            const result = getFAQTopCategory(data);
            res(result);
            return result;
          });
        }
      })
      .catch((error) => {
        import(`../../contents/${fallbackLanguage}/FAQ.json`).then((data) => {
          const result = getFAQTopCategory(data);
          res(result);
          return result;
        });
      });
  });
}
const getFAQTopCategory = (data) => {
  const filtered = Object.keys(data)
    .filter((key) => key === "FAQ")
    .reduce((obj, key) => {
      obj[key] = data[key];
      return obj;
    }, {});
  return filtered;
};

function* handleFAQTopCategories() {
  try {
    const FAQTopCategories = yield call(_FETCHFQATOPCATEGORY);
    yield put({
      type: actionTypes.FAQ_FETCH_CATEGORY_SUCCESS,
      FAQTopCategories: FAQTopCategories,
    });
  } catch (e) {
    yield put({
      type: actionTypes.FAQ_FETCH_CATEGORY_ERROR,
      message: e.message,
    });
  }
}
function* FAQTopCategories() {
  yield takeEvery(actionTypes.FAQ_FETCH_CATEGORY_INIT, handleFAQTopCategories);
}

export default FAQTopCategories;
