import * as actionTypes from '../constants/ActionTypes/Dashboard';

export const FetchDeviceInfo = (deviceId, UID) => ({
    type: actionTypes.DASHBOARD_DEVICE_INFO_CALL_INIT,
    deviceId,
    UID
})

export const FetchOrderHistory = (UID) => ({
    type: actionTypes.DASHBOARD_ORDER_HISTORY_CALL_INIT,
    UID
})

export const DownloadOrderInvoice = (UID, invoiceId,invoiceDate) => ({
    type: actionTypes.DOWNLOAD_ORDER_INVOICE,
    UID,
    invoiceId,
    invoiceDate
})

export const FetchMyAccount = (UID,deviceId) => ({
    type: actionTypes.FETCH_MY_ACCOUNT,
    UID,
    deviceId
});

export const UpdateShippingAddress = (subscriptionPayload) => ({
    type: actionTypes.UPDATE_SHIPPING_ADDR,
    subscriptionPayload
})
export const ManageUpdateShippingResetState = () => ({
    type: actionTypes.RESET_STATE
})

export const CancelOrder = (uid, deviceId, feedbackSelection, otherReason, role = null, customerCountryCode = null) => ({
    type: actionTypes.CANCEL_ORDER,
    uid,
    deviceId,
    feedbackSelection,
    otherReason,
    role,
    customerCountryCode
});

export const GetCancellationFee = (uid, deviceId) => ({
    type: actionTypes.GET_CANCELLATION_FEES,
    uid,
    deviceId
})
export const storeSelectedFeedback = (selectedFeedback) => ({
    type: actionTypes.STORE_FEEDBACK,
    selectedFeedback
})

export const SwapDevice = (UID, oldDeviceId, newDeviceId) =>({
    type: actionTypes.SWAP_DEVICE,
    UID, 
    oldDeviceId, 
    newDeviceId
})
export const resetSwapDevice = () =>({
    type: actionTypes.RESET_SWAP
})

export const UpdateSubscriptionPlan = (updatedPlanDetails) =>({
    type: actionTypes.UPDATE_PLAN,
    updatedPlanDetails
})

export const UpdatePlanReset = () => ({
    type: actionTypes.UPDATE_PLAN_RESET
})

export const SignOut = () => ({
    type: "USER_LOGOUT"
})

export const SignUpUserFeedback = (feedbackData, feedbackType) => ({
    type: actionTypes.SIGNUP_USER_FEEDBACK,
    feedbackData,
    feedbackType
})

export const GetUserSubscriptionAccountSummary = (UID) =>({
    type: actionTypes.GET_USER_SUBSCRIPTION_ACCOUNT_SUMMARY,
    UID
})

export const GetCancelSubscriptionStatus = (UID, deviceId) => ({
    type: actionTypes.GET_CANCEL_SUBSCRIPTION_STATUS,
    UID,
    deviceId
})

export const ResetUserFeedback = () => ({
    type: actionTypes.RESET_STATE,
})

export const EnrollmentStatus = (enrollmentStatus) => ({
    type: actionTypes.ENROLLMENT_STATUS_CHECK,
    enrollmentStatus
})

export const AsyncEnrollmentRetry = (payloadData) => ({
    type: actionTypes.ASYNC_ENROLLMENT_RETRY_INIT,
    payloadData
})

export const ReactivateSubscription = () => ({
    type: actionTypes.REACTIVATE_SUBSCRIPTION_INIT
})

export const ResetCancelSubscriptionResult = () => ({
    type: actionTypes.RESET_CANCEL_SUBSCRIPTION
})

export const ResetSubscriptionReactivationResult = () => ({
    type: actionTypes.RESET_REACTIVATED_SUBSCRIPTION
})

export const ResetCancelOrderAndFeedback = () => ({
    type: actionTypes.RESET_CANCEL_ORDER_AND_FEEDBACK
})

export const GetUserSubscription = (UID, deviceID) => ({
    type: actionTypes.GET_USER_SUBSCRIPTION,
    UID,
    deviceID
})