import * as actionTypes from '../../../constants/ActionTypes/Web/Customer/SignUp';

const initialState = {
    'success': false,
    'loading': false,
    'error': false,
    'scmsToken': null,
    'tokenExpiryTime':null,
    "tokenExpirationinseconds":null
}

const SCMSTokenAuthentication = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.GET_SCMS_TOKEN:
            return {
                ...state,
                loading: true,
                'success': false,
                'error': false,
                scmsToken:null,
                tokenExpiryTime:null,
                tokenExpirationinseconds:null
            }
        case actionTypes.GET_SCMS_TOKEN_SUCCESS:
            return {
                ...state,
                success: true,
                loading: false,
                scmsToken: action.scmsToken,
                tokenExpirationinseconds: action.tokenExpirationinseconds,
                tokenExpiryTime: action.tokenExpiryTime,
                'error': false
            }
        case actionTypes.GET_SCMS_TOKEN_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.message,
                scmsToken:null,
                tokenExpiryTime:null,
                tokenExpirationinseconds:null
            }
        case actionTypes.RESET_WEB_CONNECTION_API_DATA:
            return {
                ...state,
                'success': false,
                'loading': false,
                'error': false,
                'scmsToken': null,
                'tokenExpiryTime':null,
                "tokenExpirationinseconds":null
            }
        default:
            return {...state};
    }
}

export default SCMSTokenAuthentication;