import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SideBarNav from "../../components/AdminPortal/Shared/SideBarNav/SideBarNav";
import AdminHeader from "../../components/shared/header/Admin/Header/Header";
import NavItems from "../../utils/AdminPortal/SideBarNavigationMenuItems";
import "./AdminLayout.css";

const AdminLayout = ({children}) => {
    const location = useLocation();
    const [navOpen, setNavOpen] = useState(false);
    const [subNavOpen, setSubNavOpen] = useState({});

    useEffect(() => {
        const parentItem = NavItems.filter(item => {
            const matched = item?.sub_menus?.find(subMenu => subMenu.link === location.pathname);
            if(matched) {
                return item;
            } else {
                return null
            }
        });
         if(Object.values(parentItem).length > 0){
            setSubNavOpen({"id": parentItem[0].text});
         }
          // eslint-disable-next-line
    }, [])
    const toggleSidebarNav = () => {
        setNavOpen(!navOpen);
    }
    const handleSidebarNavClose = () => {
        setNavOpen(false);
    }
    const toggleSubmenu = (parent_menu_item, sub_menus) => {
        const isCurrentPath = sub_menus?.filter((item) => {
            return (item.link === location.pathname) ? true : false;
        })
        if(subNavOpen.id === parent_menu_item && !isCurrentPath){
            setSubNavOpen({})
        } else {
            setSubNavOpen({"id": parent_menu_item})
        }
    }
    const handleActiveStatusSubMenu = (parent_menu_item) => {
         setSubNavOpen({"id": parent_menu_item})
    }

    return(
        <>
            <AdminHeader toggleSidebarNav = {toggleSidebarNav}/>
            <SideBarNav 
            isSideNavOpen = {navOpen} 
            handleSidebarNavClose = {handleSidebarNavClose} 
            toggleSubmenu = {toggleSubmenu} 
            subNavOpen= {subNavOpen}
            handleActiveStatusSubMenu = {handleActiveStatusSubMenu}
            />
            <div className={`admin-user-container ${navOpen ? 'toggle-sidebar': 'null'}`}>
                {children}
            </div>
        </>
    )
}

export default AdminLayout;