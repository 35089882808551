import { call, put, takeEvery } from 'redux-saga/effects';
import i18next from "i18next";
import * as actionTypes from '../../../constants/ActionTypes/CSAgentPortal/CustomerDashboard';
import { _CALLPOSTAPI } from '../../../api/apiHandler'
import { _CSAGENT_API_ENDPOINTS, FAILURE, USER_ACTIVITY_LOGS } from '../../../constants/AppConstants';
import UserActivityLogsPayload from '../../../utils/AdminPortal/UserActivityLogsPayload';

/**
 * Method to fetch data by customer serial number
 * @param {string} deviceSerialNumber 
 * @returns object
 */
async function _FETCHCUSTOMERINFO(serialNumber, adb2cAttributeName, adb2cAttributeValue){
    const endpoint = _CSAGENT_API_ENDPOINTS.FETCH_CUSTOMER_DATA;
    let paramName, paramValue;
    if(serialNumber){
        paramName = "serialNumber"
        paramValue = serialNumber
    } else {
        paramName = adb2cAttributeName
        paramValue = adb2cAttributeValue
    }
    const logDetails = {
        action: USER_ACTIVITY_LOGS.CUSTOMER_SEARCH,
        description: `Search user ${paramName}: ${paramValue} `
    }
    const payload = {
            "serialNumber": serialNumber,
            "adb2cAttributeName": adb2cAttributeName,
            "adb2cAttributeValue": adb2cAttributeValue,
            "servicePortalUser":await UserActivityLogsPayload(logDetails)
      }
     return _CALLPOSTAPI(endpoint, payload)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* handleCustomerInfo(action){
    try {
        const deviceSerialNumber = action.deviceSerialNumber;
        const ADB2CAttributeName = action.ADB2CAttributeName;
        const ADB2CAttributeValue = action.ADB2CAttributeValue;
        if(!deviceSerialNumber && !ADB2CAttributeName && !ADB2CAttributeValue) {
            yield put({
                type: actionTypes.CUSTOMER_INFO_CALL_ERROR, 
                message: i18next.t('CSAgenttranslation:CS_AGENT_ERROR_CONTENT.ERR_INFO.NO_USER_FOUND')
            });
        } else {
            const customerData = yield call(_FETCHCUSTOMERINFO, deviceSerialNumber,ADB2CAttributeName, ADB2CAttributeValue)
            if(customerData.actionStatus.toUpperCase() !== FAILURE) {
                yield put({type: actionTypes.CUSTOMER_INFO_CALL_SUCCESS, customerData: customerData});
            } else {
                yield put({
                    type: actionTypes.CUSTOMER_INFO_CALL_ERROR, 
                    message: i18next.t('CSAgenttranslation:CS_AGENT_ERROR_CONTENT.ERR_INFO.NO_USER_FOUND')
                });
            }
        }
    } catch (e) {
         yield put({type: actionTypes.CUSTOMER_INFO_CALL_ERROR, message: i18next.t('CSAgenttranslation:CS_AGENT_ERROR_CONTENT.ERR_INFO.NO_USER_FOUND')});
    }
}

function* CustomerDashboardInfo(){
    yield takeEvery(actionTypes.CUSTOMER_INFO_CALL_INIT, handleCustomerInfo);
}

export default CustomerDashboardInfo;