import React, { useState } from "react";
import './DisplayTotalAmount.css'
import { useTranslation } from "react-i18next";
import InfoImg from "../../.././../../../assets/images/hardware-inclusive/blue-info.svg"
import DisplaySelectedPrinterFeature from "../../PrinterCheckoutBar/DisplaySelectedPrinterFeature/DisplaySelectedPrinterFeature";

const DisplayTotalAmount = (props) => {
  const {monthlyPaymentPrice, dueTodayPrice, featureSelections, printerTypeId} = props
  const {t} = useTranslation(["translation"])
  const [modalState, setModalState] = useState(false);
  const toggleModal  = () => {
      setModalState(!modalState);
  }

  return (
    <>
      <DisplaySelectedPrinterFeature
        modalState={modalState}
        toggleModal={toggleModal}
        featureSelections={featureSelections}
        printerTypeId={printerTypeId}
        monthlyPaymentPrice={monthlyPaymentPrice}
        dueTodayPrice={dueTodayPrice}
      ></DisplaySelectedPrinterFeature>
      <div className="cp-subtitle">{t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.TOTAL_AMOUNT.HEADER")}</div>
      <div className="cp-extra-title-list-header">{t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.TOTAL_AMOUNT.PAYMENT_START_NEXT_MONTH")}</div>
      <div className="cp-total-price">
        <div>{t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.TOTAL_AMOUNT.DUE_PRICE",{due_price:dueTodayPrice})}</div>
        <div className="price-with-info">
            {t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.TOTAL_AMOUNT.PER_MONTH_PRICE", {plan_price: monthlyPaymentPrice})}
            <span className="vector-exponent">
                <img className="vector-image" src={InfoImg} alt="Info" onClick={toggleModal} />
            </span>
        </div>
      </div>
    </>
  );
};

export default DisplayTotalAmount;
