export const ADMIN_USERS_FETCH_INIT = 'ADMIN_USERS_FETCH_INIT';
export const ADMIN_USERS_FETCH_SUCCESS = 'ADMIN_USERS_FETCH_SUCCESS';
export const ADMIN_USERS_FETCH_ERROR = 'ADMIN_USERS_FETCH_ERROR';
export const ADMIN_USERS_FETCH_RESET = 'ADMIN_USERS_FETCH_RESET';

export const ADMIN_USERS_ADD_INIT = 'ADMIN_USERS_ADD_INIT';
export const ADMIN_USERS_ADD_SUCCESS = 'ADMIN_USERS_ADD_SUCCESS';
export const ADMIN_USERS_ADD_ERROR = 'ADMIN_USERS_ADD_ERROR';
export const ADMIN_USERS_ADD_RESET = 'ADMIN_USERS_ADD_RESET';

/**
 * Admin User Management
 * Admin User Actions
 */
export const ADD = "ADD";
export const EDIT = "EDIT";
export const EDIT_ACCESS = 'EDIT_ACCESS';