import { combineReducers } from "redux";
import CustomerDashboardInfo from "./CSAgentPortal/CustomerDashboard/CustomerDashboardInfo";
import EnableDeviceSwap from "./CSAgentPortal/CustomerDashboard/EnableDeviceSwap";
import UpdateOrderConsumables from "./CSAgentPortal/CustomerDashboard/UpdateOrderConsumables";
import FetchEmailLogs from "./CSAgentPortal/CustomerDashboard/FetchEmailLogs";
import SupplyOrderLogs from "./CSAgentPortal/CustomerDashboard/SupplyOrderLogs";

export default combineReducers({
    CustomerDashboardInfo: CustomerDashboardInfo,
    EnableDeviceSwap: EnableDeviceSwap,
    UpdateOrderConsumables: UpdateOrderConsumables,
    FetchEmailLogs: FetchEmailLogs,
    SupplyOrderLogs: SupplyOrderLogs
})