import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../../constants/ActionTypes/Dashboard';
import { _CALLGETAPI, _CALLPUTAPI } from '../../api/apiHandler'
import { _API_ENDPOINT, ECOPROTOKEN_CLAIMS, LOCALSESSIONDATAKEYS, SUCCESS } from '../../constants/AppConstants';
import i18next from 'i18next';
import AsyncEnrollmentRetryPayload from '../../utils/Dashboard/AsyncEnrollmentRetryPayload';
import { getLocalSessionData, getUserSessionData } from '../../utils/Authentication/handleUserSession';

function _PLACEORDERCALL(payload){
    const endpoint = _API_ENDPOINT.ASYNC_ENROLLMENT_PLACEORDER_RETRY;
    return _CALLPUTAPI(endpoint, payload )
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}
function __PLACEORDER_GETCONTENTCALL(){
    const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
    const deviceId = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID);
    const endpoint = _API_ENDPOINT.ASYNC_ENROLLMENT_PLACEORDER_RETRY_GET_CONTENT;
    const payload = {
        params: {
            userId: UID,
            deviceId: deviceId
        }
    }
    return _CALLGETAPI(endpoint, payload)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}
function* handleError(message){
    yield put({
        type: actionTypes.ASYNC_ENROLLMENT_RETRY_ERROR, 
        message: message
    });
}
function* handleEnrollmentProcess(action){
    try{
        const getEnrollmentDetails = yield call(__PLACEORDER_GETCONTENTCALL);
        if(getEnrollmentDetails.actionStatus.toUpperCase() === SUCCESS){
            const asyncRetryEnrollmentPayload = AsyncEnrollmentRetryPayload(action.payloadData, getEnrollmentDetails);
            const enrollmentDetails = yield call(_PLACEORDERCALL, asyncRetryEnrollmentPayload);
            if(enrollmentDetails.actionStatus.toUpperCase() === SUCCESS){
                yield put({type: actionTypes.ASYNC_ENROLLMENT_RETRY_SUCCESS, retryInfo: enrollmentDetails});
            } else {
                yield* handleError(i18next.t('ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR'));
            }
        }
    }catch(e){
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
        if(e instanceof TypeError === false && e.message !== "401"){
            yield* handleError(e.message);
        }
    }
}
function* AsyncEnrollmentRetry(){
    yield takeEvery(actionTypes.ASYNC_ENROLLMENT_RETRY_INIT, handleEnrollmentProcess);
}

export default AsyncEnrollmentRetry;