import React from "react";
import { useTranslation } from "react-i18next";
import ecopro_logo from "../../../../../../assets/images/teaser_ecopro_logo.png";
import './ServiceUnavailable.css';

const ServiceUnavailable = () => {
    const { t } = useTranslation(['translation'])
    return (
        <div className="HW-inclusive-service-unavailable-wrapper">
            <div className="message-container">
                <div className="ecopro-logo">
                    <img src={ecopro_logo} alt='ecopro-log' title="ecopro-logo" />
                </div>
                <h1>{t('HARDWARE_SIGNUP.SERVICE_UNAVAILABLE.HEADER')}</h1>
                <p>{t('HARDWARE_SIGNUP.SERVICE_UNAVAILABLE.CONTENT')}</p>
            </div>
        </div>
    );
}

export default ServiceUnavailable;