import React, { useEffect, useState } from "react";
import './LearnMore.css';
import { useTranslation } from "react-i18next";
import { ENVSettings, EXTERNAL_LINK_ENDPOINT } from "../../../constants/ENVSettings";
import { getCountryCode, getLangCode } from "../../../utils/Internationalization/handleAppInternationalization";

const LearnMore = () => {

    const [marketingUrl, setMarketingUrl] = useState("")
    const countryCode = getCountryCode()
    const languageCode = getLangCode()
    useEffect(()=>{
        const baseurl = (!ENVSettings.MAIN_BASE_URL) ? window.env[`MAIN_BASEURL_${countryCode}`] : ENVSettings.MAIN_BASE_URL;
        let marketingUrl;
        if(languageCode.toLowerCase() === "fr"){
            marketingUrl = `${baseurl}${EXTERNAL_LINK_ENDPOINT.FrenchMarketingPage}`
        }
        else{
            marketingUrl = `${baseurl}${EXTERNAL_LINK_ENDPOINT.marketingPage}`
        }        
        setMarketingUrl(marketingUrl)
    },[countryCode, languageCode])
    const {t} = useTranslation(["translation"])
    return (
        <div className="plans-learn-more-container">
            <h5>{t("ECOPROCAROUSEL_CONTENT.LEARN_MORE")}</h5>
            <div className="learn-more-button"><a href={marketingUrl} target="_blank" rel="noopener noreferrer">{t("ECOPROCAROUSEL_CONTENT.LEARN_MORE_LINK")}</a></div>
        </div>
    )
}

export default LearnMore;