import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Enrollment, ManageSubscriptionResetState } from '../../../../actions/MyAccount';
import ErrorPage from '../../../../components/shared/errorPage/ErrorPage';
import PaymentComplete from '../../../../components/PaymentComplete/PaymentComplete';
import Loader from '../../../../components/shared/loader/Loader';
import SubHeader from '../../../../components/shared/subheader/SubHeader';
import { refreshBtn, tryAgainBtn } from '../../../../constants/AppConstants';
import './ZuoraPaymentLanding.css';
import { routePath } from '../../../../constants/Routes';
import PaymentSummary from '../../../../components/shared/paymentSummary/paymentSummary';
import { pushDataLayer } from '../../../../constants/AppMethod';
import WebSubHeader from '../../../../components/WebSubHeader/WebSubHeader';
import { useHistory } from 'react-router-dom';
import SubscriptionPayments from '../../../../components/shared/SubscriptionPayments/SubscriptionPayments';

const ZuoraPaymentLanding = (props) => {
  const { t } = useTranslation(["translation"]);
  const history = useHistory();
  const dispatch = useDispatch();
  const { location } = props;
  const PAYMENT_OPTION = location?.state?.paymentType;
  const result = useSelector((state) => state.MyAccount);
  const ManageSubscriptionResponse =  useSelector(state => state.ManageSubscription);
  const EnrollmentResponse =  useSelector(state => state.Enrollment);
  const selectedPlan = useSelector(state => state.MyPlan);

    useEffect(() => {
      return () => {
        dispatch(ManageSubscriptionResetState());
      }
    }, [dispatch, PAYMENT_OPTION]);

    const moveToPreviousPage = () => {
      history.push(routePath.ACCOUNT);
    }
    if( ManageSubscriptionResponse.loading || EnrollmentResponse.loading) {
        return <Loader slowResponse={true}></Loader>
    }
    if(EnrollmentResponse.error){
      return <ErrorPage errorInfo={EnrollmentResponse.error} showRefreshBtn={refreshBtn.HIDE} showTryAgain={tryAgainBtn.SHOW} to={routePath.SUBSCRIPTION_NETWORK_ERROR_VALIDATION} isMobileSubscriptionError = {true}></ErrorPage>
    }
    if(EnrollmentResponse.success){
      const isDiscountAvailed = (EnrollmentResponse.data?.selectedPlan?.discountPlanIds) ? true : false;
      const planPrice = EnrollmentResponse.data?.selectedPlan?.price;
      const planCurrency = EnrollmentResponse.data?.shippingAddress?.currency;

      pushDataLayer('signup','status','success')
      return <PaymentComplete isDiscountAvailed={isDiscountAvailed} planPrice={planPrice} planCurrency={planCurrency}></PaymentComplete>
    }
    if(ManageSubscriptionResponse.error){
      return <ErrorPage errorInfo={ManageSubscriptionResponse.error} showRefreshBtn={refreshBtn.HIDE} showTryAgain={tryAgainBtn.SHOW} to={routePath.SUBSCRIPTION_NETWORK_ERROR_VALIDATION} isMobileSubscriptionError = {true}></ErrorPage>
    }
    if(ManageSubscriptionResponse.success){
        dispatch(Enrollment(ManageSubscriptionResponse.subscriptionDetails));
    }
    return (
        <div className="mt-40 payment-page-outer-wrapper">
          <SubHeader
            currentStep="4"
            totalStep="4"
            stepName={t("SUB_HEADER.STEP_4.STEP_NAME")}
            stepInfo={t("SUB_HEADER.STEP_4.STEP_INFO")}
            stepInfoSub={t("SUB_HEADER.STEP_4.STEP_INFO_SUB")}
            cname="payment-mobile-step-header"
          />
          <WebSubHeader 
              currentStep="4"
              totalStep="4"
              stepName={t('SUB_HEADER.STEP_4.STEP_NAME')}
              stepInfo={t('SUB_HEADER.STEP_4.STEP_INFO')}
              moveToPreviousPage={moveToPreviousPage}
              previousBtnText={t('CUSTOMER_WEB.PAYMENT.ACCOUNT_INFO_BACK_NAV_TEXT')}
              stepInfoSub={t("SUB_HEADER.STEP_4.STEP_INFO_SUB")}
              cname="payment-desktop-step-header"
          />
          <PaymentSummary planDetails={selectedPlan.selected_plan} goTo={routePath.PLANS} />
          <SubscriptionPayments zrenderData={result.account} />
        </div>
    );
};

export default ZuoraPaymentLanding;