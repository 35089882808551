import * as actionTypes from '../../constants/ActionTypes/CarbonSaving';

const initialState = {
    carbonSaving: {},
    loading: false,
    error: "",
    success:false
}

const CarbonSaving = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.GET_CARBON_SAVING:
            return {
                ...state,
                loading: true
            }
        case actionTypes.GET_CARBON_SAVING_SUCCESS:
            return {
                ...state,
                carbonSaving: action.carbonSaving,
                loading: false,
                success:true
            }
        case actionTypes.GET_CARBON_SAVING_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message
            }
        default:
            return {...state};
    }
}

export default CarbonSaving;