import React, { useState } from "react";
import './DashboardPopup.css';
import { Container } from "reactstrap";
import EcoProButton from "../../shared/button/button";
import PartyHornIcon from "../../../assets/images/BrotherPlus/party-horn-regular-icon.svg";

const DashboardPopup = ({handleModalContinue}) => {
    const [isChecked, setIsChecked] = useState(false);
    const handleTermsAndConditions = (event) => {
        setIsChecked(event.target.checked);
    }
    return (
        <Container className="brother-plus-dashboard-popup">
            <img src={PartyHornIcon} alt="party-horn" title="party-horn"/>
            <h1>Almost there!</h1>
            <p className="ta-left">One last step before you can stat enjoying amazing benefits at your</p>
            <div className="brother-plus-benefits-wrapper ta-left">
                <ul>
                    <li>Free!</li>
                    <li>Extended warranty</li>
                    <li>Varius at eu tortor interdum varius bibendum.</li>
                    <li>arturient enim sed fermentum viverra. Nisi commodo nunc at auctor placerat.</li>
                </ul>
            </div>
            <div className="popup-terms-and-conditions ta-left">
                <input type="checkbox" name="terms-and-conditions" onChange={handleTermsAndConditions} checked={isChecked}/>
                <label className="form-check-label" htmlFor="terms-and-conditions">I accept terms and conditions </label>
            </div>
            <div className="learn-more-wrapper">
                <a href="https://www.brother.co.uk/">Learn more</a>
            </div>
            <div className="continue-btn-wrapper">
                <EcoProButton cname="default" disabled={(isChecked) ? false : true} onBtnClick={() => handleModalContinue()}>Continue</EcoProButton>
            </div>
        </Container>
    )
}

export default DashboardPopup;
