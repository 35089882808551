import * as actionTypes from '../../constants/ActionTypes/Dashboard';

const initialState = {
    myAccount:{},
    loading: false,
    error: false
  }

const FetchMyAccount = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_MY_ACCOUNT:
            return {
                ...state,
                loading: true,
                success: false
            }
        
        case actionTypes.FETCH_MY_ACCOUNT_SUCCESS:
            return {
                ...state,
                myAccount: action.myAccount,
                loading: false
            }
        
        case actionTypes.FETCH_MY_ACCOUNT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message,
                success: false
            }
        default:
            return {...state}
    }
}

export default FetchMyAccount