import { ECOPROTOKEN_CLAIMS, LOCALSESSIONDATAKEYS } from "../../constants/AppConstants";
import { determineLanguageCodeForPayload } from "../../constants/AppMethod";
import { getLocalSessionData, getUserDataByKey, getUserSessionData } from "../Authentication/handleUserSession";

const AsyncEnrollmentRetryPayload = (Subscription, shippingAddrsDetails) => {
    const UID = getUserSessionData(ECOPROTOKEN_CLAIMS.USERID);
    const deviceID = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID);
    const deviceModel = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_MODEL);
    const deviceSerialNo = getLocalSessionData(LOCALSESSIONDATAKEYS.SERIAL_NUMBER);
    const PreEnrollId = getLocalSessionData(LOCALSESSIONDATAKEYS.PRE_ENROLLMENT_ID);
    const currency = getLocalSessionData(LOCALSESSIONDATAKEYS.CURRENCY);
    const langCode = determineLanguageCodeForPayload();

    const extractDiscountPlanIds = (planDiscountDetails) => {
        const discountPlanIds =  planDiscountDetails.map((val) => {return val.planId;});
        return discountPlanIds;
    }
    try{
    const payloadData =  {
        UserId: UID,
        PreEnrollId: PreEnrollId || null,
        ShippingAddress: {
            FirstName: getUserDataByKey("firstName", 'personal_details'),
            LastName: getUserDataByKey("lastName", 'personal_details'),
            Street1: shippingAddrsDetails.shippingAddress.street1,
            Street2: shippingAddrsDetails.shippingAddress.street2 || "",
            City: shippingAddrsDetails.shippingAddress.city,
            State: shippingAddrsDetails.shippingAddress.state || "",
            StateName: "",
            PostalCode: shippingAddrsDetails.shippingAddress.postalCode,
            AddressNumber: "",
            country:   shippingAddrsDetails.shippingAddress.country,
            currency:currency,
            companyName: getUserDataByKey("companyName", 'billing_address') || "",
            languageCode: langCode
        },
        BillingAddress: {
            FirstName: getUserDataByKey("firstName", 'personal_details'),
            LastName: getUserDataByKey("lastName", 'personal_details'),
            languageCode: langCode
        },
        Device: {
            deviceId: deviceID,
            model: deviceModel,
            serialNumber: deviceSerialNo
        },
        SelectedPlan: {
            planId: Subscription.currentPlan.planId,
            discountPlanIds:extractDiscountPlanIds(Subscription.currentPlan.discount)
        }
    }
    return payloadData;
    } catch (e) {
        console.log(e);
        throw e;
    }
   
}

export default AsyncEnrollmentRetryPayload;