import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../../../constants/ActionTypes/AdminPortal/Settings';
import { LOCALSESSIONDATAKEYS, SUCCESS, USER_ACTIVITY_LOGS, _ADMINISTRATOR_API_ENDPOINTS } from '../../../constants/AppConstants';
import { _CALLPOSTAPI_WITHQS } from '../../../api/apiHandler';
import i18next from 'i18next';
import { getLocalSessionData, getUserDataByKey } from '../../../utils/Authentication/handleUserSession';
import UserActivityLogsPayload from '../../../utils/AdminPortal/UserActivityLogsPayload';

async function _updateMaintenanceServiceStatus(isServiceEnabled){
    const endpoint = _ADMINISTRATOR_API_ENDPOINTS.SETTINGS.MAINTENENCE_SERVICE.UPDATE;
    const serviceUpdatedByName = getUserDataByKey('displayName', 'personal_details');
    const queryString = {
        params: {
            userId: getLocalSessionData(LOCALSESSIONDATAKEYS.UID)
        }
      }
      const logDetails = {
        action: USER_ACTIVITY_LOGS.MAINTENANCE_PAGE_SETTINGS,
        description: `Maintenance page service was ${(isServiceEnabled) ? 'enabled' : 'disabled'} by ${serviceUpdatedByName}`
      }
    const payload = {
        "IsMaintenancePageEnabled": isServiceEnabled,
        "UpdatedBy": serviceUpdatedByName,
        servicePortalUser: await UserActivityLogsPayload(logDetails)
    }
    return _CALLPOSTAPI_WITHQS(endpoint, payload, queryString)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* handleError(msg = null){
    yield put({
        type: actionTypes.UPDATE_MAINTENANCE_SERVICE_STATUS_FAILURE,
        message: (msg) ? msg :i18next.t("Admintranslation:ADMIN_COMMON_ERRORS.UPDATE_ERROR")
    })
}

function* handleInfo(action){
    try{
        const result =  yield call(_updateMaintenanceServiceStatus, action.isServiceEnabled);
        if(result.actionStatus.toUpperCase() === SUCCESS){
            yield put({type: actionTypes.UPDATE_MAINTENANCE_SERVICE_STATUS_SUCCESS, data: result});
        } else {
            yield* handleError();
        }
    } catch (e){
        yield* handleError()
    }
}

function* MaintenanceServiceStatus_UPDATE(){
    yield takeEvery(actionTypes.UPDATE_MAINTENANCE_SERVICE_STATUS_INIT, handleInfo);
}

export default MaintenanceServiceStatus_UPDATE;