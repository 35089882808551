import React from "react";

const AdminFormLabel = (props) => {
    const {
        htmlFor,
        cname,
        required,
        children
    } = props;
    return(
        <label 
            htmlFor={htmlFor} 
            className={`form-label ${cname}`}>
                {children} 
                { required && <span style={{color: "#A41C4E", marginLeft: "5px"}} className="required-star">*</span> }
        </label>
    )
}

export default AdminFormLabel;