import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {Nav, NavItem, NavLink, TabContent,TabPane} from 'reactstrap';
import EcoProButton from "../../../components/shared/button/button";
import CustomerInfo from "../../../components/CSAgent/CustomerDashboard/CustomerInfo/CustomerInfo";
import Overview from "../../../components/CSAgent/CustomerDashboard/TabContents/Overview/Overview";
import CustomerDetails from "../../../components/CSAgent/CustomerDashboard/TabContents/CustomerDetails/CustomerDetails";
import SubscriptionDetails from "../../../components/CSAgent/CustomerDashboard/TabContents/SubscriptionDetails/SubscriptionDetails";
import { CSAgentRoutePaths } from "../../../constants/Routes";
import { FetchCustomerData, FetchCustomerDataResetState, FetchEmailLogs, FetchSupplyOrderLogs } from "../../../actions/CSAgentPortal/CustomerDashboard";
import './CustomerDashboard.css';
import Loader from "../../../components/shared/loader/Loader";
import { Helmet } from "react-helmet";
import EmailLogs from "../../../components/CSAgent/CustomerDashboard/TabContents/EmailLogs/EmailLogs";
import SupplyOrderLogs from "../../../components/CSAgent/CustomerDashboard/TabContents/SupplyOrderLogs/SupplyOrderLogs";
import PrintActivity from "../../PrintActivity/PrintActivity";
import { userRoles } from "../../../constants/UserRoles";
import { CSAGENT_SUBSCRIPTION_TYPES } from "../../../constants/AppConstants";
import BOCDeviceConnection from "../../../components/CSAgent/CustomerDashboard/TabContents/BOCDeviceConnection/BOCDeviceConnection";

const CustomerDashboard = (props) => {
    const {t} = useTranslation("CSAgenttranslation");
    const dispatch = useDispatch();
    const history = useHistory();
    const OVERVIEW = "overview";
    const CUSTOMER_DETAILS = "customer_details"; 
    const SUBSCRIPTION_DETAILS = "subscription_details";
    const EMAIL_LOGS = "email_logs";
    const SUPPLY_ORDER_LOGS = "supply_order_logs";
    const BOC_DEVICE_CONNECTION = "boc_device_connection";
    const PRINT_ACTIVITY = "print_activity";
    const [activeTab, setActiveTab] = useState(OVERVIEW);
    const dashboardInfoRes = useSelector(state => state.CSAGENT.CustomerDashboardInfo);
    const emailLogsRes = useSelector(state => state.CSAGENT.FetchEmailLogs);
    const MAILID = 'MailId';
    const BPID = "BusinessPartnerId"
    const searchTerms = props.location?.state?.searchTerms
    const dashboardInfo = history.action === "POP" ? dashboardInfoRes : props.location.state.dashboardInfo
    const CUSTOMER_SUBSCRIPTION_STATUS = dashboardInfo?.customer_data?.subscriptionStatus?.toLowerCase();
    const SUBSCRIPTION_ACTIVE_STATUS = (CUSTOMER_SUBSCRIPTION_STATUS === CSAGENT_SUBSCRIPTION_TYPES.ACTIVE.toLowerCase()) ? true : false;
   
    const goBack = () => {
        dispatch(FetchCustomerDataResetState());
        history.push(CSAgentRoutePaths.CUSTOMER_SEARCH);
    }
    useEffect(()=>{
        if(history.action === "POP" && searchTerms){
            if(Object.keys(searchTerms).includes(MAILID) || Object.keys(searchTerms).includes(BPID)){
                dispatch(FetchCustomerData(null, Object.keys(searchTerms).toString(), Object.values(searchTerms).toString()))
            } else {
                dispatch(FetchCustomerData(Object.values(searchTerms).toString()));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch])

    const onFetchEmailAndSupplyLogs = (tabType) => {
        switch(tabType){
            case EMAIL_LOGS:
                setActiveTab(tabType);
                dispatch(FetchEmailLogs(dashboardInfo?.customer_data?.userAccount?.userId));
            break;
            case SUPPLY_ORDER_LOGS:
                setActiveTab(tabType);
                dispatch(FetchSupplyOrderLogs(dashboardInfo?.customer_data?.userAccount?.userId));
            break;
            default:
                setActiveTab(OVERVIEW);
        }
    }

    if(dashboardInfo.loading) {
        return <Loader></Loader>
    }
   
    return (
        <>
        <Helmet>
          <title>{t("PAGE_TITLE.CUSTOMER_DASHBOARD")}</title>
        </Helmet>
        <div className="row customer-service-dashboard mt-40">
            <div className="col-4 customer-dashboard-right">
                <CustomerInfo {...dashboardInfo}/>
            </div>
            <div className="col-8 customer-dashboard-left">
                <div className="tab-btn-wrapper">
                    <div>
                    <Nav tabs>
                        <NavItem>
                            <NavLink active={activeTab === OVERVIEW} onClick={() => setActiveTab(OVERVIEW)}>
                                {t('CUSTOMER_DASHBOARD.TAB_ITEMS.OVERVIEW')}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink active={activeTab === CUSTOMER_DETAILS} onClick={() => setActiveTab(CUSTOMER_DETAILS)}>
                                {t('CUSTOMER_DASHBOARD.TAB_ITEMS.CUSTOMER_DETAILS')}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink active={activeTab === SUBSCRIPTION_DETAILS} onClick={() => setActiveTab(SUBSCRIPTION_DETAILS)}>
                                {t('CUSTOMER_DASHBOARD.TAB_ITEMS.SUBSCRIPTION_BILLING')}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink active={activeTab === EMAIL_LOGS} onClick={() => onFetchEmailAndSupplyLogs(EMAIL_LOGS)}>
                                {t('CUSTOMER_DASHBOARD.TAB_ITEMS.EMAIL_LOGS')}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink active={activeTab === SUPPLY_ORDER_LOGS} onClick={() => onFetchEmailAndSupplyLogs(SUPPLY_ORDER_LOGS)}>
                                {t('CUSTOMER_DASHBOARD.TAB_ITEMS.SUPPLY_ORDER_LOGS')}
                            </NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink 
                                active={activeTab === BOC_DEVICE_CONNECTION} 
                                onClick={() => setActiveTab(BOC_DEVICE_CONNECTION)}
                            >
                                {t('CUSTOMER_DASHBOARD.TAB_ITEMS.BOC_DEVICE_CONNECTION')}
                            </NavLink>
                        </NavItem>

                        {
                            (SUBSCRIPTION_ACTIVE_STATUS) 
                                ? <NavItem>
                                    <NavLink active={activeTab === PRINT_ACTIVITY} onClick={() => setActiveTab(PRINT_ACTIVITY)}>
                                        {t('CUSTOMER_DASHBOARD.TAB_ITEMS.PRINT_ACTIVITY')}
                                    </NavLink>
                                </NavItem>
                                : null
                        }
                    </Nav>
                    </div>
                    <div className="customer-end-session-btn-wrapper">
                        <EcoProButton cname="default" onBtnClick={() => goBack()}>{t('CUSTOMER_DASHBOARD.TAB_ITEMS.GO_BACK')}</EcoProButton>
                    </div>
                </div>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId={OVERVIEW}>
                        <Overview {...dashboardInfo.customer_data}/>
                    </TabPane>
                    <TabPane tabId={CUSTOMER_DETAILS}>
                        <CustomerDetails {...dashboardInfo.customer_data.userAccount}/>
                    </TabPane>
                    <TabPane tabId={SUBSCRIPTION_DETAILS}>
                        <SubscriptionDetails {...dashboardInfo.customer_data} {...dashboardInfo.subscriptionStatus}/>
                    </TabPane>
                    <TabPane tabId={EMAIL_LOGS}>
                        <EmailLogs emailLogRes={emailLogsRes}/>
                    </TabPane>
                    <TabPane tabId={SUPPLY_ORDER_LOGS}>
                        <SupplyOrderLogs handleSupplyOrderLogs = {() => onFetchEmailAndSupplyLogs(SUPPLY_ORDER_LOGS)}/>
                    </TabPane>
                    <TabPane tabId={BOC_DEVICE_CONNECTION}>
                        <BOCDeviceConnection 
                           {...dashboardInfo.customer_data} {...dashboardInfo.subscriptionStatus}
                        />
                    </TabPane>
                    {
                        (SUBSCRIPTION_ACTIVE_STATUS) 
                        ? <TabPane tabId={PRINT_ACTIVITY}>
                        <PrintActivity 
                            role={userRoles.CSAGENT} 
                            CustomerUID={dashboardInfo?.customer_data?.userAccount?.userId} 
                            customerDeviceID={dashboardInfo?.customer_data?.deviceInfo?.deviceId}
                            customerCountryCode={dashboardInfo?.customer_data?.userAccount?.countryCode}
                        />
                        </TabPane>
                        : null
                    }
                </TabContent>
            </div>
        </div>
        </>
    )
}

export default CustomerDashboard;