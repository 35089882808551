import React from 'react';
import { useTranslation } from 'react-i18next';
import HardwareSignupCard from '../../../HardwareSignupCard/HardwareSignupCard';
import { getHWPrinterFeatureTranslation } from '../../../../../../utils/HardwareInclusive/HardwareInclusive';
//import LearnMorePrinterFeature from '../../LearnMorePrinterFeature/LearnMorePrinterFeature';
// import { useState } from 'react';

const DisplayBasePackage = (props) => {
    const {printerFeatures, handleSelectionChange, selectedData, printerType} = props
    const {t} = useTranslation(["translation"])

    //@@@@@@@@ TODO
    // const [show, setShow] = useState(false);
    // const handleClose = () => { 
    //     setShow(false)
    // };
    // const DisplayLearnMore = () => setShow(true);
    return (
        <div className='cp-printer-feature-wrapper'>
            
                <div className='cp-subtitle'>{t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.BASE_PACKAGE.HEADER")}</div>
                <div className='cp-extra-title-list-header'>{t("HARDWARE_SIGNUP.CUSTOMIZE_PRINTER.BASE_PACKAGE.RIGHT_PACKAGE")}</div>

                {printerFeatures?.map(feature => (
                    <HardwareSignupCard 
                        id={feature?.featureId}
                        onHardwarePlanSelection={handleSelectionChange}
                        selectedCard={selectedData}
                        toCompareSelectedCardWith={feature?.featureId}
                        cardRightData={getHWPrinterFeatureTranslation(feature?.featureId, printerType, feature?.featureName)}
                    ></HardwareSignupCard>
                ))}
                {/* @@@@@@@@@@@@ TODO               
                <LearnMorePrinterFeature   
                    show={show}
                    handleClose={handleClose}
                ></LearnMorePrinterFeature> */}
        </div>
    );
};

export default DisplayBasePackage;