import React from "react";
import WebAddAccount from "../../../Web/Customer/Account/SignUp/AddAccountDetails/WebAddAccount";
import Account from "./Account";

const AddAccount = (props) => {
  
  return (
    <>
      <Account className="mobile-forms" />
      <WebAddAccount className="desktop-mobileconnect-forms" flow="desktop" selectedPlan={props?.location?.state?.selectedPlan} />
    </>
  );
};

export default AddAccount;
