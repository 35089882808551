import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import DisplayUpdateAccountConfirmation from '../../../components/EditMyAccount/DisplayUpdateAccountConfirmation/DisplayUpdateAccountConfirmation';
import { routePath } from '../../../constants/Routes';
import confirmationIcon from "../../../assets/images/complete-confirmation-icon.svg";
import ErrorPage from '../../../components/shared/errorPage/ErrorPage';

const UpdateAccountSuccess = () => {
    const location = useLocation();
    const { t } = useTranslation(["translation"]);
    const history = useHistory();
    const onAccountConfirm = () => {
        history.push(routePath.MY_ACCOUNT);
    }
    
    if(location?.state?.accountClicked === "shippingAddress"){
        return (
            <DisplayUpdateAccountConfirmation
            updateAddressHeader={t("EDIT_FORM.UPDATE_ACCOUNT_CONFIRMATION.SHIPPING")}
            updateAddressInfo={t("EDIT_FORM.UPDATE_ACCOUNT_CONFIRMATION_INFO.SHIPPING")}
            updateAddressBtn={t("EDIT_FORM.UPDATE_ACCOUNT_BTN.CONTINUE")}
            onUpdateClick={() => onAccountConfirm()}
            image={confirmationIcon}
        ></DisplayUpdateAccountConfirmation>
        )
    }
    else if(location?.state?.accountClicked === "billingAddress"){
        return (
            <DisplayUpdateAccountConfirmation
                updateAddressHeader={t("EDIT_FORM.UPDATE_ACCOUNT_CONFIRMATION.BILLING")}
                updateAddressInfo={t("EDIT_FORM.UPDATE_ACCOUNT_CONFIRMATION_INFO.BILLING")}
                updateAddressBtn={t("EDIT_FORM.UPDATE_ACCOUNT_BTN.CONTINUE")}
                onUpdateClick={() => onAccountConfirm()}
                image={confirmationIcon}
            ></DisplayUpdateAccountConfirmation>
        )
    }
    else if(location?.state?.accountClicked === "shippingBillingAddress"){
        return (
            <DisplayUpdateAccountConfirmation
                updateAddressHeader={t("EDIT_FORM.UPDATE_ACCOUNT_CONFIRMATION.SHIPPING_BILLING")}
                updateAddressInfo={t("EDIT_FORM.UPDATE_ACCOUNT_CONFIRMATION_INFO.SHIPPING_BILLING")}
                updateAddressBtn={t("EDIT_FORM.UPDATE_ACCOUNT_BTN.CONTINUE")}
                onUpdateClick={() => onAccountConfirm()}
                image={confirmationIcon}
            ></DisplayUpdateAccountConfirmation>
        );
    }
    else if(location?.state?.accountClicked === "paymentDetails"){
        return (
            <DisplayUpdateAccountConfirmation
              updateAddressHeader={t("EDIT_FORM.UPDATE_ACCOUNT_CONFIRMATION.PAYMENT")}
              updateAddressInfo={t("EDIT_FORM.UPDATE_ACCOUNT_CONFIRMATION_INFO.PAYMENT")}
              updateAddressBtn={t("EDIT_FORM.UPDATE_ACCOUNT_BTN.CONTINUE")}
              onUpdateClick={() => onAccountConfirm()}
              noThanksStatus=""
              image={confirmationIcon}
            ></DisplayUpdateAccountConfirmation>
          );
    } else{
        return(
            <ErrorPage></ErrorPage>
        )
    }
};

export default UpdateAccountSuccess;