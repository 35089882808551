import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AddDeviceCard from "../../../AddDeviceCard/AddDeviceCard";
import EcoProButton from "../../../shared/button/button";
import "./DisplayWebEligibleDevice.css";

const DisplayWebEligibleDevice = (props) => {
  const {header,subheader,selectInfo, eligibleDevices, selectedreplacementDeviceId, onRelacementSelection,onConfirmEligibleDeviceSelection, btnText, linkUrl, linkText,linkWithState} = props

  const { t } = useTranslation(["translation"]);
  return (
    <div className="eligible-device-container">
        <div className="eligible-device-header">
            {header}
        </div>
        <div className="eligible-device-subheader">
            {subheader}
        </div>
        <div className="eligible-device-list-header">
            {selectInfo}
        </div>
        <div className="eligible-card-list-wrapper">
            {eligibleDevices && eligibleDevices.map((device) => (
            <AddDeviceCard
                device={device}
                toSelectDeviceParameter={device.deviceId}
                selectedreplacementDeviceId={selectedreplacementDeviceId}
                onRelacementSelection={onRelacementSelection}
                imagePath={device.imagePath}
                deviceName={device.model}
                deviceInfo={`${t("DASHBOARD.DISPLAY_DEVICE.SERIAL_NUMBER")}: ${
                device.serialNumber
                }`}
                onClickDeviceParameter="eligibleDevice"
            ></AddDeviceCard>
            ))}
        </div>
        <div className='eligible-device-btn'><EcoProButton cname="default"  disabled={!selectedreplacementDeviceId} btnID='add_device' onBtnClick={() => onConfirmEligibleDeviceSelection()}>{btnText}</EcoProButton></div>
        { selectedreplacementDeviceId && <div className="eligible-device-link">
            <Link to={{
                pathname: linkUrl,
                state: linkWithState && { 'deviceId':linkWithState.deviceId,'device_model': linkWithState.device_model,'serialNumber':linkWithState.serialNumber,'networkStatus':linkWithState.networkStatus }
            }} id="change_plan">{linkText}</Link>
        </div> }
        { !selectedreplacementDeviceId && <div className="eligible-device-link">
            <span>{linkText}</span>
        </div> }
    </div>
  );
};

export default DisplayWebEligibleDevice;
