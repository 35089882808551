import * as actionTypes from '../../constants/ActionTypes/SignIn';

const initialState = {
    loading: false,
    error: null,
    success:false,
    bol_details:{}
  }

const BOLAPI = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.BOL_API_INIT:
            return {
                ...state,
                loading: true
            }
        
        case actionTypes.BOL_API_SUCCESS:
            return {
                ...state,
                loading: false,
                success:true,
                bol_details: action.BOLData
            }
        
        case actionTypes.BOL_API_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.message
            }
        default:
            return {...state}
    }
}

export default BOLAPI;