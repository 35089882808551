import React from "react";
import { useTranslation } from "react-i18next";
import { routePath } from "../../constants/Routes";
import { ReactComponent as DashboardImg } from "../../assets/images/dashboard.svg";
import { ReactComponent as EcoSavingImg } from "../../assets/images/eco-saving.svg";
import { ReactComponent as OrderHistoryImg } from "../../assets/images/order-history.svg";
import { ReactComponent as PrintActivityImg } from "../../assets/images/print.svg";
import { getFeatureSwitchByKey } from "../Authentication/handleUserSession";
import { UNIQUE_ID_GA, featureSwitchName } from "../../constants/AppConstants";

const HamburgerNavItems = () => {
    const { t } = useTranslation(['translation']); 
    const navItems = [
        {
            item_name: t("NAVIGATION_MENU.DASHBOARD"),
            item_logo:  <DashboardImg />,
            item_link: routePath.DASHBOARD,
            item_enabled: true,
            item_id: UNIQUE_ID_GA.dashboard
        },
        {
            item_name: t("NAVIGATION_MENU.ECO_SAVINGS"),
            item_logo:  <EcoSavingImg />,
            item_link: routePath.CARBON_OFFSETTING,
            item_enabled: (getFeatureSwitchByKey(featureSwitchName.CARBON_SAVING)) ? true : false,
            item_id: UNIQUE_ID_GA.ecoSaving
        },
        {
            item_name: t("NAVIGATION_MENU.ORDER_HISTORY"),
            item_logo:  <OrderHistoryImg />,
            item_link: routePath.ORDERS,
            item_enabled: true,
            item_id: UNIQUE_ID_GA.orderHistory
        },
        {
            item_name: t("NAVIGATION_MENU.PRINT_ACTIVITY"),
            item_logo:  <PrintActivityImg />,
            item_link: routePath.PRINT_ACTIVITY,
            item_enabled: true,
            item_id: UNIQUE_ID_GA.printActivity
        }
    ]
    return navItems;
}

export default HamburgerNavItems;