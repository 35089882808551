/**
 * Method to set auth token retireved from authentication services
 * @param {string} AUTHToken
 * @returns void
 */
 export const setAuthToken = (AUTHToken) => {
    localStorage.setItem('ecoproAuthToken', AUTHToken);
}

/**
 * Method to get auth token retireved from authentication services
 * @returns void
 */
 export const getAuthToken = () => {
    return localStorage.getItem('ecoproAuthToken');
}

/**
 * Method to remove the auth token set in local storage
 * @returns void
 */
export const removeAuthToken = () => {
    localStorage.removeItem('ecoproAuthToken');
}