import { call, put, takeEvery } from 'redux-saga/effects';
import i18next from "i18next";
import * as actionTypes from '../../../constants/ActionTypes/AdminPortal/PrinterModels';
import { _CALLPUTAPI_WITHQS } from '../../../api/apiHandler'
import { FAILURE, LOCALSESSIONDATAKEYS, _ADMINISTRATOR_API_ENDPOINTS } from '../../../constants/AppConstants';
import { getLocalSessionData } from '../../../utils/Authentication/handleUserSession';

function _UPDATE_PRINTERMODEL_STATUS(payload){
    const endpoint = _ADMINISTRATOR_API_ENDPOINTS.MANAGE_PRINTER_MODELS.UPDATE_MODEL_STATUS;
    const queryString = {
        params: {
            userId: getLocalSessionData(LOCALSESSIONDATAKEYS.UID)
        }
      }
     return _CALLPUTAPI_WITHQS(endpoint, payload, queryString)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}

function* handleUpdate(action){
    try {
        const payload = action.payload;
        if(Object.values(payload).length > 0){
            const result = yield call(_UPDATE_PRINTERMODEL_STATUS, payload);
            if(result.actionStatus.toUpperCase() !== FAILURE) {
                yield put({type: actionTypes.UPDATE_PRINTER_MODELS_SUCCESS, data: result});
            } else {
                yield put({
                    type: actionTypes.UPDATE_PRINTER_MODELS_ERROR, 
                    message: i18next.t('Admintranslation:ADMIN_COMMON_ERRORS.UPDATE_ERROR')
                });
            }
        } else {
            yield put({
                type: actionTypes.UPDATE_PRINTER_MODELS_ERROR, 
                message: i18next.t('Admintranslation:ADMIN_COMMON_ERRORS.INVALID_INPUT')
            });
        }
    } catch (e) {
         yield put({type: actionTypes.UPDATE_PRINTER_MODELS_ERROR, message: i18next.t('Admintranslation:ADMIN_COMMON_ERRORS.TECHNICAL_ERROR')});
    }
}

function* UpdatePrinterModelStatus(){
    yield takeEvery(actionTypes.UPDATE_PRINTER_MODELS_INIT, handleUpdate);
}

export default UpdatePrinterModelStatus;