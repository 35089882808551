import * as actionTypes from '../../constants/ActionTypes/Dashboard';

const initialState = {
    cancellationFee:{},
    loading: false,
    error: false
  }

const GetCancellationFees = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CANCELLATION_FEES:
            return {
                ...state,
                loading: true,
                success: false
            }
        
        case actionTypes.GET_CANCELLATION_FEES_SUCCESS:
            return {
                ...state,
                success:true,
                cancellationFee: action.cancellationFee,
                loading: false
            }
        
        case actionTypes.GET_CANCELLATION_FEES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message,
                success: false
            }
        default:
            return {...state}
    }
}

export default GetCancellationFees