import { call, put, takeEvery } from 'redux-saga/effects';
import i18next from "i18next";
import * as actionTypes from "../../../constants/ActionTypes/Web/Customer/SignUp";
import { _CALLGETAPI } from '../../../api/apiHandler';
import { WEB_CUSTOMER_API_ENDPOINT } from '../../../constants/AppConstants';

function _FETCHWEBSERVICEMODEL(deviceModel,promotion){
    const endpoint = WEB_CUSTOMER_API_ENDPOINT.FETCH_PLAN;
    let payload;
    if(promotion){
        payload = {
            params: {
                plantype: 'new',
                model: deviceModel,
                includeHiddenPlans: 'false',
                promotions:promotion
            }
          }
    }
    else{
        payload = {
            params: {
                plantype: 'new',
                model: deviceModel,
                includeHiddenPlans: 'false'
            }
        }
    }
     return _CALLGETAPI(endpoint, payload)
    .then(response => {
        return response.plans;
    })
    .catch((error) => {
        throw error;
    });
}
function* fetchWebServicePlan(action){
    try {
        const deviceModel = action.deviceModel;
        const promotion = action.promotion;
        if(deviceModel){
            const servicePlan = yield call(_FETCHWEBSERVICEMODEL, deviceModel,promotion&&promotion);
            yield put({type: actionTypes.FETCH_WEB_SERVICE_PLAN_SUCCESS, webServicePlan: servicePlan});
        } else {
            yield put({
                type: actionTypes.FETCH_WEB_SERVICE_PLAN_ERROR, 
                message: i18next.t('ERROR_CONTENT.INFO_GENERAL')
            });
        }
    } catch (e) {
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
        if(e instanceof TypeError === false && e.message !== "401"){
            yield put({type: actionTypes.FETCH_WEB_SERVICE_PLAN_ERROR, message: e.message});
        }
    }
}
function* WebServicePlan(){
    yield takeEvery(actionTypes.FETCH_WEB_SERVICE_PLAN, fetchWebServicePlan);
}
export default WebServicePlan;