import * as actionTypes from "../../constants/ActionTypes/MyAccount";

const initialState = {
  mobileError: false,
  telephoneError: false,
  showZipError: false,
  emailError: false,
  accountStatus: {
    personalDetails: "active",
    shippingAddress: "inactive",
    billingAddress: "inactive",
  },
  account: {
    personalDetails: {
      firstName: "",
      lastName: "",
      emailAddress: "",
      telephoneNumber: "",
      mobileNumber: "",
      offers: false,
    },
    shippingAddress: {
      address: "",
      addressLine2: "",
      city: "",
      country: "",
      postcode: "",
      company: "",
      province: ""
    },
    billingAddress: {
      address: "",
      addressLine2: "",
      city: "",
      country: "",
      postcode: "",
      company: "",
      province: "",
      eInvoiceId:"",
      sameAsShippingAddress: false,
      termsAndPrivacy: false,
    },
  },
};

const MyAccount = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_ACCOUNT_STATUS:
      return {
        ...state,
        accountStatus: action.accountStatus,
        account: action.formData,
      };
    case actionTypes.SHOW_ZIPCODE_ERROR:
      return {
        ...state,
        showZipError: action.zipErrorStatus,
      };
    case actionTypes.SHOW_MOBILE_ERROR:
      if (action.field === "mobile") {
        return {
          ...state,
          mobileError: action.mobileErrorStatus,
        };
      } else if (action.field === "telephone") {
        return {
          ...state,
          telephoneError: action.mobileErrorStatus,
        };
      };
      break;
    case actionTypes.SHOW_EMAIL_ERROR:
      return {
        ...state,
        emailError: action.emailErrorStatus,
      };
    case actionTypes.SHOW_SDI_ERROR:
      return {
        ...state,
        sdiError : action.sdiErrorStatus
      }
    default:
      return { ...state };
  }
};
export default MyAccount;
