import { call, put, takeEvery } from "redux-saga/effects";
import i18next from "i18next";
import * as actionTypes from "../../constants/ActionTypes/CarbonSaving";
import { _CALLGETAPI } from "../../api/apiHandler";
import { _API_ENDPOINT } from "../../constants/AppConstants";

function _GETCARBONSAVING(UID) {
  const endpoint = _API_ENDPOINT.GET_CARBON_SAVING;

  const payload = {
    params: {
      UserId: UID,
    },
  };
  return _CALLGETAPI(endpoint, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function* getCarbonSaving(action) {
  try {
    const UID = action.UID;
    if(UID){
      const carbonSaving = yield call(
        _GETCARBONSAVING,
        UID,
      );
        yield put({
          type: actionTypes.GET_CARBON_SAVING_SUCCESS,
          carbonSaving: carbonSaving,
        });
    } else {
      yield put({
        type: actionTypes.GET_CARBON_SAVING_ERROR,
        message: i18next.t('ERROR_CONTENT.INFO_GENERAL')
      });
    }
  } catch (e) {
    yield put({
      type: actionTypes.GET_CARBON_SAVING_ERROR,
      message: e.message,
    });
  }
}

function* GetCarbonSaving() {
  yield takeEvery(actionTypes.GET_CARBON_SAVING, getCarbonSaving);
}

export default GetCarbonSaving;
