import { all } from "redux-saga/effects";
import watchFetchCancelService from "./brotherPlus/cancelService/cancelServiceSaga";
import watchRegisterService from "./brotherPlus/EnrollmentService/RegisterService/RegisterServiceSaga";
import watchFetchBOCDeviceConnectionService from "./CSAgentPortal/BOCDeviceConnectionServiceSaga";

export default function* brotherPlusSagasIndex() {
    yield all([
        watchFetchCancelService(),
        watchRegisterService(),
        watchFetchBOCDeviceConnectionService(),
    ])
}