import React from 'react';
import rightArrow from "../../assets/images/rightArrow.svg";

const ShippingAndBillingAddress = ({moveToEditPage,header, address, addressLine2, city, postcode}) => {
  let formattedAddrs = (!address && !addressLine2 && !city && !postcode) 
  ? '' : (!addressLine2) ? `${address}, ${city}, ${postcode}` : `${address}, ${addressLine2}, ${city}, ${postcode}`;
  return (
        <div
          className="row my-account-content pointer-cursor"
          onClick={() => moveToEditPage()}
        >
          <div className="col-10">
            <div className="f-16 fc-g fw-b ta-left">
              {header}
            </div>
            <div className="mt-30 f-14 ta-left">
              {formattedAddrs}
            </div>
          </div>
          <div className="col my-account-edit-btn">
            <img src={rightArrow} alt="edit shipping address" />
          </div>
        </div>
    );
};

export default ShippingAndBillingAddress;