import { call, put, takeEvery } from 'redux-saga/effects';
import i18next from "i18next";
import * as actionTypes from "../../../constants/ActionTypes/Web/Customer/SignUp";
import { WEB_CUSTOMER_API_ENDPOINT } from '../../../constants/AppConstants';
import { _CALLGETAPI } from '../../../api/apiHandler';

function _FETCHPRINTERMODELS(printerTypeID){
    const endpoint = WEB_CUSTOMER_API_ENDPOINT.GET_PRINTERS_MODEL_LIST;
    const payload = {
        params: {
            printerTypeId:printerTypeID
        }
    }
    return _CALLGETAPI(endpoint, payload)
    .then(response => {
        return response;
    })
    .catch((error) => {
        throw error;
    });
}
function* fetchPrinterModels(action){
    try {
        const printerTypeID = action.printerTypeID;
        if(printerTypeID){
            const models = yield call(_FETCHPRINTERMODELS, printerTypeID);
            yield put({type: actionTypes.FETCH_PRINTER_MODELS_SUCCESS, models: models});
        } else {
            yield put({
                type: actionTypes.FETCH_PRINTER_MODELS_ERROR, 
                message: i18next.t('ERROR_CONTENT.INFO_GENERAL')
            });
        }
    } catch (e) {
        /**
         * This conditional statement is added to avoid
         * displaying error message in view while logout from app
         */
        if(e instanceof TypeError === false && e.message !== "401"){
            const msg = (e?.response?.status === 404) ? i18next.t('CUSTOMER_WEB.ERROR_CONTENT.NO_RESULTS_FOUND') : e.message;
            yield put({type: actionTypes.FETCH_PRINTER_MODELS_ERROR, message: msg});
        }
    }
}
function* PrinterModels(){
    yield takeEvery(actionTypes.FETCH_PRINTER_MODELS, fetchPrinterModels);
}
export default PrinterModels;