import React, {useRef} from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { ManageSubscription} from '../../actions/MyAccount';
import EcoProButton from '../shared/button/button';
import { Alert, Container } from 'reactstrap';
import { PAYMENT_TYPE, SUBSCRIPTION_TYPE } from '../../constants/AppConstants';
import { WEB_handleSelectedPlanLocalData } from '../../utils/Authentication/handleUserSession';
import { Is_HWInclusive_Signup, pushDataLayer } from '../../constants/AppMethod';
import { HWInclusive_ManageInstantPayment } from '../../actions/HardwareInclusive/HardwareInclusive';

const ZuoraPayment = (props) => {
    const dispatch = useDispatch();
    const MyAccount = useSelector((state) => state.MyAccount);
    const ZuoraPaymentInfo = useSelector(state => state.ZuoraPaymentInfoRendering);
    const SelectedPlan = useSelector(state => state.MyPlan);
    let paymentRefID = useRef();
    const paymentType = props.paymentType;
    const fetchedMyAccount = props.accountInfo;
    const signUpType = props.signUpType;
    const IsHWInclusive_Signup = Is_HWInclusive_Signup();

    const createSubscription = () => {
        pushDataLayer('add_payment_info')
        let paymentID = paymentRefID.current.value;
        let userAccountDetails;
        let subscriptionPayload;
        let subscriptionType;
        if(paymentType === PAYMENT_TYPE.UPDATE){
            subscriptionType = SUBSCRIPTION_TYPE.UPDATE_PAYMENT;
            userAccountDetails = {...MyAccount.account,
                personalDetails: {
                    "firstName": (fetchedMyAccount.myAccount.firstName) ? fetchedMyAccount.myAccount.firstName : "",
                    "lastName": (fetchedMyAccount.myAccount.lastName) ? fetchedMyAccount.myAccount.lastName : "",
                    "emailAddress": (fetchedMyAccount.myAccount.emailAddress) ? fetchedMyAccount.myAccount.emailAddress : "",
                    "telephoneNumber": (fetchedMyAccount.myAccount.telephoneNumber) ? fetchedMyAccount.myAccount.telephoneNumber : "",
                    "mobileNumber": (fetchedMyAccount.myAccount.mobileNumber) ? fetchedMyAccount.myAccount.mobileNumber : ""
                },
                billingAddress: {
                    "address": (fetchedMyAccount.myAccount.billingAddress.addressLine1) ? fetchedMyAccount.myAccount.billingAddress.addressLine1 : "",
                    "addressLine2": (fetchedMyAccount.myAccount.billingAddress.addressLine2) ? fetchedMyAccount.myAccount.billingAddress.addressLine2 : "",
                    "city": (fetchedMyAccount.myAccount.billingAddress.cityTown) ? fetchedMyAccount.myAccount.billingAddress.cityTown : "",
                    "country": (fetchedMyAccount.myAccount.billingAddress.country) ? fetchedMyAccount.myAccount.billingAddress.country : "",
                    "postcode": (fetchedMyAccount.myAccount.billingAddress.postCode) ? fetchedMyAccount.myAccount.billingAddress.postCode: "",
                    "company": (fetchedMyAccount.myAccount.billingAddress.companyName) ? fetchedMyAccount.myAccount.billingAddress.companyName : ""
                }
            }
             subscriptionPayload = {
                accountDetails: userAccountDetails,
                billingInfo: ZuoraPaymentInfo.zrenderParams
            }
        } else {
            subscriptionType = SUBSCRIPTION_TYPE.NEW;
            userAccountDetails =  MyAccount.account;
            subscriptionPayload = {
                accountDetails: MyAccount.account,
                billingInfo: ZuoraPaymentInfo.zrenderParams,
                selectedplan: (signUpType === "WEB") ? WEB_handleSelectedPlanLocalData("Get") : SelectedPlan.selected_plan
            }
        }
        dispatch(ManageSubscription(paymentID, subscriptionPayload, subscriptionType));
    }
    const createInstantPayment_HWInclusive = () => {
        pushDataLayer('add_payment_info')
        const paymentID = paymentRefID.current.value;
        const subscriptionPayload = {
            accountDetails: MyAccount.account,
            billingInfo: ZuoraPaymentInfo.zrenderParams,
            selectedplan: WEB_handleSelectedPlanLocalData("Get")
        }
        const payload = {
            paymentID: paymentID,
            subscriptionPayload: subscriptionPayload,
        }
        dispatch(HWInclusive_ManageInstantPayment(payload));
    }
    return <>
        <Container style={{display:'none'}} id="error-alert">
            <Alert id="payment-error-alert" color="danger"></Alert>
        </Container>
        <div className="col-sm-12 d-none">
            <input ref={paymentRefID}  id="paymentRefID" type="hidden" value=""/>
            <EcoProButton
              cname="default"
              btnID="card_info_submit"
              onBtnClick={() => (IsHWInclusive_Signup) 
                            ?   createInstantPayment_HWInclusive() 
                            :   createSubscription()
                        }
            >Next</EcoProButton>
        </div>
    </>
}
export default ZuoraPayment;