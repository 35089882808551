import { createSlice } from '@reduxjs/toolkit';

const RegisterServiceSlice = createSlice({
  name: 'enrollmentRegisterService',
  initialState: {
    loading: false,
    data: null,
    error: null,
    success: false
  },
  reducers: {
    EnrollmentRegisterServiceInit(state) {
      state.loading = true;
      state.error = null;
    },
    EnrollmentRegisterServiceSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.success = true
    },
    EnrollmentRegisterServiceFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = false
    },
  },
});

export const { 
  EnrollmentRegisterServiceInit, 
  EnrollmentRegisterServiceSuccess, 
  EnrollmentRegisterServiceFailure 
} = RegisterServiceSlice.actions;

export default RegisterServiceSlice.reducer;
