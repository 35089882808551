import React, { useState } from "react";
import { PAYMENT_OPTIONS } from "../../../../constants/AppConstants";
import ZuoraPaymentForm from "../ZuoraPaymentForm";
import { Accordion } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { ZuoraPaymentInfoRendering } from "../../../../actions/MyAccount";
import { paymentOptionsNameTranslation } from "../../../../constants/AppMethod";
import { useTranslation } from "react-i18next";

const PaymentOptionsAccordian = (props) => {
    const { t } = useTranslation(['translation']); 
    const { cname = 'none', paymentOptions, zrenderParams, signUpType, paymentMethod } = props;
    const dispatch = useDispatch();
    const [open, setOpen] = useState((paymentMethod) ? paymentMethod : PAYMENT_OPTIONS.CREDIT_CARD);
    const [tabContents, setTabContents] = useState({[PAYMENT_OPTIONS.CREDIT_CARD]: (paymentMethod && paymentMethod === PAYMENT_OPTIONS.DIRECT_BANK_TRANSFER) ? false : true, [PAYMENT_OPTIONS.DIRECT_BANK_TRANSFER]: (paymentMethod && paymentMethod === PAYMENT_OPTIONS.DIRECT_BANK_TRANSFER) ? true :false});

    const handlePaymentOptions = (paymentType) => {
        if(paymentType === open){
            setOpen();
        } else {
            setOpen(paymentType); 
            setTabContents(
                Object.fromEntries(Object.entries(tabContents).map(([key, val]) => {
                    return [key, (key === paymentType) ? true : false];
                }))
            );
            dispatch(ZuoraPaymentInfoRendering(paymentType));
        }
    }

    return (
        <div id="payment-option-accordian" className={`payment-options-comp payment-options-accordian-outer-wrapper zuora-payment-wrapper container ${cname}`}>
            <h2>{t('PAYMENT_OPTIONS.SELECT_PAYMENT_METHOD')}</h2>
            <div className="accordian-section">
                <Accordion className="payment-method-accordian-wrapper" defaultActiveKey={open} flush>
                    {
                        paymentOptions.data.length > 0 && paymentOptions.data.map((val) => (
                            <Accordion.Item className={`${(open === val.paymentMethodName ? 'active-item': 'inactive-item')}`} eventKey={val.paymentMethodName} onClick={() => handlePaymentOptions(val.paymentMethodName)}>

                                <Accordion.Header>
                                    { paymentOptionsNameTranslation(val.paymentMethodName) }
                                </Accordion.Header>

                                <Accordion.Body>
                                    { zrenderParams.loading &&
                                        <div className="payment-options-spinner-wrapper">
                                            <div class="spinner-border" role="status"></div>
                                        </div>
                                    }
                                    { !zrenderParams.loading 
                                        && tabContents[val.paymentMethodName]
                                        && <ZuoraPaymentForm signUpType={signUpType}/> 
                                    }
                                </Accordion.Body>

                            </Accordion.Item>
                        ))
                    }
                </Accordion>
            </div>
        </div>
    )
}


export default PaymentOptionsAccordian;