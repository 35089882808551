import React from "react";
import rightArrow from "../../../assets/images/rightArrow.svg";
import giveFeedback from "../../../assets/images/give-feedback.svg"
import { routePath } from "../../../constants/Routes";
import { useHistory } from "react-router-dom";
import './ecoprofeedback.css';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";


const EcoProFeedbackMobileConnect = () => {
    const history = useHistory();
    const { t } = useTranslation(['translation']);
    const enrollmentStatus = useSelector(state => state.EnrollmentStatus);

    const ongiveFeedback = () => {
        history.push(routePath.ECOPRO_FEEDBACK)
    }

    return(
        <div className="feedback-menu-container">
        <div className={`feedback-menu-wrapper ${(!enrollmentStatus.enrollment_completed) ? 'navigation-disabled' : 'feedback-navigation-enabled'}`} onClick={()=>ongiveFeedback()}>
              <img src={giveFeedback} alt="give feedback"/>
            <div className="feedback-menu-content">
              <div className="feedback-menu-title">{t("DASHBOARD_FEEDBACK.MENU_TITLE")}</div>
              <div className="feedback-menu-info">{t("DASHBOARD_FEEDBACK.MENU_INFO")}</div>
            </div>
            <img src={rightArrow} alt="give feedback"/>
        </div>
      </div>
    )
}

export default EcoProFeedbackMobileConnect