import * as actionTypes from '../../constants/ActionTypes/MyAccount';

const initialState = {
  'profileDetails': {},
  'success': false,
  'loading': false,
  'error': false
}

const UpdateCustomerProfile = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_USER_PROFILE_INIT:
          return {
            ...state,
            loading: true,
          };
        case actionTypes.UPDATE_USER_PROFILE_SUCCESS:
        return {
          ...state,
          profileDetails: action.profileDetails,
          success: true,
          loading: false,
        };
        case actionTypes.UPDATE_USER_PROFILE_ERROR:
        return {
            ...state,
          success: false,
          loading: false,
          error: action.message
        };
        default:
          return { ...state };
      }
}

export default UpdateCustomerProfile;