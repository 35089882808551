import React from "react";
import { useTranslation } from "react-i18next";

const NoRecords = ({colSpanNumber}) => {
    const { t } = useTranslation("Admintranslation");
    return (
        <tr><td colSpan={colSpanNumber} class="grid-no-records">{t("NO_RECORDS")}</td></tr>
    )
}

export default NoRecords;