import * as actionTypes from '../../../constants/ActionTypes/CSAgentPortal/CustomerDashboard';

const initialState = {
    updateOrderConsumables: {},
    loading: false,
    error: null,
    success:false
}

const UpdateOrderConsumables = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_ORDER_CONSUMABLES:
            return {
                ...state,
                loading: true
            }
        
        case actionTypes.UPDATE_ORDER_CONSUMABLES_SUCCESS:
            return {
                ...state,
                success: true,
                updateOrderConsumables: action.updateOrderConsumables,
                loading: false
            }
        
        case actionTypes.UPDATE_ORDER_CONSUMABLES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.message
            }
        case actionTypes.RESET_ORDER_CONSUMABLES:
            return {
                ...state,
                error: null,
                success:false
            }
        default:
            return {...state}
    }
}

export default UpdateOrderConsumables